/* Include */
@font-face {
    font-family: "monument_extendedregular";
    src: url("https://www.yudiz.com/codepen/photography-banner/monumentextended-regular.woff2")
      format("woff2");
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: "Extenda Trial 20 Micro";
    src: url("https://www.yudiz.com/codepen/photography-banner/Extenda-20Micro.woff2")
      format("woff2");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: "Extenda Trial 30 Deca";
    src: url("https://www.yudiz.com/codepen/photography-banner/Extenda-30Deca.woff2")
      format("woff2");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  
  /* Common CSS */
  body,
  html {
    overflow-x: unset !important;
    overflow-y: unset !important;
  }
  
  body {
    margin: 0;
    background-color: #212121 !important;
    background-image: url("https://www.yudiz.com/codepen/photography-banner/frame.png") !important;
    z-index: 10;
  }
  
  ::selection {
    background-color: rgba(241, 231, 40, 0.2);
    color: #ffffff;
  }
  
  /* Demo css -start */
  .info-section {
    height: 100svh;
    min-height: 780px;
    padding: 0 0 0 30px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    z-index: 1;
    user-select: none;
  }
  
  .info-section::before {
    content: "";
    border-radius: 197.5px 0px;
    opacity: 0.4;
    background: var(--yellow, #f1e728);
    filter: blur(162px);
    height: 35%;
    width: 55%;
    position: absolute;
    top: -40%;
    left: -66%;
    transform: translate(50%, 50%);
    z-index: -1;
  }
  
  /* left part */
  .left-part {
    padding: 20px 0 0;
    overflow: hidden;
  }
  
  .left-part h1 {
    margin: 0;
    color: #fff;
    font-family: "Extenda Trial 30 Deca";
    /* font-size: clamp(100px, 18.5vw, 282px); */
    font-size: 40px;
    line-height: 0.75;
    font-weight: normal;
    font-style: normal;
    text-transform: uppercase;
  }
  
  .left-part h1 .text {
    color: #f1e728;
    display: block;
    height: 200px;
    font-size: 200px !important;
  }
  
  .left-part h1 .d-flex {
    display: flex;
    align-items: center;
    font-size: 100px !important;
    margin-bottom: 15px;
  }
  
  .left-part h1 .char {
    transform: translateY(-515px);
    transition: transform 0.5s;
  }
  
  .typed-cursor {
    display: none !important;
  }
  
  .left-part p {
    width: 72%;
    margin: 20px 0 0;
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    line-height: 2;
    font-family: "monument_extendedregular";
    opacity: 0.8;
  }
  
  /* button */
  .book-link {
    margin: 40px 0 0;
    padding: 0;
    border: 0;
    font-size: 56px;
    line-height: 1;
    color: #f1f1f1;
    letter-spacing: 0.25px;
    text-transform: uppercase;
    font-family: "Extenda Trial 20 Micro";
    font-weight: 300;
    font-style: normal;
    display: inline-flex;
    align-items: center;
    gap: 28px;
    position: relative;
    text-decoration: none;
  }
  
  .book-link .linktext {
    position: relative;
    overflow: hidden;
    display: inline-block;
  }
  
  .book-link .linktext::before {
    position: absolute;
    content: "";
    left: 0;
    bottom: 6px;
    width: 100%;
    height: 3px;
    background-color: #ffffff;
    transform: scaleX(1);
    transition: transform 250ms ease-in-out;
    transform-origin: 0 0;
  }
  
  .book-link:hover .linktext:before {
    transform: scaleX(0);
    transform-origin: 100% 100%;
  }
  
  .book-link .arrow {
    height: 36px;
    width: 36px;
    top: -5px;
    display: inline-block;
    position: relative;
    overflow: hidden;
  }
  
  .book-link .arrow::before,
  .book-link .arrow::after {
    position: absolute;
    content: "";
    background-color: #f1e728;
    transition: all ease-in-out 0.35s;
    transform-origin: 0 0;
    border-radius: 30px;
  }
  
  .book-link .arrow::before {
    height: 2px;
    width: 100%;
    top: 0;
    right: 0;
  }
  
  .book-link .arrow::after {
    width: 2px;
    height: 100%;
    top: 0;
    right: 0;
  }
  
  .book-link:hover .arrow::before {
    width: 65%;
  }
  
  .book-link:hover .arrow::after {
    height: 65%;
  }
  
  .book-link .arrow span {
    background-color: #f1e728;
    height: 2px;
    width: 100%;
    display: inline-block;
    transform: rotate(-45deg) translate(-3px, -1px);
    transform-origin: right top;
    border-radius: 30px;
    position: relative;
    transition: all ease-in-out 0.35s;
    position: absolute;
    top: 0;
    left: 0;
  }
  
  .book-link .arrow span::before {
    background-color: #f1e728;
    content: "";
    height: 100%;
    width: 15px;
    left: -15px;
    top: 0;
    position: absolute;
  }
  
  /* right part */
  .right-part {
    background-color: transparent;
    height: 588px;
    width: 588px;
    margin: 0 0 0 auto;
    margin-right: -14px;
    display: block;
    position: relative;
    z-index: 1;
    flex-shrink: 0;
  }
  
  .right-part::before {
    content: "";
    border-radius: 197.5px 0px;
    opacity: 0.4;
    background: #f1e728;
    filter: blur(112px);
    height: 35%;
    width: 55%;
    position: absolute;
    top: 50%;
    right: 33%;
    transform: translate(50%, -50%);
    z-index: -1;
  }
  
  .right-part .d-flex {
    height: 100%;
    gap: 24px;
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    position: relative;
  }
  
  .main-grid {
    position: relative;
  }
  
  .boxxx {
    width: calc((100% / 3) - 16px);
    height: calc((100% / 3) - 16px);
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #555555;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    color: #ffffff;
    font-family: "monument_extendedregular";
    border-radius: 120px;
    position: absolute;
    animation: 30s infinite;
  }
  
  /* Box nth-child */
  .boxxx:nth-child(1) {
    left: 0;
    top: 0;
    animation-name: box-1;
  }
  
  .boxxx:nth-child(2) {
    left: calc(100% / 3);
    top: 0;
    animation-name: box-2;
  }
  
  .boxxx:nth-child(3) {
    left: calc((100% / 3) * 2);
    top: 0;
    animation-name: box-3;
  }
  
  .boxxx:nth-child(4) {
    left: 0;
    top: calc(100% / 3);
    animation-name: box-4;
  }
  
  .boxxx:nth-child(5) {
    left: calc((100% / 3) * 2);
    top: calc(100% / 3);
    animation-name: box-5;
  }
  
  .boxxx:nth-child(6) {
    left: 0;
    top: calc((100% / 3) * 2);
    animation-name: box-6;
  }
  
  .boxxx:nth-child(7) {
    left: calc(100% / 3);
    top: calc((100% / 3) * 2);
    animation-name: box-7;
  }
  
  .boxxx:nth-child(8) {
    left: calc((100% / 3) * 2);
    top: calc((100% / 3) * 2);
    animation-name: box-8;
  }
  
  /* 1st Child */
  @keyframes box-1 {
    0%,
    90%,
    100% {
      left: 0;
      top: 0;
    }
  
    2.5%,
    12.5% {
      left: calc(100% / 3);
    }
  
    15%,
    25% {
      left: calc((100% / 3) * 2);
      top: 0;
    }
  
    27.5% {
      left: calc((100% / 3) * 2);
      top: calc(100% / 3);
    }
  
    29.5% {
      left: calc((100% / 3) * 2);
      top: calc(100% / 3);
      width: calc((100% / 3) - 16px);
    }
  
    31.5%,
    33.5% {
      left: calc(100% / 3);
      width: 100%;
    }
  
    35.5%,
    37.5% {
      left: calc((100% / 3) * 2);
      top: calc(100% / 3);
      width: calc((100% / 3) - 16px);
    }
  
    40%,
    50% {
      left: calc((100% / 3) * 2);
      top: calc((100% / 3) * 2);
    }
  
    52.5%,
    62.5% {
      left: calc(100% / 3);
    }
  
    65%,
    75% {
      left: 0;
      top: calc((100% / 3) * 2);
    }
  
    77.5%,
    87.5% {
      top: calc(100% / 3);
    }
  }
  
  @-webkit-keyframes box-1 {
    0%,
    90%,
    100% {
      left: 0;
      top: 0;
    }
  
    2.5%,
    12.5% {
      left: calc(100% / 3);
    }
  
    15%,
    25% {
      left: calc((100% / 3) * 2);
      top: 0;
    }
  
    27.5% {
      left: calc((100% / 3) * 2);
      top: calc(100% / 3);
    }
  
    29.5% {
      left: calc((100% / 3) * 2);
      top: calc(100% / 3);
      width: calc((100% / 3) - 16px);
    }
  
    31.5%,
    33.5% {
      left: calc(100% / 3);
      width: 100%;
    }
  
    35.5%,
    37.5% {
      left: calc((100% / 3) * 2);
      top: calc(100% / 3);
      width: calc((100% / 3) - 16px);
    }
  
    40%,
    50% {
      left: calc((100% / 3) * 2);
      top: calc((100% / 3) * 2);
    }
  
    52.5%,
    62.5% {
      left: calc(100% / 3);
    }
  
    65%,
    75% {
      left: 0;
      top: calc((100% / 3) * 2);
    }
  
    77.5%,
    87.5% {
      top: calc(100% / 3);
    }
  }
  
  @keyframes box-2 {
    0%,
    90%,
    100% {
      left: calc(100% / 3);
    }
  
    2.5%,
    12.5% {
      left: calc((100% / 3) * 2);
      top: 0;
    }
  
    15%,
    17% {
      left: calc((100% / 3) * 2);
      top: calc(100% / 3);
      width: calc((100% / 3) - 16px);
    }
  
    19%,
    21% {
      left: calc(100% / 3);
      top: calc(100% / 3);
      width: 100%;
    }
  
    23%,
    25% {
      left: calc((100% / 3) * 2);
      top: calc(100% / 3);
      width: calc((100% / 3) - 16px);
    }
  
    27.5%,
    37.5% {
      left: calc((100% / 3) * 2);
      top: calc((100% / 3) * 2);
    }
  
    40%,
    50% {
      left: calc(100% / 3);
      top: calc((100% / 3) * 2);
    }
  
    52.5%,
    62.5% {
      left: 0;
      top: calc((100% / 3) * 2);
    }
  
    65%,
    75% {
      left: 0;
      top: calc(100% / 3);
    }
  
    77.5%,
    87.5% {
      left: 0;
      top: 0;
    }
  }
  
  @-webkit-keyframes box-2 {
    0%,
    90%,
    100% {
      left: calc(100% / 3);
    }
  
    2.5%,
    12.5% {
      left: calc((100% / 3) * 2);
      top: 0;
    }
  
    15%,
    17% {
      left: calc((100% / 3) * 2);
      top: calc(100% / 3);
      width: calc((100% / 3) - 16px);
    }
  
    19%,
    21% {
      left: calc(100% / 3);
      top: calc(100% / 3);
      width: 100%;
    }
  
    23%,
    25% {
      left: calc((100% / 3) * 2);
      top: calc(100% / 3);
      width: calc((100% / 3) - 16px);
    }
  
    27.5%,
    37.5% {
      left: calc((100% / 3) * 2);
      top: calc((100% / 3) * 2);
    }
  
    40%,
    50% {
      left: calc(100% / 3);
      top: calc((100% / 3) * 2);
    }
  
    52.5%,
    62.5% {
      left: 0;
      top: calc((100% / 3) * 2);
    }
  
    65%,
    75% {
      left: 0;
      top: calc(100% / 3);
    }
  
    77.5%,
    87.5% {
      left: 0;
      top: 0;
    }
  }
  
  @keyframes box-3 {
    0%,
    90%,
    100% {
      left: calc((100% / 3) * 2);
    }
  
    2.5%,
    12.5% {
      left: calc((100% / 3) * 2);
      top: calc(100% / 3);
    }
  
    4.5%,
    10.5% {
      left: calc((100% / 3) * 2);
      top: calc(100% / 3);
      width: calc((100% / 3) - 16px);
    }
  
    6.5%,
    8.5% {
      left: calc(100% / 3);
      top: calc(100% / 3);
      width: 100%;
    }
  
    15%,
    25% {
      left: calc((100% / 3) * 2);
      top: calc((100% / 3) * 2);
    }
  
    27.5%,
    37.5% {
      left: calc(100% / 3);
      top: calc((100% / 3) * 2);
    }
  
    40%,
    50% {
      left: 0;
      top: calc((100% / 3) * 2);
    }
  
    52.5%,
    62.5% {
      left: 0;
      top: calc(100% / 3);
    }
  
    65%,
    75% {
      left: 0;
      top: 0;
    }
  
    77.5%,
    87.5% {
      left: calc(100% / 3);
      top: 0;
    }
  }
  
  @-webkit-keyframes box-3 {
    0%,
    90%,
    100% {
      left: calc((100% / 3) * 2);
    }
  
    2.5%,
    12.5% {
      left: calc((100% / 3) * 2);
      top: calc(100% / 3);
    }
  
    4.5%,
    10.5% {
      left: calc((100% / 3) * 2);
      top: calc(100% / 3);
      width: calc((100% / 3) - 16px);
    }
  
    6.5%,
    8.5% {
      left: calc(100% / 3);
      top: calc(100% / 3);
      width: 100%;
    }
  
    15%,
    25% {
      left: calc((100% / 3) * 2);
      top: calc((100% / 3) * 2);
    }
  
    27.5%,
    37.5% {
      left: calc(100% / 3);
      top: calc((100% / 3) * 2);
    }
  
    40%,
    50% {
      left: 0;
      top: calc((100% / 3) * 2);
    }
  
    52.5%,
    62.5% {
      left: 0;
      top: calc(100% / 3);
    }
  
    65%,
    75% {
      left: 0;
      top: 0;
    }
  
    77.5%,
    87.5% {
      left: calc(100% / 3);
      top: 0;
    }
  }
  
  @keyframes box-4 {
    0%,
    90%,
    100% {
      top: calc(100% / 3);
    }
  
    2.5%,
    12.5% {
      left: 0;
      top: 0;
    }
  
    15%,
    25% {
      left: calc(100% / 3);
      top: 0;
    }
  
    27.5%,
    37.5% {
      left: calc((100% / 3) * 2);
      top: 0;
    }
  
    40%,
    42%,
    48%,
    50% {
      left: calc((100% / 3) * 2);
      top: calc(100% / 3);
      width: calc((100% / 3) - 16px);
    }
  
    44%,
    46% {
      left: calc(100% / 3);
      top: calc(100% / 3);
      width: 100%;
    }
  
    52.5%,
    62.5% {
      left: calc((100% / 3) * 2);
      top: calc((100% / 3) * 2);
    }
  
    65%,
    75% {
      left: calc(100% / 3);
      top: calc((100% / 3) * 2);
    }
  
    77.5%,
    87.5% {
      left: 0;
      top: calc((100% / 3) * 2);
    }
  }
  
  @-webkit-keyframes box-4 {
    0%,
    90%,
    100% {
      top: calc(100% / 3);
    }
  
    2.5%,
    12.5% {
      left: 0;
      top: 0;
    }
  
    15%,
    25% {
      left: calc(100% / 3);
      top: 0;
    }
  
    27.5%,
    37.5% {
      left: calc((100% / 3) * 2);
      top: 0;
    }
  
    40%,
    42%,
    48%,
    50% {
      left: calc((100% / 3) * 2);
      top: calc(100% / 3);
      width: calc((100% / 3) - 16px);
    }
  
    44%,
    46% {
      left: calc(100% / 3);
      top: calc(100% / 3);
      width: 100%;
    }
  
    52.5%,
    62.5% {
      left: calc((100% / 3) * 2);
      top: calc((100% / 3) * 2);
    }
  
    65%,
    75% {
      left: calc(100% / 3);
      top: calc((100% / 3) * 2);
    }
  
    77.5%,
    87.5% {
      left: 0;
      top: calc((100% / 3) * 2);
    }
  }
  
  @keyframes box-5 {
    0%,
    90%,
    92%,
    98%,
    100% {
      left: calc((100% / 3) * 2);
      top: calc(100% / 3);
      width: calc((100% / 3) - 16px);
    }
  
    2.5%,
    12.5% {
      left: calc((100% / 3) * 2);
      top: calc((100% / 3) * 2);
    }
  
    15%,
    25% {
      left: calc(100% / 3);
      top: calc((100% / 3) * 2);
    }
  
    27.5%,
    37.5% {
      left: 0;
      top: calc((100% / 3) * 2);
    }
  
    40%,
    50% {
      left: 0;
      top: calc(100% / 3);
    }
  
    52.5%,
    62.5% {
      left: 0;
      top: 0;
    }
  
    65%,
    75% {
      left: calc(100% / 3);
      top: 0;
    }
  
    77.5%,
    87.5% {
      left: calc((100% / 3) * 2);
      top: 0;
    }
  
    94%,
    96% {
      left: calc(100% / 3);
      top: calc(100% / 3);
      width: 100%;
    }
  }
  
  @-webkit-keyframes box-5 {
    0%,
    90%,
    92%,
    98%,
    100% {
      left: calc((100% / 3) * 2);
      top: calc(100% / 3);
      width: calc((100% / 3) - 16px);
    }
  
    2.5%,
    12.5% {
      left: calc((100% / 3) * 2);
      top: calc((100% / 3) * 2);
    }
  
    15%,
    25% {
      left: calc(100% / 3);
      top: calc((100% / 3) * 2);
    }
  
    27.5%,
    37.5% {
      left: 0;
      top: calc((100% / 3) * 2);
    }
  
    40%,
    50% {
      left: 0;
      top: calc(100% / 3);
    }
  
    52.5%,
    62.5% {
      left: 0;
      top: 0;
    }
  
    65%,
    75% {
      left: calc(100% / 3);
      top: 0;
    }
  
    77.5%,
    87.5% {
      left: calc((100% / 3) * 2);
      top: 0;
    }
  
    94%,
    96% {
      left: calc(100% / 3);
      top: calc(100% / 3);
      width: 100%;
    }
  }
  
  @keyframes box-6 {
    0%,
    90%,
    100% {
      left: 0;
      top: calc((100% / 3) * 2);
    }
  
    2.5%,
    12.5% {
      left: 0;
      top: calc(100% / 3);
    }
  
    15%,
    25% {
      left: 0;
      top: 0;
    }
  
    27.5%,
    37.5% {
      left: calc(100% / 3);
      top: 0;
    }
  
    40%,
    50% {
      left: calc((100% / 3) * 2);
      top: 0;
    }
  
    52.5%,
    54.5%,
    60.5%,
    62.5% {
      left: calc((100% / 3) * 2);
      top: calc(100% / 3);
      width: calc((100% / 3) - 16px);
    }
  
    56.5%,
    58.5% {
      left: calc(100% / 3);
      top: calc(100% / 3);
      width: 100%;
    }
  
    65%,
    75% {
      left: calc((100% / 3) * 2);
      top: calc((100% / 3) * 2);
    }
  
    77.5%,
    87.5% {
      left: calc(100% / 3);
      top: calc((100% / 3) * 2);
    }
  }
  
  @-webkit-keyframes box-6 {
    0%,
    90%,
    100% {
      left: 0;
      top: calc((100% / 3) * 2);
    }
  
    2.5%,
    12.5% {
      left: 0;
      top: calc(100% / 3);
    }
  
    15%,
    25% {
      left: 0;
      top: 0;
    }
  
    27.5%,
    37.5% {
      left: calc(100% / 3);
      top: 0;
    }
  
    40%,
    50% {
      left: calc((100% / 3) * 2);
      top: 0;
    }
  
    52.5%,
    54.5%,
    60.5%,
    62.5% {
      left: calc((100% / 3) * 2);
      top: calc(100% / 3);
      width: calc((100% / 3) - 16px);
    }
  
    56.5%,
    58.5% {
      left: calc(100% / 3);
      top: calc(100% / 3);
      width: 100%;
    }
  
    65%,
    75% {
      left: calc((100% / 3) * 2);
      top: calc((100% / 3) * 2);
    }
  
    77.5%,
    87.5% {
      left: calc(100% / 3);
      top: calc((100% / 3) * 2);
    }
  }
  
  @keyframes box-7 {
    0%,
    90%,
    100% {
      left: calc(100% / 3);
      top: calc((100% / 3) * 2);
    }
  
    2.5%,
    12.5% {
      left: 0;
      top: calc((100% / 3) * 2);
    }
  
    15%,
    25% {
      left: 0;
      top: calc(100% / 3);
    }
  
    27.5%,
    37.5% {
      left: 0;
      top: 0;
    }
  
    40%,
    50% {
      left: calc(100% / 3);
      top: 0;
    }
  
    52.5%,
    62.5% {
      left: calc((100% / 3) * 2);
      top: 0;
    }
  
    65%,
    67%,
    73%,
    75% {
      left: calc((100% / 3) * 2);
      top: calc(100% / 3);
      width: calc((100% / 3) - 16px);
    }
  
    69%,
    71% {
      left: calc(100% / 3);
      top: calc(100% / 3);
      width: 100%;
    }
  
    77.5%,
    87.5% {
      left: calc((100% / 3) * 2);
      top: calc((100% / 3) * 2);
    }
  }
  
  @-webkit-keyframes box-7 {
    0%,
    90%,
    100% {
      left: calc(100% / 3);
      top: calc((100% / 3) * 2);
    }
  
    2.5%,
    12.5% {
      left: 0;
      top: calc((100% / 3) * 2);
    }
  
    15%,
    25% {
      left: 0;
      top: calc(100% / 3);
    }
  
    27.5%,
    37.5% {
      left: 0;
      top: 0;
    }
  
    40%,
    50% {
      left: calc(100% / 3);
      top: 0;
    }
  
    52.5%,
    62.5% {
      left: calc((100% / 3) * 2);
      top: 0;
    }
  
    65%,
    67%,
    73%,
    75% {
      left: calc((100% / 3) * 2);
      top: calc(100% / 3);
      width: calc((100% / 3) - 16px);
    }
  
    69%,
    71% {
      left: calc(100% / 3);
      top: calc(100% / 3);
      width: 100%;
    }
  
    77.5%,
    87.5% {
      left: calc((100% / 3) * 2);
      top: calc((100% / 3) * 2);
    }
  }
  
  @keyframes box-8 {
    0%,
    90%,
    100% {
      left: calc((100% / 3) * 2);
      top: calc((100% / 3) * 2);
    }
  
    2.5%,
    12.5% {
      left: calc(100% / 3);
      top: calc((100% / 3) * 2);
    }
  
    15%,
    25% {
      left: 0;
      top: calc((100% / 3) * 2);
    }
  
    27.5%,
    37.5% {
      left: 0;
      top: calc(100% / 3);
    }
  
    40%,
    50% {
      left: 0;
      top: 0;
    }
  
    52.5%,
    62.5% {
      left: calc(100% / 3);
      top: 0;
    }
  
    65%,
    75% {
      left: calc((100% / 3) * 2);
      top: 0;
    }
  
    77.5%,
    79.5%,
    85.5%,
    87.5% {
      left: calc((100% / 3) * 2);
      top: calc(100% / 3);
      width: calc((100% / 3) - 16px);
      border-radius: 100%;
    }
  
    81.5%,
    83.5% {
      left: calc(100% / 3);
      top: calc(100% / 3);
      width: 100%;
    }
  }
  
  @-webkit-keyframes box-8 {
    0%,
    90%,
    100% {
      left: calc((100% / 3) * 2);
      top: calc((100% / 3) * 2);
    }
  
    2.5%,
    12.5% {
      left: calc(100% / 3);
      top: calc((100% / 3) * 2);
    }
  
    15%,
    25% {
      left: 0;
      top: calc((100% / 3) * 2);
    }
  
    27.5%,
    37.5% {
      left: 0;
      top: calc(100% / 3);
    }
  
    40%,
    50% {
      left: 0;
      top: 0;
    }
  
    52.5%,
    62.5% {
      left: calc(100% / 3);
      top: 0;
    }
  
    65%,
    75% {
      left: calc((100% / 3) * 2);
      top: 0;
    }
  
    77.5%,
    79.5%,
    85.5%,
    87.5% {
      left: calc((100% / 3) * 2);
      top: calc(100% / 3);
      width: calc((100% / 3) - 16px);
      border-radius: 100%;
    }
  
    81.5%,
    83.5% {
      left: calc(100% / 3);
      top: calc(100% / 3);
      width: 100%;
    }
  }
  
  /* Box Text */
  .boxxx span {
    position: absolute;
    display: block;
    opacity: 0.8;
    z-index: 5;
  }
  
  .boxxx:nth-child(1) span {
    top: 43%;
    left: -27px;
    transform: translateY(-50%);
  }
  
  .boxxx:nth-child(3) span {
    left: -10px;
    bottom: 2px;
  }
  
  .boxxx:nth-child(4) span {
    top: 50%;
    left: 55%;
    transform: translate(-50%, -50%);
  }
  
  .boxxx:nth-child(8) span {
    top: 50%;
    transform: translate(-50%, -50%);
    left: 15%;
  }
  
  /* Box Background-Image */
  .boxxx .bg-img {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 120px;
    overflow: hidden;
  }
  
  .boxxx .bg-img,
  .boxxx .bg-img img {
    height: 100%;
    width: 100%;
  }
  
  .boxxx .bg-img .imgg {
    max-width: 100%;
    object-fit: cover;
    object-position: center center;
  }
  
  /* Curve Line */
  .bg-line {
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    height: 85px;
    z-index: -1;
    overflow: hidden;
    display: flex;
    display: -webkit-flex;
    white-space: nowrap;
  }
  
  .bg-line img {
    position: relative;
    flex-shrink: 0;
    -webkit-flex-shrink: 0;
    animation: 26s linear infinite;
  }
  
  .bg-line img:nth-child(1) {
    animation-name: first-text;
  }
  
  .bg-line img:nth-child(2) {
    animation-name: second-text;
  }
  
  @keyframes first-text {
    50% {
      transform: translateX(-100%);
      opacity: 1;
    }
  
    50.05% {
      opacity: 0;
    }
  
    50.1% {
      transform: translateX(100%);
      opacity: 1;
    }
  
    100% {
      transform: translateX(0%);
    }
  }
  
  @-webkit-keyframes first-text {
    50% {
      transform: translateX(-100%);
      opacity: 1;
    }
  
    50.05% {
      opacity: 0;
    }
  
    50.1% {
      transform: translateX(100%);
      opacity: 1;
    }
  
    100% {
      transform: translateX(0%);
    }
  }
  
  @keyframes second-text {
    50% {
      transform: translateX(-100%);
    }
  
    100% {
      transform: translateX(-200%);
    }
  
    0% {
      transform: translateX(0%);
    }
  }
  
  @-webkit-keyframes second-text {
    50% {
      transform: translateX(-100%);
    }
  
    100% {
      transform: translateX(-200%);
    }
  
    0% {
      transform: translateX(0%);
    }
  }
  
  /* Dash Circle */
  .bg-dash-circle {
    position: absolute;
    bottom: -28px;
    right: -13px;
    z-index: -1;
    width: 180px;
    aspect-ratio: 1/1;
  }
  
  .bg-dash-circle img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center center;
    animation: cirlce-rotate 18s linear infinite;
  }
  
  @keyframes cirlce-rotate {
    0% {
      transform: rotate(0);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  
  @-webkit-keyframes cirlce-rotate {
    0% {
      transform: rotate(0);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  
  /* Circle Line */
  .bg-circle-h-line {
    bottom: 42px;
    left: -68px;
    z-index: 1;
    width: 181px;
    height: 111px;
    position: relative;
  }
  
  .bg-circle-h-line img {
    width: 100%;
    max-width: 100%;
    height: auto;
    position: absolute;
    left: 0;
  }
  
  .bg-circle-h-line img:nth-child(1) {
    top: 0;
    animation: top-ring-move 2.5s linear infinite;
  }
  
  .bg-circle-h-line img:nth-child(2) {
    top: 50%;
    transform: translateY(-50%);
  }
  
  .bg-circle-h-line img:nth-child(3) {
    bottom: 0;
    animation: bottom-ring-move 2.5s linear infinite;
  }
  
  @keyframes top-ring-move {
    0% {
      transform: translateY(0);
    }
  
    50% {
      transform: translateY(5px);
    }
  
    100% {
      transform: translateY(0);
    }
  }
  
  @-webkit-keyframes top-ring-move {
    0% {
      transform: translateY(0);
    }
  
    50% {
      transform: translateY(5px);
    }
  
    100% {
      transform: translateY(0);
    }
  }
  
  @keyframes bottom-ring-move {
    0% {
      transform: translateY(0);
    }
  
    50% {
      transform: translateY(-5px);
    }
  
    100% {
      transform: translateY(0);
    }
  }
  
  @-webkit-keyframes bottom-ring-move {
    0% {
      transform: translateY(0);
    }
  
    50% {
      transform: translateY(-5px);
    }
  
    100% {
      transform: translateY(0);
    }
  }
  
  /*=== Responsive ===*/
  @media screen and (min-width: 1500px) {
    .info-section {
      padding-left: 120px;
    }
  }
  
  @media screen and (min-width: 1400px) {
    .info-section {
      padding-left: 100px;
    }
  }
  
  @media screen and (max-width: 1199px) {
    .bg-line {
      height: 68px;
    }
  
    .right-part {
      height: 400px;
      width: 400px;
    }
  
    .d-flex {
      gap: 20px;
    }
  
    .main-grid::before,
    .boxxx {
      height: 118px;
    }
  
    .boxxx {
      width: 118px;
      font-size: 12px;
    }
  
    .left-part p {
      font-size: 14px;
      line-height: 1.8;
      width: 85%;
    }
  
    .left-part h1 .text {
      height: 132px;
    }
  
    .bg-dash-circle {
      width: 130px;
    }
  
    .bg-circle-h-line {
      width: 156px;
      height: 92px;
    }
  
    .book-link {
      font-size: 48px;
      gap: 24px;
    }
  
    .book-link .arrow {
      height: 28px;
      width: 28px;
    }
  }
  
  @media screen and (max-width: 767px) {
    .info-section {
      display: block;
      padding: 0;
    }
  
    .bg-line {
      height: 52px;
    }
  
    .left-part {
      padding: 40px 16px 60px;
    }
  
    .right-part {
      height: 334px;
      width: 334px;
    }
  
    .left-part h1 .text {
      height: 88px;
    }
  
    .left-part p {
      font-size: 12px;
      width: 96%;
    }
  
    .main-grid::before,
    .boxxx {
      height: 96px;
    }
  
    .boxxx {
      width: 96px;
      font-size: 10px;
    }
  
    .book-link .arrow {
      height: 24px;
      width: 24px;
    }
  
    .book-link {
      font-size: 42px;
      gap: 20px;
      margin-top: 24px;
    }
  
    .bg-dash-circle {
      width: 80px;
    }
  
    .bg-circle-h-line {
      width: 126px;
      height: 65px;
    }
  }
  