*{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
}

body{
 background:  #222;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #434343, #000000);  /*Chrome 10-25, Safari 5.1-6*/
background: linear-gradient(to right, #434343, #000000); /*W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

a {
    color: white !important;
    text-decoration: none !important;
}

a:hover{
    padding: 0.5rem 1rem;
    font-family: var(--font-family);
    color: #fff;
    font-weight: 500;
    font-size: 18px;
    border-radius: 5px;
    border: 0;
    text-decoration: none !important;
    /* outline: none; */
    cursor: pointer;
}

.gradieant__bg {
  /* background: #000000;  fallback for old browsers */
/* background: -webkit-linear-gradient(to right, #434343, #000000);  Chrome 10-25, Safari 5.1-6
background: linear-gradient(to right, #434343, #000000); W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}

.gradient__text{
    background: linear-gradient( 99.6deg,  rgb(93, 93, 94) 10.6%, rgb(131, 129, 131) 32.9%, rgb(198, 197, 197) 52.7%, rgb(210, 219, 229) 72.8%, rgba(185,205,227,1) 81.1%, rgba(154,180,212,1) 102.4% );
    /* linear-gradient( 99.6deg,  rgba(112,128,152,1) 10.6%, rgba(242,227,234,1) 32.9%, rgba(234,202,213,1) 52.7%, rgba(220,227,239,1) 72.8%, rgba(185,205,227,1) 81.1%, rgba(154,180,212,1) 102.4% ); */
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.section__padding {
    padding: 4rem 6rem;
}

.section__margin {
    margin: 4rem 6rem;
}

.scale-up-center {
	-webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}


@-webkit-keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  
  @keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  
  @media screen and (max-width: 700px){

        .section__padding {
             padding: 4rem;
        }

        .section__margin {
            margin: 4rem;
        }
  }

  @media screen and (max-width: 550px){

    .section__padding {
         padding: 4rem 2rem;
    }

    .section__margin {
        margin: 4rem 2rem;
    }
}


/* Input styles */
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import url("https://fonts.googleapis.com/css?family=Ubuntu:300,300i,400,400i,500,500i,700,700i");
*, *:before, *:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body,
#app {
  /* line-height: 1.3; */
  min-height: 100vh;
  color: #333333;
  font-family: "Ubuntu", sans-serif;
}

a:hover {
  cursor: pointer;
}

.view-height {
  min-height: 100vh;
}

.p-all {
  padding: 20px;
}

.p-top {
  padding-top: 20px;
}

.p-left {
  padding-left: 20px;
}

.p-right {
  padding-right: 20px;
}

.p-bottom {
  padding-bottom: 20px;
}

.m-all {
  margin: 20px;
}

.m-top {
  margin-top: 20px;
}

.m-left {
  margin-left: 20px;
}

.m-right {
  margin-right: 20px;
}

.m-bottom {
  margin-bottom: 20px;
}

/* Amazing flex utility class */
[class*=flex] {
  display: flex;
}
[class*=flex][class*=-direction-row] {
  flex-direction: row;
}
[class*=flex][class*=-direction-column] {
  flex-direction: column;
}
[class*=flex][class*=-align-start] {
  align-items: flex-start;
}
[class*=flex][class*=-align-center] {
  align-items: center;
}
[class*=flex][class*=-align-end] {
  align-items: flex-end;
}
[class*=flex][class*=-justify-center] {
  justify-content: center;
}
[class*=flex][class*=-space-between] {
  justify-content: space-between;
}
[class*=flex][class*=-wrap] {
  flex-wrap: wrap;
}

.foresight-logo {
  max-width: 150px;
  transition: max-width 0.2s ease-in;
}
@media (min-width: 601px) {
  .foresight-logo {
    max-width: 200px;
  }
}
@media (min-width: 993px) {
  .foresight-logo {
    max-width: 250px;
  }
}

.foresight-search-bar {
  width: 100%;
  max-width: 500px;
  position: relative;
}
.foresight-search-bar input {
  width: 100%;
  height: 50px;
  outline: none;
  color: inherit;
  display: block;
  border-radius: 25px;
  font-family: inherit;
  font-weight: lighter;
  padding: 0 25px 0 42px;
  transition: all 0.2s ease-in;
  border: solid 1px transparent;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 9px rgba(0, 0, 0, 0.1);
}
.foresight-search-bar input:focus {
  border-color: #a9dee7;
  box-shadow: 0 0 10px -1px #a9dee7;
}
.foresight-search-bar input:focus ~ i {
  color: #f15d34;
  transform: rotate(90deg);
}
.foresight-search-bar i {
  top: 13px;
  left: 13px;
  position: absolute;
  color: #aaaaaa;
  transition: all 0.2s ease-in;
}
.foresight-search-bar ::-webkit-input-placeholder {
  color: #aaaaaa;
}
.foresight-search-bar ::-moz-placeholder {
  color: #aaaaaa;
}
.foresight-search-bar :-ms-input-placeholder {
  color: #aaaaaa;
}
.foresight-search-bar :-moz-placeholder {
  color: #aaaaaa;
}

.btn {
  width: 100%;
  margin: 5px;
  color: white;
  height: 50px;
  outline: none;
  /* padding: 0 25px; */
  /* border: solid 2px; */
  border-radius: 25px;
  font-family: inherit;
  transition: all 0.2s ease-in;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 9px rgba(0, 0, 0, 0.1);
}
@media (min-width: 360px) {
  .btn {
    width: auto;
    margin: none;
  }
}
.btn.primary {
  border-color: #f15d34;
  background-color: #f15d34;
}
.btn.primary:hover {
  color: #f15d34;
  border-color: #f15d34;
}
.btn.accent {
  border-color: #a9dee7;
  background-color: #a9dee7;
}
.btn.accent:hover {
  color: #a9dee7;
  border-color: #a9dee7;
}
.btn.grey {
  border-color: #aaaaaa;
  background-color: #aaaaaa;
}
.btn.grey:hover {
  color: #aaaaaa;
  border-color: #aaaaaa;
}
.btn.dark-grey {
  border-color: #333333;
  background-color: #333333;
}
.btn.dark-grey:hover {
  color: #333333;
  border-color: #333333;
}
.btn:hover {
  cursor: pointer;
  background-color: transparent;
}
.btn:active {
  box-shadow: none;
}

.fixed-header {
  top: 0;
  left: 0;
  right: 0;
  padding: 20px;
  position: fixed;
}
.fixed-header .profile-photo {
  height: 40px;
  margin-left: 10px;
  border-radius: 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 9px rgba(0, 0, 0, 0.1);
}

.text-center {
  text-align: center;
}

.text-grey {
  color: #aaaaaa;
}

.font-lighter {
  font-weight: lighter;
}


/* DASHBOARD */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  --main-bg-color: black;
  --secondary-bg-color: #CE5959;
}

.contentD {
  font-family: "Poppins", sans-serif;
  min-height: 100vh;
  background: var(--main-bg-color);
  background: linear-gradient(
    to right top,
    var(--main-bg-color),
    var(--secondary-bg-color)
  );
  display: flex;
  align-items: center;
  justify-content: center;
}

.containerdashb {
  background-color: rgba(255, 255, 255, 0.9); /* slightly transparent fallback for Firefox */
  min-height: 80vh;
  width: 100%;
  border-radius: 2rem;
  z-index: 2;
  display: flex;
}

/* if backdrop support: very transparent and blurred */
@supports ((backdrop-filter: blur(2rem)) or (-webkit-backdrop-filter: blur(2rem))) {
  .containerdashb {
    background: linear-gradient(
    to right bottom,
    rgba(255, 255, 255, 0.7),
    rgba(255, 255, 255, 0.3)
  );
    -webkit-backdrop-filter: blur(2rem);
    backdrop-filter: blur(2rem);
  }
}

.dashboard {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  background: linear-gradient(
    to right bottom,
    rgba(255, 255, 255, 0.7),
    rgba(255, 255, 255, 0.3)
  );
  border-radius: 2rem;
}
.link {
  display: flex;
  padding: 1rem;
  margin: 1rem 0rem;
  align-items: center;
}
.link img {
  transform: scale(2);
}
.user,
.link h2,
.pro,
.progress {
  display: none;
}
.link h2:hover{
  background-color: white;
  color: orange;
}
.games {
  flex: 3;
  margin: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.status {
  margin-bottom: 1rem;
}

.status input {
  background: linear-gradient(
    to right bottom,
    rgba(255, 255, 255, 0.7),
    rgba(255, 255, 255, 0.3)
  );
  border: none;
  width: 50%;
  padding: 0.5rem;
  border-radius: 2rem;
}

.card_dashboard {
  display: flex;
  flex-direction: column;
  background: linear-gradient(
    to left top,
    rgba(255, 255, 255, 0.8),
    rgba(255, 255, 255, 0.5)
  );
  border-radius: 1rem;
  margin: 2rem 0rem;
  padding: 2rem;
  box-shadow: 6px 6px 20px rgba(122, 122, 122, 0.212);
  justify-content: space-between;
}
.card_dashboard img {
  width: 105px;
  height: 105px;
  border-radius: 1rem;
}
.card-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.percentage {
  font-weight: bold;
  background: linear-gradient(to right top,
    var(--main-bg-color),
    var(--secondary-bg-color));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

h1 {
  color: grey;
  font-weight: 600;
  font-size: 2rem;
  opacity: 0.8;
}
h2,
p {
  /* color: grey; */
  font-weight: 500;
  opacity: 0.8;
}

h3 {
  color: #426696;
  padding-top: 15px;
  font-weight: 600;
  opacity: 0.8;
}

@media screen and (min-width: 640px) {
   .link img {
  transform: scale(1)
}
   .link {
    margin: 2rem 0rem;
    padding: 1rem 5rem;
  }
}

@media screen and (min-width: 1024px){
  .containerdashb {
    width: 98%;
  }
 
  .circle1,
  .circle2 {
    background: white;
    background: linear-gradient(
      to right bottom,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.3)
    );
    height: 20rem;
    width: 20rem;
    position: absolute;
    border-radius: 50%;
  }
  .circle1 {
    top: 5%;
    right: 15%;
  }
  .circle2 {
    bottom: 5%;
    left: 25%;
  }
  .dashboard {
    justify-content: space-evenly;
  }
  .user {
    display: block;
  }
  .user img {
    border-radius: 50%;
  }
 
  .link h2 {
    display: block;
    padding: 0 2rem;
  }
  .pro {
    display: block;
    background: linear-gradient(to right top,
    var(--main-bg-color),
    var(--secondary-bg-color));
    border-radius: 2rem;
    color: white;
    padding: 1rem;
    position: relative;
  }
  .pro img {
    position: absolute;
    top: -10%;
    right: 10%;
  }
  .pro h2 {
    width: 40%;
    color: white;
    font-weight: 600;
  }
  .games {
    margin: 5rem;
  }
  h1 {
    font-size: 3rem;
  }
  .status {
    margin-bottom: 3rem;
  }
  .card_dashboard {
    flex-direction: row;
  }
  .card_dashboard img {
    margin-right: 0.5rem;
  }
  .progress {
    display: block;
    background: linear-gradient(to right top,
    var(--main-bg-color),
    var(--secondary-bg-color));
    width: 100%;
    height: 25%;
    border-radius: 1rem;
    position: relative;
    overflow: hidden;
  }
  .progress::after {
    content: "";
    width: 100%;
    height: 100%;
    background: rgb(236, 236, 236);
    position: absolute;
    left: 60%;
  }
}

/* END DASHBOARD */

/* ANIMATIONS */
.bounce-in-top {
	-webkit-animation: bounce-in-top 1.1s both;
	        animation: bounce-in-top 1.1s both;
}
/* ----------------------------------------------
 * Generated by Animista on 2022-11-3 16:49:59
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation bounce-in-top
 * ----------------------------------------
 */
 @-webkit-keyframes bounce-in-top {
  0% {
    -webkit-transform: translateY(-500px);
            transform: translateY(-500px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    -webkit-transform: translateY(-65px);
            transform: translateY(-65px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  72% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  81% {
    -webkit-transform: translateY(-28px);
            transform: translateY(-28px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  90% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  95% {
    -webkit-transform: translateY(-8px);
            transform: translateY(-8px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}
@keyframes bounce-in-top {
  0% {
    -webkit-transform: translateY(-500px);
            transform: translateY(-500px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    -webkit-transform: translateY(-65px);
            transform: translateY(-65px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  72% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  81% {
    -webkit-transform: translateY(-28px);
            transform: translateY(-28px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  90% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  95% {
    -webkit-transform: translateY(-8px);
            transform: translateY(-8px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}

.jello-horizontal {
	-webkit-animation: jello-horizontal 0.9s infinite both;
	        animation: jello-horizontal 0.9s infinite both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-11-7 10:26:24
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation jello-horizontal
 * ----------------------------------------
 */
@-webkit-keyframes jello-horizontal {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
            transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
            transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
            transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
            transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
            transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
}
@keyframes jello-horizontal {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
            transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
            transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
            transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
            transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
            transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
}

.tracking-in-expand {
	-webkit-animation: tracking-in-expand 1s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
	        animation: tracking-in-expand 1s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
}
/* ----------------------------------------------
 * Generated by Animista on 2022-11-7 14:32:36
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation tracking-in-expand
 * ----------------------------------------
 */
 @-webkit-keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
@keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}


.dashboardlinks:hover{
   color: white;
   background-color: grey;
}
/* PRODUCTS */
@import url('https://fonts.googleapis.com/css?family=Muli:400,400i,700,700i');


.containr{
  background:-moz-radial-gradient(circle at 50% 50%, rgba(21, 0, 80, 1) 1%, rgba(0, 0, 0, 1) 100%);

  /* safari 5.1+,chrome 10+ */
  background:-webkit-radial-gradient(circle at 50% 50%, rgba(21, 0, 80, 1) 1%, rgba(0, 0, 0, 1) 100%);

  /* opera 11.10+ */
  background:-o-radial-gradient(circle at 50% 50%, rgba(21, 0, 80, 1) 1%, rgba(0, 0, 0, 1) 100%);

  /* ie 10+ */
  background:-ms-radial-gradient(circle at 50% 50%, rgba(21, 0, 80, 1) 1%, rgba(0, 0, 0, 1) 100%);

  /* global 92%+ browsers support */
  background:radial-gradient(circle at 50% 50%, rgba(21, 0, 80, 1) 1%, rgba(0, 0, 0, 1) 100%);
  margin: 2rem;
}
.shell{
  padding:80px 0;
}
.wsk-cp-product{
  background: #EFFFFD;
  padding:15px;
  border-radius:6px;
  box-shadow: rgba(6, 173, 215, 0.544) 0px 30px 90px;
  position:relative;
  margin:20px auto;
}
.wsk-cp-img{
  position:absolute;
  top:5px;
  left:50%;
  transform:translate(-50%);
  -webkit-transform:translate(-50%);
  -ms-transform:translate(-50%);
  -moz-transform:translate(-50%);
  -o-transform:translate(-50%);
  -khtml-transform:translate(-50%);
  width: 100%;
  padding: 15px;
  transition: all 0.2s ease-in-out;
}
.wsk-cp-img img{
  width:100%;
  transition: all 0.2s ease-in-out;
  border-radius:6px;
}
.wsk-cp-product:hover .wsk-cp-img{
  top:-40px;
}
.wsk-cp-product:hover .wsk-cp-img img{
  box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
}
.wsk-cp-text{
  padding-top:150%;
}
.wsk-cp-text .category{
  text-align:center;
  font-size:12px;
  font-weight:bold;
  padding:5px;
  margin-bottom:45px;
  position:relative;
  transition: all 0.2s ease-in-out;
}
.wsk-cp-text .category > *{
  position:absolute;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
  -webkit-transform: translate(-50%,-50%);
  -moz-transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);
  -o-transform: translate(-50%,-50%);
  -khtml-transform: translate(-50%,-50%);
    
}
.wsk-cp-text .category > span{
  padding: 12px 30px;
  border: 1px solid #313131;
  background:#212121;
  color:#fff;
  box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
  border-radius:27px;
  transition: all 0.05s ease-in-out;
  
}
.wsk-cp-product:hover .wsk-cp-text .category > span{
  border-color:#ddd;
  box-shadow: none;
  padding: 11px 28px;
}
.wsk-cp-product:hover .wsk-cp-text .category{
  margin-top: 0px;
}
.wsk-cp-text .title-product{
  text-align:center;
}
.wsk-cp-text .title-product h3{
  font-size:20px;
  font-weight:bold;
  margin:15px auto;
  overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  width:100%;
}
.wsk-cp-text .description-prod p{
  margin:0;
}
/* Truncate */
.wsk-cp-text .description-prod {
  text-align:center;
  width: 100%;
  height:62px;
  overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  margin-bottom:15px;
}
.card-footer{
  padding: 25px 0 5px;
  border-top: 1px solid #ddd;
}
.card-footer:after, .card-footer:before{
  content:'';
  display:table;
}
.card-footer:after{
  clear:both;
}

.card-footer .wcf-left{
  float:left;
  
}

.card-footer .wcf-right{
  float:right;
}

.price{
  font-size:18px;
  font-weight:bold;
  color: green;
}

a.buy-btn{
  display:block;
  color:#212121;
  text-align:center;
  font-size: 20px;
  width:35px;
  height:35px;
  line-height:35px;
  border-radius:50%;
  border:1px solid #212121;
  transition: all 0.2s ease-in-out;
}
a.buy-btn:hover , a.buy-btn:active, a.buy-btn:focus{
  /* border-color: #FF9800;
  background: #FF9800; */
  color: #fff;
  text-decoration:none;
}
.wsk-btn{
  display:inline-block;
  color:#212121;
  text-align:center;
  font-size: 18px;
  transition: all 0.2s ease-in-out;
  /* border-color: #FF9800;
  background: #FF9800; */
  padding:12px 30px;
  border-radius:27px;
  margin: 0 5px;
}
.wsk-btn:hover, .wsk-btn:focus, .wsk-btn:active{
  text-decoration:none;
  color:#fff;
}  
.red{
  color:#F44336;
  font-size:22px;
  display:inline-block;
  margin: 0 5px;
}
@media screen and (max-width: 991px) {
  .wsk-cp-product{
    margin:40px auto;
  }
  .wsk-cp-product .wsk-cp-img{
  top:-40px;
}
.wsk-cp-product .wsk-cp-img img{
  box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
}
  .wsk-cp-product .wsk-cp-text .category > span{
  border-color:#ddd;
  box-shadow: none;
  padding: 11px 28px;
}
.wsk-cp-product .wsk-cp-text .category{
  margin-top: 0px;
}
a.buy-btn{
  border-color: #FF9800;
  background: #FF9800;
  color: #fff;
}
}



.jt {
  position: relative;
  font-size: 20vmin;
  font-family: 'Staatliches', sans-serif;
  text-transform: uppercase;
  font-display: swap;
}

.jt__row {
  display: block;
}
.jt__row:nth-child(1) {
  clip-path: polygon(0% 75%, 100% 75%, 100% 100%, 0% 100%);
}
.jt__row:nth-child(2) {
  clip-path: polygon(0% 50%, 100% 50%, 100% 75.5%, 0% 75.5%);
}
.jt__row:nth-child(3) {
  clip-path: polygon(0% 25%, 100% 25%, 100% 50.5%, 0% 50.5%);
}
.jt__row:nth-child(4) {
  clip-path: polygon(0% 0%, 100% -10%, 100% 35.5%, 0% 25.5%);
}
.jt__row:nth-child(5) {
  clip-path: polygon(0% -25%, 100% -45%, 100% -9.5%, 0% 0.5%);
}
.jt__row:nth-child(6) {
  clip-path: polygon(0% -50%, 100% -85%, 100% -44.4%, 0% -24.5%);
}

.jt__row.jt__row--sibling {
  position: absolute;
  top: 0;
  left: 0;
  user-select: none;
}

.jt__text {
  display: block;
  transform-origin: bottom left;
  font-size : 20px;
  color: #0081B4;
  margin-left: 7rem;
  animation: moveIn 2s cubic-bezier(.36,0,.06,1) alternate infinite;
}
.jt__row:nth-child(1) .jt__text {
  transform: translateY(-0.1em);
}
.jt__row:nth-child(2) .jt__text {
  transform: translateY(-0.3em) scaleY(1.1);
}
.jt__row:nth-child(3) .jt__text {
  transform: translateY(-0.5em) scaleY(1.2) rotate(-1deg);
}
.jt__row:nth-child(4) .jt__text {
  transform: translateY(-0.7em) scaleY(1.3) rotate(-2deg);
}
.jt__row:nth-child(5) .jt__text {
  transform: translateY(-0.9em) scaleY(1.4) rotate(-3deg);
}
.jt__row:nth-child(6) .jt__text {
  transform: translateY(-1.1em) scaleY(1.5) rotate(-4deg);
}

@keyframes moveIn {
  80%, 100% { transform: translateY(0em)}
}



/* Add class debug on class jt for debugging */
.debug .jt__row:nth-child(even) {
  color: black;
  background: white;
}
.debug .jt__row:nth-child(odd) {
  color: white;
  background: black;
}

/* Some hygiene */
* { box-sizing: border-box }

/* Just stuff for making it pretty: */



.bounce-in-top {
	-webkit-animation: bounce-in-top 4s 1.2s both;
	        animation: bounce-in-top 4s 1.2s both;
}
/* ----------------------------------------------
 * Generated by Animista on 2023-4-18 18:4:56
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation bounce-in-top
 * ----------------------------------------
 */
 @-webkit-keyframes bounce-in-top {
  0% {
    -webkit-transform: translateY(-500px);
            transform: translateY(-500px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    -webkit-transform: translateY(-65px);
            transform: translateY(-65px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  72% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  81% {
    -webkit-transform: translateY(-28px);
            transform: translateY(-28px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  90% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  95% {
    -webkit-transform: translateY(-8px);
            transform: translateY(-8px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}
@keyframes bounce-in-top {
  0% {
    -webkit-transform: translateY(-500px);
            transform: translateY(-500px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    -webkit-transform: translateY(-65px);
            transform: translateY(-65px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  72% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  81% {
    -webkit-transform: translateY(-28px);
            transform: translateY(-28px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  90% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  95% {
    -webkit-transform: translateY(-8px);
            transform: translateY(-8px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}

/* LOADER */
@import url(https://fonts.googleapis.com/css?family=Archivo+Narrow);
.cube-folding {
  width: 60px;
  height: 60px;
  display: inline-block;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  transform: rotate(45deg);
  font-size: 0;
}
.cube-folding span {
  position: relative;
  width: 30px;
  height: 30px;
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  transform: scale(1.1);
  display: inline-block;
}
.cube-folding span::before {
  content: "";
  background-color: #FFB000;
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  width: 30px;
  height: 30px;
  -moz-transform-origin: 100% 100%;
  -ms-transform-origin: 100% 100%;
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
  -webkit-animation: folding 2.5s infinite linear both;
  -moz-animation: folding 2.5s infinite linear both;
  animation: folding 2.5s infinite linear both;
}
.cube-folding .leaf2 {
  -webkit-transform: rotateZ(90deg) scale(1.1);
  -moz-transform: rotateZ(90deg) scale(1.1);
  transform: rotateZ(90deg) scale(1.1);
}
.cube-folding .leaf2::before {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
  background-color: #FFB000;
}
.cube-folding .leaf3 {
  -webkit-transform: rotateZ(270deg) scale(1.1);
  -moz-transform: rotateZ(270deg) scale(1.1);
  transform: rotateZ(270deg) scale(1.1);
}
.cube-folding .leaf3::before {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
  background-color: #FFB000;
}
.cube-folding .leaf4 {
  -webkit-transform: rotateZ(180deg) scale(1.1);
  -moz-transform: rotateZ(180deg) scale(1.1);
  transform: rotateZ(180deg) scale(1.1);
}
.cube-folding .leaf4::before {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
  background-color: #FFB000;
}

@-webkit-keyframes folding {
  0%, 10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
    -moz-transform: perspective(140px) rotateX(-180deg);
    transform: perspective(140px) rotateX(-180deg);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
  25%, 75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
    -moz-transform: perspective(140px) rotateX(0deg);
    transform: perspective(140px) rotateX(0deg);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  90%, 100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
    -moz-transform: perspective(140px) rotateY(180deg);
    transform: perspective(140px) rotateY(180deg);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
}
@-moz-keyframes folding {
  0%, 10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
    -moz-transform: perspective(140px) rotateX(-180deg);
    transform: perspective(140px) rotateX(-180deg);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
  25%, 75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
    -moz-transform: perspective(140px) rotateX(0deg);
    transform: perspective(140px) rotateX(0deg);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  90%, 100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
    -moz-transform: perspective(140px) rotateY(180deg);
    transform: perspective(140px) rotateY(180deg);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
}
@-ms-keyframes folding {
  0%, 10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
    -moz-transform: perspective(140px) rotateX(-180deg);
    transform: perspective(140px) rotateX(-180deg);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
  25%, 75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
    -moz-transform: perspective(140px) rotateX(0deg);
    transform: perspective(140px) rotateX(0deg);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  90%, 100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
    -moz-transform: perspective(140px) rotateY(180deg);
    transform: perspective(140px) rotateY(180deg);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
}
@keyframes folding {
  0%, 10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
    -moz-transform: perspective(140px) rotateX(-180deg);
    transform: perspective(140px) rotateX(-180deg);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
  25%, 75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
    -moz-transform: perspective(140px) rotateX(0deg);
    transform: perspective(140px) rotateX(0deg);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  90%, 100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
    -moz-transform: perspective(140px) rotateY(180deg);
    transform: perspective(140px) rotateY(180deg);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
}
.cube-wrapper {
  position: fixed;
  left: 50%;
  top: 50%;
  margin-top: -100px;
  margin-left: -100px;
  width: 200px;
  height: 200px;
  text-align: center;
  background-color: rgba(9, 9, 9, 0.663);
  z-index: 10000;
}
.cube-wrapper:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: -40px;
  margin: auto;
  width: 90px;
  height: 6px;
  background-color: rgba(245, 182, 7, 0.618);
  -webkit-filter: blur(2px);
  filter: blur(2px);
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  -webkit-animation: shadow 0.5s ease infinite alternate;
  -moz-animation: shadow 0.5s ease infinite alternate;
  animation: shadow 0.5s ease infinite alternate;
}
.cube-wrapper .loading {
  font-size: 20px;
  letter-spacing: 0.1em;
  display: block;
  color: white;
  position: relative;
  top: 25px;
  z-index: 2;
  -webkit-animation: text 0.5s ease infinite alternate;
  -moz-animation: text 0.5s ease infinite alternate;
  animation: text 0.5s ease infinite alternate;
}

@-webkit-keyframes text {
  100% {
    top: 35px;
  }
}
@-moz-keyframes text {
  100% {
    top: 35px;
  }
}
@-ms-keyframes text {
  100% {
    top: 35px;
  }
}
@keyframes text {
  100% {
    top: 35px;
  }
}
@-webkit-keyframes shadow {
  100% {
    bottom: -18px;
    width: 100px;
  }
}
@-moz-keyframes shadow {
  100% {
    bottom: -18px;
    width: 100px;
  }
}
@-ms-keyframes shadow {
  100% {
    bottom: -18px;
    width: 100px;
  }
}
@keyframes shadow {
  100% {
    bottom: -18px;
    width: 100px;
  }
}

/* END LOADER */


/* TABLE START */
@media screen and (max-width: 700px) {
  table, tr, td {
    display: block;
  }

  td:first-child {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100px;
  }
  td:not(:first-child) {
    clear: both;
    margin-left: 100px;
    padding: 4px 20px 4px 90px;
    position: relative;
    text-align: left;
  }
  td:not(:first-child):before {
    color: #91ced4;
    content: "";
    display: block;
    left: 0;
    position: absolute;
  }
  td:nth-child(2):before {
    content: "ID:";
  }
  td:nth-child(3):before {
    content: "Phone:";
  }
  td:nth-child(4):before {
    content: "Asset Name:";
  }
  td:nth-child(5):before {
    content: "Asset Number:";
  }

  td:nth-child(6):before {
    content: "Latitude:";
  }

  td:nth-child(7):before {
    content: "Longitude:";
  }

  tr {
    padding: 10px 0;
    position: relative;
  }
  tr:first-child {
    display: none;
  }
}


/* TABLE END */


/* CLEVER HILLS SCREEN */

.containerdashcard {
  max-width: 1400px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 1rem;
}

.carddashcard {
  position: relative;
  max-width: 300px;
  height: 210px;
  background: #fff;
  margin: 30px 10px;
  padding: 20px 15px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  transition: 0.3s ease-in-out;
}
.carddashcard:hover {
  height: 420px;
}
.carddashcard:hover .imgBx {
  transform: translateY(-10px);
  box-shadow: 0 15px 20px rgba(0, 0, 0, 0.4);
}
.carddashcard:hover .content {
  transition: 0.75s ease-in-out;
  visibility: visible;
  opacity: 1;
  margin-top: -40px;
  transition-delay: 0.2s;
}
.carddashcard .imgBx {
  position: relative;
  width: 260px;
  height: 260px;
  top: -60px;
  left: 20px;
  z-index: 1;
  transition: 0.5s ease-out;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
  transition-delay: 0.4s;
}
.carddashcard .imgBx img {
  max-width: 100%;
  border-radius: 4px;
}
.carddashcard .content {
  position: relative;
  margin-top: -180px;
  text-align: justify;
  color: #111;
  visibility: hidden;
  opacity: 0;
  transition: 0.25s ease-out;
}
.carddashcard .content h2 {
  text-align: center;
  padding: 0;
  margin: 0;
}
.carddashcard .content p {
  height: 140px;
  padding: 0 0.5em;
  overflow-y: auto;
}
.carddashcard .content p* {
  /* Emerging W3C standard - currently Firefox-only */
  scrollbar-width: thin;
  scrollbar-color: #e1e1e1 rgba(225, 225, 225, 0.1);
}
.carddashcard .content p::-webkit-scrollbar {
  width: 12px;
}
.carddashcard .content p::-webkit-scrollbar-track {
  background: #c8c8c8;
}
.carddashcard .content p::-webkit-scrollbar-thumb {
  background: #fbfbfb;
  border-radius: 6px;
  border: 3px solid #c8c8c8;
}



/* GRID GALLERY */
/*

All grid code is placed in a 'supports' rule (feature query) at the bottom of the CSS (Line 77). 
        
The 'supports' rule will only run if your browser supports CSS grid.

Flexbox is used as a fallback so that browsers which don't support grid will still recieve an identical layout.

*/

@import url(https://fonts.googleapis.com/css?family=Montserrat:500);

:root {
	/* Base font size */
	font-size: 10px;
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

.containergallery {
	max-width: 100rem;
	margin: 0 auto;
	padding: 0 2rem 2rem;
}

.heading {
	font-family: "Montserrat", Arial, sans-serif;
	font-size: 4rem;
	font-weight: 500;
	line-height: 1.5;
	text-align: center;
	padding: 3.5rem 0;
	color: #1a1a1a;
}

.heading span {
	/* display: block; */
}

.gallery {
	display: flex;
	flex-wrap: wrap;
	/* Compensate for excess margin on outer gallery flex items */
	margin: -1rem -1rem;
}

.gallery-item {
	/* Minimum width of 24rem and grow to fit available space */
	flex: 1 0 24rem;
	/* Margin value should be half of grid-gap value as margins on flex items don't collapse */
	margin: 1rem;
	box-shadow: 0.3rem 0.4rem 0.4rem rgba(0, 0, 0, 0.4);
	overflow: hidden;
}

.gallery-image {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
	transition: transform 400ms ease-out;
}

.gallery-image:hover {
	transform: scale(1.15);
}

/*

The following rule will only run if your browser supports CSS grid.

Remove or comment-out the code block below to see how the browser will fall-back to flexbox styling. 

*/

@supports (display: grid) {
	.gallery {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(24rem, 1fr));
		grid-gap: 2rem;
	}

	.gallery,
	.gallery-item {
		margin: 0;
	}
}


/* PLANS APPROVAL FORM */
.aside {
	background: #17181c;
	box-shadow: 0 0 0.25em hsla(223,10%,10%,0.5);
	border-radius: 0.375em 0.375em 0 0;
	position: fixed;
	bottom: 2;
	left: 1.25em;
	max-width: 30.5em;
	width: calc(100% - 0.5em);
	transform: translateY(100%);
	transition: transform 0.3s ease-in-out;
}
button, input {
	color: #e3e4e8;
	font: 1em/1.5 "Hind", -apple-system, sans-serif;
}
 .upload-btn {
	overflow: hidden;
}
button:hover, button:focus, .upload-btn input[type=file]:hover + button, .upload-btn input[type=file]:focus + button {
	background: #C08B5C;
}
button, .upload-btn input[type=file], .upload-btn input[type=file]::-webkit-file-upload-button {
	cursor: pointer;
}
button, input {
	display: block;
	width: 100%;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}
button {
	background:#2762f3;
	border-radius: 0.375em;
	color: #fff;
	margin-bottom: 1.5em;
	padding: 0.75em 1em;
	transition: background 0.1s linear;
}
button:focus {
	outline: 0;
}
form {
	padding: 1.5em 1.5em 0 1.5em;
	position: relative;
}
input {
	background: #fff;
	border-radius: 0.25em;
	box-shadow: 0 0 0 1px var(--inputBorder) inset;
	padding: 0.75em;
}
input:disabled {
	background: #e3e4e8;
	cursor: not-allowed;
	text-overflow: ellipsis;
}
label {
	display: inline-block;
	font-weight: bold;
}
.aside-toggle, .aside-toggle:hover, .aside-toggle:focus {
	background-color: #17181c;
}
.aside-toggle {
	border-radius: 0.375em 0.375em 0 0;
	margin: 0;
	padding: 0.25em 1em;
	position: absolute;
	bottom: 100%;
	left: 50%;
	transform: translateX(-50%);
	transition: none;
	width: 4.5em;
	height: 1.5em;
}
.aside-toggle:before {
	border-left: 0.5em solid transparent;
	border-right: 0.5em solid transparent;
	border-bottom: 0.5em solid;
	color: var(--pColor);
	content: "";
	display: block;
	position: absolute;
	top: 33%;
	left: calc(50% - 0.5em);
	width: 0; 
	height: 0; 
	transition: color 0.1s linear;
}
.aside-toggle:hover:before, .aside-toggle:focus:before {
	color: var(--buttonBg);
}
.aside-open {
	transform: translateY(0%);
}
.aside-open .aside-toggle:before {
	border-bottom: 0;
	border-top: 0.5em solid;
}
.sr {
	clip: rect(1px,1px,1px,1px);
	overflow: hidden;
	position: absolute;
}
.upload-btn, .upload-btn input[type=text], .upload-btn input[type=file] + button {
	margin-bottom: 0.75em;
}
.upload-btn {
	display: flex;
	justify-content: space-between;
	position: relative;
}
.upload-btn input[type=text] {
	width: calc(62.5% - 0.375em);
}
.upload-btn input[type=file], .upload-btn input[type=file] + button {
	width: calc(37.5% - 0.375em);
}
.upload-btn input[type=file] {
	position: absolute;
	opacity: 0;
	top: 0;
	left: 0;
	height: 3em;
}
.upload-btn input[type=file] + button svg {
	display: block;
	margin: auto;
	width: 1.5em;
	height: 1.5em;
}
.upload-btn input[type=file] + button path {
	fill: #fff;
}





:root {
  --yellow: linear-gradient(180deg, #F8D49A -146.42%, #FAD79D -46.42%);
  --orange: #fca61f;
  --black: #242d49;
  --gray: #788097;
  --purple: linear-gradient(180deg, #BB67FF 0%, #C484F3 100%);
  --pink: #FF919D;
  --glass: rgba(255, 255, 255, 0.54);
  --boxShadow: 0px 19px 60px rgb(0 0 0 / 8%);
  --smboxShadow: -79px 51px 60px rgba(0, 0, 0, 0.08);
  --activeItem: #f799a354;
}

.AppGlass {
  display: grid;
  height: 97%;
  width: 97%;
  background: var(--glass);
  border-radius: 2rem;
  gap: 16px;
  grid-template-columns: 11rem auto 20rem;
  overflow: hidden;
}

@media screen and (max-width: 1200px) {
  .AppGlass {
    grid-template-columns: 10% 50% auto;
    overflow-y: scroll;
  }
}

@media screen and (max-width: 768px) {
  .AppGlass {
    grid-template-columns: 1fr;
  }
}

/* Table CSS */
@font-face {
  font-family: "Fakt Pro";
  font-style: normal;
  font-weight: 400;
  src: url("https://github.com/awt2542/FramerSite/blob/master/static/css/fonts/FaktPro-Normal.eot"); /* IE9 Compat Modes */
  src: url("https://github.com/awt2542/FramerSite/blob/master/static/css/fonts/FaktPro-Normal.eot?#iefix")
      format("embedded-opentype"),
    url("https://github.com/awt2542/FramerSite/blob/master/static/css/fonts/FaktPro-Normal.woff")
      format("woff"); /* Modern Browsers */
}

@font-face {
  font-family: "Fakt Pro";
  font-style: normal;
  font-weight: 500;
  src: url("https://github.com/awt2542/FramerSite/blob/master/static/css/fonts/FaktPro-Medium.eot"); /* IE9 Compat Modes */
  src: url("https://github.com/awt2542/FramerSite/blob/master/static/css/fonts/FaktPro-Medium,.eot?#iefix")
      format("embedded-opentype"),
    url("https://github.com/awt2542/FramerSite/blob/master/static/css/fonts/FaktPro-Medium.woff")
      format("woff"); /* Modern Browsers */
}

@font-face {
  font-family: "Fakt Pro";
  font-style: normal;
  font-weight: 700;
  src: url("https://github.com/awt2542/FramerSite/blob/master/static/css/fonts/FaktPro-SemiBold.eot"); /* IE9 Compat Modes */
  src: url("https://github.com/awt2542/FramerSite/blob/master/static/css/fonts/FaktPro-SemiBold.eot?#iefix")
      format("embedded-opentype"),
    url("https://github.com/awt2542/FramerSite/blob/master/static/css/fonts/FaktPro-SemiBold.woff")
      format("woff"); /* Modern Browsers */
}

.search {
  width: 100%;
  height: 36px;
  /* position: relative; */
  display: flex;
}

.searchTerm {
  width: 100%;
  border: 3px solid #FFC436;
  border-right: none;
  font-size: 16px;
  padding: 5px;
  height: 36px;
  border-radius: 5px 0 0 5px;
  outline: none;
  color: #9DBFAF;
}

.searchTerm:focus{
  color: black;
}

.searchButton {
  width: 40px;
  height: 36px;
  border: 1px solid #FFC436;
  padding: 2px;
  background: #FFC436;
  text-align: center;
  color: #fff;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  font-size: 20px;
}

/*Resize the wrap to see the search bar change!*/
.wrap{
  width: 60%;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-bottom: 15px;
}

.cardGrid {
  height: 100vh;
  padding: 1rem;
  margin: 0;
  background: #f9f9f9;
}

.ContainerGrid {
  display: inline-block;
  width: 100%;
  height: 70%;
  overflow-y: scroll;
}

.CardStack {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  width: 100%;
}

.Cardgrid {
  display: block;
  width: calc(100vw / 4);
  min-width: 210px;
  margin: 0.5rem;
  background: #fff;
  border-radius: 0.25rem;
  transition: all 0.3s ease-in;
}

.Cardgrid:hover {
  box-shadow: 0px 22px 24px 0px rgba(46, 51, 51, 0.07);
  transition: all 0.3s ease-out;
}

.AvatarWrap {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  height: 5rem;
  padding: 1rem 0;
}

.Avatar {
  display: inline-block;
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 8px;
}

.modall {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  width: 35%;
  min-width: 310px;
  background-image: linear-gradient(to right top, #020306, #353537, #686869, #9f9fa0, #dadada);
  padding: 20px;
}

.StatusOff {
  position: absolute;
  align-self: flex-start;
  background: rgb(241, 241, 241);
  border-radius: 50%;
  border: 2px solid #fff;
  height: 0.75rem;
  width: 0.75rem;
  margin: 16px 0 0 52px;
}

.StatusOn {
  position: absolute;
  align-self: flex-start;
  background: #4cd964;
  border-radius: 50%;
  border: 2px solid #fff;
  height: 0.75rem;
  width: 0.75rem;
  margin: 16px 0 0 52px;
}

.Title {
  font-family: "Fakt Pro", -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 1em;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: normal;
  overflow-wrap: normal;
  text-align: center;
  color: rgb(33, 33, 33);
  padding: 0 1.5rem;
}

.Description {
  font-family: "Fakt Pro", -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.6;
  letter-spacing: normal;
  overflow-wrap: normal;
  text-align: center;
  color: rgb(117, 117, 117);
  padding: 0.75rem 1rem 1.5rem 1rem;
}

.Actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid rgb(208, 210, 211, 0.4);
  margin: 0 1rem;
  padding: 1rem 0;
}

.Link {
  color: rgb(10, 132, 174);
  text-decoration: none;
}

.Link:hover {
  color: rgb(5, 59, 78);
}

.Primary {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  font-size: 13px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 1px;
  white-space: nowrap;
  text-transform: uppercase;
  color: rgb(51, 51, 51);
  cursor: pointer;
  background: rgb(241, 241, 241);
  border: 1px solid rgb(241, 241, 241);
  border-radius: 3px;
  padding: 0px 16px;
  transition: border-color 0.25s ease 0s, background 0.25s ease 0s;
}

.Primary:hover {
  color: rgb(51, 51, 51);
  background: rgb(233, 232, 232);
  border-color: rgb(233, 232, 232);
}

.Secondary {
  display: inline-flex;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
  line-height: 32px;
  text-transform: uppercase;
  white-space: nowrap;
  letter-spacing: 1px;
  font-size: 13px;
  font-weight: 500;
  color: rgb(51, 51, 51);
  opacity: 1;
  cursor: pointer;
  background: transparent;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(208, 210, 211);
  border-image: initial;
  border-radius: 3px;
  padding: 0px 16px;
  transition: border-color 0.25s ease 0s, background 0.25s ease 0s;
}

.Secondary:hover {
  color: rgb(51, 51, 51);
  background: rgba(0, 0, 0, 0.05);
  border-color: rgb(181, 183, 184);
}

@keyframes blip {
  from {
    border: 2px solid #4cd964;
  }
  to {
    border: 2px solid #fff;
  }
}


@media (min-width: 48em){
.col-md-3 {
    width: 30% !important;
}
}

