.gpt3__blog {
    display: flex;
    flex-direction: column;    
}

.gpt3__blog-heading {
    width: 100%;
    text-align: left;

    margin-bottom: 5rem;
}

.gpt3__blog-heading h1 {
    font-size: 62px;
    line-height: 75px;
    font-family: var(--font-family);
    font-weight: 800;
}

.gpt3__blog-container {
    display: flex;
    flex-direction: row;
}

.gpt3__blog-container_groupA {
    flex: 0.75;
    margin-right: 2rem;
}

.gpt3__blog-container_groupB {
    flex: 1;

    display:grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
}

.agreementOFS{

    padding: 1rem;
    background:-moz-radial-gradient(circle at 50% 50%, rgba(60, 64, 72, 1) 0%, rgba(0, 0, 0, 1) 100%);

    /* safari 5.1+,chrome 10+ */
    background:-webkit-radial-gradient(circle at 50% 50%, rgba(60, 64, 72, 1) 0%, rgba(0, 0, 0, 1) 100%);

    /* opera 11.10+ */
    background:-o-radial-gradient(circle at 50% 50%, rgba(60, 64, 72, 1) 0%, rgba(0, 0, 0, 1) 100%);

    /* ie 10+ */
    background:-ms-radial-gradient(circle at 50% 50%, rgba(60, 64, 72, 1) 0%, rgba(0, 0, 0, 1) 100%);

    /* global 92%+ browsers support */
    background:radial-gradient(circle at 50% 50%, rgba(60, 64, 72, 1) 0%, rgba(0, 0, 0, 1) 100%);

    /* box-shadow: rgb(72, 77, 38) 0px 20px 30px -10px; */
    box-shadow: rgb(255, 217, 19)  0px 0px 0px 2px inset, rgb(255, 255, 255) 10px -10px 0px -3px, rgb(255, 217, 19)  10px -10px, rgb(255, 255, 255) 20px -20px 0px -3px, rgb(255, 217, 19) 20px -20px, rgb(255, 255, 255) 30px -30px 0px -3px, rgb(255, 156, 85) 30px -30px, rgb(255, 255, 255) 40px -40px 0px -3px, rgb(255, 85, 85) 40px -40px;
    /* box-shadow: rgba(67, 44, 11, 0.25) 0px 54px 55px, rgba(224, 174, 7, 0.12) 0px -12px 30px, rgba(172, 150, 5, 0.12) 0px 4px 6px, rgba(122, 113, 8, 0.17) 0px 12px 13px, rgba(155, 113, 8, 0.09) 0px -3px 5px; */
}

.ratesClearance{
    padding: 1rem;
    background:-moz-radial-gradient(circle at 50% 50%, rgba(60, 64, 72, 1) 0%, rgba(0, 0, 0, 1) 100%);

    /* safari 5.1+,chrome 10+ */
    background:-webkit-radial-gradient(circle at 50% 50%, rgba(60, 64, 72, 1) 0%, rgba(0, 0, 0, 1) 100%);

    /* opera 11.10+ */
    background:-o-radial-gradient(circle at 50% 50%, rgba(60, 64, 72, 1) 0%, rgba(0, 0, 0, 1) 100%);

    /* ie 10+ */
    background:-ms-radial-gradient(circle at 50% 50%, rgba(60, 64, 72, 1) 0%, rgba(0, 0, 0, 1) 100%);

    /* global 92%+ browsers support */
    background:radial-gradient(circle at 50% 50%, rgba(60, 64, 72, 1) 0%, rgba(0, 0, 0, 1) 100%);

    /* box-shadow: rgb(72, 77, 38) 0px 20px 30px -10px; */
    box-shadow: rgb(255, 217, 19)  0px 0px 0px 2px inset, rgb(255, 255, 255) 10px -10px 0px -3px, rgb(255, 217, 19)  10px -10px, rgb(255, 255, 255) 20px -20px 0px -3px, rgb(255, 217, 19) 20px -20px, rgb(255, 255, 255) 30px -30px 0px -3px, rgb(255, 156, 85) 30px -30px, rgb(255, 255, 255) 40px -40px 0px -3px, rgb(255, 85, 85) 40px -40px;
}
.plansApprval{
    padding: 1rem;
    background:-moz-radial-gradient(circle at 50% 50%, rgba(60, 64, 72, 1) 0%, rgba(0, 0, 0, 1) 100%);

    /* safari 5.1+,chrome 10+ */
    background:-webkit-radial-gradient(circle at 50% 50%, rgba(60, 64, 72, 1) 0%, rgba(0, 0, 0, 1) 100%);

    /* opera 11.10+ */
    background:-o-radial-gradient(circle at 50% 50%, rgba(60, 64, 72, 1) 0%, rgba(0, 0, 0, 1) 100%);

    /* ie 10+ */
    background:-ms-radial-gradient(circle at 50% 50%, rgba(60, 64, 72, 1) 0%, rgba(0, 0, 0, 1) 100%);

    /* global 92%+ browsers support */
    background:radial-gradient(circle at 50% 50%, rgba(60, 64, 72, 1) 0%, rgba(0, 0, 0, 1) 100%);

    /* box-shadow: rgb(72, 77, 38) 0px 20px 30px -10px; */
    box-shadow: rgb(255, 217, 19)  0px 0px 0px 2px inset, rgb(255, 255, 255) 10px -10px 0px -3px, rgb(255, 217, 19)  10px -10px, rgb(255, 255, 255) 20px -20px 0px -3px, rgb(255, 217, 19) 20px -20px, rgb(255, 255, 255) 30px -30px 0px -3px, rgb(255, 156, 85) 30px -30px, rgb(255, 255, 255) 40px -40px 0px -3px, rgb(255, 85, 85) 40px -40px;
}

.rumaniEstat{
    padding: 1rem;
    background:-moz-radial-gradient(circle at 50% 50%, rgba(60, 64, 72, 1) 0%, rgba(0, 0, 0, 1) 100%);

    /* safari 5.1+,chrome 10+ */
    background:-webkit-radial-gradient(circle at 50% 50%, rgba(60, 64, 72, 1) 0%, rgba(0, 0, 0, 1) 100%);

    /* opera 11.10+ */
    background:-o-radial-gradient(circle at 50% 50%, rgba(60, 64, 72, 1) 0%, rgba(0, 0, 0, 1) 100%);

    /* ie 10+ */
    background:-ms-radial-gradient(circle at 50% 50%, rgba(60, 64, 72, 1) 0%, rgba(0, 0, 0, 1) 100%);

    /* global 92%+ browsers support */
    background:radial-gradient(circle at 50% 50%, rgba(60, 64, 72, 1) 0%, rgba(0, 0, 0, 1) 100%);

    /* box-shadow: rgb(72, 77, 38) 0px 20px 30px -10px; */
    box-shadow: rgb(255, 217, 19)  0px 0px 0px 2px inset, rgb(255, 255, 255) 10px -10px 0px -3px, rgb(255, 217, 19)  10px -10px, rgb(255, 255, 255) 20px -20px 0px -3px, rgb(255, 217, 19) 20px -20px, rgb(255, 255, 255) 30px -30px 0px -3px, rgb(255, 156, 85) 30px -30px, rgb(255, 255, 255) 40px -40px 0px -3px, rgb(255, 85, 85) 40px -40px;
}
@media screen and (max-width: 990px) {
    .gpt3__blog-container {
        flex-direction: column-reverse;
    }


    .gpt3__blog-container_groupA {
        margin: 2rem 0;
    }

    .gpt3__blog-container_groupA .gpt3__blog-container_article  {
       width: 48%;
    }

    .gpt3__blog-container_groupA .gpt3__blog-container_article-image {
        height: 250px;
    }
}

@media screen and (max-width: 700px) {
    .gpt3__blog-container_groupB {
        grid-template-columns: repeat(1, 1fr);
    }

    .gpt3__blog-container_groupA .gpt3__blog-container_article  {
       width: 100%;
    }

    .gpt3__blog-heading h1 {
        font-size: 46px;
        line-height: 52px;
    }
}

@media screen and (max-width: 550px) {
    .gpt3__blog-heading h1 {
        font-size: 34px;
        line-height: 42px;
    }

}