/* Please ❤ this if you like it! */

/* ========================================= * 
		        BEST VIEWED FULLSCREEN
   https://codepen.io/ig_design/full/NWxwBvw
 * ========================================= */
 

 
 .section {
   position: relative;
   width: 100%;
   display: block;
 }
 .full-height {
   min-height: 100vh;
 }
 .over-hide {
   overflow: hidden;
 }
 .padding-tb {
   padding: 100px 0;
 }
 [type="radio"]:checked,
 [type="radio"]:not(:checked) {
   position: absolute;
   left: -9999px;
 }
 .checkbox:checked + label,
 .checkbox:not(:checked) + label {
   position: relative;
   cursor: pointer;
   margin: 0 auto;
   text-align: center;
   margin-right: 6px;
   margin-left: 6px;
   display: inline-block;
   width: 50px;
   height: 50px;
   border: 3px solid #bdc3c7;
   background-size: cover;
   background-position: center;
   box-sizing: border-box;
   -webkit-transition: all 0.2s ease;
   transition: all 0.2s ease;
   background-image: url("https://st4.depositphotos.com/5344180/30128/i/600/depositphotos_301284170-stock-photo-elemental-chromium-specimen-sample-isolated.jpg");
   animation: border-transform 6s linear infinite alternate forwards;
   -webkit-animation-play-state: paused;
   -moz-animation-play-state: paused;
   animation-play-state: paused;
 }
 .checkbox.scnd + label {
   background-image: url("https://imagizer.imageshack.com/v2/735x348q70/923/cnwtbH.jpg");
 }
 .checkbox.thrd + label {
   background-image: url("https://st4.depositphotos.com/8707842/24741/i/600/depositphotos_247413936-stock-photo-natural-coal-black-background-isolated.jpg");
 }
 .checkbox.foth + label {
   background-image: url("https://st2.depositphotos.com/1008404/5970/i/600/depositphotos_59707589-stock-photo-copper-background.jpg");
 }
 
 .checkbox:checked + label {
   box-shadow: 0 8px 25px 0 rgba(16, 39, 112, 0.3);
   transform: scale(1.3);
   -webkit-animation-play-state: running;
   -moz-animation-play-state: running;
   animation-play-state: running;
 }
 @keyframes border-transform {
   0%,
   100% {
     border-radius: 63% 37% 54% 46% / 55% 48% 52% 45%;
   }
   14% {
     border-radius: 40% 60% 54% 46% / 49% 60% 40% 51%;
   }
   28% {
     border-radius: 54% 46% 38% 62% / 49% 70% 30% 51%;
   }
   42% {
     border-radius: 61% 39% 55% 45% / 61% 38% 62% 39%;
   }
   56% {
     border-radius: 61% 39% 67% 33% / 70% 50% 50% 30%;
   }
   70% {
     border-radius: 50% 50% 34% 66% / 56% 68% 32% 44%;
   }
   84% {
     border-radius: 46% 54% 50% 50% / 35% 61% 39% 65%;
   }
 }
 
 .slider-height-padding {
   padding-top: 440px;
 }
 
 .ul {
   position: absolute;
   top: 0;
   left: 0;
   display: block;
   width: 100%;
   z-index: 100;
   padding: 0;
   margin: 0;
   list-style: none;
 }
 .ul .li {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   display: block;
   z-index: 100;
   padding: 0;
   margin: 0;
   list-style: none;
   height: 400px;
   border: 5px solid #bdc3c7;
   background-size: cover;
   background-position: center;
   background-image: url("https://st4.depositphotos.com/5344180/30128/i/600/depositphotos_301284170-stock-photo-elemental-chromium-specimen-sample-isolated.jpg");
   border-radius: 50%;
   box-sizing: border-box;
   font-family: "Poppins", sans-serif;
   font-weight: 900;
   /* font-size: 16px; */
   letter-spacing: 2px;
   line-height: 2.7;
   color: #343434;
   writing-mode: vertical-rl;
   opacity: 0;
   pointer-events: none;
   box-shadow: 0 8px 25px 0 rgba(16, 39, 112, 0.1);
   -webkit-transition: all 0.5s ease;
   transition: all 0.5s ease;
 }
 .ul .li span {
   mix-blend-mode: difference;
 }
 .ul .li:nth-child(2) {
   background-image: url("../../assets/irrigatorX.jpg");
 }
 .ul .li:nth-child(3) {
   background-image: url("https://st4.depositphotos.com/8707842/24741/i/600/depositphotos_247413936-stock-photo-natural-coal-black-background-isolated.jpg");
 }
 .ul .li:nth-child(4) {
   background-image: url("https://st2.depositphotos.com/1008404/5970/i/600/depositphotos_59707589-stock-photo-copper-background.jpg");
 }
 
 .checkbox.frst:checked ~ .ul .li:nth-child(1) {
   opacity: 1;
   pointer-events: auto;
   border-radius: 16px;
 }
 .checkbox.scnd:checked ~ .ul .li:nth-child(2) {
   opacity: 1;
   pointer-events: auto;
   border-radius: 16px;
 }
 .checkbox.thrd:checked ~ .ul .li:nth-child(3) {
   opacity: 1;
   pointer-events: auto;
   border-radius: 16px;
 }
 .checkbox.foth:checked ~ .ul .li:nth-child(4) {
   opacity: 1;
   pointer-events: auto;
   border-radius: 16px;
 }
 
 
 @media (max-width: 767px) {
   .slider-height-padding {
     padding-top: 340px;
   }
   .ul .li {
     height: 300px;
     font-size: 13px;
     letter-spacing: 1px;
   }
 }
 
 @media (max-width: 575px) {
   .slider-height-padding {
     padding-top: 240px;
   }
   .ul .li {
     height: 200px;
   }
   .text_slider_body {
    width: 100% !important;
    background-color: green;
  }
  .typing-slider{
    width: 100% !important;
    padding: 30px;
  }
 }


 /* TEXT SLIDER */
 .text_slider_body {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  height: 30vh;
  background-color: #FFCC00;
}

@keyframes cursor {
  from, to {
    border-color: transparent;
  }
  50% {
    border-color: black;
  }
}
@keyframes typing {
  from {
    width: 100%;
  }
  90%, to {
    width: 0;
  }
}
@keyframes slide {
  33.3333333333% {
    font-size: 3rem;
    letter-spacing: 3px;
  }
  to {
    font-size: 0;
    letter-spacing: 0;
  }
}
.typing-slider {
  font-family: Consolas, monospace;
  font-weight: bold;
  text-align: center;
  white-space: nowrap;
}

.typing-slider p {
  position: relative;
  display: inline;
  font-size: 0;
  text-transform: uppercase;
  letter-spacing: 0;
  animation: slide 15s step-start infinite;
}

.typing-slider p::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border-left: 3px solid black;
  background-color: #FFCC00;
  animation: typing 5s infinite, cursor 1s infinite;
}

.typing-slider p:nth-child(1) {
  animation-delay: 0s;
}
.typing-slider p:nth-child(1)::after {
  animation-delay: 0s;
  animation-timing-function: steps(16), step-end;
}

.typing-slider p:nth-child(2) {
  animation-delay: 5s;
}
.typing-slider p:nth-child(2)::after {
  animation-delay: 5s;
  animation-timing-function: steps(23), step-end;
}

.typing-slider p:nth-child(3) {
  animation-delay: 10s;
}
.typing-slider p:nth-child(3)::after {
  animation-delay: 10s;
  animation-timing-function: steps(12), step-end;
}
 