@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css?family=Playfair+Display:700|IBM+Plex+Sans:500&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato:400,700);
@import url(https://fonts.googleapis.com/css2?family=Mohave:wght@300;700&family=Open+Sans&display=swap);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
@import url(https://fonts.googleapis.com/css?family=Ubuntu:300,300i,400,400i,500,500i,700,700i);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Muli:400,400i,700,700i);
@import url(https://fonts.googleapis.com/css?family=Archivo+Narrow);
@import url(https://fonts.googleapis.com/css?family=Montserrat:500);
@import url(https://fonts.googleapis.com/css?family=Open+Sans);
/* OPT CSS */
@tailwind base;
@tailwind components;
@tailwind utilities;

.react-tel-input .form-control {
  width: 60%;
  /* padding: 1.5rem 0px; */
  margin-left: 30px !important;
  font-size: 1rem;
  line-height: 1.5rem;
}
.react-tel-input .selected-flag .arrow{
  background: transparent;
}
.react-tel-input .country-list .country-name {
  /* display: none !important; */
  color: #040C18 !important;
}

.react-tel-input .country-list .country {
  padding: 7px 9px;
  display: block;
}
.opt-container {
  display: flex;
  justify-content: space-between;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  margin-left: 30px;
  /* width: 74%; */
  color: #FF731D !important;
}

.opt-container input {
  flex: 1 1;
  padding-top: 1.25rem;
  font-size: 14px !important;
  /* 20px */
  padding-bottom: 1.25rem;
  /* 20px */
  outline: 2px solid transparent;
  color: orange !important;
  outline-offset: 2px;
  border: 1px solid orange !important;
  width: 200px;
}

.opt-container input:focus{
  border: 1px solid white !important;
}

.box input {
  border: 1px solid white !important;
}
:root {
  --font-family: 'Manrope', sans-serif;

  /* --gradient-text:    linear-gradient(89.97deg, #EEEEEE 1.84%, #CF0A0A 102.67%); */
  --gradient-text: #FFB100;
  --gradient-bar: linear-gradient(103.22deg, #FF731D -13.86%, #EAEAEA 99.55%);

  --color-bg: #040C18;
  --color-footer: linear-gradient(97.3deg, rgba(25, 50, 70, 0.81) 10.7%, rgba(155, 65, 25, 0.72) 39.5%, rgba(255, 192, 0, 0.81) 69.7%);
  --color-blog: #042c54;
  --color-text: #EAEAEA;
  --color-subtext: #EAEAEA;
}
.gpt3__blog-container_article {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    background: var(--color-footer);
}

.gpt3__blog-container_article-image {
    width: 100%;
    height: 100%;
    background: var(--color-bg);
}

.gpt3__blog-container_article-image img {
    width: 100%;
    height: 100%;
}

.gpt3__blog-container_article-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding: 1rem 1.5rem;
    height: 100%;
}

.gpt3__blog-container_article-content p {
    font-family: var(--font-family);
    font-size: 11.65px;
    font-weight: 700;
    line-height: 35px;

    color: #fff;
}

.gpt3__blog-container_article-content h3 {
    font-family: var(--font-family);
    font-size: 25.11px;
    font-weight: 800;
    line-height: 30.30px;
    
    color: #fff;
    margin-bottom: 5rem;
    cursor: pointer;
}

.gpt3__blog-container_article-content p:last-child {
    cursor: pointer;
}


@media screen and (max-width: 550px) {
    .gpt3__blog-container_article-content h3 {
        font-size: 18px;
        line-height: 25p;
    }
}
.gpt3__blog {
    display: flex;
    flex-direction: column;    
}

.gpt3__blog-heading {
    width: 100%;
    text-align: left;

    margin-bottom: 5rem;
}

.gpt3__blog-heading h1 {
    font-size: 62px;
    line-height: 75px;
    font-family: var(--font-family);
    font-weight: 800;
}

.gpt3__blog-container {
    display: flex;
    flex-direction: row;
}

.gpt3__blog-container_groupA {
    flex: 0.75 1;
    margin-right: 2rem;
}

.gpt3__blog-container_groupB {
    flex: 1 1;

    display:grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
}

@media screen and (max-width: 990px) {
    .gpt3__blog-container {
        flex-direction: column-reverse;
    }


    .gpt3__blog-container_groupA {
        margin: 2rem 0;
    }

    .gpt3__blog-container_groupA .gpt3__blog-container_article  {
       width: 48%;
    }

    .gpt3__blog-container_groupA .gpt3__blog-container_article-image {
        height: 250px;
    }
}

@media screen and (max-width: 700px) {
    .gpt3__blog-container_groupB {
        grid-template-columns: repeat(1, 1fr);
    }

    .gpt3__blog-container_groupA .gpt3__blog-container_article  {
       width: 100%;
    }

    .gpt3__blog-heading h1 {
        font-size: 46px;
        line-height: 52px;
    }
}

@media screen and (max-width: 550px) {
    .gpt3__blog-heading h1 {
        font-size: 34px;
        line-height: 42px;
    }

}
.gpt3__blog {
    display: flex;
    flex-direction: column;    
}

.gpt3__blog-heading {
    width: 100%;
    text-align: left;

    margin-bottom: 5rem;
}

.gpt3__blog-heading h1 {
    font-size: 62px;
    line-height: 75px;
    font-family: var(--font-family);
    font-weight: 800;
}

.gpt3__blog-container {
    display: flex;
    flex-direction: row;
}

.gpt3__blog-container_groupA {
    flex: 0.75 1;
    margin-right: 2rem;
}

.gpt3__blog-container_groupB {
    flex: 1 1;

    display:grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
}

.agreementOFS{

    padding: 1rem;

    /* safari 5.1+,chrome 10+ */

    /* opera 11.10+ */

    /* ie 10+ */

    /* global 92%+ browsers support */
    background:radial-gradient(circle at 50% 50%, rgba(60, 64, 72, 1) 0%, rgba(0, 0, 0, 1) 100%);

    /* box-shadow: rgb(72, 77, 38) 0px 20px 30px -10px; */
    box-shadow: rgb(255, 217, 19)  0px 0px 0px 2px inset, rgb(255, 255, 255) 10px -10px 0px -3px, rgb(255, 217, 19)  10px -10px, rgb(255, 255, 255) 20px -20px 0px -3px, rgb(255, 217, 19) 20px -20px, rgb(255, 255, 255) 30px -30px 0px -3px, rgb(255, 156, 85) 30px -30px, rgb(255, 255, 255) 40px -40px 0px -3px, rgb(255, 85, 85) 40px -40px;
    /* box-shadow: rgba(67, 44, 11, 0.25) 0px 54px 55px, rgba(224, 174, 7, 0.12) 0px -12px 30px, rgba(172, 150, 5, 0.12) 0px 4px 6px, rgba(122, 113, 8, 0.17) 0px 12px 13px, rgba(155, 113, 8, 0.09) 0px -3px 5px; */
}

.ratesClearance{
    padding: 1rem;

    /* safari 5.1+,chrome 10+ */

    /* opera 11.10+ */

    /* ie 10+ */

    /* global 92%+ browsers support */
    background:radial-gradient(circle at 50% 50%, rgba(60, 64, 72, 1) 0%, rgba(0, 0, 0, 1) 100%);

    /* box-shadow: rgb(72, 77, 38) 0px 20px 30px -10px; */
    box-shadow: rgb(255, 217, 19)  0px 0px 0px 2px inset, rgb(255, 255, 255) 10px -10px 0px -3px, rgb(255, 217, 19)  10px -10px, rgb(255, 255, 255) 20px -20px 0px -3px, rgb(255, 217, 19) 20px -20px, rgb(255, 255, 255) 30px -30px 0px -3px, rgb(255, 156, 85) 30px -30px, rgb(255, 255, 255) 40px -40px 0px -3px, rgb(255, 85, 85) 40px -40px;
}
.plansApprval{
    padding: 1rem;

    /* safari 5.1+,chrome 10+ */

    /* opera 11.10+ */

    /* ie 10+ */

    /* global 92%+ browsers support */
    background:radial-gradient(circle at 50% 50%, rgba(60, 64, 72, 1) 0%, rgba(0, 0, 0, 1) 100%);

    /* box-shadow: rgb(72, 77, 38) 0px 20px 30px -10px; */
    box-shadow: rgb(255, 217, 19)  0px 0px 0px 2px inset, rgb(255, 255, 255) 10px -10px 0px -3px, rgb(255, 217, 19)  10px -10px, rgb(255, 255, 255) 20px -20px 0px -3px, rgb(255, 217, 19) 20px -20px, rgb(255, 255, 255) 30px -30px 0px -3px, rgb(255, 156, 85) 30px -30px, rgb(255, 255, 255) 40px -40px 0px -3px, rgb(255, 85, 85) 40px -40px;
}

.rumaniEstat{
    padding: 1rem;

    /* safari 5.1+,chrome 10+ */

    /* opera 11.10+ */

    /* ie 10+ */

    /* global 92%+ browsers support */
    background:radial-gradient(circle at 50% 50%, rgba(60, 64, 72, 1) 0%, rgba(0, 0, 0, 1) 100%);

    /* box-shadow: rgb(72, 77, 38) 0px 20px 30px -10px; */
    box-shadow: rgb(255, 217, 19)  0px 0px 0px 2px inset, rgb(255, 255, 255) 10px -10px 0px -3px, rgb(255, 217, 19)  10px -10px, rgb(255, 255, 255) 20px -20px 0px -3px, rgb(255, 217, 19) 20px -20px, rgb(255, 255, 255) 30px -30px 0px -3px, rgb(255, 156, 85) 30px -30px, rgb(255, 255, 255) 40px -40px 0px -3px, rgb(255, 85, 85) 40px -40px;
}
@media screen and (max-width: 990px) {
    .gpt3__blog-container {
        flex-direction: column-reverse;
    }


    .gpt3__blog-container_groupA {
        margin: 2rem 0;
    }

    .gpt3__blog-container_groupA .gpt3__blog-container_article  {
       width: 48%;
    }

    .gpt3__blog-container_groupA .gpt3__blog-container_article-image {
        height: 250px;
    }
}

@media screen and (max-width: 700px) {
    .gpt3__blog-container_groupB {
        grid-template-columns: repeat(1, 1fr);
    }

    .gpt3__blog-container_groupA .gpt3__blog-container_article  {
       width: 100%;
    }

    .gpt3__blog-heading h1 {
        font-size: 46px;
        line-height: 52px;
    }
}

@media screen and (max-width: 550px) {
    .gpt3__blog-heading h1 {
        font-size: 34px;
        line-height: 42px;
    }

}
.gpt3__features-container__feature {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;

    margin: 1rem;
}

.gpt3__features-container__feature-title {
    flex: 1 1;
    max-width: 180px;
    margin-right: 2rem;
}

.gpt3__features-container__feature-title h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.04em;
    text-align: left;
    color: #fff;
}

.gpt3__features-container__feature-title div {
    width: 38px;
    height: 3px;
    background: var(--gradient-bar);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 0.25rem;
}

.gpt3__features-container_feature-text {
    flex: 2 1;
    max-width: 390px;
    display: flex;
}

.gpt3__features-container_feature-text p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: var(--color-text);
}

@media screen and (max-width: 550px) {
    .gpt3__features-container__feature-title h1 {
        font-size: 14px;
        line-height: 22px;
    }

    .gpt3__features-container_feature-text p {
        font-size: 12px;
        line-height: 20px;
    }

    .gpt3__features-container__feature {
        margin: 1rem 0;
    }
}

  
.gpt3__features {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.gpt3__features-heading {
    flex: 1 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

    text-align: left;
    margin-right: 5rem;
}

.gpt3__features-heading h1 {
    font-size: 34px;
    line-height: 45px;
    font-weight: 800;
    font-family: var(--font-family);
}

.gpt3__features-heading p {
    color: var(--color-subtext);
    font-size: 16px;
    line-height: 30px;
    font-weight: 500;
    font-family: var(--font-family);

    margin-top: 2rem;
}

.gpt3__features-container {
    flex: 1.5 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

@media screen and (max-width: 990px) {
    .gpt3__features {
        flex-direction: column;
    }

    .gpt3__features-heading {
        margin: 0 0 2rem 0;
    }
}


@media screen and (max-width: 550px) {
    .gpt3__features-heading h1 {
        font-size: 28px;
        line-height: 38px;
    }
}
.gpt3__footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background: var(--color-footer);
}

.gpt3__footer-heading {
    width: 100%;
    text-align: center;

    margin-bottom: 3rem;
}

.gpt3__footer-heading h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 64px;
    line-height: 75px;
}

.gpt3__footer-btn {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 1rem;
    border: 1px solid #fff;
    text-align: center;

    margin-bottom: 10rem;
    cursor: pointer;
}

.gpt3__footer-btn p {
    font-size: 18px;
    font-family: var(--font-family);
    line-height: 21px;
    color: #fff;
    word-spacing: 2px;
}

.gpt3__footer-links {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;

    width: 100%;
    text-align: left;
}

.gpt3__footer-links div {
    width: 250px;
    margin: 1rem;
}

.gpt3__footer-links_logo {
    display: flex;
    flex-direction: column;
}

.gpt3__footer-links_logo img {
    width: 118px;
    height: 30px;

    margin-bottom: 1rem;
}

.gpt3__footer-links_logo p {
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 15px;
    color: #fff;
}

.gpt3__footer-links_div {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

.gpt3__footer-links_div h4 {
    font-size: 14px;
    line-height: 17px;
    font-family: var(--font-family);
    color: #fff;

    margin-bottom: 0.9rem;
}

.gpt3__footer-links_div p {
    font-size: 12px;
    line-height: 15px;
    font-family: var(--font-family);
    color: #fff;

    margin: 0.5rem 0;
    cursor: pointer;
}

.gpt3__footer-copyright {
    margin-top: 2rem;
    text-align: center;
    width: 100%;
}

.gpt3__footer-copyright p {
    font-size: 12px;
    font-family: var(--font-family);
    line-height: 15px;
    color: #fff;
}

@media screen and (max-width: 850px) {
    .gpt3__footer-heading h1 {
        font-size: 44px;
        line-height: 50px;
    }
}

@media screen and (max-width: 550px) {
    .gpt3__footer-heading h1 {
        font-size: 34px;
        line-height: 42px;
    }

    .gpt3__footer-links div {
        margin: 1rem 0;
    }

    .gpt3__footer-btn p {
        font-size: 14px;
        line-height: 20px;
    }
}

@media screen and (max-width: 400px) {
    .gpt3__footer-heading h1 {
        font-size: 27px;
        line-height: 38px;
    }
}
.bounce-top {
	animation: bounce-top 10s cubic-bezier(0.390, 0.575, 0.565, 1.000) infinite both;
}
/* ----------------------------------------------
 * Generated by Animista on 2023-4-18 17:3:6
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation bounce-top
 * ----------------------------------------
 */
  @keyframes bounce-top {
    0% {
      transform: translateY(-45px);
      animation-timing-function: ease-in;
      opacity: 1;
    }
    24% {
      opacity: 1;
    }
    40% {
      transform: translateY(-24px);
      animation-timing-function: ease-in;
    }
    65% {
      transform: translateY(-12px);
      animation-timing-function: ease-in;
    }
    82% {
      transform: translateY(-6px);
      animation-timing-function: ease-in;
    }
    93% {
      transform: translateY(-4px);
      animation-timing-function: ease-in;
    }
    25%,
    55%,
    75%,
    87% {
      transform: translateY(0px);
      animation-timing-function: ease-out;
    }
    100% {
      transform: translateY(0px);
      animation-timing-function: ease-out;
      opacity: 1;
    }
  }
  

/* ----------------------------------------------
 * Generated by Animista on 2023-1-4 21:23:15
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation heartbeat
 * ----------------------------------------
 */
  @keyframes heartbeat {
    from {
      transform: scale(1);
      transform-origin: center center;
      animation-timing-function: ease-out;
    }
    10% {
      transform: scale(0.91);
      animation-timing-function: ease-in;
    }
    17% {
      transform: scale(0.98);
      animation-timing-function: ease-out;
    }
    33% {
      transform: scale(0.87);
      animation-timing-function: ease-in;
    }
    45% {
      transform: scale(1);
      animation-timing-function: ease-out;
    }
  }
  
.gpt3__header{
    display: flex;
}

.gpt3__header-content{
    flex: 1 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin-right: 5rem;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.gpt3__header-content{
        /* width: 200px;
        height: 200px;
        margin: auto; */
        /* background: url("//blog.codepen.io/wp-content/uploads/2012/06/Button-White-Large.png") no-repeat 50%/70% rgba(0, 0, 0, 0.1); */
        color: #000000;
        background: radial-gradient( circle farthest-corner at -8.9% 51.2%,  rgb(152, 152, 152) 0%, rgb(183, 182, 182) 15.9%, rgb(214, 211, 208) 15.9%, rgb(143, 142, 142) 24.4%, rgb(249, 246, 246) 24.5%, rgb(255, 255, 255) 66% );
        box-shadow: rgba(48, 47, 36, 0.885) 0px 3px 6px, rgba(80, 79, 61, 0.775) 0px 3px 6px;
      }
      .gpt3__header-content::before, .gpt3__header-content::after {
        content: "";
        /* z-index: 211; */
        margin: 5%;
        box-shadow: inset 0 0 0 2px;
        animation: clipMe 8s linear infinite;
      }
      .gpt3__header-content::before {
        animation-delay: -4s;
      }
      .gpt3__header-content:hover::after, .gpt3__header-content:hover::before {
        background-color: rgba(255, 0, 0, 0.3);
      }
      
      @keyframes clipMe {
        0%, 100% {
          clip: rect(0px, 220px, 2px, 0px);
        }
        25% {
          clip: rect(0px, 2px, 220px, 0px);
        }
        50% {
          clip: rect(218px, 220px, 220px, 0px);
        }
        75% {
          clip: rect(0px, 220px, 220px, 218px);
        }
      }

.gpt3__header-content h1{
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 62px;
    line-height: 75px;
    letter-spacing: -0.04em;
}

.gpt3__header-content p{
    font-family: var(--font-family);
    font-size: 20px;
    font-style: normal;
    line-height: 27px;
    color: rgb(92, 92, 92);
    margin-top: 1.5rem;
}

.gpt3__header-content__input{
    width: 100%;
    margin: 2rem 0 1rem;
    display: flex;
}

.gpt3__header-content__input input{
    flex: 2 1;
    width: 100%;
    min-height: 50px;
    background: var(--color-footer);
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    font-family: var(--font-family);
    font-size: 20px;
    line-height: 27px;
    border: 2px solid var(--color-footer);
    padding: 0 1rem;
    outline: none;
    color: black;
}

.gpt3__header-content__input button{
    flex: 0.6 1;
    width: 100%;
    min-height: 50px;
    /* background: #ff4820; */
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    font-family: var(--font-family);
    font-size: 20px;
    font-weight: bold;
    padding: 0.1rem;
    line-height: 27px;
    color: #fff;
    cursor: pointer;
    outline: none;
    border: none;
}

.gpt3__header-content__people{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 2rem;
}

.gpt3__header-content__people img{
    width: 181.79px;
    height: 38px;
}

.gpt3__header-content__people p {
  margin: 0 0 0 1rem;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 12px;
  line-height: 38px;
  color: #fff;
  text-align: center;
}

.gpt3__header-image{
    flex: 1 1;
    justify-content: center;
    align-items: center;
}

.gpt3__header-image .headImg{
    max-width: 100% !important;
    height: auto;
}

@media screen and (max-width: 1050px){
    .gpt3__header{
        flex-direction: column;
    }

    .gpt3__header-content{
        margin: 0 0 3rem;
    }
}

@media screen and (max-width: 650px){
    .gpt3__header h1{
       font-size: 48px;
       line-height: 60px;
    }

    .gpt3__header p{
        font-size: 16px;
        line-height: 24px;
     }

     .gpt3__header-content__people{
        flex-direction: column;
     }
     .gpt3__header-content__people p{
        margin: 0;
     }

     .gpt3__header-content__input input,
     .gpt3__header-content__input button
     {
        font-size: 16px;
        line-height: 24px;
     }
}

@media screen and (max-width: 490px){
    .gpt3__header h1{
        font-size: 36px;
        line-height: 48px;
     }
 
     .gpt3__header p{
         font-size: 14px;
         line-height: 24px;
      }

      .gpt3__header-content__input input,
     .gpt3__header-content__input button
     {
        font-size: 12px;
        line-height: 16px;
     }
}

.wa-float-img-circle {
    width: 90px; height: 90px; bottom: 95px; right: 20px; border-radius: 100%; position: fixed; z-index: 99999; display: flex; transition: all .3s; align-items: center; justify-content: center; background: #25D366; } 
    .wa-float-img-circle img { position: relative; } .wa-float-img-circle:before { position: absolute; content: ''; background-color: #25D366; width: 70px; height: 70px; bottom: -7px; right: -7px; border-radius: 100%; animation: wa-float-circle-fill-anim 2.3s infinite ease-in-out; transform-origin: center; opacity: .2; } .wa-float-img-circle:hover{ box-shadow: 0px 3px 16px #24af588a; } .wa-float-img-circle:focus{ box-shadow: 0px 0 0 3px #25d36645; } .wa-float-img-circle:hover:before, .wa-float-img-circle:focus:before{ display: none; } @keyframes wa-float-circle-fill-anim { 0% { transform: rotate(0deg) scale(0.7) skew(1deg); } 50% { transform: rotate(0deg) scale(1) skew(1deg); } 100% { transform: rotate(0deg) scale(0.7) skew(1deg); } }

    .heartbeat {
        animation: heartbeat 1.5s ease-in-out infinite both;
    }
.gpt3__possibility {
    display: flex;
    flex-direction: row;
    margin-left: auto;
    margin-right: auto;
    width: 92%;
}

.gpt3__possibility-image {
    flex: 1 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    margin-right: 2rem;
}

.gpt3__possibility-image img {
    width: 100%;
    height: 100%;
}

.gpt3__possibility-content {
    flex: 1 1;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex-direction: column;
    box-shadow: rgba(48, 47, 36, 0.885) 0px 3px 6px, rgba(80, 79, 61, 0.775) 0px 3px 6px;
}

.gpt3__possibility-content h4 {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: #F9E2AF;
}

.gpt3__possibility-content h4:last-child {
    color: #ff8A71;
}

.gpt3__possibility-content h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 34px;
    line-height: 45px;
    margin: 1rem 0;
}

.gpt3__possibility-content p {
    color: var(--color-text);
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;

    margin-bottom: 2rem;
}

@media screen and (max-width: 950px) {
    .gpt3__possibility {
        flex-direction: column;
    }

    .gpt3__possibility-image {
        margin: 1rem 0;
    }

    .gpt3__possibility-image img {
        width: unset;
        height: unset;
    }

    .gpt3__possibility-content {
        margin-top: 2rem;
    }
}

@media screen and (max-width: 700px) {
    .gpt3__possibility-image img {
        width: 100%;
        height: 100%;
    }
}

/* Button */

.wrapb {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .buttonb {
    width: 140px;
    height: 45px;
    font-family: 'Roboto', sans-serif;
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 2.5px;
    font-weight: 500;
    color: #000;
    background-color: #fff;
    border: none;
    border-radius: 45px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
    }
  
  .buttonb:hover {
    background-color: #2EE59D;
    box-shadow: 0px 15px 20px rgba(46, 229, 157, 0.4);
    color: #fff;
    transform: translateY(-7px);
  }

  .slider::-webkit-scrollbar {
    display: none;
 }

 .slider{
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
.gpt3__brand{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.gpt3__brand div{
    flex: 1 1;
    max-width: 150px;
    min-width: 120px;
    margin: 1rem;

    display: flex;
    justify-content: center;
    align-items: center;
}
.cardminerals-list {
    z-index: 0;
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  
  .cardminerals {
    margin: 30px auto;
    width: 300px;
    height: 300px;
    border-radius: 40px;
  box-shadow: 5px 5px 30px 7px rgba(0,0,0,0.25), -5px -5px 30px 7px rgba(0,0,0,0.22);
    cursor: pointer;
    transition: 0.4s;
  }
  
  .cardminerals .card_image {
    width: inherit;
    height: inherit;
    border-radius: 40px;
  }
  
  .cardminerals .card_image img {
    width: inherit;
    height: inherit;
    border-radius: 40px;
    object-fit: cover;
  }
  
  .cardminerals .card_title {
    text-align: center;
    border-radius: 0px 0px 40px 40px;
    font-family: sans-serif;
    font-weight: bold;
    font-size: 30px;
    /* margin-top: -80px; */
    height: 40px;
  }
  
  .cardminerals:hover {
    transform: scale(0.9, 0.9);
    box-shadow: 5px 5px 30px 15px rgba(0,0,0,0.25), 
      -5px -5px 30px 15px rgba(0,0,0,0.22);
  }
  
  .title-white {
    color: white;
  }
  
  .title-black {
    color: black;
  }
  
  @media all and (max-width: 500px) {
    .cardminerals-list {
      /* On small screens, we are no longer using row direction but column */
      flex-direction: column;
    }
  }
  
  /* FORM APPLYICATION */
  /* body {
    align-items: center;
    background-color: #000;
    display: flex;
    justify-content: center;
    height: 100vh;
  } */
  
  .form {
    background:  linear-gradient( 97.3deg,  rgba(25,50,70,0.81) 10.7%, rgba(155,65,25,0.72) 39.5%, rgba(255,192,0,0.81) 69.7% );
    border-radius: 20px;
    box-sizing: border-box;
    height: 450px;
    padding: 20px;
    width: 100%;
  }
  
  .title {
    color: #eee;
    font-family: sans-serif;
    font-size: 36px;
    font-weight: 600;
    margin-top: 30px;
  }
  
  .subtitle {
    color: #eee;
    font-family: sans-serif;
    font-size: 16px;
    font-weight: 600;
    margin-top: 10px;
  }
  
  .input-container {
    height: 50px;
    position: relative;
    width: 100%;
  }
  
  .ic1 {
    margin-top: 40px;
  }
  
  .ic2 {
    margin-top: 30px;
  }
  
  .input {
    /* background-color: #303245; */
    border-radius: 12px;
    border: 0;
    box-sizing: border-box;
    
    font-size: 18px;
    height: 100%;
    outline: 0;
    padding: 4px 20px 0;
    width: 100%;
  }
  
  .cut {
    /* background-color: #15172b; */
    border-radius: 10px;
    height: 20px;
    left: 20px;
    position: absolute;
    top: -20px;
    transform: translateY(0);
    transition: transform 200ms;
    width: 100px;
  }
  
  .cut-short {
    width: 50px;
  }
  
  .input:focus ~ .cut,
  .input:not(:placeholder-shown) ~ .cut {
    transform: translateY(8px);
  }
  
  .placeholder {
    color: white;
    font-family: sans-serif;
    left: 20px;
    line-height: 14px;
    pointer-events: none;
    position: absolute;
    transform-origin: 0 50%;
    transition: transform 200ms, color 200ms;
    top: 20px;
  }
  
  .input:focus ~ .placeholder,
  .input:not(:placeholder-shown) ~ .placeholder {
    transform: translateY(-30px) translateX(10px) scale(0.75);
  }
  
  .input:not(:placeholder-shown) ~ .placeholder {
    /* color: #808097; */
  }
  
  .input:focus ~ .placeholder {
    color: #dc2f55;
  }
  
  .submit {
    background-color: orangered;
    border-radius: 12px;
    border: 0;
    box-sizing: border-box;
    color: #eee;
    cursor: pointer;
    font-size: 18px;
    height: 50px;
    margin-top: 38px;
    outline: 0;
    text-align: center;
    width: 100%;
  }
  
  .submit:active {
    background-color: #06b;
  }
 
  .close-Button:hover{
    background-color: grey;
    border: hidden;
  }

.gpt3__cta {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;

    padding: 2rem;
    margin: 4rem;
    border-radius: 1rem;
    background: var(--gradient-bar)
}

.gpt3__cta-content {
    display: flex;
    flex-direction: column;

    text-align: left;
    color: #fff;
}

.gpt3__cta-content p {
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 30px;
    font-weight: 500;
}

.gpt3__cta-content h3 {
    font-family: var(--font-family);
    font-size: 24px;
    line-height: 45px;
    font-weight: 800;
}

.gpt3__cta-btn {
    display: flex;
    justify-content: center;
    align-items: center;

    margin-left: 2rem;
}

.gpt3__cta-btn button {
    background: #000;
    color: #fff;
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 18px;
    line-height: 30px;
    padding: 0.5rem 1rem;
    border-radius: 2rem;

    border: none;
    outline: none;
    cursor: pointer;
    min-width: 150px;
}

@media screen and (max-width: 650px) {
    .gpt3__cta {
        flex-direction: column;
    }

    .gpt3__cta-btn {
        margin: 2rem 0 0;
    }
}

@media screen and (max-width: 550px) {
    .gpt3__cta {
        flex-direction: column;
        margin: 4rem 2rem;
    }

    .gpt3__cta-content h3 {
        font-size: 18px;
        line-height: 32px;
    }

    .gpt3__cta-btn button {
        font-size: 14px;
        line-height: 28px;
    }
}
.gpt3__navbar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 6rem;
}

.gpt3__navbar-links{
    flex: 1 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}


.gpt3__navbar-links_logo{
    margin-right: 2rem;
}

.gpt3__navbar-links_logo img{
    width: 62.56px;
    height: 50.02px;
}

.gpt3__navbar-links_container{
    display: flex;
    flex-direction: row;
}

.gpt3__navbar-sign{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.gpt3__navbar-links_container p,
.gpt3__navbar-sign p,
.gpt3__navbar-menu_container p {
    color: #fff;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    text-transform: capitalize;
    margin: 0.6rem;
    cursor: pointer;
}

.gpt3__navbar-sign button,
.gpt3__navbar-menu_container button {
    padding: 0.5rem 1rem;
    font-family: var(--font-family);
    color: #fff;
    font-weight: 500;
    font-size: 18px;
    left: 1153px;
    top:55px;
    background: #ff4820;
    border-radius: 5px;
    border: 0;
    outline: none;
    cursor: pointer;
}

.gpt3__navbar-menu {
    margin-left: 1rem;
    display: none;
    position: relative;
    color: #000000;
}

.gpt3__navbar-menu svg{
    cursor: pointer;
}

.gpt3__navbar-menu_container{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    text-align: end;
    background: var(--color-footer);
    padding: 2rem;
    z-index: 2;
    position: absolute;
    top: 40px;
    right: 0;
    margin-top: 1rem;
    min-width: 210px;
    border-radius: 5px;
    box-shadow: 0 0 5 rgba(0,0,0, 0.2);
}

.gpt3__navbar-menu_container p {
    margin: 1rem 0;
}

.gpt3__navbar-menu_container-links-sign {
    display: none;
}

@media screen and (max-width: 1050px){
    .gpt3__navbar-links_container{
        display: none;
    }

    .gpt3__navbar-menu{
        display: flex;
    }
}


@media screen and (max-width: 700px){
    .gpt3__navbar{
        padding: 2rem 4rem;
    }
    
}


@media screen and (max-width: 700px){
    .gpt3__navbar{
        padding: 2rem;
    }

    .gpt3__navbar-sign{
        display: none;
    }

    svg {
        visibility: visible !important;
    }
    .gpt3__navbar-menu_container{
        top: 23px;
    }

    .gpt3__navbar-menu_container-links-sign{
        display: block;
    }
}

.nav_link:hover{
    background-color: orange;
}
:root {
  --color-primary: orange;
  --color-secondary: #101118;
  --color-accent: #1D1F2F;
  --color-focus: #6D64F7;
  --base-duration: 600ms;
  --base-ease: cubic-bezier(0.25, 0.46, 0.45, 0.84);
}

*, *:before, *:after {
  box-sizing: border-box;
}

html, body {
  height: 100%;
}



#app {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  overflow-x: hidden;
  width: 100%;
}

h1, h2, h3 {
  font-family: "Playfair Display", serif;
}

.visuallyhidden {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute !important;
  white-space: nowrap;
  width: 1px;
}

.icon {
  fill: var(--color-primary);
  width: 100%;
}

.slidebtn {
  /* background-color: var(--color-primary); */
  border: none;
  border-radius: 0.125rem;
  color: white;
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  padding: 1rem 2.5rem 1.125rem;
}
.slidebtn:focus {
  /* outline-color: var(--color-focus); */
  outline-offset: 2px;
  outline-style: solid;
  outline-width: 3px;
}
.slidebtn:active {
  transform: translateY(1px);
}

.slider__controls {
  display: flex;
  justify-content: center;
  position: absolute;
  top: calc(100% + 1rem);
  width: 100%;
}
.slider__controls .slidebtn {
  --size: 3rem;
  align-items: center;
  background-color: transparent;
  border: 3px solid transparent;
  border-radius: 100%;
  display: flex;
  height: var(--size);
  padding: 0;
  width: var(--size);
}
.slider__controls .slidebtn:focus {
  /* border-color: var(--color-focus); */
  outline: none;
}
.slider__controls .btn--previous > * {
  transform: rotate(180deg);
}

.slider {
  --slide-size: 56vmin;
  --slide-margin: 4vmin;
  height: var(--slide-size);
  margin-left:  auto;
  margin-right:  auto;
  margin-bottom: 150px;
  position: relative;
  width: var(--slide-size);
}

.slider__wrapper {
  display: flex;
  margin: 0 calc(var(--slide-margin) * -1);
  position: absolute;
  transition: transform var(--base-duration) cubic-bezier(0.25, 1, 0.35, 1);
}

.slide {
  align-items: center;
  color: black;
  display: flex;
  flex: 1 1;
  flex-direction: column;
  height: var(--slide-size);
  justify-content: center;
  margin: 0 var(--slide-margin);
  opacity: 0.25;
  position: relative;
  text-align: center;
  transition: opacity calc(var(--base-duration) / 2) var(--base-ease), transform calc(var(--base-duration) / 2) var(--base-ease);
  width: var(--slide-size);
  z-index: 1;
}
.slide--previous:hover, .slide--next:hover {
  opacity: 0.5;
}
.slide--previous {
  cursor: w-resize;
}
.slide--previous:hover {
  transform: translateX(2%);
}
.slide--next {
  cursor: e-resize;
}
.slide--next:hover {
  transform: translateX(-2%);
}

.slide--current {
  --x: 0;
  --y: 0;
  --d: 50;
  opacity: 1;
  pointer-events: auto;
  -webkit-user-select: auto;
          user-select: auto;
}
@media (hover: hover) {
  .slide--current:hover .slide__image-wrapper {
    transform: scale(1.025) translate(calc(var(--x) / var(--d) * 1px), calc(var(--y) / var(--d) * 1px));
  }
}

.slide__image-wrapper {
  /* background-color: var(--color-accent); */
  border-radius: 1%;
  height: 100%;
  left: 0%;
  overflow: hidden;
  position: absolute;
  top: 0%;
  transition: transform calc(var(--base-duration) / 4) var(--base-ease);
  width: 100%;
}

.slide__image {
  --d: 20;
  height: 110%;
  left: -5%;
  object-fit: cover;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: -5%;
  transition: opacity var(--base-duration) var(--base-ease), transform var(--base-duration) var(--base-ease);
  -webkit-user-select: none;
          user-select: none;
  width: 110%;
}
@media (hover: hover) {
  .slide--current .slide__image {
    transform: translate(calc(var(--x) / var(--d) * 1px), calc(var(--y) / var(--d) * 1px));
  }
}

.slide__headline {
  font-size: 8vmin;
  font-weight: 600;
  position: relative;
}

.slide__content {
  --d: 60;
  opacity: 0;
  padding: 4vmin;
  position: relative;
  transition: transform var(--base-duration) var(--base-ease);
  visibility: hidden;
}
.slide--current .slide__content {
  animation: fade-in calc(var(--base-duration) / 2) var(--base-ease) forwards;
  visibility: visible;
}
@media (hover: hover) {
  .slide--current .slide__content {
    transform: translate(calc(var(--x) / var(--d) * -1px), calc(var(--y) / var(--d) * -1px));
  }
}
.slide__content > * + * {
  margin-top: 2rem;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* html, body {
    height: 100%;
  }
  
  html {
    background: #eee;
  }
  
  body {
    display: flex;
    justify-content: center;
    align-items: center;
  } */
  
  .box {
    width: 100vw;
    height: 70vh;
    border-radius: 5px;
    box-shadow: 0 2px 30px rgba(0, 0, 0, 0.2);
    background: white;
    position: relative;
    margin: 15px;
    overflow: hidden;
    transform: translate3d(0, 0, 0);
  }
  
  .wave {
    opacity: 0.2;
    position: absolute;
    background: rgb(3, 40, 59);
    width: 160vw;
    height: 160vw;
    transform-origin: 50% 48%;
    border-radius: 43%;
    animation: drift 16000ms infinite linear;
    left: -25vw;
    top: -120vw;
  }
  
  .wave.-two {
    animation: drift 20000ms infinite linear;
    opacity: 0.2;
    background: white;
  }
  
  .wave.-three {
    animation: drift 18000ms infinite linear;
  }
  
  .box:after {
    content: "";
    display: block;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 11;
    transform: translate3d(0, 0, 0);
  }
  
  .box-header {
    height: 30vw;
    background-image: -o-linear-gradient(right, #0c93C0, #FFF);
    background: linear-gradient(to bottom, #121b53, rgba(221, 238, 255, 0) 80%, rgba(0, 0, 0, 0));
    z-index: 20;
    position: relative;
  }
  
  .title {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 40;
    line-height: 300px;
    text-align: center;
    transform: translate3d(0, 0, 0);
    color: white;
    text-transform: uppercase;
    font-family: "Playfair Display", serif;
    letter-spacing: 0.4em;
    font-size: 30px;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
    text-indent: 0.3em;
  }
  
  @keyframes drift {
    from {
      transform: rotate(0deg);
    }
    from {
      transform: rotate(360deg);
    }
  }
.demo-wrapper {
    padding: 2em .5em;
    width: 100%;
    height:100%;
    perspective: 3300px;
    position: relative;
  }

  .dashboard {
    margin: 0 auto;
    width: 100%;
    padding: 1em;
    transform: translateX(200px);
    opacity:0;
    animation: start 1s ease-out forwards;
  }
  @keyframes start{
    0%{
      transform: translateX(200px);
      opacity:0;
    }
    50%{
      opacity:1;
    }
    100%{
      transform: translateX(0);
      opacity:1;
    }
  }

  .fadeOutback{
    animation: fadeOutBack 0.3s ease-out 1 normal forwards;
   }
   .fadeInForward-1, .fadeInForward-2, .fadeInForward-3 {
     /*remember: in the second animation u have to set the final values reached by the first one*/
     opacity:0;
     transform: translateZ(-5em) scale(0.75);
     animation: fadeInForward .5s cubic-bezier(.03,.93,.43,.77) .4s normal forwards;
   }

   .fadeInForward-2{
     animation-delay: .55s;
   }
   .fadeInForward-3{
     animation-delay: .7s;
   }

   @keyframes fadeOutBack{
     0% {transform: translateX(-2em) scale(1); opacity:1;}
     70% {transform: translateZ(-5em) scale(0.6); opacity:0.5;}
     95% {transform: translateZ(-5em) scale(0.6); opacity:0.5;}
     100% {transform: translateZ(-5em) scale(0); opacity:0;}
   }
   @keyframes fadeInForward{
     0% {transform: translateZ(-5em) scale(0); opacity:0;}
     100% {transform: translateZ(0) scale(1); opacity:1;}
   }

   .r-page {
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: 2em;
    font-weight: 300;
    position: absolute;
    right: 0;
    top: 0;
    left:0;
    bottom:0;
    opacity: 0;
    color: white;
    z-index: 10;
    padding:10px;
    transform-origin: 100% 0%;
    transform: rotateY(-90deg) translateZ(5em)
  }

  .s-page {
    color: white;
    z-index: 10;
    text-align: center;
    font-size: 2em;
    font-weight: 300;
  }
  .page-content{
    overflow-y:auto;
    max-height:100%;
    font-size:.6em;
    padding:.6em;
    text-align:left;
  }

  .s-page, .r-page{
    background-color: white;
    color:black;
  }

  .page-title {
    margin: .25em 0;
    font-weight: 100;
    font-size: 3em;
    text-align:center;
  }

  .close-button {
    font-size: 1.5em;
    width: 1em;
    height: 1em;
    position: absolute;
    top: .75em;
    right: .75em;
    cursor: pointer;
    line-height: .8em;
    text-align: center
  }

   /* opens the r-page type pages*/
   .openpage{ 
    animation: rotatePageInFromRight 1s cubic-bezier(.66,.04,.36,1.03) 1 normal forwards;
  }
  /* closes the r-page type pages */
  .slidePageLeft{
    transform: rotateY(0) translateZ(0) ; opacity:1;
    animation:slidePageLeft .8s ease-out 1 normal forwards;
  }
  /* opens the s-page type pages */
  .slidePageInFromLeft{
    animation: slidePageInFromLeft .8s cubic-bezier(.01,1,.22,.99) 1 0.25s normal forwards;
  }
  /* closes the s-page type pages*/
  .slidePageBackLeft{
    opacity:1; 
    left:0;
    animation: slidePageBackLeft .8s ease-out 1 normal forwards;
  }

  @keyframes rotatePageInFromRight{
    0% {transform:rotateY(-90deg) translateZ(5em);opacity:0}
    30% {opacity:1}
    100% {transform: rotateY(0deg) translateZ(0) ; opacity:1}
  }

  /*When the close-button is clicked, the page slides to the left*/
  /*note that the start of the second animation is the same state as the
  end of the previous one*/

  @keyframes slidePageLeft{
    0% {left:0; transform: rotateY(0deg) translateZ(0) ; opacity:1}
    70% {opacity:1;}
    100% {opacity:0; left:-150%; transform: rotateY(0deg)}
  }
  @keyframes slidePageInFromLeft{
    0% {opacity:0; }
    30% {opacity:1}
    100% {opacity:1; left:0;}
  }
  @keyframes slidePageBackLeft{
    0% {opacity:1; left:0; transform: scale(0.95);}
    10% {transform: scale(0.9);}
    70% {opacity:1;}
    100% {opacity:0; left:-150%;}
  }

  .tile{
    float: left;
    margin: 0 auto 1%;
    color: white;
    font-size: 1.3em;
    text-align: center;
    height: 8em;
    font-weight: 300;
    overflow: hidden;
    cursor: pointer;
    position:relative;
    background-color: #fff;
    color: #333;
    position:relative;
    transition: background-color 0.2s ease-out
  }
  .tile-2xbig{
    height:16.15em;
    width:100%;
  }
  .tile-big {
    width: 100%
  }
  .tile-small {
    width: 49%;
    margin-right: 2%
  }
  .tile-small.last {
    margin-right: 0
  }

  .tile-caption{
    position:absolute;
    z-index:1;
    background-color: #455962;
    color:#fff;
    font-size:1em;
    padding:1em;
    text-align: left;
  }
  .caption-bottom{
    left:0;
    bottom:0;
    right:0;
    height:40%;
  }
  .caption-left{
    left:-100%;
    top:0;
    bottom:0;
    width:40%;
    transition: left .3s linear;
  }
  .tile:hover .caption-left{
    left:0;
  }

  .tile div{
    position:absolute;
    top:0; left:0; right:0; bottom:0;
    width:100%;
    height:100%;
    text-align:center;
    display:table;
    padding:0 1em;
    transition: all .3s ease;
  }
  .tile div p{
    display:table-cell;
    vertical-align:middle;
  }

  /* slide text inside tile up */
            /* 2nd div will be positioned below the bottom of the tile*/
            .slideTextUp div:nth-child(2){
                top:100%;
              }
              /*both divs will be translated up on hover*/
              .slideTextUp:hover div{
                transform: translateY(-100%);
              }
              .tile-1 p{
                font-size:1.3em;
              }

    /* slide text inside tile to the right*/
    .slideTextRight div:first-child{
        left:-100%;
      }
      .slideTextRight:hover div{
        transform: translateX(100%);
      } 

      /* slide text inside tile to the left */
      .slideTextLeft div:nth-child(2){
        left:100%;
      }
      .slideTextLeft:hover div{
        transform: translateX(-100%);
      } 

         /* rotate tile in 3D*/
         .rotate3d{
            /* add a perspective to the tile to activate 3d space inside it*/
            perspective: 800px;
            overflow: visible;
          }
          .faces{
            /* preserve the 3d space in the container wrapping the two "faces" and define a transition */
            transform-style: preserve-3d;
            transition: transform 1s;
          }
          .faces div {
            /* position faces on top of each other */
            display: block;
            position: absolute;
            top:0; left:0; right:0; bottom:0;
            width: 100%;
            height: 100%;
            /* hide backface visibility so that the back of the face is hidden when it's rotated */
            -webkit-backface-visibility: hidden;
                    backface-visibility: hidden;
          }

          .rotate3dY:hover .faces:hover{
            transform: rotateY( 180deg );
        }
        .rotate3dX:hover .faces:hover{
            transform: rotateX( 180deg );
        }

        .col1,
            .col2,
            .col3 {
              width: 99%;
              margin: 1em auto
            }
            @media screen and (min-width: 43.75em) {
              .col1,
              .col2,
              .col3 {
                float: left;
                margin-right: 1%;
                width: 49%
              }
              .page-title{
                font-size:2.5em;
              }
              .page-content{
                font-size:1em;
              }
              .close-button{
                font-size:2em;
              }
            }

            @media screen and (min-width: 64em) {
              .col1,
              .col2,
              .col3 {
                float: left;
                margin-right: .5%;
                width: 31%
              }

              .col3 {
                margin-right: 0
              }

              .col1 {
                margin-left: 2em
              }
              .page-title{
                font-size:3.5em;
              }
            }
#container {
  position: relative;
  margin: auto;
  /* overflow: hidden; */
  width: 65%;
  height: 60vh;
}

.h1pop {
  font-size: 1.6em !important;
  font-weight: 100;
  letter-spacing: 3px;
  padding-top: 5px;
  color: #FCFCFC;
  padding-bottom: 5px;
  text-transform: uppercase;
}

.green {
  color: #4ec07d;
}

.red {
  color: #e96075;
}

.alert {
  font-weight: 700;
  letter-spacing: 5px;
}

.pPop {
  margin-top: -5px;
  font-size: 1.5em;
  font-weight: 100;
  color: black;
  letter-spacing: 1px;
}

button, .dot {
  cursor: pointer;
}

#success-box {
  width: 80%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  background: linear-gradient(to bottom right, #B0DB7D 40%, #99DBB4 100%);
  border-radius: 20px;
  box-shadow: 5px 5px 20px rgba(203, 205, 211, 0.1);
  perspective: 40px;
}

#error-box {
  width: 80%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  background: linear-gradient(to bottom left, #EF8D9C 40%, #FFC39E 100%);
  border-radius: 20px;
  box-shadow: 5px 5px 20px rgba(203, 205, 211, 0.1);
}

.dot {
  width: 8px;
  height: 8px;
  background: #FCFCFC;
  border-radius: 50%;
  position: absolute;
  top: 4%;
  right: 15%;
}
.dot:hover {
  background: orangered;
}

.two {
  right: 12%;
  opacity: 0.5;
}

.face {
  position: absolute;
  width: 22%;
  height: 35%;
  background: #FCFCFC;
  border-radius: 50%;
  border: 1px solid #777777;
  top: 9%;
  left: 37.5%;
  z-index: 2;
  animation: bounce 1s ease-in infinite;
}

.face2 {
  position: absolute;
  width: 15%;
  height: 25%;
  background: #FCFCFC;
  border-radius: 50%;
  border: 1px solid #777777;
  top: 9%;
  left: 37.5%;
  z-index: 2;
  animation: roll 3s ease-in-out infinite;
}

/* @media (min-width:600px)  { portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) }
@media (min-width:801px)  { tablet, landscape iPad, lo-res laptops ands desktops }
@media (min-width:1025px) { big landscape tablets, laptops, and desktops }
@media (min-width:1281px) { hi-res laptops and desktops } */
.eye {
  position: absolute;
  width: 5px;
  height: 5px;
  background: #777777;
  border-radius: 50%;
  top: 40%;
  left: 20%;
}

.right {
  left: 68%;
}

.mouth {
  position: absolute;
  top: 43%;
  left: 41%;
  width: 7px;
  height: 7px;
  border-radius: 50%;
}

.happy {
  border: 2px solid;
  border-color: transparent #777777 #777777 transparent;
  transform: rotate(45deg);
}

.sad {
  top: 49%;
  border: 2px solid;
  border-color: #777777 transparent transparent #777777;
  transform: rotate(45deg);
}

.shadow {
  position: absolute;
  width: 21%;
  height: 5%;
  opacity: 0.5;
  background: #777777;
  left: 40%;
  top: 43%;
  border-radius: 50%;
  z-index: 1;
}

.scale {
  animation: scale 1s ease-in infinite;
}

.move {
  animation: move 3s ease-in-out infinite;
}

.popmessage {
  position: relative;
  width: 100%;
  text-align: center;
  height: 40%;
  top: 47%;
}

.button-box {
  position: absolute;
  background: #FCFCFC;
  width: 50%;
  height: 15%;
  border-radius: 20px;
  top: 73%;
  left: 25%;
  outline: 0;
  border: none;
  box-shadow: 2px 2px 10px rgba(119, 119, 119, 0.5);
  transition: all 0.5s ease-in-out;
}

.button-boxx {
  position: relative;
  background: #FCFCFC;
  width: 50%;
  height: 15%;
  border-radius: 20px;
  outline: 0;
  border: none;
  top: 25%;
  left: 25%;
  box-shadow: 2px 2px 10px rgba(119, 119, 119, 0.5);
  transition: all 0.5s ease-in-out;
}

.button-box:hover {
  background: #efefef;
  transform: scale(1.05);
  transition: all 0.3s ease-in-out;
}

.button-boxx:hover {
  background: #efefef;
  transform: scale(1.05);
  transition: all 0.3s ease-in-out;
}
@keyframes bounce {
  50% {
    transform: translateY(-10px);
  }
}
@keyframes scale {
  50% {
    transform: scale(0.9);
  }
}
@keyframes roll {
  0% {
    transform: rotate(0deg);
    left: 25%;
  }
  50% {
    left: 60%;
    transform: rotate(168deg);
  }
  100% {
    transform: rotate(0deg);
    left: 25%;
  }
}
@keyframes move {
  0% {
    left: 25%;
  }
  50% {
    left: 60%;
  }
  100% {
    left: 25%;
  }
}


.listCont {
  font-family: sans-serif;
  font-size: 18px;
  text-align: center;
}
.search{
  color: black
}
.holder {
  width: 50%;
  margin: 50px auto 20px;
  color: "white";
  padding: 20px;
}

ul {
  list-style: none;
}

ul li {
  font-size: 18px;
  overflow: hidden;
  padding-bottom: 20px;
}

ul li:last-child {
  margin-bottom: 0;
}

.listImage {
  float: left;
  display: block;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-right: 10px;
}

span {
  /* display: block; */
  width: 100%;
  /* font-size: 18px; */
  font-weight: bold;
}

span.phone {
  font-weight: normal;
  font-style: italic;
  font-size: 18px;
  color: #999;
}

.gpt3__whatgpt3 {
     display: flex;
     flex-direction: column;
     background: var(--color-footer);

     padding: 2rem;
     background-image: linear-gradient( 97.3deg,  rgba(25,50,70,0.81) 10.7%, rgba(250, 81, 9, 0.72) 39.5%, rgba(255,192,0,0.81) 69.7% );
}


.gpt3__whatgpt3-feature {
    display: flex;
}

/* Customizing feature component as per needs */
.gpt3__whatgpt3-feature .gpt3__features-container__feature {
    margin: 0;
}

/* Customizing feature component as per needs */
.gpt3__whatgpt3-feature .gpt3__features-container_feature-text {
    max-width: 700px;
}

.gpt3__whatgpt3-heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    
    margin: 4rem 0 2rem;
}

.gpt3__whatgpt3-heading h1 {
    font-size: 34px;
    line-height: 45px;
    font-weight: 800;
    font-family: var(--font-family);
    max-width: 510px;
}

.gpt3__whatgpt3-heading p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: var(--color-subtext);
    cursor: pointer;
}

.gpt3__whatgpt3-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    
    margin-top: 2rem;
}


/* Customizing feature component as per needs */
.gpt3__whatgpt3-container .gpt3__features-container__feature {
    flex: 1 1;
    margin: 1rem;
    min-width: 210px;
    display: unset;
    flex-direction: column;
}

/* Customizing feature component as per needs */
.gpt3__whatgpt3-container .gpt3__features-container_feature-text {
    margin-top: 0.5rem;
}

@media screen and (max-width: 850px) {
    .gpt3__whatgpt3-heading {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 0rem;
    }

    .gpt3__whatgpt3-heading p {
        margin-top: 1rem;
    }
}

@media screen and (max-width: 650px) {
    /* Customizing feature component as per needs */
    .gpt3__whatgpt3-feature .gpt3__features-container__feature {
        flex-direction: column;
    }

    /* Customizing feature component as per needs */
    .gpt3__whatgpt3-feature .gpt3__features-container_feature-text {
        margin-top: 0.5rem;
    }

    .gpt3__whatgpt3-heading h1 {
        font-size: 28px;
        line-height: 40px;
    }
}

@media screen and (max-width: 350px) {
    /* Customizing feature component as per needs */
    .gpt3__whatgpt3-container .gpt3__features-container__feature {
        margin: 1rem 0;
        min-width: 100%;
    }
}

/* Please ❤ this if you like it! */

/* ========================================= * 
		        BEST VIEWED FULLSCREEN
   https://codepen.io/ig_design/full/NWxwBvw
 * ========================================= */
 

 
 .section {
   position: relative;
   width: 100%;
   display: block;
 }
 .full-height {
   min-height: 100vh;
 }
 .over-hide {
   overflow: hidden;
 }
 .padding-tb {
   padding: 100px 0;
 }
 [type="radio"]:checked,
 [type="radio"]:not(:checked) {
   position: absolute;
   left: -9999px;
 }
 .checkbox:checked + label,
 .checkbox:not(:checked) + label {
   position: relative;
   cursor: pointer;
   margin: 0 auto;
   text-align: center;
   margin-right: 6px;
   margin-left: 6px;
   display: inline-block;
   width: 50px;
   height: 50px;
   border: 3px solid #bdc3c7;
   background-size: cover;
   background-position: center;
   box-sizing: border-box;
   transition: all 0.2s ease;
   background-image: url("https://st4.depositphotos.com/5344180/30128/i/600/depositphotos_301284170-stock-photo-elemental-chromium-specimen-sample-isolated.jpg");
   animation: border-transform 6s linear infinite alternate forwards;
   animation-play-state: paused;
 }
 .checkbox.scnd + label {
   background-image: url("https://imagizer.imageshack.com/v2/735x348q70/923/cnwtbH.jpg");
 }
 .checkbox.thrd + label {
   background-image: url("https://st4.depositphotos.com/8707842/24741/i/600/depositphotos_247413936-stock-photo-natural-coal-black-background-isolated.jpg");
 }
 .checkbox.foth + label {
   background-image: url("https://st2.depositphotos.com/1008404/5970/i/600/depositphotos_59707589-stock-photo-copper-background.jpg");
 }
 
 .checkbox:checked + label {
   box-shadow: 0 8px 25px 0 rgba(16, 39, 112, 0.3);
   transform: scale(1.3);
   animation-play-state: running;
 }
 @keyframes border-transform {
   0%,
   100% {
     border-radius: 63% 37% 54% 46% / 55% 48% 52% 45%;
   }
   14% {
     border-radius: 40% 60% 54% 46% / 49% 60% 40% 51%;
   }
   28% {
     border-radius: 54% 46% 38% 62% / 49% 70% 30% 51%;
   }
   42% {
     border-radius: 61% 39% 55% 45% / 61% 38% 62% 39%;
   }
   56% {
     border-radius: 61% 39% 67% 33% / 70% 50% 50% 30%;
   }
   70% {
     border-radius: 50% 50% 34% 66% / 56% 68% 32% 44%;
   }
   84% {
     border-radius: 46% 54% 50% 50% / 35% 61% 39% 65%;
   }
 }
 
 .slider-height-padding {
   padding-top: 440px;
 }
 
 .ul {
   position: absolute;
   top: 0;
   left: 0;
   display: block;
   width: 100%;
   z-index: 100;
   padding: 0;
   margin: 0;
   list-style: none;
 }
 .ul .li {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   display: block;
   z-index: 100;
   padding: 0;
   margin: 0;
   list-style: none;
   height: 400px;
   border: 5px solid #bdc3c7;
   background-size: cover;
   background-position: center;
   background-image: url("https://st4.depositphotos.com/5344180/30128/i/600/depositphotos_301284170-stock-photo-elemental-chromium-specimen-sample-isolated.jpg");
   border-radius: 50%;
   box-sizing: border-box;
   font-family: "Poppins", sans-serif;
   font-weight: 900;
   /* font-size: 16px; */
   letter-spacing: 2px;
   line-height: 2.7;
   color: #343434;
   writing-mode: vertical-rl;
   opacity: 0;
   pointer-events: none;
   box-shadow: 0 8px 25px 0 rgba(16, 39, 112, 0.1);
   transition: all 0.5s ease;
 }
 .ul .li span {
   mix-blend-mode: difference;
 }
 .ul .li:nth-child(2) {
   background-image: url(/static/media/irrigatorX.0504dcbc.jpg);
 }
 .ul .li:nth-child(3) {
   background-image: url("https://st4.depositphotos.com/8707842/24741/i/600/depositphotos_247413936-stock-photo-natural-coal-black-background-isolated.jpg");
 }
 .ul .li:nth-child(4) {
   background-image: url("https://st2.depositphotos.com/1008404/5970/i/600/depositphotos_59707589-stock-photo-copper-background.jpg");
 }
 
 .checkbox.frst:checked ~ .ul .li:nth-child(1) {
   opacity: 1;
   pointer-events: auto;
   border-radius: 16px;
 }
 .checkbox.scnd:checked ~ .ul .li:nth-child(2) {
   opacity: 1;
   pointer-events: auto;
   border-radius: 16px;
 }
 .checkbox.thrd:checked ~ .ul .li:nth-child(3) {
   opacity: 1;
   pointer-events: auto;
   border-radius: 16px;
 }
 .checkbox.foth:checked ~ .ul .li:nth-child(4) {
   opacity: 1;
   pointer-events: auto;
   border-radius: 16px;
 }
 
 
 @media (max-width: 767px) {
   .slider-height-padding {
     padding-top: 340px;
   }
   .ul .li {
     height: 300px;
     font-size: 13px;
     letter-spacing: 1px;
   }
 }
 
 @media (max-width: 575px) {
   .slider-height-padding {
     padding-top: 240px;
   }
   .ul .li {
     height: 200px;
   }
   .text_slider_body {
    width: 100% !important;
    background-color: green;
  }
  .typing-slider{
    width: 100% !important;
    padding: 30px;
  }
 }


 /* TEXT SLIDER */
 .text_slider_body {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  height: 30vh;
  background-color: #FFCC00;
}

@keyframes cursor {
  from, to {
    border-color: transparent;
  }
  50% {
    border-color: black;
  }
}
@keyframes typing {
  from {
    width: 100%;
  }
  90%, to {
    width: 0;
  }
}
@keyframes slide {
  33.3333333333% {
    font-size: 3rem;
    letter-spacing: 3px;
  }
  to {
    font-size: 0;
    letter-spacing: 0;
  }
}
.typing-slider {
  font-family: Consolas, monospace;
  font-weight: bold;
  text-align: center;
  white-space: nowrap;
}

.typing-slider p {
  position: relative;
  display: inline;
  font-size: 0;
  text-transform: uppercase;
  letter-spacing: 0;
  animation: slide 15s step-start infinite;
}

.typing-slider p::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border-left: 3px solid black;
  background-color: #FFCC00;
  animation: typing 5s infinite, cursor 1s infinite;
}

.typing-slider p:nth-child(1) {
  animation-delay: 0s;
}
.typing-slider p:nth-child(1)::after {
  animation-delay: 0s;
  animation-timing-function: steps(16), step-end;
}

.typing-slider p:nth-child(2) {
  animation-delay: 5s;
}
.typing-slider p:nth-child(2)::after {
  animation-delay: 5s;
  animation-timing-function: steps(23), step-end;
}

.typing-slider p:nth-child(3) {
  animation-delay: 10s;
}
.typing-slider p:nth-child(3)::after {
  animation-delay: 10s;
  animation-timing-function: steps(12), step-end;
}
 
/* Include */
@font-face {
    font-family: "monument_extendedregular";
    src: url("https://www.yudiz.com/codepen/photography-banner/monumentextended-regular.woff2")
      format("woff2");
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: "Extenda Trial 20 Micro";
    src: url("https://www.yudiz.com/codepen/photography-banner/Extenda-20Micro.woff2")
      format("woff2");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: "Extenda Trial 30 Deca";
    src: url("https://www.yudiz.com/codepen/photography-banner/Extenda-30Deca.woff2")
      format("woff2");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  
  /* Common CSS */
  body,
  html {
    overflow-x: unset !important;
    overflow-y: unset !important;
  }
  
  body {
    margin: 0;
    background-color: #212121 !important;
    background-image: url("https://www.yudiz.com/codepen/photography-banner/frame.png") !important;
    z-index: 10;
  }
  
  ::selection {
    background-color: rgba(241, 231, 40, 0.2);
    color: #ffffff;
  }
  
  /* Demo css -start */
  .info-section {
    height: 100svh;
    min-height: 780px;
    padding: 0 0 0 30px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    z-index: 1;
    -webkit-user-select: none;
            user-select: none;
  }
  
  .info-section::before {
    content: "";
    border-radius: 197.5px 0px;
    opacity: 0.4;
    background: var(--yellow, #f1e728);
    filter: blur(162px);
    height: 35%;
    width: 55%;
    position: absolute;
    top: -40%;
    left: -66%;
    transform: translate(50%, 50%);
    z-index: -1;
  }
  
  /* left part */
  .left-part {
    padding: 20px 0 0;
    overflow: hidden;
  }
  
  .left-part h1 {
    margin: 0;
    color: #fff;
    font-family: "Extenda Trial 30 Deca";
    /* font-size: clamp(100px, 18.5vw, 282px); */
    font-size: 40px;
    line-height: 0.75;
    font-weight: normal;
    font-style: normal;
    text-transform: uppercase;
  }
  
  .left-part h1 .text {
    color: #f1e728;
    display: block;
    height: 200px;
    font-size: 200px !important;
  }
  
  .left-part h1 .d-flex {
    display: flex;
    align-items: center;
    font-size: 100px !important;
    margin-bottom: 15px;
  }
  
  .left-part h1 .char {
    transform: translateY(-515px);
    transition: transform 0.5s;
  }
  
  .typed-cursor {
    display: none !important;
  }
  
  .left-part p {
    width: 72%;
    margin: 20px 0 0;
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    line-height: 2;
    font-family: "monument_extendedregular";
    opacity: 0.8;
  }
  
  /* button */
  .book-link {
    margin: 40px 0 0;
    padding: 0;
    border: 0;
    font-size: 56px;
    line-height: 1;
    color: #f1f1f1;
    letter-spacing: 0.25px;
    text-transform: uppercase;
    font-family: "Extenda Trial 20 Micro";
    font-weight: 300;
    font-style: normal;
    display: inline-flex;
    align-items: center;
    grid-gap: 28px;
    gap: 28px;
    position: relative;
    text-decoration: none;
  }
  
  .book-link .linktext {
    position: relative;
    overflow: hidden;
    display: inline-block;
  }
  
  .book-link .linktext::before {
    position: absolute;
    content: "";
    left: 0;
    bottom: 6px;
    width: 100%;
    height: 3px;
    background-color: #ffffff;
    transform: scaleX(1);
    transition: transform 250ms ease-in-out;
    transform-origin: 0 0;
  }
  
  .book-link:hover .linktext:before {
    transform: scaleX(0);
    transform-origin: 100% 100%;
  }
  
  .book-link .arrow {
    height: 36px;
    width: 36px;
    top: -5px;
    display: inline-block;
    position: relative;
    overflow: hidden;
  }
  
  .book-link .arrow::before,
  .book-link .arrow::after {
    position: absolute;
    content: "";
    background-color: #f1e728;
    transition: all ease-in-out 0.35s;
    transform-origin: 0 0;
    border-radius: 30px;
  }
  
  .book-link .arrow::before {
    height: 2px;
    width: 100%;
    top: 0;
    right: 0;
  }
  
  .book-link .arrow::after {
    width: 2px;
    height: 100%;
    top: 0;
    right: 0;
  }
  
  .book-link:hover .arrow::before {
    width: 65%;
  }
  
  .book-link:hover .arrow::after {
    height: 65%;
  }
  
  .book-link .arrow span {
    background-color: #f1e728;
    height: 2px;
    width: 100%;
    display: inline-block;
    transform: rotate(-45deg) translate(-3px, -1px);
    transform-origin: right top;
    border-radius: 30px;
    position: relative;
    transition: all ease-in-out 0.35s;
    position: absolute;
    top: 0;
    left: 0;
  }
  
  .book-link .arrow span::before {
    background-color: #f1e728;
    content: "";
    height: 100%;
    width: 15px;
    left: -15px;
    top: 0;
    position: absolute;
  }
  
  /* right part */
  .right-part {
    background-color: transparent;
    height: 588px;
    width: 588px;
    margin: 0 0 0 auto;
    margin-right: -14px;
    display: block;
    position: relative;
    z-index: 1;
    flex-shrink: 0;
  }
  
  .right-part::before {
    content: "";
    border-radius: 197.5px 0px;
    opacity: 0.4;
    background: #f1e728;
    filter: blur(112px);
    height: 35%;
    width: 55%;
    position: absolute;
    top: 50%;
    right: 33%;
    transform: translate(50%, -50%);
    z-index: -1;
  }
  
  .right-part .d-flex {
    height: 100%;
    grid-gap: 24px;
    gap: 24px;
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    position: relative;
  }
  
  .main-grid {
    position: relative;
  }
  
  .boxxx {
    width: calc((100% / 3) - 16px);
    height: calc((100% / 3) - 16px);
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #555555;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    color: #ffffff;
    font-family: "monument_extendedregular";
    border-radius: 120px;
    position: absolute;
    animation: 30s infinite;
  }
  
  /* Box nth-child */
  .boxxx:nth-child(1) {
    left: 0;
    top: 0;
    animation-name: box-1;
  }
  
  .boxxx:nth-child(2) {
    left: calc(100% / 3);
    top: 0;
    animation-name: box-2;
  }
  
  .boxxx:nth-child(3) {
    left: calc((100% / 3) * 2);
    top: 0;
    animation-name: box-3;
  }
  
  .boxxx:nth-child(4) {
    left: 0;
    top: calc(100% / 3);
    animation-name: box-4;
  }
  
  .boxxx:nth-child(5) {
    left: calc((100% / 3) * 2);
    top: calc(100% / 3);
    animation-name: box-5;
  }
  
  .boxxx:nth-child(6) {
    left: 0;
    top: calc((100% / 3) * 2);
    animation-name: box-6;
  }
  
  .boxxx:nth-child(7) {
    left: calc(100% / 3);
    top: calc((100% / 3) * 2);
    animation-name: box-7;
  }
  
  .boxxx:nth-child(8) {
    left: calc((100% / 3) * 2);
    top: calc((100% / 3) * 2);
    animation-name: box-8;
  }
  
  /* 1st Child */
  @keyframes box-1 {
    0%,
    90%,
    100% {
      left: 0;
      top: 0;
    }
  
    2.5%,
    12.5% {
      left: calc(100% / 3);
    }
  
    15%,
    25% {
      left: calc((100% / 3) * 2);
      top: 0;
    }
  
    27.5% {
      left: calc((100% / 3) * 2);
      top: calc(100% / 3);
    }
  
    29.5% {
      left: calc((100% / 3) * 2);
      top: calc(100% / 3);
      width: calc((100% / 3) - 16px);
    }
  
    31.5%,
    33.5% {
      left: calc(100% / 3);
      width: 100%;
    }
  
    35.5%,
    37.5% {
      left: calc((100% / 3) * 2);
      top: calc(100% / 3);
      width: calc((100% / 3) - 16px);
    }
  
    40%,
    50% {
      left: calc((100% / 3) * 2);
      top: calc((100% / 3) * 2);
    }
  
    52.5%,
    62.5% {
      left: calc(100% / 3);
    }
  
    65%,
    75% {
      left: 0;
      top: calc((100% / 3) * 2);
    }
  
    77.5%,
    87.5% {
      top: calc(100% / 3);
    }
  }
  
  @keyframes box-2 {
    0%,
    90%,
    100% {
      left: calc(100% / 3);
    }
  
    2.5%,
    12.5% {
      left: calc((100% / 3) * 2);
      top: 0;
    }
  
    15%,
    17% {
      left: calc((100% / 3) * 2);
      top: calc(100% / 3);
      width: calc((100% / 3) - 16px);
    }
  
    19%,
    21% {
      left: calc(100% / 3);
      top: calc(100% / 3);
      width: 100%;
    }
  
    23%,
    25% {
      left: calc((100% / 3) * 2);
      top: calc(100% / 3);
      width: calc((100% / 3) - 16px);
    }
  
    27.5%,
    37.5% {
      left: calc((100% / 3) * 2);
      top: calc((100% / 3) * 2);
    }
  
    40%,
    50% {
      left: calc(100% / 3);
      top: calc((100% / 3) * 2);
    }
  
    52.5%,
    62.5% {
      left: 0;
      top: calc((100% / 3) * 2);
    }
  
    65%,
    75% {
      left: 0;
      top: calc(100% / 3);
    }
  
    77.5%,
    87.5% {
      left: 0;
      top: 0;
    }
  }
  
  @keyframes box-3 {
    0%,
    90%,
    100% {
      left: calc((100% / 3) * 2);
    }
  
    2.5%,
    12.5% {
      left: calc((100% / 3) * 2);
      top: calc(100% / 3);
    }
  
    4.5%,
    10.5% {
      left: calc((100% / 3) * 2);
      top: calc(100% / 3);
      width: calc((100% / 3) - 16px);
    }
  
    6.5%,
    8.5% {
      left: calc(100% / 3);
      top: calc(100% / 3);
      width: 100%;
    }
  
    15%,
    25% {
      left: calc((100% / 3) * 2);
      top: calc((100% / 3) * 2);
    }
  
    27.5%,
    37.5% {
      left: calc(100% / 3);
      top: calc((100% / 3) * 2);
    }
  
    40%,
    50% {
      left: 0;
      top: calc((100% / 3) * 2);
    }
  
    52.5%,
    62.5% {
      left: 0;
      top: calc(100% / 3);
    }
  
    65%,
    75% {
      left: 0;
      top: 0;
    }
  
    77.5%,
    87.5% {
      left: calc(100% / 3);
      top: 0;
    }
  }
  
  @keyframes box-4 {
    0%,
    90%,
    100% {
      top: calc(100% / 3);
    }
  
    2.5%,
    12.5% {
      left: 0;
      top: 0;
    }
  
    15%,
    25% {
      left: calc(100% / 3);
      top: 0;
    }
  
    27.5%,
    37.5% {
      left: calc((100% / 3) * 2);
      top: 0;
    }
  
    40%,
    42%,
    48%,
    50% {
      left: calc((100% / 3) * 2);
      top: calc(100% / 3);
      width: calc((100% / 3) - 16px);
    }
  
    44%,
    46% {
      left: calc(100% / 3);
      top: calc(100% / 3);
      width: 100%;
    }
  
    52.5%,
    62.5% {
      left: calc((100% / 3) * 2);
      top: calc((100% / 3) * 2);
    }
  
    65%,
    75% {
      left: calc(100% / 3);
      top: calc((100% / 3) * 2);
    }
  
    77.5%,
    87.5% {
      left: 0;
      top: calc((100% / 3) * 2);
    }
  }
  
  @keyframes box-5 {
    0%,
    90%,
    92%,
    98%,
    100% {
      left: calc((100% / 3) * 2);
      top: calc(100% / 3);
      width: calc((100% / 3) - 16px);
    }
  
    2.5%,
    12.5% {
      left: calc((100% / 3) * 2);
      top: calc((100% / 3) * 2);
    }
  
    15%,
    25% {
      left: calc(100% / 3);
      top: calc((100% / 3) * 2);
    }
  
    27.5%,
    37.5% {
      left: 0;
      top: calc((100% / 3) * 2);
    }
  
    40%,
    50% {
      left: 0;
      top: calc(100% / 3);
    }
  
    52.5%,
    62.5% {
      left: 0;
      top: 0;
    }
  
    65%,
    75% {
      left: calc(100% / 3);
      top: 0;
    }
  
    77.5%,
    87.5% {
      left: calc((100% / 3) * 2);
      top: 0;
    }
  
    94%,
    96% {
      left: calc(100% / 3);
      top: calc(100% / 3);
      width: 100%;
    }
  }
  
  @keyframes box-6 {
    0%,
    90%,
    100% {
      left: 0;
      top: calc((100% / 3) * 2);
    }
  
    2.5%,
    12.5% {
      left: 0;
      top: calc(100% / 3);
    }
  
    15%,
    25% {
      left: 0;
      top: 0;
    }
  
    27.5%,
    37.5% {
      left: calc(100% / 3);
      top: 0;
    }
  
    40%,
    50% {
      left: calc((100% / 3) * 2);
      top: 0;
    }
  
    52.5%,
    54.5%,
    60.5%,
    62.5% {
      left: calc((100% / 3) * 2);
      top: calc(100% / 3);
      width: calc((100% / 3) - 16px);
    }
  
    56.5%,
    58.5% {
      left: calc(100% / 3);
      top: calc(100% / 3);
      width: 100%;
    }
  
    65%,
    75% {
      left: calc((100% / 3) * 2);
      top: calc((100% / 3) * 2);
    }
  
    77.5%,
    87.5% {
      left: calc(100% / 3);
      top: calc((100% / 3) * 2);
    }
  }
  
  @keyframes box-7 {
    0%,
    90%,
    100% {
      left: calc(100% / 3);
      top: calc((100% / 3) * 2);
    }
  
    2.5%,
    12.5% {
      left: 0;
      top: calc((100% / 3) * 2);
    }
  
    15%,
    25% {
      left: 0;
      top: calc(100% / 3);
    }
  
    27.5%,
    37.5% {
      left: 0;
      top: 0;
    }
  
    40%,
    50% {
      left: calc(100% / 3);
      top: 0;
    }
  
    52.5%,
    62.5% {
      left: calc((100% / 3) * 2);
      top: 0;
    }
  
    65%,
    67%,
    73%,
    75% {
      left: calc((100% / 3) * 2);
      top: calc(100% / 3);
      width: calc((100% / 3) - 16px);
    }
  
    69%,
    71% {
      left: calc(100% / 3);
      top: calc(100% / 3);
      width: 100%;
    }
  
    77.5%,
    87.5% {
      left: calc((100% / 3) * 2);
      top: calc((100% / 3) * 2);
    }
  }
  
  @keyframes box-8 {
    0%,
    90%,
    100% {
      left: calc((100% / 3) * 2);
      top: calc((100% / 3) * 2);
    }
  
    2.5%,
    12.5% {
      left: calc(100% / 3);
      top: calc((100% / 3) * 2);
    }
  
    15%,
    25% {
      left: 0;
      top: calc((100% / 3) * 2);
    }
  
    27.5%,
    37.5% {
      left: 0;
      top: calc(100% / 3);
    }
  
    40%,
    50% {
      left: 0;
      top: 0;
    }
  
    52.5%,
    62.5% {
      left: calc(100% / 3);
      top: 0;
    }
  
    65%,
    75% {
      left: calc((100% / 3) * 2);
      top: 0;
    }
  
    77.5%,
    79.5%,
    85.5%,
    87.5% {
      left: calc((100% / 3) * 2);
      top: calc(100% / 3);
      width: calc((100% / 3) - 16px);
      border-radius: 100%;
    }
  
    81.5%,
    83.5% {
      left: calc(100% / 3);
      top: calc(100% / 3);
      width: 100%;
    }
  }
  
  /* Box Text */
  .boxxx span {
    position: absolute;
    display: block;
    opacity: 0.8;
    z-index: 5;
  }
  
  .boxxx:nth-child(1) span {
    top: 43%;
    left: -27px;
    transform: translateY(-50%);
  }
  
  .boxxx:nth-child(3) span {
    left: -10px;
    bottom: 2px;
  }
  
  .boxxx:nth-child(4) span {
    top: 50%;
    left: 55%;
    transform: translate(-50%, -50%);
  }
  
  .boxxx:nth-child(8) span {
    top: 50%;
    transform: translate(-50%, -50%);
    left: 15%;
  }
  
  /* Box Background-Image */
  .boxxx .bg-img {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 120px;
    overflow: hidden;
  }
  
  .boxxx .bg-img,
  .boxxx .bg-img img {
    height: 100%;
    width: 100%;
  }
  
  .boxxx .bg-img .imgg {
    max-width: 100%;
    object-fit: cover;
    object-position: center center;
  }
  
  /* Curve Line */
  .bg-line {
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    height: 85px;
    z-index: -1;
    overflow: hidden;
    display: flex;
    display: -webkit-flex;
    white-space: nowrap;
  }
  
  .bg-line img {
    position: relative;
    flex-shrink: 0;
    -webkit-flex-shrink: 0;
    animation: 26s linear infinite;
  }
  
  .bg-line img:nth-child(1) {
    animation-name: first-text;
  }
  
  .bg-line img:nth-child(2) {
    animation-name: second-text;
  }
  
  @keyframes first-text {
    50% {
      transform: translateX(-100%);
      opacity: 1;
    }
  
    50.05% {
      opacity: 0;
    }
  
    50.1% {
      transform: translateX(100%);
      opacity: 1;
    }
  
    100% {
      transform: translateX(0%);
    }
  }
  
  @keyframes second-text {
    50% {
      transform: translateX(-100%);
    }
  
    100% {
      transform: translateX(-200%);
    }
  
    0% {
      transform: translateX(0%);
    }
  }
  
  /* Dash Circle */
  .bg-dash-circle {
    position: absolute;
    bottom: -28px;
    right: -13px;
    z-index: -1;
    width: 180px;
    aspect-ratio: 1/1;
  }
  
  .bg-dash-circle img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center center;
    animation: cirlce-rotate 18s linear infinite;
  }
  
  @keyframes cirlce-rotate {
    0% {
      transform: rotate(0);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  
  /* Circle Line */
  .bg-circle-h-line {
    bottom: 42px;
    left: -68px;
    z-index: 1;
    width: 181px;
    height: 111px;
    position: relative;
  }
  
  .bg-circle-h-line img {
    width: 100%;
    max-width: 100%;
    height: auto;
    position: absolute;
    left: 0;
  }
  
  .bg-circle-h-line img:nth-child(1) {
    top: 0;
    animation: top-ring-move 2.5s linear infinite;
  }
  
  .bg-circle-h-line img:nth-child(2) {
    top: 50%;
    transform: translateY(-50%);
  }
  
  .bg-circle-h-line img:nth-child(3) {
    bottom: 0;
    animation: bottom-ring-move 2.5s linear infinite;
  }
  
  @keyframes top-ring-move {
    0% {
      transform: translateY(0);
    }
  
    50% {
      transform: translateY(5px);
    }
  
    100% {
      transform: translateY(0);
    }
  }
  
  @keyframes bottom-ring-move {
    0% {
      transform: translateY(0);
    }
  
    50% {
      transform: translateY(-5px);
    }
  
    100% {
      transform: translateY(0);
    }
  }
  
  /*=== Responsive ===*/
  @media screen and (min-width: 1500px) {
    .info-section {
      padding-left: 120px;
    }
  }
  
  @media screen and (min-width: 1400px) {
    .info-section {
      padding-left: 100px;
    }
  }
  
  @media screen and (max-width: 1199px) {
    .bg-line {
      height: 68px;
    }
  
    .right-part {
      height: 400px;
      width: 400px;
    }
  
    .d-flex {
      grid-gap: 20px;
      gap: 20px;
    }
  
    .main-grid::before,
    .boxxx {
      height: 118px;
    }
  
    .boxxx {
      width: 118px;
      font-size: 12px;
    }
  
    .left-part p {
      font-size: 14px;
      line-height: 1.8;
      width: 85%;
    }
  
    .left-part h1 .text {
      height: 132px;
    }
  
    .bg-dash-circle {
      width: 130px;
    }
  
    .bg-circle-h-line {
      width: 156px;
      height: 92px;
    }
  
    .book-link {
      font-size: 48px;
      grid-gap: 24px;
      gap: 24px;
    }
  
    .book-link .arrow {
      height: 28px;
      width: 28px;
    }
  }
  
  @media screen and (max-width: 767px) {
    .info-section {
      display: block;
      padding: 0;
    }
  
    .bg-line {
      height: 52px;
    }
  
    .left-part {
      padding: 40px 16px 60px;
    }
  
    .right-part {
      height: 334px;
      width: 334px;
    }
  
    .left-part h1 .text {
      height: 88px;
    }
  
    .left-part p {
      font-size: 12px;
      width: 96%;
    }
  
    .main-grid::before,
    .boxxx {
      height: 96px;
    }
  
    .boxxx {
      width: 96px;
      font-size: 10px;
    }
  
    .book-link .arrow {
      height: 24px;
      width: 24px;
    }
  
    .book-link {
      font-size: 42px;
      grid-gap: 20px;
      gap: 20px;
      margin-top: 24px;
    }
  
    .bg-dash-circle {
      width: 80px;
    }
  
    .bg-circle-h-line {
      width: 126px;
      height: 65px;
    }
  }
  
/* SLIDER */
.bdt-timeline-container {
  display: flow-root;
  box-sizing: content-box;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  overflow: hidden;
  margin-top: 50px;
}

.upk-salf-slider-wrapper {
  display: flex;
  flex-direction: row;
  background: #FFC94A;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
  height: 400px;
  padding: 30px;
  position: relative;
}

.upk-salf-slider-wrapper .upk-salf-item {
  position: relative;
  display: flex;
  flex-direction: row-reverse;
}

.upk-salf-slider-wrapper .upk-salf-item.swiper-slide-active .upk-salf-title,
.upk-salf-slider-wrapper .upk-salf-item.swiper-slide-active .upk-salf-desc,
.upk-salf-slider-wrapper .upk-salf-item.swiper-slide-active .upk-salf-button {
  opacity: 1;
}

.upk-salf-slider-wrapper .upk-salf-item .upk-salf-image-wrap {
  height: 100%;
  width: 100%;
}

.upk-salf-slider-wrapper .upk-salf-item .upk-xanc-img {
  display: block;
  width: 100% !important;
  max-width: 100% !important;
  height: 800px;
  object-fit: cover;
}

.upk-salf-slider-wrapper .upk-salf-item .upk-salf-content-wrap {
  position: absolute;
  left: 0;
  bottom: 0;
  top: unset;
  max-width: 460px;
  overflow: hidden;
  background: #ffffff8a;
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  transition: -webkit-backdrop-filter 0.9s;
  transition: backdrop-filter 0.9s;
  transition: backdrop-filter 0.9s, -webkit-backdrop-filter 0.9s;
  padding: 20px;
  padding-left: 20px;
}

.upk-salf-slider-wrapper .upk-salf-item .upk-salf-title {
  font-size: 23px;
  font-weight: 700;
  line-height: 1.2;
  color: #2B2D42;
  text-transform: capitalize;
  margin-bottom: 20px;
  opacity: 0;
}

.upk-salf-slider-wrapper .upk-salf-item .upk-salf-desc {
  color: #656565;
  font-size: 14px;
  line-height: 1.6;
  text-transform: capitalize;
  margin-bottom: 20px;
  opacity: 0;
}

.upk-salf-slider-wrapper .upk-salf-item .upk-salf-button {
  opacity: 0;
}

.upk-salf-slider-wrapper .upk-salf-item .upk-salf-button .link {
  color: #2b2d42;
  cursor: pointer;
  font-weight: 500;
  text-decoration: none;
  text-transform: capitalize;
  font-size: 14px;
  transition: all 0.3s ease;
}

.upk-salf-slider-wrapper .upk-salf-item .upk-salf-button .link:hover {
  color: #ff215a;
}

.upk-salf-slider-wrapper .upk-salf-item .upk-salf-button .link--arrowed {
  display: inline-block;
}

.upk-salf-slider-wrapper .upk-salf-item .upk-salf-button .link--arrowed .arrow-icon {
  position: relative;
  top: 0;
  transition: transform 0.3s ease;
  vertical-align: middle;
}

.upk-salf-slider-wrapper .upk-salf-item .upk-salf-button .link--arrowed .arrow-icon--circle {
  transition: stroke-dashoffset 0.3s ease;
  stroke-dasharray: 95;
  stroke-dashoffset: 95;
}

.upk-salf-slider-wrapper .upk-salf-item .upk-salf-button .link--arrowed g {
  stroke: currentColor;
  color: #2b2d42;
}

.upk-salf-slider-wrapper .upk-salf-item .upk-salf-button .link--arrowed:hover .arrow-icon {
  transform: translate3d(5px, 0, 0);
}

.upk-salf-slider-wrapper .upk-salf-item .upk-salf-button .link--arrowed:hover .arrow-icon--circle {
  stroke-dashoffset: 0;
}

.upk-salf-slider-wrapper .upk-salf-item .upk-salf-button .link--arrowed:hover g {
  color: #ff215a;
}

.upk-salf-slider-wrapper .upk-page-scroll {
  position: absolute;
  bottom: 8%;
  right: 5%;
  transform: rotate(90deg);
  z-index: 1;
  display: none;
}

.upk-salf-slider-wrapper .upk-page-scroll .arrow-up {
  height: 70px;
  width: 70px;
  display: block;
  background: #ffffffc9;
  -webkit-backdrop-filter: blur(18px);
  backdrop-filter: blur(18px);
  position: relative;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.25, 1.7, 0.35, 1.5);
  transform: rotate(-90deg);
  overflow: hidden;
}

.upk-salf-slider-wrapper .upk-page-scroll .arrow-slide {
  left: 0;
  top: -100%;
  width: 100%;
  height: 100%;
  background: #ff215a;
  position: absolute;
  display: block;
  z-index: 0;
}

.upk-salf-slider-wrapper .upk-page-scroll .long-arrow-left {
  display: block;
  margin: 30px auto;
  width: 16px;
  height: 16px;
  border-top: 2px solid #2b2d42;
  border-left: 2px solid #2b2d42;
}

.upk-salf-slider-wrapper .upk-page-scroll .long-arrow-left {
  transform: rotate(-135deg);
}

.upk-salf-slider-wrapper .upk-page-scroll .long-arrow-left::after {
  content: "";
  display: block;
  width: 2px;
  height: 25px;
  background-color: #2b2d42;
  transform: rotate(-45deg) translate(8px, 3px);
  left: 0;
  top: 0;
}

.upk-salf-slider-wrapper .upk-page-scroll .arrow-up:hover {
  transition: all 0.1s;
}

.upk-salf-slider-wrapper .upk-page-scroll .arrow-up:hover .left-arm:after {
  transform: rotate(-10deg);
}

.upk-salf-slider-wrapper .upk-page-scroll .arrow-up:hover .right-arm:after {
  transform: rotate(10deg);
}

.upk-salf-slider-wrapper .upk-page-scroll .arrow-up:hover .arrow-slide {
  transition: all 0.5s ease-in-out;
  transform: translateY(200%);
}

.upk-salf-slider-wrapper .upk-salf-nav-pag-wrap {
  /* position: absolute; */
  top: 0;
  height: 100%;
  right: 0;
}

.upk-salf-slider-wrapper .upk-salf-navigation {
  margin-top: 40px;
  margin-right: 2px;
}

.upk-salf-slider-wrapper .upk-salf-navigation .link--arrowed {
  display: inline-block;
}

.upk-salf-slider-wrapper .upk-salf-navigation .link--arrowed .arrow-icon {
  position: relative;
  top: 0;
  transition: transform 0.3s ease;
  vertical-align: middle;
}

.upk-salf-slider-wrapper .upk-salf-navigation .link--arrowed .arrow-icon--circle {
  transition: stroke-dashoffset 0.3s ease;
  stroke-dasharray: 95;
  stroke-dashoffset: 95;
}

.upk-salf-slider-wrapper .upk-salf-navigation .link--arrowed g {
  stroke: currentColor;
  color: #2b2d42;
}

.upk-salf-slider-wrapper .upk-salf-navigation .link--arrowed:hover .arrow-icon {
  transform: translate3d(5px, 0, 0);
}

.upk-salf-slider-wrapper .upk-salf-navigation .link--arrowed:hover .arrow-icon--circle {
  stroke-dashoffset: 0;
}

.upk-salf-slider-wrapper .upk-salf-navigation .link--arrowed:hover g {
  color: #ff215a;
}

.upk-salf-slider-wrapper .upk-salf-navigation .upk-button-next {
  margin-top: 15px;
  transform: rotate(90deg);
}

.upk-salf-slider-wrapper .upk-salf-navigation .upk-button-prev {
  transform: rotate(-90deg);
}

.upk-salf-slider-wrapper .upk-salf-pagi-wrap {
  position: absolute;
  top: 43%;
  right: 0;
  margin-right: 22px;
}

@keyframes progress {
  0% {
    stroke-dashoffset: 75;
    opacity: 1;
  }

  95% {
    stroke-dashoffset: 0;
    opacity: 1;
  }

  100% {
    opacity: 0;
    stroke-dashoffset: 0;
  }
}

.upk-salf-slider-wrapper .upk-salf-pagi-wrap .swiper-pagination-bullet {
  background-color: transparent;
  opacity: 0.8;
}

.upk-salf-slider-wrapper .upk-salf-pagi-wrap .swiper-pagination-bullet--svg-animation {
  width: 20px;
  height: 20px;
  margin: 6px -7px;
  display: inline-block;
}

.upk-salf-slider-wrapper .upk-salf-pagi-wrap .swiper-pagination-bullet--svg-animation svg {
  transform: rotate(-90deg);
}

.upk-salf-slider-wrapper .upk-salf-pagi-wrap .swiper-pagination-bullet--svg-animation .svg__circle-inner {
  stroke: #2b2d42;
  fill: transparent;
  transition: all 0.3s ease;
}

.upk-salf-slider-wrapper .upk-salf-pagi-wrap .swiper-pagination-bullet-active .svg__circle {
  stroke: #ff215a;
  stroke-dasharray: 75;
  stroke-dashoffset: 0;
  animation: progress 4s ease-in-out 1 forwards;
}

.upk-salf-slider-wrapper .upk-salf-pagi-wrap .swiper-pagination-bullet-active .svg__circle-inner {
  fill: #2b2d42;
  stroke: #ff215a;
}

@media (min-width: 768px) {
  .upk-salf-slider-wrapper {
    height: 550px;
    padding: 60px;
  }

  .upk-salf-slider-wrapper .upk-page-scroll {
    bottom: 4%;
    display: inherit;
  }

  .upk-salf-slider-wrapper .upk-salf-item .upk-salf-title {
    font-size: 40px;
  }

  .upk-salf-slider-wrapper .upk-salf-item .upk-salf-content-wrap {
    max-width: 400px;
    padding: 40px;
    padding-left: 0;
    top: 50%;
    transform: translateY(-50%);
    bottom: unset;
  }

  .upk-salf-slider-wrapper .upk-salf-item .upk-salf-image-wrap {
    width: 80%;
  }

  .upk-salf-slider-wrapper .upk-salf-navigation {
    margin-top: 60px;
    margin-right: 20px;
  }

  .upk-salf-slider-wrapper .upk-salf-pagi-wrap {
    margin-right: 47px;
  }

  .upk-salf-slider-wrapper .upk-salf-pagi-wrap .swiper-pagination-bullet--svg-animation {
    margin: 6px 0;
  }
}

@media (min-width: 1024px) {
  .upk-salf-slider-wrapper {
    height: 650px;
    padding: 70px;
  }

  .upk-salf-slider-wrapper .upk-page-scroll {
    bottom: 9%;
  }

  .upk-salf-slider-wrapper .upk-salf-item .upk-salf-title {
    font-size: 50px;
  }

  .upk-salf-slider-wrapper .upk-salf-item .upk-salf-content-wrap {
    max-width: 460px;
    padding: 50px;
    padding-left: 0;
  }

  .upk-salf-slider-wrapper .upk-salf-item .upk-salf-desc {
    font-size: 16px;
  }

  .upk-salf-slider-wrapper .upk-salf-item .upk-salf-button .link {
    font-size: 16px;
  }

  .upk-salf-slider-wrapper .upk-salf-item .upk-salf-image-wrap {
    width: 70%;
  }

  .upk-salf-slider-wrapper .upk-salf-navigation {
    margin-top: 85px;
    margin-right: 30px;
  }

  .upk-salf-slider-wrapper .upk-salf-pagi-wrap {
    margin-right: 53px;
  }

  .upk-salf-slider-wrapper .upk-salf-pagi-wrap .swiper-pagination-bullet--svg-animation {
    margin: 6px -5px;
  }
}

@media (min-width: 1440px) {
  .upk-salf-slider-wrapper {
    height: 700px;
    padding: 80px;
  }

  .upk-salf-slider-wrapper .upk-salf-item .upk-salf-title {
    font-size: 55px;
  }
}

.button {
  background: #f00;
  padding: 18px 20px;
  text-transform: uppercase;
  margin-top: 50px;
  margin-bottom: 50px;
  display: inline-block;
  text-decoration: none;
  font-weight: 700;
  font-size: 15px;
  color: #fff;
}

/* SLIDER */

/*SERVICES */
.servicecard-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.servicecard {
  overflow: visible;
  width: 300px;
  height: 320px;
  margin: 10px;
}

.servicecontent {
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 300ms;
  box-shadow: 0px 0px 10px 1px #000000ee;
  border-radius: 5px;
}

.servicefront,
.serviceback {
  background-color: #151515;
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  border-radius: 5px;
  overflow: hidden;
}

.serviceback {
  width: 100%;
  height: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.serviceback::before {
  position: absolute;
  content: ' ';
  display: block;
  width: 160px;
  height: 160%;
  background: linear-gradient(90deg, transparent, #ff9966, #ff9966, #ff9966, #ff9966, transparent);
  animation: rotation_481 5000ms infinite linear;
}

.serviceback-content {
  position: absolute;
  width: 99%;
  height: 99%;
  background-color: #151515;
  border-radius: 5px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 30px;
  gap: 30px;
}

.servicecard:hover .servicecontent {
  transform: rotateY(180deg);
}

@keyframes rotation_481 {
  0% {
    transform: rotateZ(0deg);
  }

  0% {
    transform: rotateZ(360deg);
  }
}

.servicefront {
  transform: rotateY(180deg);
  background-image: url('https://img.freepik.com/free-vector/abstract-black-gold-luxury-background_361591-4205.jpg?t=st=1713879190~exp=1713882790~hmac=cce9d1b84c8a1f8ea4e576930fe0edbadaa6fddf9ab5824d5e890a86a57d2554&w=360');
  background-size: cover;
  background-position: center;
  color: white;
}

.servicefront .servicefront-content {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  /* Center align text */
}

.servicefront-content .servicebadge {
  background-color: #00000055;
  padding: 2px 10px;
  border-radius: 10px;
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: 20px;
  /* Add top margin */
}

.servicedescription {
  box-shadow: 0px 0px 10px 5px #00000088;
  width: 100%;
  padding: 10px;
  background-color: #00000099;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  border-radius: 5px;
}

.servicetitle {
  max-width: 100%;
  display: flex;
  justify-content: space-around;
}

.servicetitle p {
  width: 90%;
  font-size: 15px;
}

.servicebutton-container {
  text-align: center;
  margin-top: 0px;
  margin-bottom: 40px;
}

.servicecard-button {
  font-family: 'Montserrat', sans-serif;
  text-decoration: none;
  padding: 8px 16px;
  border: 1px solid #ffffff;
  color: #ffffff;
  transition: background-color 0.3s;
  background-color: transparent;
}

.servicecard-button:hover {
  background-color: #FFC94A;
  color: #151515;
}

.servicefront .serviceimg {
  position: absolute;
  width: 100px !important;
  object-fit: cover;
  object-position: center;
}

.servicecircle {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  background-color: #ffbb66;
  position: relative;
  filter: blur(15px);
  animation: floating 2600ms infinite linear;
}

#servicebottom {
  background-color: #ff8866;
  left: 50px;
  top: 0px;
  width: 150px;
  height: 150px;
  animation-delay: -800ms;
}

#serviceright {
  background-color: #ff2233;
  left: 160px;
  top: -80px;
  width: 30px;
  height: 30px;
  animation-delay: -1800ms;
}

@keyframes floating {
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(0px);
  }
}

@media (max-width: 768px) {
  .servicecard-container {
    flex-direction: column;
    align-items: center;
    margin-top: 45rem;
    margin-bottom: 45rem;
  }

  .servicecard {
    margin: 10px 0;
    /* Add some margin to separate the stacked cards */
    min-width: 300px;
    /* Set a minimum width for each card */
    min-height: 400px;
    /* Set a minimum height for each card */
  }
}

/*SERVICES */






/*TABS*/
.tabs {
  width: 80%;
  height: 100px;
  margin: auto;
  margin-top: 50px;
  display: flex;
  align-items: center;
  box-shadow: 10px 10px 19px #1c1e22, -10px -10px 19px #262a2e;
  overflow: hidden;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.tabs li {
  background: white;
  padding-top: 30px;
  padding-left: 15px;
  width: 25%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  font-family: 'Poppins', sans-serif;
  transition: .5s;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  -ms-transition: .5s;
  -o-transition: .5s;
  cursor: pointer;
}

.tabs li:hover {
  background: #1c1e22;
  box-shadow: 10px 10px 19px #1c1e22, -10px -10px 19px #262a2e;
  color: yellow;
  position: relative;
  z-index: 1;
  border-radius: 10px;
}

.active {
  background: #1c1e22;
  color: white;
  box-shadow: 10px 10px 19px #1c1e22, -10px -10px 19px #262a2e;
  position: relative;
  z-index: 1;
  border-radius: 10px;
}

.contents {
  width: 80%;
  margin: auto;
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  grid-gap: 30px;
  gap: 30px;
}

.boxxxx {
  grid-gap: 20px;
  gap: 20px;
  background: linear-gradient(97.3deg, rgba(25, 50, 70, 0.81) 10.7%, rgba(250, 81, 9, 0.72) 39.5%, rgba(255, 192, 0, 0.81) 69.7%);
  box-shadow: 10px 10px 19px #1c1e22, -10px -10px 19px #262a2e;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  padding: 20px;
  width: 100%;
  animation: moving 1s ease;
  -webkit-animation: moving 1s ease;
}

.boxxxx img {
  width: 50%;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.boxxxx h3 {
  color: #c4cfde;
  font-family: 'Poppins', sans-serif;
  font-size: 2rem;
  margin-bottom: 20px;
}

.boxxxx p {
  color: black;
  opacity: .5;
  font-family: 'Poppins', sans-serif;
  font-size: 1rem;
}

.show {
  display: flex;
}

.hide {
  display: none;
}

@keyframes moving {
  from {
    transform: translateX(-50px);
    -webkit-transform: translateX(-50px);
    -moz-transform: translateX(-50px);
    -ms-transform: translateX(-50px);
    -o-transform: translateX(-50px);
    opacity: 0;
  }

  to {
    transform: translateX(0px);
    -webkit-transform: translateX(0px);
    -moz-transform: translateX(0px);
    -ms-transform: translateX(0px);
    -o-transform: translateX(0px);
    opacity: 1;
  }
}

/* SERVICES */

.viewport{
  position:relative;
  /* background: linear-gradient( 97.3deg,  rgba(25,50,70,0.81) 10.7%, rgba(250, 81, 9, 0.72) 39.5%, rgba(255,192,0,0.81) 69.7% ); */
  background-size: 200% 200%;
  height: auto;
  width:96%;
  margin-left: auto;
  margin-right: auto ;
animation: AnimationName 10s ease infinite;
  
}
@keyframes AnimationName { 
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}

.ServiceCardd p{
  font-family: lato;
  margin:0px;
  color: #2A3335;
  font-weight: 400;
  font-size:15px;
  line-height:110%;
  padding-left: 8%;
  padding-right: 8%;
  padding-top:3%;
  padding-bottom:8%;
  border-radius:20px;
  cursor:pointer;
  transition: ease 1s;
  -webkit-transition: ease 1s;
}

.ServiceCardd h3{
  font-family: lato;
  margin:0px;
  color: #2A3335;
  font-weight: 400;
  font-size:20px;
  line-height:90%;
  padding-left: 8%;
  padding-top:8%;
  padding-bottom:3%;
  border-radius:20px;
  cursor:pointer;
  transition: ease 1s;
  -webkit-transition: ease 1s;
}

h1{
  font-family: lato;
  color:white;
  font-weight: 400;
  font-size:35px;
  line-height:90%;
  padding-bottom:140px;
  padding-top:150px;
  position:relative;
  text-align:center;
  
}

.title{
  width:78%;
  margin:auto;
  padding-left:0px;
  padding-bottm:0px;
}

.cardsbuttonz:hover{
  background-color: orange !important;
}

.ServiceCardds{
  width:90%;
  position:relative;
  margin-left:auto;
  margin-right:auto;
  padding-bottom:200px;
  padding-top: 100px;
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
  
}

.ServiceCardd{
  width:22%;
  position:relative;
  background-image: linear-gradient(120deg, #f6d365 0%, #fda085 100%);
  margin:1.13%;
  border-radius:5px;
  transition: 1s;
  -webkit-transition:0.5s;
  cursor:pointer;
    flex: 1 1 auto;
}

.ServiceCardd:hover{
  transform: scale(1.05);
  box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.729);
  border: 5px solid #FFE5CF;
}

@media screen and (max-width: 1200px){
  .ServiceCardd{
  width:30%;
  margin:1.6%;
  }
}

@media screen and (max-width: 800px){
  .ServiceCardd{
  width:45%;
  margin:2.5%;
  }
}

@media screen and (max-width: 500px){
  .ServiceCardd{
  width:98%;
  margin:2%;
  }
}

.ServiceCardd_img{
  width:100%;
  height:200px;
  position:reltive;
  border-radius: 5px 5px 0px 0px;
  background-size: cover;
  background-position:center center; 
  background-color: white;
  transition:1s;
  -webkit-transition:1s;
}

.ServiceCardd1{
   background-image: url(https://img.freepik.com/free-photo/high-view-arrow-standing-coins_23-2148546941.jpg?ga=GA1.1.173630586.1722529162&semt=sph);
}
.ServiceCardd2{
   background-image: url(https://img.freepik.com/free-photo/financial-charts-with-pencil-table_1232-3120.jpg?t=st=1722537680~exp=1722541280~hmac=409ad43879970e25a43f97fee96acbeda01aa70ad9c7cdaf82c4f0e3e5d670d6&w=1800);
}
.ServiceCardd3{
   background-image: url(https://img.freepik.com/free-photo/pile-coins-arrow-pointing-up_23-2148546825.jpg?ga=GA1.1.173630586.1722529162&semt=sph);
}
.ServiceCardd4{
   background-image: url(https://img.freepik.com/free-photo/fraud-word-with-letter-magnifying-glass-flat-lay_23-2148546897.jpg?ga=GA1.1.173630586.1722529162&semt=sph);
}
.ServiceCardd5{
   background-image: url(https://img.freepik.com/free-photo/business-commerce_53876-92772.jpg?t=st=1722538176~exp=1722541776~hmac=601b72d7c7c71a0c329ee1697c5e796cac88ef130bb35ac8a1a341967561bc86&w=1060);
}
.ServiceCardd6{
   background-image: url(http://www.festabimbianimazione.it/media/k2/galleries/136/Colori%20Fluo%20festa%20Arezzo%207.JPG);
}

.ServiceCardd7{
  background-image: url(https://img.freepik.com/free-photo/social-media-communication-concept_23-2150822060.jpg?t=st=1737712072~exp=1737715672~hmac=0d19e6705e42affe4a46111bf95c7fa609ca7612fc8544e4ef1af1a03ef3e545&w=826)
}

.ServiceCardd8{
  background-image: url(https://img.freepik.com/free-photo/social-media-communication-concept_23-2150822056.jpg?t=st=1737711921~exp=1737715521~hmac=e04cd8c62b0053c8df1b518c86f2855bf7516db6fc7c5b6332bf3f1317ca9e01&w=1480)
}

.ServiceCardd10{
  background-image: url(https://img.freepik.com/free-photo/texture-yellow-rock-background_181624-37652.jpg?t=st=1726914998~exp=1726918598~hmac=5b5ceddaf172f992ffff5368b1884d5296aefd65f75a4f328cd55d9adbdeaba5&w=1800);
}
.ServiceCardd11{
  
}

.line{
  height:2px;
  width:84%;
  margin:auto;
  background-color: #FF885B;
}

.title input{
  background: transparent;
  border:1px solid white;
  padding-top:10px;
  padding-bottom:10px;
  padding-left:20px;
  display:inline;
  font-family: lato;
  color: white;
  font-size:18px;
  line-height:90%;
  border-radius:30px;
  width:150px;
  float:right;
  margin-top:30px;
}

.title textarea:focus, input:focus{
    outline: none;
}

.plus{
  width:20px;
  height:20px;
  background: transparent;
  border-radius: 50%;
  position: absolute;
  border: 2px solid white;
  top:10px;
  right:10px;
  transition: 1s;
  -webkit-transition: 1s;
}

.plus:hover{
  transform: scale(1.1) rotate(90deg);
}

.plus p{
  padding: 0px !important;
  display:inline;
  margin:0px;
  line-height:50%;
  left:50%;
  top:45.3%;
  transform:translate(-50%,-50%);
  position:absolute;
  color: white;
}

.zoomed{
  width:100%;
}

.background-video{
 z-index: -10;
 width: 100vw;
 height: 999px;
 position: absolute;
 overflow: hidden;
 top: 0;

 
 
  /* position: fixed; optional depending on what you want to do in your app */
  
}  

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 180vh !important;
  background: rgba(0, 0, 0, 0.6); /* Dark overlay with 50% opacity */
  z-index: -1; /* Ensure the overlay is behind the content but above the video */
}

/**
 * ----------------------------------------
 * animation wobble-hor-bottom
 * ----------------------------------------
 */

.wobble-hor-bottom {
	animation: wobble-hor-bottom 6s infinite both;
}
  @keyframes wobble-hor-bottom {
    0%,
    100% {
      transform: translateX(0%);
      transform-origin: 50% 50%;
    }
    15% {
      transform: translateX(-30px) rotate(-6deg);
    }
    30% {
      transform: translateX(15px) rotate(6deg);
    }
    45% {
      transform: translateX(-15px) rotate(-3.6deg);
    }
    60% {
      transform: translateX(9px) rotate(2.4deg);
    }
    75% {
      transform: translateX(-6px) rotate(-1.2deg);
    }
  }

  /**
 * ----------------------------------------
 * end animation wobble-hor-bottom
 * ----------------------------------------
 */


/**
 * ----------------------------------------
 * animation slide-in-right
 * ----------------------------------------
 */

 .slide-in-right {
	animation: slide-in-right 5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
      @keyframes slide-in-right {
        0% {
          transform: translateX(1000px);
          opacity: 0;
        }
        100% {
          transform: translateX(0);
          opacity: 1;
        }
      }

/**
 * ----------------------------------------
 * animation slide-in-right
 * ----------------------------------------
 */


/**
 * ----------------------------------------
 * animation slide-in-elliptic-top-fwd
 * ----------------------------------------
 */

 .slide-in-elliptic-top-fwd {
	animation: slide-in-elliptic-top-fwd 2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
      @keyframes slide-in-elliptic-top-fwd {
        0% {
          transform: translateY(-600px) rotateX(-30deg) scale(0);
          transform-origin: 50% 100%;
          opacity: 0;
        }
        100% {
          transform: translateY(0) rotateX(0) scale(1);
          transform-origin: 50% 1400px;
          opacity: 1;
        }
      }

/**
 * ----------------------------------------
 * animation slide-in-elliptic-top-fwd
 * ----------------------------------------
 */
/* 
** Layout, Text & Colors 
*/

/* body {
    background: #150f21;
    font-size: 18px;
  } */

/* p {
    line-height: 1.5;
} */

/* Cards */
/* .card-column {
    width: 50%;
    float: left;
    padding: 4%;
    box-sizing: border-box;
}

.column-1 {
    padding-top: 100px;
}

.cardgrowth {
    width: 30%;
    max-width: 40%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    background: #EB5160;
    color: #fff;
    cursor: pointer;
    margin-bottom: 60px;
}

.borderr {
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 6px;
    border: 1px solid #fff;
    opacity: 0.5;
    left: -6px;
    top: -6px;
}

.cardgrowth h1 {
    position: relative;
    padding: 190px 0px 100px 10px;
    width: 90%;
}

.cardgrowth>img {
    width: 60%;
    position: absolute;
    top: -6%;
    left: -6%;
}

.card-color-0 {
    background-color: #EB5160;
}

.card-color-1 {
    background-color: #8F3985;
}

.card-color-2 {
    background-color: #8DAA91;
}

.card-color-3 {
    background-color: #888DA7;
} */

/* The cover (expanding background) */
/* .coverr {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #EB5160;
    z-index: 9999;
} */

/* The open page content */
/* .open-contentt {
    width: 100%;
    height: 100%;
    z-index: 10000;
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0;
    pointer-events: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.open-contentt img {
    position: relative;
    width: 90%;
    margin-left: 3%;
    margin-top: 20px;
    z-index: 5;
}

.open-contentt .textt {
    background: #fff;
    margin-top: -56%;
    padding: 60% 5% 5% 5%;
    width: 95%;
    margin-left: 5%;
    margin-bottom: 5%;
}

.open-contentt .textt h1,
.open-contentt .textt p {
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.close-contentt {
    display: block;
    position: absolute;
    right: 12px;
    top: 12px;
    width: 30px;
    height: 30px;
}

.close-contentt span {
    background: #222;
    width: 30px;
    height: 6px;
    display: block;
    position: absolute;
    top: 14px;
}

.x-1 {
    transform: rotate(45deg);
}

.x-2 {
    transform: rotate(-45deg);
} */

/* 
  ** Transitions
  */

/* .cardgrowth {
    transition: opacity 200ms linear 320ms, transform 200ms ease-out 320ms;
}

.borderr {
    transition: opacity 200ms linear, transform 200ms ease-out;
}

.cardgrowth img {
    transition: opacity 200ms linear 0ms, transform 200ms ease-in 0ms;
}

.cardgrowth h1 {
    transform: translate3d(20%, 0px, 0px);
    transition: opacity 200ms linear 120ms, transform 200ms ease-in 120ms;
} */

/* Clicked card */
/* .cardgrowth.clicked img {
    transform: translate3d(0px, -40px, 0px);
    opacity: 0;
}

.cardgrowth.clicked .borderr {
    opacity: 0;
    transform: scale(1.3);
}

.cardgrowth.out,
.cardgrowth.out img {
    transform: translate3d(0px, -40px, 0px);
    opacity: 0;
}

.cardgrowth.out h1,
.cardgrowth.clicked h1 {
    transform: translate3d(20%, -40px, 0px);
    opacity: 0;
}

.coverr {
    transition: transform 300ms ease-in-out;
}

.open-contentt {
    transition: opacity 200ms linear 0ms;
}

.open-contentt.open {
    opacity: 1;
    pointer-events: all;
    transition-delay: 1000ms;
} */

/* 
  ** Media Queries
  */

/* @media screen and (max-width: 600px) {
    .card-column {
        width: 90%;
    }

    .column-1 {
        padding-top: 0px;
    }

    .open-contentt img {
        margin-top: 40px;
    }
} */

.gallery {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin: 50px 0;
  }
  
  .growthcontainer {
    width: 250px;
    margin: 15px;
    box-sizing: border-box;
    float: left;
    text-align: center;
    border-radius: 20px;
    cursor: pointer;
    padding-top: 10px;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    transition: 4s;
    background: #f2f2f2;
  }
  
  .content:hover {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    transform: translate(0px -8px);
  }
  
  img {
    width: 250px;
    /* height: 280px; */
    text-align: center;
    margin: 0 auto;
    display: block;
    margin-top: -15px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    obect-fit: cover;
  }
  
  p {
    color: #b2bec3;
    padding-top: 0 8px;
  }
  
  h6 {
    font-size: 26px;
    text-align: center;
    color: #222f3e;
    margin: 0;
  }
  
  ul {
    list-style: none;
    /* display: flex; */
    justify-content: center;
    align-items: center;
    padding: 0;
  }
  
  li {
    padding: 5px;
  }
  
  .fa {
    font-size: 26px;
    transition: 4s;
  }
  
  .checked {
    color: #ff9f43;
  }
  
  .fa:hover {
    transform: scale(1.3);
    transition: 0.6s;
  }
  
  button {
    text-align: center;
    font-size: 24px;
    color: #fff;
    width: 100%;
    padding: 15px;
    border: 0;
    outline: none;
    cursor: pointer;
    margin-top: 5px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
  }
  
  .buy-1 {
    background-color: #2183a2;
  }
  
  .buy-2 {
    background-color: #3b3e6e;
  }
  
  .buy-3 {
    background-color: #0b0b0b;
  }
  
  .buy-4 {
    background-color: #ff9f43;
  }
  
  @media (max-width: 1000px) {
    .content {
      width: 45%;
    }
  }
  
  @media (max-width: 750px) {
    .content {
      width: 100%;
    }
  }

  .fix {
    overflow: hidden;
  }
  .containerrr {
    padding: 10px 70px 70px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
  }
  /* img-pop */
  .img-pop {
    position: relative;
    width: 480px;
    margin: 0 20px 20px 0;
  }
  
  img {
    width: 100%;
    max-width: 480px;
  }
  
  .btn {
    font-size: 50px;
    text-decoration: none;
    height: 100px;
    width: 100px;
    background: #179f5d;
    color: #fff;
    line-height: 100px;
    text-align: center;
    border-radius: 50%;
    transition: all 0.3s;
  }
  
  .btn:hover {
    background: white !important;
    color: black !important;
  }
  
  .img-pop a {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
  }
  
  /* video 2 center using margin auto*/
  
  .video2 {
    position: relative;
    width: 480px;
  }
  
  .video2 a {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 80px;
    height: 80px;
  }
  .btn2 img {
    width: 80px;
    transition: all 0.3s;
  }
  
  .btn2 img:hover {
    width: 90px;
  }
  
  /* video 3 using flex */
  
  .video3 {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .video3 a {
    position: absolute;
  }
  
  .btn3 img {
    width: 80px;
    transition: all 0.3s;
  }
  .btn3 img:hover {
    width: 150px;
  }
  
  /* image hover */
  .img-hover {
    transition: all 0.5s;
  }
  .img-hover:hover {
    transform: scale(1.2) rotate(2deg);
  }
  
  .popup{
    display: none;
  }

  .activePopup{
    /* position: relative; */
    /* display: block;
    position: absolute; */
    width: 100%;
    max-width: 480px;
  }

  /* megajoule Card */

.Megajoulecard:after {
  content: "";
  display: table;
  clear: both;
}

.Megajoulecard {
  width: 90% !important;
  /* background-color: white; */
  margin: 60px auto;
  padding: 10px;
}

.img {
  width: 80%;
  /* height: 250px; */
}

.h1,
.h2 {
  margin: 0;
}

.item {
  float: left;
  width: 50%;
  box-sizing: border-box;
  padding: 0px 10px;
}

.h1 {
  font-size: 2.5rem;
  font-family: 'Coda';
  text-align: center;
  color: #2e3849;
}

.h2 {
  font-family: 'Lato';
  color: #8f9491;
  text-transform: uppercase;
  text-align: center;
  font-size: 1.8rem;
}

.p {
  font-family: 'Open Sans';
  color: black;
  font-size: 13px;
}

.open {
  font-family: 'Open Sans';
  color: #8f9491;
  font-size: 0.8rem;
}

#price1 {
  display: block;
  font-family: 'Lato';
  text-align: center;
  font-size: 1.4rem;
  font-weight: bold;
}

#green-arrow a {
  text-decoration: none;
  display: block;
  font-size: 2rem;
  color: #38e4ae;
  padding-left: 10px;
}

.p {
  margin-top: 5px;
}

.stock {
  font-family: 'Lato';
  font-weight: bold;
  color: #2e3849;
}

.fa-star {
  color: #edc368;
}

#grey,
.arrow {
  color: #8f9491;
}

.arrow {
  font-size: 2.5rem;
  font-family: 'Open Sans';
  display: inline !important;
  margin-bottom: 0;
  background-color: white;
  border: 0;
}

#number {
  font-size: 1.8rem;
  font-family: 'Lato';
}

#quantity {
  font-size: 1.3rem;
  font-family: 'Lato';
}

#cart {
  background-color:orange !important;
  border: 0;
  color: white;
  font-size: 15px;
  padding: 6px;
}

/* MODAL CSS */
.open-modal-btn {
  background: #fff;
  padding: 18px 25px;
  border-radius: 20px;
  font-weight: 500;
  border: 0;
  outline: none;
  font-size: 22px;
  cursor: pointer;
  line-height: 23px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
  transition: all 0.1s ease-in-out;
}

.open-modal-btn:hover {
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.1);
  transform: translateY(-1px);
}

.open-modal-btn:active {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  transform: translateY(0);
}

.overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  transition: all 0.5s ease-in-out;
}

.modalll {
  background-color: #272829;
  width: 98%;
  max-width: 98%;
  max-height: 100vh;
  overflow: scroll;
  padding: 40px 30px;
  border-radius: 10px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
  transition: all 0.5s ease-in-out;
}

.modalll img {
  border-radius: 5px;
  width: 100%;
}

.modalll svg {
  position: absolute;
  right: 10px;
  top: 10px;
  height: 24px;
  width: 24px;
  opacity: 0.3;
  cursor: pointer;
}

.modalll svg:hover {
  opacity: 1;
}

.overlay.animated {
  opacity: 0;
  visibility: hidden;
}

.overlay.animated.show {
  opacity: 1;
  visibility: visible;
}

.overlay.animated.show .modalll {
  opacity: 1;
  visibility: visible;
}

.overlay.animated.show .modalll {
  transform: translate(-50%, -50%);
}

/* Youtube Video */
.videocard {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  background-color: #fff;
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.2);
}
@media (min-width: 480px) {
  .videocard {
    width: 70%;
    max-width: 760px;
  }
}
.card__sharing {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 30px 0 15px;
}
.card__social {
  display: flex;
}
.card__social-icon {
  margin-left: 1.5em;
  font-size: 1.3rem;
}
.videocard__info {
  margin: 0 auto;
  max-width: 500px;
  padding: 6% 8%;
}
@media (min-width: 768px) {
  .videocard__info {
    padding: 4% 10%;
  }
}
.videocard__author {
  font-weight: 300;
  font-size: 1.05rem;
}
.videocard__title {
  margin: 0.5em 0 0.7em;
  font-size: 1.3rem;
  font-weight: 500;
}
.videocard__description {
  font-weight: 300;
  line-height: 1.4;
  color: #828282;
}
.videocard__timestamp {
  display: flex;
  color: #828282;
  align-items: center;
  font-size: 1rem;
  line-height: 1;
  color: #d2d1d1;
  letter-spacing: 1px;
}
.card__timestamp-icon {
  margin-right: 0.5em;
  font-size: 1.3em;
  line-height: 1;
}

.video {
  width: 100%;
  background: url("https://img.freepik.com/free-photo/beautiful-landscape-with-small-village_23-2149721819.jpg?t=st=1710936542~exp=1710940142~hmac=179180ae134f05134f7e2523abd1c4f0bdf2c8e1f42f27674a335e78d6ec577c&w=2000") center/cover no-repeat;
}
.video__container {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 56.25%;
}
.video__placeholder {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("https://img.freepik.com/free-photo/beautiful-landscape-with-small-village_23-2149721819.jpg?t=st=1710936542~exp=1710940142~hmac=179180ae134f05134f7e2523abd1c4f0bdf2c8e1f42f27674a335e78d6ec577c&w=2000") center/cover no-repeat;
}
.video__embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* EXPANDABLE CARDS */
/* Mohave & Open Sans Fonts */
/* Fonts & Colors */
:root {
  --title-font: "Mohave", sans-serif;
  --body-font: "Open Sans", sans-serif;
  --orange: hsl(0, 100%, 65%);
  --lighter-orange: hsl(0, 100%, 77%);
  --grey: hsl(197, 3%, 46%);
  --dark: hsl(0, 0%, 16%);
  --white: hsl(0, 0%, 100%);
}

/* General */
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

::selection {
  background-color: var(--lighter-orange);
  color: var(--white);
}


p {
  margin: 0;
}
p:not(:last-child) {
  margin-bottom: 14px;
}

ul {
  list-style: none;
}

.expandableImage {
  width: 100%;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

/* Layout */
.main {
  max-width: 1200px;
  margin: 0 auto;
}

/* Typography */
.card_title {
  font-family: var(--title-font);
  font-size: 28px;
  line-height: 24px;
  text-transform: uppercase;
}

.card_text p {
  font-family: var(--body-font);
  font-size: 14px;
  line-height: 24px;
}

/* Utilities */
.orange {
  color: var(--orange);
}

.note-sm {
  color: var(--grey);
  font-family: var(--title-font);
  font-size: 14px;
}

/* Components */
.expandablecards {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  margin: 50px 0;
}
.expandablecards_item {
  display: flex;
  padding: 1rem;
}

.expandablecard {
  background-color: var(--white);
  border-radius: 0.25rem;
  box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.expandablecard_content {
  padding: 26px 16px 16px;
}
.expandablecard_title {
  margin-bottom: 5px;
}
.expandablecard_text {
  margin: 16px 0;
}
.expandablecard_btn {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 12px;
  text-transform: capitalize;
  font-family: var(--body-font);
  float: right;
}
.expandablecard_btn:hover {
  text-decoration: underline;
}

/* Media queries */
@media (min-width: 40rem) {
  .expandablecards_item {
    width: 50%;
  }
}
@media (min-width: 56rem) {
  .expandablecards_item {
    width: 33.3333%;
  }
}

/* GROWTH SEEKING ASSET APPLICATION */
.gsacontainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 800px;
  background: #fff;
  margin: 40px auto;
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
}
.gsacontainer .gsaitem {
  flex-basis: 25%;
  max-width: 25%;
  text-align: center;
  position: relative;
  padding: 0.5rem;
  box-sizing: border-box;
}
.gsacontainer .gsaitem a {
  text-decoration: none;
  color: #333;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.gsacontainer .gsaitem a:before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  width: 0px;
  height: 0px;
  opacity: 0;
  border: 2px solid #007bfc;
  border-bottom: none;
  border-radius: 8px 8px 0 0;
  transition: width 0.15s ease-out 0.15s, left 0.15s ease-out 0.15s, height 0.15s ease-out, opacity ease-out 0.3s;
}
.gsacontainer .gsaitem a:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0px;
  height: 0px;
  opacity: 0;
  border: 2px solid #007bfc;
  border-top: none;
  border-radius: 0 0 8px 8px;
  transition: width 0.15s ease-out 0.15s, left 0.15s ease-out 0.15s, height 0.15s ease-out, opacity ease-out 0.3s;
}
.gsacontainer .gsaitem-image {
  padding: 2.4rem;
  height: 4rem;
  width: 4rem;
}
.gsacontainer .gsaitem-text {
  font-size: 1rem;
  font-size: 1.2rem;
  font-weight: 700;
  color: #555;
  line-height: 1rem;
  transition: transform ease 0.4s, opacity ease 0.4s;
  opacity: 1;
}
.gsacontainer .gsaitem-link-desc {
  font-size: 1rem;
  font-size: 1.2rem;
  font-weight: 700;
  color: #555;
  line-height: 1rem;
  transition: transform ease 0.4s, opacity ease 0.4s;
  opacity: 0;
}
.gsacontainer .gsaitem a:hover {
  /*
  &:before{
    opacity: 1;
    width: calc(50% - 1px);
    height: calc(100% - 2px);
  }

  &:after{
    opacity: 1;
    width: calc(50% - 1px);
    height: calc(100% - 2px);
  }

  */
}
.gsacontainer .gsaitem a:hover .gsaitem-text {
  transform: translatey(-1rem);
  opacity: 0;
}
.gsacontainer .gsaitem a:hover .gsaitem-link-desc {
  transform: translatey(-1rem);
  opacity: 1;
}
.gsacontainer .gsaitem a:hover:before {
  width: 100%;
  height: calc(100% - 10px);
  left: 0px;
  opacity: 1;
  transition: width 0.15s ease-out, left 0.15s ease-out, height 0.15s ease-out 0.15s, opacity ease-out 0.15s;
}
.gsacontainer .gsaitem a:hover:after {
  width: 100%;
  height: 10px;
  left: 0px;
  opacity: 1;
  transition: width 0.15s ease-out, left 0.15s ease-out, height 0.15s ease-out 0.15s, opacity ease-out 0.15s;
}
.dashcontainer {
  height: 100%;
  margin: 0;
  font-family: kanit;
  font-size: 16px;
  letter-spacing: 0.05rem;
}

.options{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap ;/* Allows items to wrap to the next row */
  grid-gap: 10px;
  gap: 10px; /* Adds space between items */
}
.worldmap {
  width: 100%;
  position: absolute;

  z-index: 0;
}

.assetRow.selected {
  background-image: linear-gradient(120deg, #f6d365 0%, #fda085 100%) !important;
  /* Change background when clicked */
}

.gm-style .gm-style-iw-c {
  background: linear-gradient(to top, #dfe9f3 0%, white 100%) !important;
}

.chat-input {
  width: 100%;
  box-sizing: border-box;
  padding-left: 25px;
  align-self: flex-end;
  display: flex;
  margin-bottom: 15px;
  position: relative;
}

.chat-input textarea {
  font-family: kanit;
  padding: 10px;
  color: #eee;
  background: transparent;
  border: 3px solid orange;
  border-right: 0px;
  text-shadow: 0px 0px 0px #eee;
  -webkit-text-fill-color: transparent;
  font-size: 1rem;
  width: 100%;
  box-sizing: border-box;
  resize: none;
  transition: background .2s ease-out;
}

.submit-icon {
  width: 50px;
  height: 100%;
  cursor: pointer;
  flex-grow: 1;
  background: orangered;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 50px;
  color: #bbbbbf;
  box-sizing: border-box;
  border: 3px solid #333339;
}

textarea::-webkit-input-placeholder {
  color: #68686e;
  text-shadow: none;
  -webkit-text-fill-color: initial;
}

.chat-input textarea:focus {
  outline: none;
  background: transparent;
  background: #222229;
}

.chat-input textarea:hover {
  background: #222229;
  transition: background .2s ease-out;
}

.spacing-adjuster {
  width: 100%;
  flex-grow: 1;
  height: 100%;
}

.draghere {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  color: white;
  font-size: 1.4rem;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  background: transparent;
  border: 2px dashed #58585e;
}

.file-container li:hover {
  background: #3f3f45;
}

.performance-table {
  table-layout: fixed;
  color: #fff;
  width: 100%;
  min-width: 100%;
  border-collapse: collapse;
  box-sizing: border-box;
  font-size: 1.3rem;
  font-weight: 200;
}

.align-right {
  text-align: right;
  float: right;
}

.dashli {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  display: block !important;
  background: linear-gradient(109.6deg, rgba(0, 0, 0, 0.93) 11.2%, rgba(63, 61, 61, 1) 78.9%);
  margin-bottom: 10px;
  cursor: pointer;
}

.performance-table tr:nth-child(odd) {
  background: #515158;
}

.performance-table tr:nth-child(even) {
  background: #48484e;
}

.performance-table tr:nth-child(1) {
  background: #333339;
  font-size: 1.2rem;
}

.dashtable-header {
  background: linear-gradient(to top, #dfe9f3 0%, white 100%);
  margin-top: 30px;
}

.element-header {
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: 15px;
  box-sizing: border-box;
  margin-bottom: 15px;
  width: 100%;
}

.performance-table td {
  padding: 20px 5px 20px 5px;
  color: #fff !important;
  line-height: 1em;
  text-align: right;
}

p {
  margin-top: 0.4rem;
  font-weight: 200;
}




.dashrow.todo {
  background: linear-gradient(109.6deg, rgba(0, 0, 0, 0.93) 11.2%, rgba(63, 61, 61, 1) 78.9%);
}

.table-header.todo {
  background: transparent;
  margin-left: 15px;
  margin-right: 15px;
}

.element-header.social {
  margin-bottom: 0px;
}

.social-element {
  width: 66.6666%;
  flex-flow: column;
  height: 100%;
  display: flex;
}

.todo-element {
  width: 33.3333%;
  height: 100%;
}

.file-container {
  /* background: linear-gradient( 97.3deg,  rgba(25,50,70,0.81) 10.7%, rgba(250, 81, 9, 0.72) 39.5%, rgba(255,192,0,0.81) 69.7% ); */
  background: linear-gradient(109.6deg, rgba(0, 0, 0, 0.93) 11.2%, rgba(63, 61, 61, 1) 78.9%);
  color: #eee;
  border: 15px solid #29292e;
  border-top: 0px;
  height: 400px;
  padding: 25px;
  display: flex;
  flex-flow: column;
  box-sizing: border-box;
}

.dashul {
  list-style: none;
  background: linear-gradient(109.6deg, rgba(0, 0, 0, 0.93) 11.2%, rgba(63, 61, 61, 1) 78.9%);
  margin: 0;
  padding: 0;
}

.jimmy-image {
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  width: 60px;
  background: url('https://pexels.imgix.net/photos/2261/food-man-person-eating.jpg?fit=crop&w=65&h=65');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
}

.anna-image {
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  width: 60px;
  background: url('https://pexels.imgix.net/photos/54204/pexels-photo-54204.jpeg?fit=crop&w=60&h=60');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
}

.chatbox-container {
  margin-left: 25px;
  flex-flow: row;
  margin-top: 20px;
  display: flex;
}

.chatbox-left {
  background: #48484e;
  line-height: 1.2em;
  max-width: 75%;
  min-height: 77px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  padding: 10px;
  box-sizing: border-box;
  font-size: 1rem;
  padding-right: 20px;
  color: #fff;
  font-weight: 200;
}

.username {
  display: block;
  color: #ccc;
}

.username.right {
  text-align: right;
}

.update-number {
  display: inline-block;
  background: #FF8A8A;
  height: 1rem;
  margin-left: 30px;
  color: #ccc;
  font-size: .9rem;
  margin-top: .3rem;
  margin-bottom: .3rem;
  padding: 1px;
  padding-bottom: 8px;
  text-align: center;
  font-weight: 500;
  width: 1.5rem;
  border-radius: 10px;
}

.country-select {
  margin-top: 10px;
  color: black;
  cursor: pointer;
  display: inline-block;
  font-weight: 200;
  letter-spacing: .2rem;
  margin-right: 10px;
  font-size: 1.3rem;
  padding-left: 20px;
  padding-right: 20px;
  border-bottom: 3px solid #44444a;
  position: relative;
  transition: border-color .2s ease-out;
}

.country-select:first-child {
  border-bottom-color: orange;
  margin-left: 0px;
}

.country-select:hover {
  border-color: #FFAD60;
  transition: border-color .2s ease-out;
}

.country-selected {
  margin-top: 10px;
  color: black;
  cursor: pointer;
  display: inline-block;
  font-weight: 200;
  letter-spacing: .2rem;
  margin-right: 10px;
  font-size: 1.3rem;
  padding-left: 20px;
  padding-right: 20px;
  border-bottom: 3px solid #FFAD60;
  position: relative;
  transition: border-color .2s ease-out;
}

th:after {
  width: 50%;
  margin: auto;
  content: '';
  height: 2px;
  background: #ccc;
}

.main-color {
  color: #FFAD60;
}

td.table-desc {
  font-size: 1.3rem;
  text-align: center;
}

.navigation-elm {
  padding-left: 20px;
  box-sizing: border-box;
  color: #ccc;
  display: flex;
  align-items: center;
  height: 60px;
  width: 100%;
  cursor: pointer;
  font-weight: 200;
  letter-spacing: 0.05rem;
  transition: background .1s ease-out;
}

.sub-navigation-elm {
  padding-left: 80px;
  box-sizing: border-box;
  background: rgba(100, 100, 130, 0.1);
  color: #ccc;
  display: flex;
  align-items: center;
  height: 50px;
  width: 100%;
  cursor: pointer;
  font-weight: 200;
  letter-spacing: 0.05rem;
  font-size: .9rem;
  transition: background .1s ease-out;
}

.sub-navigation-elm:hover {
  background: rgba(100, 100, 130, 0.3);
}

.navigation-elm:nth-child(1) {
  background: rgba(100, 100, 130, 0.2);
}

.navigation-elm:hover {
  background: rgba(100, 100, 130, 0.2);
  transition: all .1s ease-out;
}

.navigation-elm:hover .nav-text {
  transform: scale(1.1);
  transition: transform .1s ease-out;
}

.nav-text {
  transition: transform .1s ease-out;
}

.piechart-label {
  width: 70%;
  height: 100%;
  line-height: 1em;
  min-height: 100px;
  color: #eee;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  padding: 0 5% 0 5%;
}

.small {
  font-size: .9rem;
}

.label-heading {
  color: #fff;
  align-self: flex-start;
  font-size: 1.4rem;
  padding: 10px
}

.key-metrics {
  min-height: 80px;
  border-top: 4px solid black;
  min-width: 100px;
  padding: 0 10px 0 10px;
  color: white;
  flex-flow: column;
  line-height: 1em;
  text-align: center;
  background: #3f3f45;
  margin: 60px 60px 20px 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
}

a,
a:active,
a:visited {
  color: inherit;
}

.madeby {
  color: #999;
  position: absolute;
  right: 15px;
  font-size: 1rem;
  top: 15px;
}

.white-border {
  border-top: 4px solid white;
}

.blue-border {
  border-top: 4px solid #5AB3D1;
}

.fa-fw {
  margin-right: 15px;
}

.dashsidebar {
  padding-top: 2%;
  height: 100%;
  left: 0;
  width: 300px;
  display: flex;
  color: #fff;
  flex-flow: column;
  position: fixed;
  background: radial-gradient(circle farthest-corner at -8.9% 51.2%, rgba(255, 124, 0, 1) 0%, rgba(255, 124, 0, 1) 15.9%, rgba(255, 163, 77, 1) 15.9%, rgba(255, 163, 77, 1) 24.4%, rgb(27, 27, 27) 24.5%, rgb(27, 29, 31) 66%);
  z-index: 1;
}

.dashrow {
  display: flex;
  width: 80%;
  margin-left: 300px;
}

.profilerow {
  display: flex;
  width: 98%;
  margin-left: auto;
  margin-right: auto;
}

.stats-table td:last-of-type {
  padding-right: 15px;
}

.stats-table td:first-of-type {
  padding-left: 15px;
}

.tableHead li:first-of-type {
  padding-left: 15px;
}

.tableHead li {
  padding: 20px 5px 20px 5px;
  font-size: 1rem;
  text-align: right;
  margin-right: 5%;
}

.tableHead {
  list-style: none;

}

.stats-table {
  width: 100%;
  height: 500px;
  overflow: auto;
  /* background: #3a3a3f; */
  box-sizing: border-box;
  border: 25px solid #E2EBF4;
  border-right-width: 15px;
}

.piechart-element {
  width: 33.3333%;
  box-sizing: border-box;
  display: flex;
  padding-right: 20px;
  min-height: 100px;
  max-height: 200px;
  justify-content: center;
  align-items: center;
  position: relative;
}

.position-marker {
  content: '';
  position: absolute;
  height: 3vw;
  width: 3vw;
  border-radius: 50%;
  background: hsla(200, 30%, 45%, 0.6);
  top: 20%;
  left: 10%;
  z-index: 4;
  position: relative;
  transition: all .2s ease-out;
}

.position-marker.no2 {
  top: 5%;
  width: 2vw;
  height: 2vw;
  left: 45%;
  z-index: 2;
}

.position-marker.no3 {
  top: 5%;
  width: 2vw;
  height: 2vw;
  left: 18%;
  z-index: 2;
}

.position-marker.no4 {
  top: -5%;
  width: 3vw;
  height: 3vw;
  left: 40%;
  z-index: 2;
}

.position-marker.no5 {
  top: -5%;
  width: 4vw;
  height: 4vw;
  left: 75%;
  z-index: 6;
}

.position-marker.no6 {
  top: 0%;
  width: 1.5vw;
  height: 1.5vw;
  left: 30%;
  z-index: 6;
}

.order-scroll {
  text-align: right;
  cursor: pointer;
  width: 100%;
  padding-right: 5px;
  padding-bottom: 3px;
  color: black;
  border: 15px solid #FEFEFE;
  border-top: 0px;
  box-sizing: border-box;
  transition: background .2s ease-out, color .2s ease-out;
}

.order-scroll:hover {
  color: #fff;
  background: rgba(0, 0, 0, 0.1);
  transition: background .2s ease-out, color .2s ease-out;
}

.order-container {
  height: 600px;
  max-height: 600px;
  /* Adjust the height as needed */
  overflow-y: auto;
  /* Enables vertical scrolling */
  background: linear-gradient(to top, #dfe9f3 0%, white 100%);

}

.position-marker:hover {
  background: hsla(200, 20%, 55%, 0.6);
  transform: scale(1.1);
  transition: all .2s ease-out;
}

.position-marker:after {
  content: '';
  background: hsla(200, 30%, 45%, 0.8);
  height: 33.33%;
  width: 33.33%;
  position: absolute;
  top: 0;
  left: 0;
  margin: auto;
  bottom: 0;
  right: 0;
  border-radius: 50%;
  z-index: 2;
}

.piechart-container {
  width: 30%;
  display: flex;
  position: relative;
}

.piechart-percent {
  position: absolute;
  width: 30%;
  top: 0;
  right: 10px;
  font-size: 1.8vw;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.map-container {
  height: 83vh;
  width: 60vw;
  background-image: url('https://upload.wikimedia.org/wikipedia/commons/a/a0/World_map_with_points.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top;
}

.stats-element {

  background-image: linear-gradient(to top, #dfe9f3 0%, white 100%);

  border-right-width: 0px;
  box-sizing: border-box;
  position: relative;
  /* width: 66.666%; */
}

.order-element {
  color: #100f0f;
  width: 33.333%;
  display: flex;
  flex-flow: column;
  box-sizing: border-box;

  background-image: linear-gradient(to top, #dfe9f3 0%, white 100%);

}

.order-element h1 {
  padding-left: 20px;
  font-size: 2.12rem !important;
  padding-top: 10px;
}

.order-element br {
  line-height: 1em;
}

.highlight {
  color: #FFBB75;
}

.order-element p {
  padding-left: 20px;
  margin-bottom: 10px;
  font-size: 1rem;
  line-height: 1.2em;
}

.order-element p:last-child {
  margin-bottom: 5px;
}

h1 {
  color: #fff;
  font-weight: 200;
  line-height: .9em;
  margin: 0;
  margin-top: .2em;
  font-size: 1.2rem;
}

.order-item {
  background-image: linear-gradient(120deg, #f6d365 0%, #fda085 100%);
  width: 100%;
  /* padding: 10px 0 10px 0; */
  color: #4A4947;
  border: 15px solid #FEFEFE;
  border-top: 0px;
  box-sizing: border-box;
  position: relative;
}

.order-item:after {
  content: '';
  background: #3C3D37;
  height: 100%;
  width: 5px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 5;
}

.order-scroll.up {
  border-bottom: 0px;
}

.order-item.highlight:after {
  content: '';
  background: #FFBB75;
  height: 100%;
  width: 5px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 5;
}

.order-item.hold:after {
  content: '';
  background: #999;
  height: 100%;
  width: 5px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 5;
}

.inactive {
  color: #aaa;
}

.piechart-container canvas {
  margin-top: 10%;
  margin-bottom: 10%;
}

.piechart-element:nth-child(odd) {
  background: #3a3a3f;
}

.piechart-element:nth-child(even) {
  background: #3f3f45;
}

.linechart-element {
  position: relative;
  overflow: hidden;
  transform: scale(1.02);
  height: 40%;
}

.dashcontainer {
  overflow-X: hidden;
  /* background: linear-gradient(97.3deg, rgba(25, 50, 70, 0.81) 10.7%, rgba(250, 81, 9, 0.72) 39.5%, rgba(255, 192, 0, 0.81) 69.7%); */
  box-sizing: border-box;
  width: 100%;
  z-index: 0;
  position: relative;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #555;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #222227;
  max-height: 20p;
}

@media only screen and (max-width: 1280px) {
  html {
    font-size: 14px;
  }

  .position-marker {
    left: 20%;
  }

  .position-marker.no2 {
    left: 45%;
  }

  .position-marker.no3 {
    left: 25%;
  }

  .position-marker.no4 {
    left: 42%;
  }

  .position-marker.no5 {
    left: 70%;
  }

  .position-marker.no6 {
    left: 32%;
  }

  .madeby {
    top: 75px;
  }

  .navigation-elm:nth-child(1) {
    background: #333339;
  }

  .dashsidebar {
    width: 100%;
    flex-flow: row;
    height: 60px;
    font-size: .9rem;
    padding-top: 0px;
    margin-bottom: 40px;
    position: relative;
  }

  .sub-navigation-elm {
    display: none;
  }

  .update-number {
    display: none;
  }

  .dashcontainer {
    padding-top: 60px;
    padding-left: 0px;
  }

  .dashrow {
    display: flex;
    width: 100%;
    margin-left: 0px;
  }
}

@media only screen and (max-width:920px) {
  .text-long {
    display: none;
  }
}

@media only screen and (max-width: 800px) {
  .key-metrics {
    margin-top: 0px;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }

  .dashrow {
    flex-direction: column;
  }

  .piechart-element {
    width: 100%;
  }

  .piechart-label {
    padding-left: 15px;
  }

  .stats-element {
    width: 100%;
  }

  .order-element {
    width: 100%;
  }

  .social-element {
    width: 100%;
  }

  .todo-element {
    width: 100%;
  }

  .map-container {
    height: 60vw;
  }

  .piechart-percent {
    font-size: 8vw;
  }

  .chat-input {
    padding-right: 15px;
    padding-left: 15px;
  }

  .stats-table {
    border-width: 15px;
  }
}

@media only screen and (max-width: 640px) {
  .text-short {
    display: none;
  }

  .navigation-elm {
    font-size: 4.5vw;
    justify-content: center;
    text-align: center;
  }
}
 div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
  color: white;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
/* body {
	line-height: 1;
} */
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
  
}

.linebar{
  text-align:center;

}



.linebar h1{
  padding: 60px 0;
  font-weight:800;
  font-size:36px;
  text-decoration:none;
}


.linebar a{
  text-decoration:none;
  color:#1a237e;
  
}

.footer {
  left: 0;
  bottom: 0;
  width: 100%;
  height:40px;
  background-color: #E3E3E3;
 
}
.footer p{
  padding:10px;
  text-align:center;
  color: black;
}
.footer strong{
  font-weight:800;
}
.linebar2{
 background-image: linear-gradient(120deg, #fccb90 0%, #d57eeb 100%);
 width: 80%;
 margin-left: auto;
 margin-right: auto;
  text-align:center;
}

.linebar2 ul{
  padding: 15px 0px;
}

li a{
  text-decoration:none;
  /* font-weight:bold; */
  color:white; 
  padding: 0 15px;
}

li a:hover{
  color: #F8F4EC;
  background-color: #222831;
}

li{
  display:inline;
  

}


.bazarheader{
  display:flex;
  flex-wrap:wrap;
  margin-top:40px;
  margin-left:40px;

}
.content1{

  height:250px;
  min-width:300px;
  margin-right:65px;
}
.content2{
  margin-right:15px;
  margin-bottom:15px;
  font-size:25px;
  height:450px;
  width:350px;
  border-radius:15px;
}

.catalog{
  font-size:60px;
  font-weight:bold;
  color: orange;
}

.catalog #bazzarbutton{
  /* color:white; */
  font-weight:bold;
  font-size: 14px;
  border-radius:100px;
  width: 120px;
  height:45px;
  border-style:none;
  margin-bottom:15px;
  background-image:linear-gradient(to right, #B65EBA, #6454F0 );
}
.catalog button:hover{
  cursor:pointer;
  background-image:linear-gradient(to right, #B654BA, #6434F0 );

}

.circledesign {
    font-size:30px;
    bottom: 0;
    width: auto;
    height: 50px;
}

.ImageContent {
    width: auto;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    height: 401px;
    /* background-image: url(https://i.pinimg.com/564x/01/10/fb/0110fba5b46c2d0ccc9b9a5e4fe72295.jpg); */

  background-size:120%;
    background-repeat: no-repeat;

  
    
  }
 .ImageContent2 {
    width: auto;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    height: 401px;
    background-image:       url(https://firebasestorage.googleapis.com/v0/b/miami-auth-58a82.appspot.com/o/MIAMI%20PHOTOSE%2Fhousing.png?alt=media&token=c6a71e84-54ef-4f7e-ae84-727a53e60f7e);

  background-size:100%;
    background-repeat: no-repeat;

}

.circledesign{
  font-weight:bold;
  color: orange;
}

.content2:hover{

  opacity:0.9;
  
background-image: url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%239C92AC' fill-opacity='0.28'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  cursor:pointer;
background-color: white;
}

::selection {
  background: #03A9F4; /* WebKit/Blink Browsers */
}

/* SEARCH ASSET  */
#search {
	display: grid;
	grid-area: search;
	grid-template:
		"search" 60px
		/ 420px;
	justify-content: center;
	align-content: center;
	justify-items: stretch;
	align-items: stretch;
	/* background: hsl(0, 0%, 99%); */
  margin-bottom: 25px;
}

#search input {
	display: block;
	grid-area: search;
	-webkit-appearance: none;
	appearance: none;
	width: 100%;
	height: 100%;
	background: none;
	padding: 0 30px 0 60px;
	border: none;
	border-radius: 100px;
	font: 24px/1 system-ui, sans-serif;
	outline-offset: -8px;
}


#search svg {
	grid-area: search;
	overflow: visible;
	color: #FFF;
	fill: none;
	stroke: currentColor;
	visibility: visible !important;
}

.spark {
	fill: currentColor;
	stroke: none;
	r: 15;
}

.spark:nth-child(1) {
	animation:
		spark-radius 2.03s 1s both,
		spark-one-motion 2s 1s both;
}

@keyframes spark-radius {
	0% { r: 0; animation-timing-function: cubic-bezier(0, 0.3, 0, 1.57) }
	30% { r: 15; animation-timing-function: cubic-bezier(1, -0.39, 0.68, 1.04) }
	95% { r: 8 }
	99% { r: 10 }
	99.99% { r: 7 }
	100% { r: 0 }
}

@keyframes spark-one-motion {
	0% { transform: translate(-20%, 50%); animation-timing-function: cubic-bezier(0.63, 0.88, 0, 1.25) }
	20% { transform: rotate(-0deg) translate(0%, -50%); animation-timing-function: ease-in }
	80% { transform: rotate(-230deg) translateX(-20%) rotate(-100deg) translateX(15%); animation-timing-function: linear }
	100% { transform: rotate(-360deg) translate(30px, 100%); animation-timing-function: cubic-bezier(.64,.66,0,.51) }
}

.spark:nth-child(2) {
	animation:
		spark-radius 2.03s 1s both,
		spark-two-motion 2.03s 1s both;
}

@keyframes spark-two-motion {
	0% { transform: translate(120%, 50%) rotate(-70deg) translateY(0%); animation-timing-function: cubic-bezier(0.36, 0.18, 0.94, 0.55) }
	20% { transform: translate(90%, -80%) rotate(60deg) translateY(-80%); animation-timing-function: cubic-bezier(0.16, 0.77, 1, 0.4) }
	40% { transform: translate(110%, -50%) rotate(-30deg) translateY(-120%); animation-timing-function: linear }
	70% { transform: translate(100%, -50%) rotate(120deg) translateY(-100%); animation-timing-function: linear }
	80% { transform: translate(95%, 50%) rotate(80deg) translateY(-150%); animation-timing-function: cubic-bezier(.64,.66,0,.51) }
	100% { transform: translate(100%, 50%) rotate(120deg) translateY(0%) }
}

.spark:nth-child(3) {
	animation:
		spark-radius 2.05s 1s both,
		spark-three-motion 2.03s 1s both;
}

@keyframes spark-three-motion {
	0% { transform: translate(50%, 100%) rotate(-40deg) translateX(0%); animation-timing-function: cubic-bezier(0.62, 0.56, 1, 0.54) }
	30% { transform: translate(40%, 70%) rotate(20deg) translateX(20%); animation-timing-function: cubic-bezier(0, 0.21, 0.88, 0.46) }
	40% { transform: translate(65%, 20%) rotate(-50deg) translateX(15%); animation-timing-function: cubic-bezier(0, 0.24, 1, 0.62) }
	60% { transform: translate(60%, -40%) rotate(-50deg) translateX(20%); animation-timing-function: cubic-bezier(0, 0.24, 1, 0.62) }
	70% { transform: translate(70%, -0%) rotate(-180deg) translateX(20%); animation-timing-function: cubic-bezier(0.15, 0.48, 0.76, 0.26) }
	100% { transform: translate(70%, -0%) rotate(-360deg) translateX(0%) rotate(180deg) translateX(20%); }
}




.burst {
	stroke-width: 3;
}

.burst :nth-child(2n) { color: #ecebea }
.burst :nth-child(3n) { color: #ffab00 }
.burst :nth-child(4n) { color: #e5e7e4 }
.burst :nth-child(5n) { color: #c8c9c9 }

.circle {
	r: 6;
}

.rect {
	width: 10px;
	height: 10px;
}

.triangle {
	d: path("M0,-6 L7,6 L-7,6 Z");
	stroke-linejoin: round;
}

.plus {
	d: path("M0,-5 L0,5 M-5,0L 5,0");
	stroke-linecap: round;
}




.burst:nth-child(4) {
	transform: translate(30px, 100%) rotate(150deg);
}

.burst:nth-child(5) {
	transform: translate(50%, 0%) rotate(-20deg);
}

.burst:nth-child(6) {
	transform: translate(100%, 50%) rotate(75deg);
}

.burst * {}

@keyframes particle-fade {
	0%, 100% { opacity: 0 }
	5%, 80% { opacity: 1 }
}

.burst :nth-child(1) { animation: particle-fade 600ms 2.95s both, particle-one-move 600ms 2.95s both; }
.burst :nth-child(2) { animation: particle-fade 600ms 2.95s both, particle-two-move 600ms 2.95s both; }
.burst :nth-child(3) { animation: particle-fade 600ms 2.95s both, particle-three-move 600ms 2.95s both; }
.burst :nth-child(4) { animation: particle-fade 600ms 2.95s both, particle-four-move 600ms 2.95s both; }
.burst :nth-child(5) { animation: particle-fade 600ms 2.95s both, particle-five-move 600ms 2.95s both; }
.burst :nth-child(6) { animation: particle-fade 600ms 2.95s both, particle-six-move 600ms 2.95s both; }

@keyframes particle-one-move { 0% { transform: rotate(0deg) translate(-5%) scale(0.0001, 0.0001) } 100% { transform: rotate(-20deg) translateX(8%) scale(0.5, 0.5) } }
@keyframes particle-two-move { 0% { transform: rotate(0deg) translate(-5%) scale(0.0001, 0.0001) } 100% { transform: rotate(0deg) translateX(8%) scale(0.5, 0.5) } }
@keyframes particle-three-move { 0% { transform: rotate(0deg) translate(-5%) scale(0.0001, 0.0001) } 100% { transform: rotate(20deg) translateX(8%) scale(0.5, 0.5) } }
@keyframes particle-four-move { 0% { transform: rotate(0deg) translate(-5%) scale(0.0001, 0.0001) } 100% { transform: rotate(-35deg) translateX(12%) } }
@keyframes particle-five-move { 0% { transform: rotate(0deg) translate(-5%) scale(0.0001, 0.0001) } 100% { transform: rotate(0deg) translateX(12%) } }
@keyframes particle-six-move { 0% { transform: rotate(0deg) translate(-5%) scale(0.0001, 0.0001) } 100% { transform: rotate(35deg) translateX(12%) } }



.bar {
	width: 100%;
	height: 100%;
	ry: 50%;
	stroke-width: 10;
	animation: bar-in 900ms 3s both;
}

@keyframes bar-in {
	0% { stroke-dasharray: 0 180 0 226 0 405 0 0 }
	100% { stroke-dasharray: 0 0 181 0 227 0 405 0 }
}

.magnifier {
	animation: magnifier-in 600ms 3.6s both;
	transform-box: fill-box;
}

@keyframes magnifier-in {
	0% { transform: translate(20px, 8px) rotate(-45deg) scale(0.01, 0.01); }
	50% { transform: translate(-4px, 8px) rotate(-45deg); }
	100% { transform: translate(0px, 0px) rotate(0deg); }
}

.magnifier .glass {
	cx: 27;
	cy: 27;
	r: 8;
	stroke-width: 3;
}
.magnifier .handle {
	x1: 32;
	y1: 32;
	x2: 44;
	y2: 44;
	stroke-width: 3;
}



#results {
	grid-area: results;
	background: hsl(0, 0%, 95%);
}
.housefinContainer {
    background-color: #f4f4f4;
    height: 550px;
    width: 80% !important;
    margin: 0 auto;
    border-radius: 15px;
    box-shadow: 0 0 15px #999;
}

h2 {
    font-weight: 200;
}

.header {
    background-image: linear-gradient(#c0e13e, #0d111e);
    color: #fff;
    padding: 20px;
    margin: 0;
    border-radius: 15px 15px 0 0;
    margin-bottom: 20px;
}

.currency {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100px;
    background-color: #fefefe;
    border-radius: 15px;
    margin: 10px 20px;
    border: 1px solid #f0f0f0;
}

.currency input {
    border: none;
    font-size: 22px;
    text-align: left;
    margin-left: 20px;
    outline: none;
    width: 150px;
    border-bottom: 1px solid #f0f0f0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}

.currency select {
    border: 0;
    padding-right: 10px;
    font-size: 22px;
    margin-right: 20px;
}

.middle {
    display: flex;
    align-items: center;
    justify-content: center;
}

.middle button {
    background-color: #57aff2;
    border: 2px solid #ecf0f1;
    border-radius: 50%;
    cursor: pointer;
    color: #fff;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 50px;
    transform: rotate(90deg);
    z-index: 1;
    position: absolute;
    outline: none;
}

.middle button:active {
    transform: rotate(90deg) scale(0.9);
}

.rate {
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    padding: 0 10px;
    margin: 10px 15px 30px 25px;
    color: #a8a8a8;
    text-transform: uppercase;
}

@media screen and (min-width: 640px) {

    .housefinContainer {
        background-color: #f4f4f4;
        width: 80% !important;
        margin: 0 auto;
    }

}


/* ATOM STRUCTURE */
/* @import url("https://cdnjs.cloudflare.com/ajax/libs/bulma/0.7.5/css/bulma.min.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0/css/all.min.css");
@import url("https://fonts.googleapis.com/css?family=Quicksand:400,700&display=swap"); */

.has-content-centered {
  justify-content: center;
}

.has-content-right {
  justify-content: flex-end;
}

.is-flex, .modal .modal-card-foot button, .circle, .layer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.is-round, .modal .modal-card-foot button, .ellipses .ellipsis {
  border-radius: 50%;
}

.is-strong {
  font-weight: 700;
}

.tittle, .subtitle {
  text-transform: uppercase;
  color: #ffffff;
}
.tittle.is-strong, .subtitle.is-strong {
  font-weight: 700;
  color: #94AD42;
}

.circle-graphic {
  pointer-events: none;
}
.circle-graphic.pause .module,
.circle-graphic.pause .node {
  animation-play-state: paused;
}

.layer {
  width: 90%;
  height: 100%;
  position: absolute;
}

.module {
  position: absolute;
  display: flex;
  justify-content: flex-end;
  width: 38em;
  left: calc(50% - 6em);
  top: calc(50% - 6em);
  transform-origin: 6em 6em;
  animation: spin 48s linear infinite;
  transition: transform 0.3s ease-out;
}
.module:before {
  content: "";
  display: block;
  width: calc(100% - 6em);
  position: absolute;
  border-bottom: dotted 8px #05BBC1;
  top: 50%;
  left: 6em;
  margin-top: -4px;
}
.module.pause .leaf {
  opacity: 1;
}
.module .circle {
  animation: spin 48s linear infinite reverse;
  pointer-events: all;
  cursor: pointer;
  transition: background 0.2s ease-out;
}
.module .circle.pause {
  animation-play-state: paused;
}
.module .circle:hover {
  background: #ffffff;
  border-color: #ffffff;
  box-shadow: 0px 10px 15px #062133;
}
.module .circle:hover .tittle,
.module .circle:hover .subtitle {
  color: #94AD42;
}
.module:nth-child(1) {
  animation-delay: -8s;
}
.module:nth-child(1) .circle {
  animation-delay: -8s;
}
.module:nth-child(2) {
  animation-delay: -16s;
}
.module:nth-child(2) .circle {
  animation-delay: -16s;
}
.module:nth-child(3) {
  animation-delay: -24s;
}
.module:nth-child(3) .circle {
  animation-delay: -24s;
}
.module:nth-child(4) {
  animation-delay: -32s;
}
.module:nth-child(4) .circle {
  animation-delay: -32s;
}
.module:nth-child(5) {
  animation-delay: -40s;
}
.module:nth-child(5) .circle {
  animation-delay: -40s;
}
.module:nth-child(6) {
  animation-delay: -48s;
}
.module:nth-child(6) .circle {
  animation-delay: -48s;
}

.circle {
  width: 13em;
  height: 13em;
  border-radius: 50%;
  position: relative;
  border: solid 3px #94AD42;
  background: #051b2a;
}
.circle.is-main {
  background-color: rgba(5, 30, 46, 0.95);
  border-width: 15px;
  width: 16.5em;
  height: 16.5em;
  overflow: hidden;
  pointer-events: all;
  cursor: pointer;
  transition: background 0.2s ease-out, border 0.2s ease-out;
}
.circle.is-main:before {
  content: "";
  background: url("http://innou.io/beta/assets/images/maze-white.png") center;
  background-size: 50%;
  position: absolute;
  animation: spin 48s linear reverse infinite;
  width: 100%;
  height: 100vh;
  opacity: 0.25;
  z-index: 1;
}
.circle.is-main .tittle,
.circle.is-main .subtitle {
  position: relative;
  z-index: 2;
}
.circle.is-main .subtitle {
  margin-top: -1em;
}
.circle.is-main:hover {
  background-color: #ffffff;
  border-color: #ffffff;
}
.circle.is-main:hover .tittle,
.circle.is-main:hover .subtitle {
  color: #94AD42;
}
.circle.is-second {
  background: none;
  border: dotted 1px #05BBC1;
  width: 50em;
  height: 50em;
}
.circle.is-third {
  background: none;
  border: dashed 5px #666666;
  width: 58em;
  height: 56em;
  transition: border-color 0.2s ease-out;
  animation: spin 192s linear infinite reverse;
}
.pause .circle.is-third {
  border-color: #880621;
}

.leaf {
  position: absolute;
  width: 3em;
  height: 3em;
  background: url("https://making-the-web.com/sites/default/files/clipart/156536/green-leaf-icon-156536-2289922.png") no-repeat center;
  background-size: contain;
  transform: rotate(40deg);
  top: 4em;
  right: -4.5em;
  opacity: 0.3;
}

.ellipses {
  transform: rotate(30deg);
}
.ellipses .ellipsis {
  background: url("https://walls4u.co.uk/app/uploads/2018/10/AdobeStock_184585340.jpg");
  position: absolute;
  width: 60em;
  height: 37em;
  border: solid 2px #94AD42;
  opacity: 0.25;
}
.ellipses .ellipsis:nth-child(1) {
  animation: spin linear infinite;
  animation-duration: 47s;
  animation-delay: -8s;
}
.ellipses .ellipsis:nth-child(2) {
  animation: spin linear infinite;
  animation-duration: 46s;
  animation-delay: -16s;
}
.ellipses .ellipsis:nth-child(3) {
  animation: spin linear infinite;
  animation-duration: 45s;
  animation-delay: -24s;
}
.ellipses .circle.is-cover {
  position: absolute;
  background: rgba(6, 33, 51, 0.9);
  border: none;
  width: 42em;
  height: 42em;
}

.modal .modal-card-head {
  background: #94AD42;
  border: none;
  text-transform: uppercase;
}
.modal .modal-card-title {
  color: #ffffff;
}
.modal .modal-card-body,
.modal .modal-card-foot {
  background: #09304a;
  border: none;
  color: #ffffff;
}
.modal .modal-card-body p {
  min-height: 360px;
  max-height: 360px;
  padding-right: 1em;
  padding-bottom: 1em;
  margin: 1em;
  margin-right: 0;
  margin-bottom: 0;
  overflow: auto;
}
.modal .modal-card-body p.is-overflowing {
  border-bottom: solid 4px #880621;
}
.modal .modal-card-foot button {
  background: #94AD42;
  color: #ffffff;
  border: none;
  width: 2.5em;
  height: 2.5em;
}
.modal.is-active .modal-card {
  animation: scaleUpBounce 0.2s ease-in-out;
}

@keyframes spin {
  to {
    transform: rotate(1turn);
  }
}
@keyframes scaleUpBounce {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

/* WALLET INFOR */
.bank {
  width: 90% !important;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  font-family: 'Ubuntu', sans-serif;
}

.mobile {
  width: 100%;
  height: 95%;
  background-color: white;
  border-radius: 35px;
  padding: 0 20px;
  box-shadow: -3px 2px 19px #dadada;
  padding-bottom: 10px;
}

.profile {
  display: flex;
  align-items: center;
  height: 60px;
  margin: 20px 0;
}

.profile__avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  background-color: #96edc9;
  border-radius: 40%;
  margin: 0;
}

.profile__avatar img {
  width: 50%;
}

.profile__name {
  font-weight: 600;
  margin: 0 20px;
  font-size: 15px;
}

.balance {
  margin: 20px 0;
}

.balance__text {
  font-size: 15px;
  color: #979797;
  margin-bottom: 10px;
}

.balance__amount {
  font-weight: 600;
  font-size: 36px;
}

.walletcard {
  display: flex;
  flex-direction: column;
  background: rgb(48, 188, 238);
  background: linear-gradient(90deg, rgba(48, 188, 238, 1) 0%, rgba(21, 208, 149, 1) 100%);
  margin: 20px 0;
  border-radius: 20px;
  padding: 25px 30px;
}

.card__information {
  margin-bottom: 20px;
  align-items: center;
  justify-content: space-between;
}

.card__information__text {
  font-size: 20px;
  color: #ffffff;
  line-height: 26px;
  font-weight: 600;
  margin-right: 25px;
}

.walletcard__button {
  width: 100%;
  background-color: #ffffff;
  color: #000000;
  border: none;
  border-radius: 10px;
  line-height: 45px;
  font-weight: 600;
  font-size: 16px;
}

.nav {
  display: flex;
}

.wallettabs {
  min-height: 35%;
}

.nav__tab {
  font-size: 16px;
  font-weight: 600;
  color: #979797;
  width: 150px;
  cursor: pointer;
}

.nav__tab--active {
  color: #000000;
}

.indicator {
  display: inline-block;
  background-color: #38c1ff;
  height: 4px;
  border-radius: 20px;
  vertical-align: 3px;
  transform: translate(calc(var(--i)* 150px));
  transition-duration: 0.3s;
}

.walletcontent {
  margin-top: 15px;
  background-color: #29ADB2;
  overflow-y: scroll;
  opacity: 0;
  height: 0;
}

.walletcontent:first-child {
  opacity: 1;
  height: 180px;
  font-size: 14px;
}

.today {
  display: flex;
  justify-content: space-between;
  color: #45474B;
}

.transaction {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
}

.transaction__who {
  display: flex;
  align-items: center;
}

.transaction__who figure {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 40%;
  margin-right: 20px;
}

.transaction__who img {
  width: 40%;
}

.transaction__who__name {
  font-weight: 400;
  color: #31363F;
  margin-bottom: 4px;
}

.transaction__who__type {
  font-size: 13px;
}

.transaction__data__amount {
  font-weight: 600;
  margin-bottom: 4px;
  color: #EEF5FF;
}

.transaction__data__amount--depot {
  color: #58c87e;
}

.transaction__data__date {
  color: #31363F;
  font-size: 13px;
  text-align: right;
}

.transaction__who--apple figure,
.transaction__who--amazon figure {
  background-color: black;
}

.transaction__who--tom figure {
  background-color: #5cdfac;
}

.transaction__who--tom figure img,
.transaction__who--amazon figure img {
  width: 50%;
}

/* WALLET OPTION */

.about {
  position: fixed;
  z-index: 10;
  bottom: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  transition: all 0.2s ease;
}
.about .bg_links {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 100%;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  position: absolute;
}
.about .logo {
  width: 40px;
  height: 40px;
  z-index: 9;
  background-image: url(https://rafaelalucas91.github.io/assets/codepen/logo_white.svg);
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: 10px 7px;
  opacity: 0.9;
  transition: all 1s 0.2s ease;
  bottom: 0;
  right: 0;
}
.about .social {
  opacity: 0;
  right: 0;
  bottom: 0;
}
.about .social .icon {
  width: 100%;
  height: 100%;
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  display: flex;
  transition: all 0.2s ease, background-color 0.4s ease;
  opacity: 0;
  border-radius: 100%;
}
.about .social.portfolio {
  transition: all 0.8s ease;
}
.about .social.portfolio .icon {
  background-image: url(https://rafaelalucas91.github.io/assets/codepen/link.svg);
}
.about .social.dribbble {
  transition: all 0.3s ease;
}
.about .social.dribbble .icon {
  background-image: url(https://rafaelalucas91.github.io/assets/codepen/dribbble.svg);
}
.about .social.linkedin {
  transition: all 0.8s ease;
}
.about .social.linkedin .icon {
  background-image: url(https://rafaelalucas91.github.io/assets/codepen/linkedin.svg);
}
.about:hover {
  width: 105px;
  height: 105px;
  transition: all 0.6s cubic-bezier(0.64, 0.01, 0.07, 1.65);
}
.about:hover .logo {
  opacity: 1;
  transition: all 0.6s ease;
}
.about:hover .social {
  opacity: 1;
}
.about:hover .social .icon {
  opacity: 0.9;
}
.about:hover .social:hover {
  background-size: 28px;
}
.about:hover .social:hover .icon {
  background-size: 65%;
  opacity: 1;
}
.about:hover .social.portfolio {
  right: 0;
  bottom: calc(100% - 40px);
  transition: all 0.3s 0s cubic-bezier(0.64, 0.01, 0.07, 1.65);
}
.about:hover .social.portfolio .icon:hover {
  background-color: #698fb7;
}
.about:hover .social.dribbble {
  bottom: 45%;
  right: 45%;
  transition: all 0.3s 0.15s cubic-bezier(0.64, 0.01, 0.07, 1.65);
}
.about:hover .social.dribbble .icon:hover {
  background-color: #ea4c89;
}
.about:hover .social.linkedin {
  bottom: 0;
  right: calc(100% - 40px);
  transition: all 0.3s 0.25s cubic-bezier(0.64, 0.01, 0.07, 1.65);
}
.about:hover .social.linkedin .icon:hover {
  background-color: #0077b5;
}

.wrapper {
  width: 100vw;
  margin: 0 auto;
  height: 400px;
  background-color: #161616;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: all 0.3s ease;
}

@media screen and (max-width: 767px) {
  .wrapper {
    height: 700px;
  }
  .bank{
    display: block;
  }
}
.walletoptioncontent {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1024px) {
  .walletoptioncontent {
    padding-top: 300px;
    display: block;
    /* flex-direction: column; */
  }
}
.walletoptioncard {
  width: 100%;
  max-width: 300px;
  min-width: 200px;
  height: 250px;
  background-color: #F1EFEF;
  margin: 10px;
  border-radius: 10px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.24);
  border: 2px solid rgba(7, 7, 7, 0.12);
  font-size: 16px;
  transition: all 0.3s ease;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  transition: all 0.3s ease;
}

.icon {
  margin: 0 auto;
  width: 100%;
  height: 80px;
  max-width: 80px;
  background: linear-gradient(97.3deg, rgba(25, 50, 70, 0.81) 10.7%, rgba(155, 65, 25, 0.72) 39.5%, rgba(255, 192, 0, 0.81) 69.7%);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  transition: all 0.8s ease;
  background-position: 0px;
  background-size: 200px;
}

.material-icons.md-18 {
  font-size: 18px;
}

.material-icons.md-24 {
  font-size: 24px;
}

.material-icons.md-36 {
  font-size: 36px;
}

.material-icons.md-48 {
  font-size: 48px;
}

.walletoptioncard .title {
  width: 100%;
  margin: 0;
  text-align: center;
  margin-top: 30px;
  color: black;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 4px;
}

.walletoptioncard .text {
  width: 80%;
  margin: 0 auto;
  font-size: 13px;
  text-align: center;
  margin-top: 20px;
  color: black;
  font-weight: 200;
  letter-spacing: 2px;
  opacity: 0;
  max-height: 0;
  transition: all 0.3s ease;
}

.walletoptioncard:hover {
  height: 270px;
}

.walletoptioncard:hover .info {
  height: 90%;
}

.walletoptioncard:hover .text {
  transition: all 0.3s ease;
  opacity: 1;
  max-height: 40px;
}

.walletoptioncard:hover .icon {
  background-position: -120px;
  transition: all 0.3s ease;
}

.walletoptioncard:hover .icon i {
  background: linear-gradient(90deg, #FF7E7E, #FF4848);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  opacity: 1;
  transition: all 0.3s ease;
}

/* HOUSEFIN */
.housefindashboard {
  width: 100%;
  display: flex;
  padding: 25px;
  background: #0c0c0c83;
  border-radius: 20px;
}
.housefindashboard .dashsidebar {
  border-radius: 20px;
  width: 250px;
  margin-right: 30px;
  padding: 20px 0px;
  height: -webkit-max-content;
  height: max-content;
  position: -webkit-sticky;
  position: sticky;
  top: 50px;
  background-color: #fcf5ed1b;
}
.housefindashboard .dashsidebar .logo {
  font-size: 20px;
  font-weight: bolder;
  text-align: center;
  margin-bottom: 25px;
}
.housefindashboard .dashsidebar .dashmenu .ulist .liitem {
  position: relative;
  display: block;
  font-size: 17px;
  font-weight: 700;
  color: #555558;
  padding: 20px 30px;
  cursor: pointer;
  margin-bottom: 5px;
}
.housefindashboard .dashsidebar .dashmenu .ulist .liitem:hover {
  background:#31363F !important;
  color: #eeba45;
}
.housefindashboard .dashsidebar .dashmenu .ulist .liitem:hover i {
  color: #eeba45;
}
.housefindashboard .dashsidebar .dashmenu .ulist .liitem:hover:after {
  content: "";
  height: 100%;
  width: 4px;
  background: #ecb432;
  margin-left: auto;
  display: inline-block;
  position: absolute;
  right: 0;
  top: 0;
  transition: 300ms all;
}
.housefindashboard .dashsidebar .dashmenu .ulist .liitem i {
  font-size: 20px;
  margin-right: 15px;
}
.housefindashboard .content {
  flex: 1 1;
}
.housefindashboard .content .searchbar {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.housefindashboard .content .searchbar i {
  padding-left: 22px;
  position: absolute;
  color: white;
}
.housefindashboard .content .searchbar i.fa-bell {
  right: 80px;
  font-size: 25px;
}
.housefindashboard .content .searchbar img {
  right: 15px;
  border-radius: 50%;
  position: absolute;
}
.housefindashboard .content .searchbar input {
  border-radius: 15px;
  color: white;
  font-weight: 500;
  background-color: #fcf5ed1b;
  font-size: 18px;
  width: 100%;
  padding: 20px 55px;
}
.housefindashboard .content .items {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.housefindashboard .content .items .itemgoogle {
  margin-top: 25px;
  background: #323232df;
  width: 30%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 15px;
  padding: 20px;
}
.housefindashboard .content .items .itemgoogle .itemheader {
  align-items: center;
  display: flex;
}
.housefindashboard .content .items .itemgoogle .itemheader .logo i.fa-google {
  width: 50px;
  height: 50px;
  font-size: 26px;
  color: #DB4437;
  display: grid;
  place-items: center;
  background: #eed5c9;
  border-radius: 15px;
  margin-right: 15px;
}
.housefindashboard .content .items .itemgoogle .itemheader .brand h3 {
  color: #232a3d;
}
.housefindashboard .content .items .itemgoogle .itemheader .brand h5 {
  color: #999cab;
}
.housefindashboard .content .items .itemgoogle .itemheader i.fa-ellipsis-v {
  color: #767b8e;
  margin-left: auto;
}
.housefindashboard .content .items .itemgoogle .itemcontent .importants {
  margin-top: 30px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}
.housefindashboard .content .items .itemgoogle .itemcontent .importants a {
  background: #fff7e6;
  color: #ff7b74;
  padding: 10px 15px;
  font-size: 13px;
  border: 1px solid #ffaea9;
  border-radius: 5px;
}
.housefindashboard .content .items .itemgoogle .itemcontent .importants a.ilk {
  background: #fff7e6;
  border-radius: none;
  border: none;
  color: #ffb01b;
  padding: 10px;
  font-size: 13px;
  font-weight: bolder;
}
.housefindashboard .content .items .itemgoogle .itemcontent .taskdone {
  color: #a8abb7;
}
.housefindashboard .content .items .itemgoogle .itemcontent .taskdone span {
  color: #444;
}
.housefindashboard .content .items .itemgoogle .itemcontent .taskdone .progresbar {
  width: 100%;
  height: 6px;
  margin-top: 15px;
  border-radius: 10px;
  overflow: hidden;
  background: #e9ebf3;
}
.housefindashboard .content .items .itemgoogle .itemcontent .taskdone .progresbar .inner {
  width: 70%;
  height: 100%;
  border-radius: 10px;
  background: #545dad;
}
.housefindashboard .content .items .itemgoogle .itemcontent .importans2 {
  margin-bottom: 20px;
  margin-top: 15px;
  display: flex;
}
.housefindashboard .content .items .itemgoogle .itemcontent .importans2 a.ilk {
  background: #f0f8ef;
  color: #79bd72;
  padding: 10px 15px;
  font-size: 13px;
  color: #77bc70;
  font-weight: bolder;
  border-radius: 5px;
  margin-right: 10px;
}
.housefindashboard .content .items .itemgoogle .itemcontent .importans2 a {
  background: #e6f2ff;
  padding: 10px 15px;
  font-size: 13px;
  font-weight: bolder;
  border-radius: 5px;
  margin-right: 10px;
}
.housefindashboard .content .items .itemgoogle .itemcontent .users {
  padding: 0 0 0 10px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}
.housefindashboard .content .items .itemgoogle .itemcontent .users img {
  border-radius: 50%;
  border: 3px solid #fff;
  margin-left: -10px;
}
.housefindashboard .content .items .itemgoogle .itemcontent .users div.more {
  display: grid;
  place-items: center;
  border: 3px solid #fff;
  background: #fff;
  font-weight: bolder;
  color: #8f93a2;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  margin-left: -10px;
}
.housefindashboard .content .items .itemgoogle .itemcontent .importants3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.housefindashboard .content .items .itemgoogle .itemcontent .importants3 a {
  background: #fff1eb;
  color: #fd9965;
  padding: 10px 15px;
  font-size: 13px;
  font-weight: bolder;
  border-radius: 5px;
  margin-right: 10px;
}
.housefindashboard .content .items .itemgoogle .itemcontent .importants3 i {
  color: #ffa700;
  padding: 10px;
  border: 1px solid #e2e2e3;
  border-radius: 50%;
}
.housefindashboard .content .items .itemtask {
  margin-top: 25px;
  background: #ffc55a;
  border-radius: 15px;
  padding: 20px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 30%;
}
.housefindashboard .content .items .itemtask .itemhead span {
  color: #8c8d97;
}
.housefindashboard .content .items .itemtask .tasks {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}
.housefindashboard .content .items .itemtask .tasks .taskitem {
  font-weight: 600;
  display: flex;
  padding: 12px 0;
  color: #444;
  cursor: pointer;
  font-size: 15px;
  border-bottom: 1px solid #a1a1a154;
  justify-content: space-between;
}
.housefindashboard .content .items .itemcalendar {
  margin-top: 25px;
  width: 30%;
  border-radius: 15px;
  padding: 20px;
  background: #FFC55A;
}
.housefindashboard .content .items .itemcalendar .itemhead {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.housefindashboard .content .items .itemcalendar .itemhead h3 {
  font-size: 23px;
}
.housefindashboard .content .items .itemcalendar .itemhead .arrows i {
  cursor: pointer;
  color: #FC4100;
  font-size: 23px;
}
.housefindashboard .content .items .itemcalendar .itemhead .arrows i:first-child {
  margin-right: 5px;
}
.housefindashboard .content .items .itemcalendar .takvim {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
}
.housefindashboard .content .items .itemcalendar .takvim .item {
  color: #3d4353;
  font-weight: bold;
  width: 14.23%;
}
.housefindashboard .content .items .itemcalendar .takvim .days {
  cursor: pointer;
  margin: 15px 0 0;
  width: 14.23%;
  text-align: center;
  padding: 10px;
  font-weight: 500;
}
.housefindashboard .content .items .itemcalendar .takvim .days.old {
  color: #d4dade;
}
.housefindashboard .content .items .itemcalendar .takvim .days.select {
  background: #ecb22e;
  border-radius: 50%;
  color: #fff;
}
.housefindashboard .content .items .itemgoogle.slack .itemheader .logo i {
  width: 50px;
  height: 50px;
  font-size: 26px;
  color: #444;
  display: grid;
  place-items: center;
  background: #fff;
  border-radius: 15px;
  margin-right: 15px;
}
.housefindashboard .content .items .itemgoogle.slack .itemcontent .importants a.ilk {
  background: #e0ecdb;
  color: #70b868;
}
.housefindashboard .content .items .itemgoogle.slack .itemcontent .importants a {
  background: #fff;
  border: 1px solid #70b868;
  color: #70b868;
}
.housefindashboard .content .items .itemgoogle.slack .itemcontent .taskdone .progresbar .inner {
  background: #e257b3;
}
.housefindashboard .content .items .itemvideos {
  margin-top: 25px;
  background: #393E46;
  width: 30%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 15px;
  padding: 20px;
}
.housefindashboard .content .items .itemvideos .itemheads .dashtitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.housefindashboard .content .items .itemvideos .itemheads .dashtitle i {
  font-size: 25px;
}

::placeholder {
  color: #787778;
}

::-webkit-scrollbar {
  width: 10px;
  background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
  background-color: #ff7c00;
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.2) 75%, transparent 75%, transparent);
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #F5F5F5;
}

@media (max-width: 768px) {
  .items {
    flex-direction: column;
  }

  .itemgoogle, .itemtask, .itemcalendar, .itemvideos {
    width: 100% !important;
  }
}


/* Get Megajoules */
div.container {
  position: relative;
  margin: 70px auto;
  width: 350px;
  text-align: center;
  background: #fff;
  box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.1), 0px 60px 75px -25px rgba(0, 0, 0, 0.2);
  cursor: default;
}
div.container img.img-fruit {
  margin-top: -60px;
}
div.container div.control-btn span {
  position: absolute;
  margin-top: 20px;
  width: 35px;
  height: 35px;
  background: orange;
  color: #fff;
  font-size: 13px;
  /* display: inline-block; */
  line-height: 36px;
  cursor: pointer;
  border-radius: 100%;
  box-shadow: 0px 2px 7px rgba(241, 44, 51, 0.7);
  transition: background 0.3s ease-in;
}
div.container div.control-btn span:hover {
  background: orange;
}
div.container span.prev {
  left: -17.5px;
}
div.container span.next {
  right: -17.5px;
}
div.container h3 {
  font-size: 30px;
  letter-spacing: 3px;
  margin-top: 35px;
  color: orange;
}
div.container p {
  font-size: 11px;
  padding: 15px 45px;
  color: #878787;
  line-height: 22px;
}
div.container div.properties {
  margin-top: 20px;
  height: 130px;
}
div.container div.properties:before, div.container div.properties:after {
  content: " ";
  display: inline-block;
}
div.container div.properties:after {
  clear: both;
}
div.container div.properties div {
  display: inline-block;
  text-align: center;
  width: 33.33%;
  float: left;
}
div.container div.properties div h4 {
  font-size: 14px;
  color: #757575;
  padding: 25px 0px;
}
div.container div.properties .qty .fa {
  font-size: 7px;
  color: #757575;
  background: #dfdfdf;
  border-radius: 100%;
  padding: 4px;
  cursor: pointer;
}
div.container div.properties .qty .number {
  font-size: 11px;
  color: #757575;
  padding: 5px;
}
div.container div.properties .price .fa {
  font-size: 18px;
  color: #757575;
}
div.container div.properties .price .amount {
  color: #646363;
  font-size: 18px;
  padding: 0 5px;
}
div.container div.properties .delivery .fa {
  font-size: 15px;
  color: #757575;
}
div.container div.properties .delivery .time {
  display: inline-block;
  color: #757575;
  font-size: 11px;
}
div.container .ip-add-cart {
  border: none;
  padding: 12px 50px;
  background: black;
  margin-bottom: 15px;
  color: #fff;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 25px;
  box-shadow: 0px 15px 45px rgba(242, 45, 52, 0.5);
  cursor: pointer;
  transition: background 0.3s ease-in;
}
div.container .ip-add-cart:hover {
  background: #da1920;
}

/* Start Credit Style */
div.credit {
  position: absolute;
  padding: 0px 10px;
  padding-bottom: 8px;
  height: 15px;
  right: 10px;
  top: 10px;
  display: inline-block;
  background: rgba(255, 255, 255, 0.7);
  font-size: 7px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.7);
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 10px 15px -15px rgba(0, 0, 0, 0.3);
  cursor: default;
}
div.credit:hover {
  border: 1px solid rgba(0, 0, 0, 0.2);
}
div.credit img {
  position: relative;
  width: 14px;
  margin-left: 3px;
  top: 4px;
}

.vid-container{
    position:relative;
    height:100vh;
    overflow:hidden;
  }
  .bgvid{
    position:absolute;
    left:0;
    top:0;
    width:100vw;
  }
  .inner-container{
    width:400px;
    height:400px;
    position:absolute;
    top:calc(50vh - 200px);
    left:calc(50vw - 200px);
    overflow:hidden;
  }
  .bgvid.inner{
    top:calc(-50vh + 200px);
    left:calc(-50vw + 200px);
    filter: url("data:image/svg+xml;utf9,<svg%20version='1.1'%20xmlns='http://www.w3.org/2000/svg'><filter%20id='blur'><feGaussianBlur%20stdDeviation='10'%20/></filter></svg>#blur");
    -ms-filter: blur(10px);
    -o-filter: blur(10px);
    filter:blur(10px);
  }
  .box{
    position:absolute;
    height:100%;
    width:100%;
    font-family:Helvetica;
    color:#fff;
    background:rgba(0,0,0,0.13);
    padding:30px 0px;
  }
  .box h1{
    text-align:center;
    margin:30px 0;
    font-size:30px;
  }
  .box input{
    display:block;
    width:300px;
    margin:20px auto;
    padding:15px;
    background:rgba(0,0,0,0.2);
    color:#fff;
    border:0;
  }

  .react-tel-input{
    margin-left: 30px !important;
  }
  .box input:focus,.box input:active,.box button:focus,.box button:active{
    outline:none;
  }
  .box button{
    background:#2ecc71;
    border:0;
    color:#fff;
    padding:10px;
    font-size:20px;
    width:330px;
    margin:20px auto;
    display:block;
    cursor:pointer;
  }
  .box button:active{
    background:#27ae60;
  }
  .box p{
    font-size:14px;
    text-align:center;
  }
  .box p span{
    cursor:pointer;
    /* color:#666; */
  }
*{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
}

body{
 background:  #222;  /* fallback for old browsers */  /*Chrome 10-25, Safari 5.1-6*/
background: linear-gradient(to right, #434343, #000000); /*W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

a {
    color: white !important;
    text-decoration: none !important;
}

a:hover{
    padding: 0.5rem 1rem;
    font-family: var(--font-family);
    color: #fff;
    font-weight: 500;
    font-size: 18px;
    border-radius: 5px;
    border: 0;
    text-decoration: none !important;
    /* outline: none; */
    cursor: pointer;
}

.gradieant__bg {
  /* background: #000000;  fallback for old browsers */
/* background: -webkit-linear-gradient(to right, #434343, #000000);  Chrome 10-25, Safari 5.1-6
background: linear-gradient(to right, #434343, #000000); W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}

.gradient__text{
    background: linear-gradient( 99.6deg,  rgb(93, 93, 94) 10.6%, rgb(131, 129, 131) 32.9%, rgb(198, 197, 197) 52.7%, rgb(210, 219, 229) 72.8%, rgba(185,205,227,1) 81.1%, rgba(154,180,212,1) 102.4% );
    /* linear-gradient( 99.6deg,  rgba(112,128,152,1) 10.6%, rgba(242,227,234,1) 32.9%, rgba(234,202,213,1) 52.7%, rgba(220,227,239,1) 72.8%, rgba(185,205,227,1) 81.1%, rgba(154,180,212,1) 102.4% ); */
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.section__padding {
    padding: 4rem 6rem;
}

.section__margin {
    margin: 4rem 6rem;
}

.scale-up-center {
	animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}
  
  @keyframes scale-up-center {
    0% {
      transform: scale(0.5);
    }
    100% {
      transform: scale(1);
    }
  }
  
  @media screen and (max-width: 700px){

        .section__padding {
             padding: 4rem;
        }

        .section__margin {
            margin: 4rem;
        }
  }

  @media screen and (max-width: 550px){

    .section__padding {
         padding: 4rem 2rem;
    }

    .section__margin {
        margin: 4rem 2rem;
    }
}


/* Input styles */
*, *:before, *:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body,
#app {
  /* line-height: 1.3; */
  min-height: 100vh;
  color: #333333;
  font-family: "Ubuntu", sans-serif;
}

a:hover {
  cursor: pointer;
}

.view-height {
  min-height: 100vh;
}

.p-all {
  padding: 20px;
}

.p-top {
  padding-top: 20px;
}

.p-left {
  padding-left: 20px;
}

.p-right {
  padding-right: 20px;
}

.p-bottom {
  padding-bottom: 20px;
}

.m-all {
  margin: 20px;
}

.m-top {
  margin-top: 20px;
}

.m-left {
  margin-left: 20px;
}

.m-right {
  margin-right: 20px;
}

.m-bottom {
  margin-bottom: 20px;
}

/* Amazing flex utility class */
[class*=flex] {
  display: flex;
}
[class*=flex][class*=-direction-row] {
  flex-direction: row;
}
[class*=flex][class*=-direction-column] {
  flex-direction: column;
}
[class*=flex][class*=-align-start] {
  align-items: flex-start;
}
[class*=flex][class*=-align-center] {
  align-items: center;
}
[class*=flex][class*=-align-end] {
  align-items: flex-end;
}
[class*=flex][class*=-justify-center] {
  justify-content: center;
}
[class*=flex][class*=-space-between] {
  justify-content: space-between;
}
[class*=flex][class*=-wrap] {
  flex-wrap: wrap;
}

.foresight-logo {
  max-width: 150px;
  transition: max-width 0.2s ease-in;
}
@media (min-width: 601px) {
  .foresight-logo {
    max-width: 200px;
  }
}
@media (min-width: 993px) {
  .foresight-logo {
    max-width: 250px;
  }
}

.foresight-search-bar {
  width: 100%;
  max-width: 500px;
  position: relative;
}
.foresight-search-bar input {
  width: 100%;
  height: 50px;
  outline: none;
  color: inherit;
  display: block;
  border-radius: 25px;
  font-family: inherit;
  font-weight: lighter;
  padding: 0 25px 0 42px;
  transition: all 0.2s ease-in;
  border: solid 1px transparent;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 9px rgba(0, 0, 0, 0.1);
}
.foresight-search-bar input:focus {
  border-color: #a9dee7;
  box-shadow: 0 0 10px -1px #a9dee7;
}
.foresight-search-bar input:focus ~ i {
  color: #f15d34;
  transform: rotate(90deg);
}
.foresight-search-bar i {
  top: 13px;
  left: 13px;
  position: absolute;
  color: #aaaaaa;
  transition: all 0.2s ease-in;
}
.foresight-search-bar ::-webkit-input-placeholder {
  color: #aaaaaa;
}
.foresight-search-bar ::-moz-placeholder {
  color: #aaaaaa;
}
.foresight-search-bar :-ms-input-placeholder {
  color: #aaaaaa;
}
.foresight-search-bar :-moz-placeholder {
  color: #aaaaaa;
}

.btn {
  width: 100%;
  margin: 5px;
  color: white;
  height: 50px;
  outline: none;
  /* padding: 0 25px; */
  /* border: solid 2px; */
  border-radius: 25px;
  font-family: inherit;
  transition: all 0.2s ease-in;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 9px rgba(0, 0, 0, 0.1);
}
@media (min-width: 360px) {
  .btn {
    width: auto;
    margin: none;
  }
}
.btn.primary {
  border-color: #f15d34;
  background-color: #f15d34;
}
.btn.primary:hover {
  color: #f15d34;
  border-color: #f15d34;
}
.btn.accent {
  border-color: #a9dee7;
  background-color: #a9dee7;
}
.btn.accent:hover {
  color: #a9dee7;
  border-color: #a9dee7;
}
.btn.grey {
  border-color: #aaaaaa;
  background-color: #aaaaaa;
}
.btn.grey:hover {
  color: #aaaaaa;
  border-color: #aaaaaa;
}
.btn.dark-grey {
  border-color: #333333;
  background-color: #333333;
}
.btn.dark-grey:hover {
  color: #333333;
  border-color: #333333;
}
.btn:hover {
  cursor: pointer;
  background-color: transparent;
}
.btn:active {
  box-shadow: none;
}

.fixed-header {
  top: 0;
  left: 0;
  right: 0;
  padding: 20px;
  position: fixed;
}
.fixed-header .profile-photo {
  height: 40px;
  margin-left: 10px;
  border-radius: 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 9px rgba(0, 0, 0, 0.1);
}

.text-center {
  text-align: center;
}

.text-grey {
  color: #aaaaaa;
}

.font-lighter {
  font-weight: lighter;
}


/* DASHBOARD */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  --main-bg-color: black;
  --secondary-bg-color: #CE5959;
}

.contentD {
  font-family: "Poppins", sans-serif;
  min-height: 100vh;
  background: var(--main-bg-color);
  background: linear-gradient(
    to right top,
    var(--main-bg-color),
    var(--secondary-bg-color)
  );
  display: flex;
  align-items: center;
  justify-content: center;
}

.containerdashb {
  background-color: rgba(255, 255, 255, 0.9); /* slightly transparent fallback for Firefox */
  min-height: 80vh;
  width: 100%;
  border-radius: 2rem;
  z-index: 2;
  display: flex;
}

/* if backdrop support: very transparent and blurred */
@supports (((-webkit-backdrop-filter: blur(2rem)) or (backdrop-filter: blur(2rem))) or (-webkit-backdrop-filter: blur(2rem))) {
  .containerdashb {
    background: linear-gradient(
    to right bottom,
    rgba(255, 255, 255, 0.7),
    rgba(255, 255, 255, 0.3)
  );
    -webkit-backdrop-filter: blur(2rem);
    backdrop-filter: blur(2rem);
  }
}

.dashboard {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  background: linear-gradient(
    to right bottom,
    rgba(255, 255, 255, 0.7),
    rgba(255, 255, 255, 0.3)
  );
  border-radius: 2rem;
}
.link {
  display: flex;
  padding: 1rem;
  margin: 1rem 0rem;
  align-items: center;
}
.link img {
  transform: scale(2);
}
.user,
.link h2,
.pro,
.progress {
  display: none;
}
.link h2:hover{
  background-color: white;
  color: orange;
}
.games {
  flex: 3 1;
  margin: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.status {
  margin-bottom: 1rem;
}

.status input {
  background: linear-gradient(
    to right bottom,
    rgba(255, 255, 255, 0.7),
    rgba(255, 255, 255, 0.3)
  );
  border: none;
  width: 50%;
  padding: 0.5rem;
  border-radius: 2rem;
}

.card_dashboard {
  display: flex;
  flex-direction: column;
  background: linear-gradient(
    to left top,
    rgba(255, 255, 255, 0.8),
    rgba(255, 255, 255, 0.5)
  );
  border-radius: 1rem;
  margin: 2rem 0rem;
  padding: 2rem;
  box-shadow: 6px 6px 20px rgba(122, 122, 122, 0.212);
  justify-content: space-between;
}
.card_dashboard img {
  width: 105px;
  height: 105px;
  border-radius: 1rem;
}
.card-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.percentage {
  font-weight: bold;
  background: linear-gradient(to right top,
    var(--main-bg-color),
    var(--secondary-bg-color));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

h1 {
  color: grey;
  font-weight: 600;
  font-size: 2rem;
  opacity: 0.8;
}
h2,
p {
  /* color: grey; */
  font-weight: 500;
  opacity: 0.8;
}

h3 {
  color: #426696;
  padding-top: 15px;
  font-weight: 600;
  opacity: 0.8;
}

@media screen and (min-width: 640px) {
   .link img {
  transform: scale(1)
}
   .link {
    margin: 2rem 0rem;
    padding: 1rem 5rem;
  }
}

@media screen and (min-width: 1024px){
  .containerdashb {
    width: 98%;
  }
 
  .circle1,
  .circle2 {
    background: white;
    background: linear-gradient(
      to right bottom,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.3)
    );
    height: 20rem;
    width: 20rem;
    position: absolute;
    border-radius: 50%;
  }
  .circle1 {
    top: 5%;
    right: 15%;
  }
  .circle2 {
    bottom: 5%;
    left: 25%;
  }
  .dashboard {
    justify-content: space-evenly;
  }
  .user {
    display: block;
  }
  .user img {
    border-radius: 50%;
  }
 
  .link h2 {
    display: block;
    padding: 0 2rem;
  }
  .pro {
    display: block;
    background: linear-gradient(to right top,
    var(--main-bg-color),
    var(--secondary-bg-color));
    border-radius: 2rem;
    color: white;
    padding: 1rem;
    position: relative;
  }
  .pro img {
    position: absolute;
    top: -10%;
    right: 10%;
  }
  .pro h2 {
    width: 40%;
    color: white;
    font-weight: 600;
  }
  .games {
    margin: 5rem;
  }
  h1 {
    font-size: 3rem;
  }
  .status {
    margin-bottom: 3rem;
  }
  .card_dashboard {
    flex-direction: row;
  }
  .card_dashboard img {
    margin-right: 0.5rem;
  }
  .progress {
    display: block;
    background: linear-gradient(to right top,
    var(--main-bg-color),
    var(--secondary-bg-color));
    width: 100%;
    height: 25%;
    border-radius: 1rem;
    position: relative;
    overflow: hidden;
  }
  .progress::after {
    content: "";
    width: 100%;
    height: 100%;
    background: rgb(236, 236, 236);
    position: absolute;
    left: 60%;
  }
}

/* END DASHBOARD */

/* ANIMATIONS */
.bounce-in-top {
	animation: bounce-in-top 1.1s both;
}
/* ----------------------------------------------
 * Generated by Animista on 2022-11-3 16:49:59
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation bounce-in-top
 * ----------------------------------------
 */
@keyframes bounce-in-top {
  0% {
    transform: translateY(-500px);
    animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    transform: translateY(0);
    animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    transform: translateY(-65px);
    animation-timing-function: ease-in;
  }
  72% {
    transform: translateY(0);
    animation-timing-function: ease-out;
  }
  81% {
    transform: translateY(-28px);
    animation-timing-function: ease-in;
  }
  90% {
    transform: translateY(0);
    animation-timing-function: ease-out;
  }
  95% {
    transform: translateY(-8px);
    animation-timing-function: ease-in;
  }
  100% {
    transform: translateY(0);
    animation-timing-function: ease-out;
  }
}

.jello-horizontal {
	animation: jello-horizontal 0.9s infinite both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-11-7 10:26:24
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation jello-horizontal
 * ----------------------------------------
 */
@keyframes jello-horizontal {
  0% {
    transform: scale3d(1, 1, 1);
  }
  30% {
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}

.tracking-in-expand {
	animation: tracking-in-expand 1s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
}
/* ----------------------------------------------
 * Generated by Animista on 2022-11-7 14:32:36
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation tracking-in-expand
 * ----------------------------------------
 */
@keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}


.dashboardlinks:hover{
   color: white;
   background-color: grey;
}
/* PRODUCTS */


.containr{

  /* safari 5.1+,chrome 10+ */

  /* opera 11.10+ */

  /* ie 10+ */

  /* global 92%+ browsers support */
  background:radial-gradient(circle at 50% 50%, rgba(21, 0, 80, 1) 1%, rgba(0, 0, 0, 1) 100%);
  margin: 2rem;
}
.shell{
  padding:80px 0;
}
.wsk-cp-product{
  background: #EFFFFD;
  padding:15px;
  border-radius:6px;
  box-shadow: rgba(6, 173, 215, 0.544) 0px 30px 90px;
  position:relative;
  margin:20px auto;
}
.wsk-cp-img{
  position:absolute;
  top:5px;
  left:50%;
  transform:translate(-50%);
  -webkit-transform:translate(-50%);
  -ms-transform:translate(-50%);
  -moz-transform:translate(-50%);
  -o-transform:translate(-50%);
  -khtml-transform:translate(-50%);
  width: 100%;
  padding: 15px;
  transition: all 0.2s ease-in-out;
}
.wsk-cp-img img{
  width:100%;
  transition: all 0.2s ease-in-out;
  border-radius:6px;
}
.wsk-cp-product:hover .wsk-cp-img{
  top:-40px;
}
.wsk-cp-product:hover .wsk-cp-img img{
  box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
}
.wsk-cp-text{
  padding-top:150%;
}
.wsk-cp-text .category{
  text-align:center;
  font-size:12px;
  font-weight:bold;
  padding:5px;
  margin-bottom:45px;
  position:relative;
  transition: all 0.2s ease-in-out;
}
.wsk-cp-text .category > *{
  position:absolute;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
  -webkit-transform: translate(-50%,-50%);
  -moz-transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);
  -o-transform: translate(-50%,-50%);
  -khtml-transform: translate(-50%,-50%);
    
}
.wsk-cp-text .category > span{
  padding: 12px 30px;
  border: 1px solid #313131;
  background:#212121;
  color:#fff;
  box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
  border-radius:27px;
  transition: all 0.05s ease-in-out;
  
}
.wsk-cp-product:hover .wsk-cp-text .category > span{
  border-color:#ddd;
  box-shadow: none;
  padding: 11px 28px;
}
.wsk-cp-product:hover .wsk-cp-text .category{
  margin-top: 0px;
}
.wsk-cp-text .title-product{
  text-align:center;
}
.wsk-cp-text .title-product h3{
  font-size:20px;
  font-weight:bold;
  margin:15px auto;
  overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  width:100%;
}
.wsk-cp-text .description-prod p{
  margin:0;
}
/* Truncate */
.wsk-cp-text .description-prod {
  text-align:center;
  width: 100%;
  height:62px;
  overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  margin-bottom:15px;
}
.card-footer{
  padding: 25px 0 5px;
  border-top: 1px solid #ddd;
}
.card-footer:after, .card-footer:before{
  content:'';
  display:table;
}
.card-footer:after{
  clear:both;
}

.card-footer .wcf-left{
  float:left;
  
}

.card-footer .wcf-right{
  float:right;
}

.price{
  font-size:18px;
  font-weight:bold;
  color: green;
}

a.buy-btn{
  display:block;
  color:#212121;
  text-align:center;
  font-size: 20px;
  width:35px;
  height:35px;
  line-height:35px;
  border-radius:50%;
  border:1px solid #212121;
  transition: all 0.2s ease-in-out;
}
a.buy-btn:hover , a.buy-btn:active, a.buy-btn:focus{
  /* border-color: #FF9800;
  background: #FF9800; */
  color: #fff;
  text-decoration:none;
}
.wsk-btn{
  display:inline-block;
  color:#212121;
  text-align:center;
  font-size: 18px;
  transition: all 0.2s ease-in-out;
  /* border-color: #FF9800;
  background: #FF9800; */
  padding:12px 30px;
  border-radius:27px;
  margin: 0 5px;
}
.wsk-btn:hover, .wsk-btn:focus, .wsk-btn:active{
  text-decoration:none;
  color:#fff;
}  
.red{
  color:#F44336;
  font-size:22px;
  display:inline-block;
  margin: 0 5px;
}
@media screen and (max-width: 991px) {
  .wsk-cp-product{
    margin:40px auto;
  }
  .wsk-cp-product .wsk-cp-img{
  top:-40px;
}
.wsk-cp-product .wsk-cp-img img{
  box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
}
  .wsk-cp-product .wsk-cp-text .category > span{
  border-color:#ddd;
  box-shadow: none;
  padding: 11px 28px;
}
.wsk-cp-product .wsk-cp-text .category{
  margin-top: 0px;
}
a.buy-btn{
  border-color: #FF9800;
  background: #FF9800;
  color: #fff;
}
}



.jt {
  position: relative;
  font-size: 20vmin;
  font-family: 'Staatliches', sans-serif;
  text-transform: uppercase;
  font-display: swap;
}

.jt__row {
  display: block;
}
.jt__row:nth-child(1) {
  -webkit-clip-path: polygon(0% 75%, 100% 75%, 100% 100%, 0% 100%);
          clip-path: polygon(0% 75%, 100% 75%, 100% 100%, 0% 100%);
}
.jt__row:nth-child(2) {
  -webkit-clip-path: polygon(0% 50%, 100% 50%, 100% 75.5%, 0% 75.5%);
          clip-path: polygon(0% 50%, 100% 50%, 100% 75.5%, 0% 75.5%);
}
.jt__row:nth-child(3) {
  -webkit-clip-path: polygon(0% 25%, 100% 25%, 100% 50.5%, 0% 50.5%);
          clip-path: polygon(0% 25%, 100% 25%, 100% 50.5%, 0% 50.5%);
}
.jt__row:nth-child(4) {
  -webkit-clip-path: polygon(0% 0%, 100% -10%, 100% 35.5%, 0% 25.5%);
          clip-path: polygon(0% 0%, 100% -10%, 100% 35.5%, 0% 25.5%);
}
.jt__row:nth-child(5) {
  -webkit-clip-path: polygon(0% -25%, 100% -45%, 100% -9.5%, 0% 0.5%);
          clip-path: polygon(0% -25%, 100% -45%, 100% -9.5%, 0% 0.5%);
}
.jt__row:nth-child(6) {
  -webkit-clip-path: polygon(0% -50%, 100% -85%, 100% -44.4%, 0% -24.5%);
          clip-path: polygon(0% -50%, 100% -85%, 100% -44.4%, 0% -24.5%);
}

.jt__row.jt__row--sibling {
  position: absolute;
  top: 0;
  left: 0;
  -webkit-user-select: none;
          user-select: none;
}

.jt__text {
  display: block;
  transform-origin: bottom left;
  font-size : 20px;
  color: #0081B4;
  margin-left: 7rem;
  animation: moveIn 2s cubic-bezier(.36,0,.06,1) alternate infinite;
}
.jt__row:nth-child(1) .jt__text {
  transform: translateY(-0.1em);
}
.jt__row:nth-child(2) .jt__text {
  transform: translateY(-0.3em) scaleY(1.1);
}
.jt__row:nth-child(3) .jt__text {
  transform: translateY(-0.5em) scaleY(1.2) rotate(-1deg);
}
.jt__row:nth-child(4) .jt__text {
  transform: translateY(-0.7em) scaleY(1.3) rotate(-2deg);
}
.jt__row:nth-child(5) .jt__text {
  transform: translateY(-0.9em) scaleY(1.4) rotate(-3deg);
}
.jt__row:nth-child(6) .jt__text {
  transform: translateY(-1.1em) scaleY(1.5) rotate(-4deg);
}

@keyframes moveIn {
  80%, 100% { transform: translateY(0em)}
}



/* Add class debug on class jt for debugging */
.debug .jt__row:nth-child(even) {
  color: black;
  background: white;
}
.debug .jt__row:nth-child(odd) {
  color: white;
  background: black;
}

/* Some hygiene */
* { box-sizing: border-box }

/* Just stuff for making it pretty: */



.bounce-in-top {
	animation: bounce-in-top 4s 1.2s both;
}
/* ----------------------------------------------
 * Generated by Animista on 2023-4-18 18:4:56
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation bounce-in-top
 * ----------------------------------------
 */
@keyframes bounce-in-top {
  0% {
    transform: translateY(-500px);
    animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    transform: translateY(0);
    animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    transform: translateY(-65px);
    animation-timing-function: ease-in;
  }
  72% {
    transform: translateY(0);
    animation-timing-function: ease-out;
  }
  81% {
    transform: translateY(-28px);
    animation-timing-function: ease-in;
  }
  90% {
    transform: translateY(0);
    animation-timing-function: ease-out;
  }
  95% {
    transform: translateY(-8px);
    animation-timing-function: ease-in;
  }
  100% {
    transform: translateY(0);
    animation-timing-function: ease-out;
  }
}

/* LOADER */
.cube-folding {
  width: 60px;
  height: 60px;
  display: inline-block;
  transform: rotate(45deg);
  font-size: 0;
}
.cube-folding span {
  position: relative;
  width: 30px;
  height: 30px;
  transform: scale(1.1);
  display: inline-block;
}
.cube-folding span::before {
  content: "";
  background-color: #FFB000;
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  width: 30px;
  height: 30px;
  transform-origin: 100% 100%;
  animation: folding 2.5s infinite linear both;
}
.cube-folding .leaf2 {
  transform: rotateZ(90deg) scale(1.1);
}
.cube-folding .leaf2::before {
  animation-delay: 0.3s;
  background-color: #FFB000;
}
.cube-folding .leaf3 {
  transform: rotateZ(270deg) scale(1.1);
}
.cube-folding .leaf3::before {
  animation-delay: 0.9s;
  background-color: #FFB000;
}
.cube-folding .leaf4 {
  transform: rotateZ(180deg) scale(1.1);
}
.cube-folding .leaf4::before {
  animation-delay: 0.6s;
  background-color: #FFB000;
}
@keyframes folding {
  0%, 10% {
    transform: perspective(140px) rotateX(-180deg);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
  25%, 75% {
    transform: perspective(140px) rotateX(0deg);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  90%, 100% {
    transform: perspective(140px) rotateY(180deg);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
}
.cube-wrapper {
  position: fixed;
  left: 50%;
  top: 50%;
  margin-top: -100px;
  margin-left: -100px;
  width: 200px;
  height: 200px;
  text-align: center;
  background-color: rgba(9, 9, 9, 0.663);
  z-index: 10000;
}
.cube-wrapper:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: -40px;
  margin: auto;
  width: 90px;
  height: 6px;
  background-color: rgba(245, 182, 7, 0.618);
  filter: blur(2px);
  border-radius: 100%;
  animation: shadow 0.5s ease infinite alternate;
}
.cube-wrapper .loading {
  font-size: 20px;
  letter-spacing: 0.1em;
  display: block;
  color: white;
  position: relative;
  top: 25px;
  z-index: 2;
  animation: text 0.5s ease infinite alternate;
}
@keyframes text {
  100% {
    top: 35px;
  }
}
@keyframes shadow {
  100% {
    bottom: -18px;
    width: 100px;
  }
}

/* END LOADER */


/* TABLE START */
@media screen and (max-width: 700px) {
  table, tr, td {
    display: block;
  }

  td:first-child {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100px;
  }
  td:not(:first-child) {
    clear: both;
    margin-left: 100px;
    padding: 4px 20px 4px 90px;
    position: relative;
    text-align: left;
  }
  td:not(:first-child):before {
    color: #91ced4;
    content: "";
    display: block;
    left: 0;
    position: absolute;
  }
  td:nth-child(2):before {
    content: "ID:";
  }
  td:nth-child(3):before {
    content: "Phone:";
  }
  td:nth-child(4):before {
    content: "Asset Name:";
  }
  td:nth-child(5):before {
    content: "Asset Number:";
  }

  td:nth-child(6):before {
    content: "Latitude:";
  }

  td:nth-child(7):before {
    content: "Longitude:";
  }

  tr {
    padding: 10px 0;
    position: relative;
  }
  tr:first-child {
    display: none;
  }
}


/* TABLE END */


/* CLEVER HILLS SCREEN */

.containerdashcard {
  max-width: 1400px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 1rem;
}

.carddashcard {
  position: relative;
  max-width: 300px;
  height: 210px;
  background: #fff;
  margin: 30px 10px;
  padding: 20px 15px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  transition: 0.3s ease-in-out;
}
.carddashcard:hover {
  height: 420px;
}
.carddashcard:hover .imgBx {
  transform: translateY(-10px);
  box-shadow: 0 15px 20px rgba(0, 0, 0, 0.4);
}
.carddashcard:hover .content {
  transition: 0.75s ease-in-out;
  visibility: visible;
  opacity: 1;
  margin-top: -40px;
  transition-delay: 0.2s;
}
.carddashcard .imgBx {
  position: relative;
  width: 260px;
  height: 260px;
  top: -60px;
  left: 20px;
  z-index: 1;
  transition: 0.5s ease-out;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
  transition-delay: 0.4s;
}
.carddashcard .imgBx img {
  max-width: 100%;
  border-radius: 4px;
}
.carddashcard .content {
  position: relative;
  margin-top: -180px;
  text-align: justify;
  color: #111;
  visibility: hidden;
  opacity: 0;
  transition: 0.25s ease-out;
}
.carddashcard .content h2 {
  text-align: center;
  padding: 0;
  margin: 0;
}
.carddashcard .content p {
  height: 140px;
  padding: 0 0.5em;
  overflow-y: auto;
}
.carddashcard .content p* {
  /* Emerging W3C standard - currently Firefox-only */
  scrollbar-width: thin;
  scrollbar-color: #e1e1e1 rgba(225, 225, 225, 0.1);
}
.carddashcard .content p::-webkit-scrollbar {
  width: 12px;
}
.carddashcard .content p::-webkit-scrollbar-track {
  background: #c8c8c8;
}
.carddashcard .content p::-webkit-scrollbar-thumb {
  background: #fbfbfb;
  border-radius: 6px;
  border: 3px solid #c8c8c8;
}



/* GRID GALLERY */
/*

All grid code is placed in a 'supports' rule (feature query) at the bottom of the CSS (Line 77). 
        
The 'supports' rule will only run if your browser supports CSS grid.

Flexbox is used as a fallback so that browsers which don't support grid will still recieve an identical layout.

*/

:root {
	/* Base font size */
	font-size: 10px;
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

.containergallery {
	max-width: 100rem;
	margin: 0 auto;
	padding: 0 2rem 2rem;
}

.heading {
	font-family: "Montserrat", Arial, sans-serif;
	font-size: 4rem;
	font-weight: 500;
	line-height: 1.5;
	text-align: center;
	padding: 3.5rem 0;
	color: #1a1a1a;
}

.heading span {
	/* display: block; */
}

.gallery {
	display: flex;
	flex-wrap: wrap;
	/* Compensate for excess margin on outer gallery flex items */
	margin: -1rem -1rem;
}

.gallery-item {
	/* Minimum width of 24rem and grow to fit available space */
	flex: 1 0 24rem;
	/* Margin value should be half of grid-gap value as margins on flex items don't collapse */
	margin: 1rem;
	box-shadow: 0.3rem 0.4rem 0.4rem rgba(0, 0, 0, 0.4);
	overflow: hidden;
}

.gallery-image {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
	transition: transform 400ms ease-out;
}

.gallery-image:hover {
	transform: scale(1.15);
}

/*

The following rule will only run if your browser supports CSS grid.

Remove or comment-out the code block below to see how the browser will fall-back to flexbox styling. 

*/

@supports (display: grid) {
	.gallery {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(24rem, 1fr));
		grid-gap: 2rem;
	}

	.gallery,
	.gallery-item {
		margin: 0;
	}
}


/* PLANS APPROVAL FORM */
.aside {
	background: #17181c;
	box-shadow: 0 0 0.25em hsla(223,10%,10%,0.5);
	border-radius: 0.375em 0.375em 0 0;
	position: fixed;
	bottom: 2;
	left: 1.25em;
	max-width: 30.5em;
	width: calc(100% - 0.5em);
	transform: translateY(100%);
	transition: transform 0.3s ease-in-out;
}
button, input {
	color: #e3e4e8;
	font: 1em/1.5 "Hind", -apple-system, sans-serif;
}
 .upload-btn {
	overflow: hidden;
}
button:hover, button:focus, .upload-btn input[type=file]:hover + button, .upload-btn input[type=file]:focus + button {
	background: #C08B5C;
}
button, .upload-btn input[type=file], .upload-btn input[type=file]::-webkit-file-upload-button {
	cursor: pointer;
}
button, input {
	display: block;
	width: 100%;
	-webkit-appearance: none;
	appearance: none;
}
button {
	background:#2762f3;
	border-radius: 0.375em;
	color: #fff;
	margin-bottom: 1.5em;
	padding: 0.75em 1em;
	transition: background 0.1s linear;
}
button:focus {
	outline: 0;
}
form {
	padding: 1.5em 1.5em 0 1.5em;
	position: relative;
}
input {
	background: #fff;
	border-radius: 0.25em;
	box-shadow: 0 0 0 1px var(--inputBorder) inset;
	padding: 0.75em;
}
input:disabled {
	background: #e3e4e8;
	cursor: not-allowed;
	text-overflow: ellipsis;
}
label {
	display: inline-block;
	font-weight: bold;
}
.aside-toggle, .aside-toggle:hover, .aside-toggle:focus {
	background-color: #17181c;
}
.aside-toggle {
	border-radius: 0.375em 0.375em 0 0;
	margin: 0;
	padding: 0.25em 1em;
	position: absolute;
	bottom: 100%;
	left: 50%;
	transform: translateX(-50%);
	transition: none;
	width: 4.5em;
	height: 1.5em;
}
.aside-toggle:before {
	border-left: 0.5em solid transparent;
	border-right: 0.5em solid transparent;
	border-bottom: 0.5em solid;
	color: var(--pColor);
	content: "";
	display: block;
	position: absolute;
	top: 33%;
	left: calc(50% - 0.5em);
	width: 0; 
	height: 0; 
	transition: color 0.1s linear;
}
.aside-toggle:hover:before, .aside-toggle:focus:before {
	color: var(--buttonBg);
}
.aside-open {
	transform: translateY(0%);
}
.aside-open .aside-toggle:before {
	border-bottom: 0;
	border-top: 0.5em solid;
}
.sr {
	clip: rect(1px,1px,1px,1px);
	overflow: hidden;
	position: absolute;
}
.upload-btn, .upload-btn input[type=text], .upload-btn input[type=file] + button {
	margin-bottom: 0.75em;
}
.upload-btn {
	display: flex;
	justify-content: space-between;
	position: relative;
}
.upload-btn input[type=text] {
	width: calc(62.5% - 0.375em);
}
.upload-btn input[type=file], .upload-btn input[type=file] + button {
	width: calc(37.5% - 0.375em);
}
.upload-btn input[type=file] {
	position: absolute;
	opacity: 0;
	top: 0;
	left: 0;
	height: 3em;
}
.upload-btn input[type=file] + button svg {
	display: block;
	margin: auto;
	width: 1.5em;
	height: 1.5em;
}
.upload-btn input[type=file] + button path {
	fill: #fff;
}





:root {
  --yellow: linear-gradient(180deg, #F8D49A -146.42%, #FAD79D -46.42%);
  --orange: #fca61f;
  --black: #242d49;
  --gray: #788097;
  --purple: linear-gradient(180deg, #BB67FF 0%, #C484F3 100%);
  --pink: #FF919D;
  --glass: rgba(255, 255, 255, 0.54);
  --boxShadow: 0px 19px 60px rgb(0 0 0 / 8%);
  --smboxShadow: -79px 51px 60px rgba(0, 0, 0, 0.08);
  --activeItem: #f799a354;
}

.AppGlass {
  display: grid;
  height: 97%;
  width: 97%;
  background: var(--glass);
  border-radius: 2rem;
  grid-gap: 16px;
  gap: 16px;
  grid-template-columns: 11rem auto 20rem;
  overflow: hidden;
}

@media screen and (max-width: 1200px) {
  .AppGlass {
    grid-template-columns: 10% 50% auto;
    overflow-y: scroll;
  }
}

@media screen and (max-width: 768px) {
  .AppGlass {
    grid-template-columns: 1fr;
  }
}

/* Table CSS */
@font-face {
  font-family: "Fakt Pro";
  font-style: normal;
  font-weight: 400;
  src: url("https://github.com/awt2542/FramerSite/blob/master/static/css/fonts/FaktPro-Normal.eot"); /* IE9 Compat Modes */
  src: url("https://github.com/awt2542/FramerSite/blob/master/static/css/fonts/FaktPro-Normal.eot?#iefix")
      format("embedded-opentype"),
    url("https://github.com/awt2542/FramerSite/blob/master/static/css/fonts/FaktPro-Normal.woff")
      format("woff"); /* Modern Browsers */
}

@font-face {
  font-family: "Fakt Pro";
  font-style: normal;
  font-weight: 500;
  src: url("https://github.com/awt2542/FramerSite/blob/master/static/css/fonts/FaktPro-Medium.eot"); /* IE9 Compat Modes */
  src: url("https://github.com/awt2542/FramerSite/blob/master/static/css/fonts/FaktPro-Medium,.eot?#iefix")
      format("embedded-opentype"),
    url("https://github.com/awt2542/FramerSite/blob/master/static/css/fonts/FaktPro-Medium.woff")
      format("woff"); /* Modern Browsers */
}

@font-face {
  font-family: "Fakt Pro";
  font-style: normal;
  font-weight: 700;
  src: url("https://github.com/awt2542/FramerSite/blob/master/static/css/fonts/FaktPro-SemiBold.eot"); /* IE9 Compat Modes */
  src: url("https://github.com/awt2542/FramerSite/blob/master/static/css/fonts/FaktPro-SemiBold.eot?#iefix")
      format("embedded-opentype"),
    url("https://github.com/awt2542/FramerSite/blob/master/static/css/fonts/FaktPro-SemiBold.woff")
      format("woff"); /* Modern Browsers */
}

.search {
  width: 100%;
  height: 36px;
  /* position: relative; */
  display: flex;
}

.searchTerm {
  width: 100%;
  border: 3px solid #FFC436;
  border-right: none;
  font-size: 16px;
  padding: 5px;
  height: 36px;
  border-radius: 5px 0 0 5px;
  outline: none;
  color: #9DBFAF;
}

.searchTerm:focus{
  color: black;
}

.searchButton {
  width: 40px;
  height: 36px;
  border: 1px solid #FFC436;
  padding: 2px;
  background: #FFC436;
  text-align: center;
  color: #fff;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  font-size: 20px;
}

/*Resize the wrap to see the search bar change!*/
.wrap{
  width: 60%;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-bottom: 15px;
}

.cardGrid {
  height: 100vh;
  padding: 1rem;
  margin: 0;
  background: #f9f9f9;
}

.ContainerGrid {
  display: inline-block;
  width: 100%;
  height: 70%;
  overflow-y: scroll;
}

.CardStack {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  width: 100%;
}

.Cardgrid {
  display: block;
  width: calc(100vw / 4);
  min-width: 210px;
  margin: 0.5rem;
  background: #fff;
  border-radius: 0.25rem;
  transition: all 0.3s ease-in;
}

.Cardgrid:hover {
  box-shadow: 0px 22px 24px 0px rgba(46, 51, 51, 0.07);
  transition: all 0.3s ease-out;
}

.AvatarWrap {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  height: 5rem;
  padding: 1rem 0;
}

.Avatar {
  display: inline-block;
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 8px;
}

.modall {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  width: 35%;
  min-width: 310px;
  background-image: linear-gradient(to right top, #020306, #353537, #686869, #9f9fa0, #dadada);
  padding: 20px;
}

.StatusOff {
  position: absolute;
  align-self: flex-start;
  background: rgb(241, 241, 241);
  border-radius: 50%;
  border: 2px solid #fff;
  height: 0.75rem;
  width: 0.75rem;
  margin: 16px 0 0 52px;
}

.StatusOn {
  position: absolute;
  align-self: flex-start;
  background: #4cd964;
  border-radius: 50%;
  border: 2px solid #fff;
  height: 0.75rem;
  width: 0.75rem;
  margin: 16px 0 0 52px;
}

.Title {
  font-family: "Fakt Pro", -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 1em;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: normal;
  overflow-wrap: normal;
  text-align: center;
  color: rgb(33, 33, 33);
  padding: 0 1.5rem;
}

.Description {
  font-family: "Fakt Pro", -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.6;
  letter-spacing: normal;
  overflow-wrap: normal;
  text-align: center;
  color: rgb(117, 117, 117);
  padding: 0.75rem 1rem 1.5rem 1rem;
}

.Actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid rgb(208, 210, 211, 0.4);
  margin: 0 1rem;
  padding: 1rem 0;
}

.Link {
  color: rgb(10, 132, 174);
  text-decoration: none;
}

.Link:hover {
  color: rgb(5, 59, 78);
}

.Primary {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  font-size: 13px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 1px;
  white-space: nowrap;
  text-transform: uppercase;
  color: rgb(51, 51, 51);
  cursor: pointer;
  background: rgb(241, 241, 241);
  border: 1px solid rgb(241, 241, 241);
  border-radius: 3px;
  padding: 0px 16px;
  transition: border-color 0.25s ease 0s, background 0.25s ease 0s;
}

.Primary:hover {
  color: rgb(51, 51, 51);
  background: rgb(233, 232, 232);
  border-color: rgb(233, 232, 232);
}

.Secondary {
  display: inline-flex;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
  line-height: 32px;
  text-transform: uppercase;
  white-space: nowrap;
  letter-spacing: 1px;
  font-size: 13px;
  font-weight: 500;
  color: rgb(51, 51, 51);
  opacity: 1;
  cursor: pointer;
  background: transparent;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(208, 210, 211);
  border-image: initial;
  border-radius: 3px;
  padding: 0px 16px;
  transition: border-color 0.25s ease 0s, background 0.25s ease 0s;
}

.Secondary:hover {
  color: rgb(51, 51, 51);
  background: rgba(0, 0, 0, 0.05);
  border-color: rgb(181, 183, 184);
}

@keyframes blip {
  from {
    border: 2px solid #4cd964;
  }
  to {
    border: 2px solid #fff;
  }
}


@media (min-width: 48em){
.col-md-3 {
    width: 30% !important;
}
}


/* Main Css Style Rules */
:root{
    --white-c     : rgb(255, 255, 255);
    --bodyBg-c    : rgb(228, 251, 255);
    --whiteSomke-c: rgb(241, 241, 241);
    --noProduct-c : rgba(255, 255, 255, 0.302);
    --doveGray-c  : rgb(102, 102, 102);  
    --black-c     : rgb(0, 0, 0);
    --nero-c      : rgb(30, 30, 30);
    --shadow01-c  : rgba(0, 0, 0, 0.040);
    --shadow02-c  : rgba(0, 0, 0, 0.150);
    --gray-c      : rgba(43, 42, 42, 0.795);
    --bunker-c    : rgb(34, 37, 41);
    --lightBunke-c: rgb(43, 49, 58);
    --closeBtnBg-c: rgba(255, 255, 255, 0.336);
    --widnowBg-c  : rgba(0, 255, 55, 0.575);
    --primary-c   : rgb(80, 216, 144);
    --actionBg-c  : rgba(96, 255, 170, 0.726);
    --inCartProd-c: rgb(184, 245, 214);
    --inCartBord-c: rgb(210, 255, 210);
    --red-c       : rgb(255, 0, 0);
    --heartRead-c : rgb(233, 6, 6); 
    --orange-c    : rgb(255, 87, 34);
    --navBlue-c   : rgb(0, 102, 255);
  }

  
  /* Main Animation  */
  @keyframes feedInLoad{
    from{
      opacity: 0;
    }
    to{
      opacity: 1;
    }
  }
  /* End Main Css Style Rules */
  
  /* Start conatiner */
  .conatiner{
    width: 95%;
    min-height: 100vh;
    position: relative;
    margin: auto;
    padding: 10px 0 50px 0;
    text-align: center;
    overflow: hidden;
    animation: feedInLoad 0.5s ease-in-out;
  }
  /* For The Hide Scroll */
  .conatiner.hide{
    height: 100vh;
  }
  
  /* Header */
  header{
    width: 100%;
    position: relative;
    display: flex;
    background-color: var(--primary-c);
    color: var(--white-c);
    justify-content: space-between;
    padding: 20px 40px;
    align-items: center;
    z-index: 1000;
    transition: 0.3s ease-in-out;
  }
  /* Hide Header */
  .conatiner.hide > header{
    opacity: 0;
  }
  .header-logo h1{
    font-size: 30px;
    letter-spacing: 2px;
    font-weight: 600;
    text-transform: uppercase;
  }
  /* Nav Links */
  .header-nav{
    list-style: none;
  }
  .header-nav li{
    position: relative;
    display: inline-block;
    cursor: pointer;
    margin: 0 10px;
    transition: 0.3s;
  }
  .header-nav li:active{
    transform: scale(0.8);
  }
  /* Icon */
  .header-nav li i{
    font-size: 28px;
  }
  /* Badge */
  .header-nav li > span{
    position: absolute;
    min-width: 15px;
    min-height: 15px;
    border-radius: 10px;
    background-color: var(--red-c);
    text-align: center;
    line-height: 13px;
    font-size: 13px;
    left: 17px;
    padding: 3px 6px;
  }
  /* End Header */
  
  /* Search Area */
  .search-area{
    position: relative;
    height: 250px;
    text-align: center;
    padding: 50px 20px;
    z-index: 100;
    transition: 0.3s ease-in-out;
  }
  /* Blur The Search Area */
  .conatiner.hide .search-area{
    filter: blur(7px);
  }
  .search-area h2{
    color: var(--black-c);
    font-size: 30px;
    letter-spacing: 1px;
    font-weight: 400;
    margin-bottom: 50px;
  }
  .search-area input{
    border: 1px solid var(--whiteSomke-c);
    outline: none;
    padding: 15px 20px;
    width: 60%;
    transition: 0.3s ease-in-out;
    border-radius: 2px;
  }
  @media (max-width: 768px){
    .search-area input{
      width: 85%;
    }
  }
  .search-area input:focus{
    border-color: var(--primary-c);
  }
  .search-area input::placeholder{
    font-family: 'Open Sans', sans-serif;
  }
  .search-area input::-moz-placeholder{
    font-family: 'Open Sans', sans-serif;
  }
  /* End Search Area */
  
  /* Products Area */
  .products-area{
    position: relative;
    width: 70%;
    padding: 20px 0;
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
    z-index: 10;
    transition: 0.5s ease-in;
  }
  /* Blur Products Area */
  .conatiner.hide .products-area{
    filter: blur(10px);
  }
  /* Product Box */
  .product-box{
    position: relative;
    width: 230px;
    min-height: 300px;
    background-color: var(--white-c);
    box-shadow: 0 0 30px var(--shadow01-c);
    overflow: hidden;
    transition: 0.3s ease-in-out;
    padding-bottom: 30px;
    margin: 10px;
  }
  .product-box:hover{
    box-shadow: 0px 18px 26px var(--shadow02-c);
  }
  /* Favoriet Icon */
  .product-favorite{
    position: relative;
    width: 100%;
    text-align: right;
    z-index: 100;
  }
  .product-favorite i{
    position: absolute;
    cursor: pointer;
    font-size: 24px;
    right: 10px;
    top: 10px;
    color: var(--nero-c);
    transition: 0.3s ease-in-out;
  }
  .product-box .product-favorite i:hover{
    opacity: 0.7;
  }
  .product-box .product-favorite i:active{
    opacity: 1;
    transform: scale(0.9);
  }
  /* Product Image */
  .product-image{
    position: relative;
    width: 100%;
    height: 225px;
    display: flex;
    align-items: center;
  }
  .product-image img{
    max-width: 150px;
    margin: auto;
  }
  /* Action Box */
  .action-box{
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: 0.3s ease-in-out;
    z-index: 2;
  }
  .action-box::before{
    content: '';
    position: relative;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: var(--primary-c);
    opacity: 0.7;
    z-index: 1;
  }
  .product-box:hover .action-box{
    opacity: 1;
  }
  /* Button Add To Cart */
  .action-box button{
    position: absolute;
    top: 45px;
    outline: none;
    background-color: transparent;
    border:  2px solid var(--white-c);
    color: var(--white-c);
    padding: 10px 30px;
    font-weight: 500;
    font-size: 16px;
    transition: 0.4s ease-in-out;
    transform: scale(1.5);
    pointer-events: none;
    z-index: 1000;
  }
  .action-box button i{
    font-size: 17px;
  }
  .action-box button:hover{
    background-color: var(--white-c);
    color: var(--primary-c);
  }
  .product-box:hover .action-box button{
    transform: scale(1);
    pointer-events: all;
    cursor: pointer;
  }
  /* Product Info */
  .product-info{
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 80px;
    padding: 10px 20px;
    background-color: var(--white-c);
    overflow: hidden;
    transition: 0.3s ease-in-out;
    z-index: 2;
  }
  .product-box:hover .product-info{
    height: 170px;
  }
  /* info-head */
  .info-head{
    position: relative;
    display: flex;
    justify-content: space-between;
    height: 75px;
    align-items: center;
  }
  /* info-head [ Title ] */
  .info-head .title{
    font-weight: 500;
    letter-spacing: 1px;
    font-size: 20px;
  }
  /* info-head [ Price ] */
  .info-head .price{
    font-size: 20px;
    font-weight: 600;
    color:var(--primary-c);;
  }
  /* Description Info */
  .desc-info{
    position: relative;
    top: -6px;
    padding: 0 5px 0;
  }
  .desc-info p{
    font-size: 14px;
    /* color: var(--gray-c); */
  }
  /* Details Info */
  .deatils-info{
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px 0 0 0;
  }
  /* Details Info [ Size ] */
  .size-info{}
  /* Title */
  .size-info .size-title{
    text-transform: uppercase;
    margin-bottom: 5px;
    display: block;
    font-weight: bold;
    text-align: left;
  }
  /* Type */
  .size-info .size-type{
    font-size: 13px;
    padding: 0 5px;
  }
  /* Details Info [ Color ] */
  .color-info{
    text-align: center;
  }
  /* Title */
  .color-info .color-title{
    text-transform: uppercase;
    margin-bottom: 5px;
    display: block;
    font-weight: bold;
  }
  /* Type */
  .color-info .color-type{
    width: 16px;
    height: 16px;
    background-color: var(--black-c);
    border-radius: 50%;
    display: inline-block;
    transition: 0.3s;
  }
  .color-info .color-type:hover{
    transform: scale(1.1);
  }
  
  /* Alarm No Search */
  .noSearchAlarm{
    display: none;
  }
  .noSearchAlarm.show{
    display: block;
  }
  .conatiner.hide .products-area .noSearchAlarm{
    opacity: 0;
  }
  /* End Products Area */
  
  /* Cart Container */
  .cart-container{
    position: fixed;
    top: -200px;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    z-index: 1;
    transition: 0.3s ease-in-out;
    opacity: 0;
  }
  /* Open Cart */
  .cart-container.open{
    top: 0;
    opacity: 1;
    z-index: 1100;
    transition: 0.3s ease-in-out;
  }
  /* Animation */
  .cart-container.open .noProductsInCart{
    animation: feedInLoad 0.6s ease-in-out;
  }
  /* The Overlay Layer */
  .cart-container::after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--primary-c);
    width: 100%;
    height: 100%;
    opacity: 0.6;
    z-index: -1;
  }
  /* Cart Header */
  .cart-header{
    position: relative;
    width: 100%;
    padding: 30px 20px;
    text-align: left;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  /* Heading Text */
  .cart-header > h2{
    color: var(--white-c);
    font-weight: 500;
    font-size: 28px;
    text-transform: uppercase;
    letter-spacing: 2px;
    transition: 1s ease-in-out;
    z-index: 101;
  }
  /* Close Cart Button */
  .cart-header .closeCart-btn{
    width: 120px;
    height: 50px;
    color: var(--white-c);
    cursor: pointer;
    transition: 0.6s ease;
    background-color: var(--closeBtnBg-c);
    line-height: 50px;
    padding: 0 10px;
    border-radius: 5px;
    z-index: 101;
  }
  /* Animation */
  .cart-container.open .closeCart-btn {
    animation: cmoeFromRight 0.5s;
  }
  /* Actions */
  .close-cart-action{
    width: 100%;
    height: 100%;
    position: relative;
    text-align: center;
  }
  .close-cart-action i{
    font-size: 25px;
    position: relative;
    top: 3px;
  }
  .close-cart-action span{
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 2px;
  }
  /* Close Button Effects */
  .closeCart-btn:hover{
    opacity: 0.7;
  }
  .closeCart-btn:active{
    opacity: 1;
  }
  /* No Product Yet Alarm Box */
  .noProductsInCart{
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 400px;
    padding: 20px;
    background-color: var(--noProduct-c);
    transform: translate(-50%, -50%);
    z-index: 100;
    opacity: 0;
    display: none;
    transition: 0.3s ease-in-out;
    z-index: 101;
  }
  .noProductsInCart.show{
    opacity: 1;
    display: block;
  }
  .noProductsInCart i{
    color: var(--white-c);
    display: block;
    font-size: 100px;
    opacity: 0.5;
  }
  .noProductsInCart h3{
    color: var(--white-c);
    letter-spacing: 1px;
    font-weight: 400;
    padding: 20px 0;
  }
  .noProductsInCart button{
    border: none;
    outline: none;
    background-color: var(--bunker-c);
    color: var(--white-c);
    padding: 15px 25px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    font-size: 13px;
    text-transform: uppercase;
  }
  .noProductsInCart button:hover{
    background-color: var(--lightBunke-c);
  }
  .noProductsInCart button:active{
    background-color: var(--navBlue-c);
  }
  
  /* In Cart Area */
  .inCart-area{
    position: relative;
    width: 100%;
    height: 620px;
    text-align: left;
    display: block;
    overflow: auto;
    padding-bottom: 100px;
  }
  /* In Cart Product */
  .inCart-product{
    position: relative;
    width: 50%;
    height: 180px;
    background-color: var(--white-c);
    padding: 10px;
    border-radius: 3px;
    border: 1px solid var(--inCartBord-c);
    border-right: none;
    margin: 15px 0px 10px 100px;
    z-index: 101;
  }
  .inCart-product:first-child{
    margin: 30px 0px 10px 100px;
  }
  .inCart-product img{
    width: 150px;
    height: 100%;
    object-fit: cover;
    float: left;
  }
  /* In Cart Product Info */
  .inCart-product-info{
    position: relative;
    left: 20px;
    float: left;
  }
  .inCart-product-info span{
    /* display: block; */
    line-height: 25px;
  }
  /* Title */
  .inCart-product-info span:first-child{
    font-size: 25px;
    letter-spacing: 2px;
    font-weight: 600;
    padding: 15px 0;
  }
  /* Quantity Box Area */
  .quantity-box{
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
  }
  .quantity-box span{
    /* display: block; */
    font-size: 30px;
  }
  .quantity-box .btn{
    cursor: pointer;
  }
  /* Disabled Decrease Button */
  .disabled{
    pointer-events: none;
    opacity: 0.3;
  }
  /* Quantity Buttons Effects */
  .quantity-box .btn:active{
    transform: scale(0.9);
  }
  /* Delete Button */
  .delete-btn{
    position: absolute;
    top: 0;
    right: -70px;
    width: 70px;
    height: 100%;
    background-color: var(--red-c);
    padding: 10px;
    color: var(--white-c);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 1px solid var(--inCartBord-c);
    border-left: none;
  }
  .delete-btn i {
    font-size: 40px;
    cursor: pointer;
    transition: 0.3s;
  }
  .delete-btn:hover i{
    opacity: 0.8;
  }
  .delete-btn:active i{
    transform: scale(0.9);
    opacity: 1;
  }
  /* In Cart Controls*/
  .inCart-controls{
    position: absolute;
    left: 50%;
    bottom: 0;
    width: 90%;
    height: 150px;
    background-color: transparent;
    transform: translateX(-50%);
    z-index: 1000;
  }
  /* Total Price Box */
  .totalPrice-box,
  .checkout-btn{
    position: absolute;
    top: 50%;
    min-width: 100px;
    transform: translateY(-50%);
    background-color: var(--orange-c);
    z-index: 100;
    padding: 15px 25px;
    color: var(--white-c);
    z-index: 101;
  }
  .totalPrice-box{
    left: 0;
  }
  .checkout-btn{
    right: 0;
  }
  .totalPrice-box > span{
    letter-spacing: 2px;
  }
  /* Checkout Button */
  .checkout-btn{
    background-color: var(--black-c);
    padding: 15px 42px;
    color: var(--white-c);
    cursor: pointer;
    transition: 0.3s ease-in-out;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
  .checkout-btn:hover{
    background-color: var(--bunker-c);
  }
  .checkout-btn:active{
    background-color: var(--navBlue-c);
  }
  @keyframes cmoeFromRight{
    from{
      right: -200px;
    }
    to{
      right: 100px;
    }
  }
  /* End Cart Conatiner */
  
  /* Favorites Container */
  .favorites-container{
    position: fixed;
    top: -200px;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    z-index: 1;
    opacity: 0;
    transition: 0.3s ease-in-out;
  }
  /* Open Favorites */
  .favorites-container.open{
    top: 0;
    opacity: 1;
    z-index: 1100;
    transition: 0.3s ease-in-out;
  }
  /* The Overlay Layer */
  .favorites-container::after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--primary-c);
    width: 100%;
    height: 100%;
    opacity: 0.6;
    z-index: -1;
  }
  /* Favorites Header */
  .favorites-header{
    position: relative;
    width: 100%;
    padding: 30px 20px;
    text-align: left;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  /* Heading Text */
  .favorites-header > h2{
    color: var(--white-c);
    font-weight: 500;
    font-size: 28px;
    text-transform: uppercase;
    letter-spacing: 2px;
    left: -100px;
    transition: 1s ease-in-out;
    z-index: 101;
  }
  /* Close Favorite Button */
  .favorites-header .closeFavorites-btn{
    width: 120px;
    height: 50px;
    color: var(--white-c);
    cursor: pointer;
    transition: 0.6s ease;
    background-color: var(--closeBtnBg-c);
    line-height: 50px;
    padding: 0 10px;
    border-radius: 5px;
    z-index: 101;
  }
  /* Animation */
  .favorites-container.open .closeFavorites-btn{
    animation: cmoeFromRight 0.8s;
  }
  /* Actions */
  .close-Favorites-action{
    width: 100%;
    height: 100%;
    position: relative;
    text-align: center;
  }
  .close-Favorites-action i{
    font-size: 25px;
    position: relative;
    top: 3px;
  }
  .close-Favorites-action span{
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 2px;
  }
  /* Close Button Effects */
  .closeFavorites-btn:hover{
    opacity: 0.7;
  }
  .closeFavorites-btn:active{
    opacity: 1;
  }
  /* No Product Yet Alarm Box */
  .noFavorites{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 400px;
    padding: 20px;
    background-color: var(--noProduct-c);
    opacity: 0;
    display: none;
    transition: 0.3s ease-in-out;
    z-index: 101;
  }
  /* Animation */
  .cart-container.open .noFavorites{
    animation: feedInLoad 0.6s ease-in-out;
  }
  .noFavorites.show{
    opacity: 1;
    display: block;
  }
  .noFavorites i{
    color: var(--white-c);
    display: block;
    font-size: 100px;
    opacity: 0.5;
  }
  .noFavorites h3{
    color: var(--white-c);
    letter-spacing: 1px;
    font-weight: 400;
    padding: 20px 0;
  }
  .noFavorites button{
    border: none;
    outline: none;
    background-color: var(--bunker-c);
    color: var(--white-c);
    padding: 15px 25px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
  }
  .noFavorites button:hover{
    background-color: var(--lightBunke-c);
  }
  .noFavorites button:active{
    background-color: var(--navBlue-c);
    transform: scale(0.9);
  }
  /* In Favorites Area */
  .inFavorites-area{
    position: relative;
    width: 100%;
    height: 620px;
    text-align: left;
    display: block;
    overflow: auto;
    padding-bottom: 100px;
  }
  /* In Favorites Product */
  .inFavorites-product{
    position: relative;
    width: 50%;
    height: 180px;
    background-color: var(--white-c);
    padding: 10px;
    border-radius: 3px;
    border: 1px solid var(--inCartBord-c);
    border-right: none;
    margin: 15px 0px 10px 100px;
    z-index: 101;
  }
  .inFavorites-product:first-child{
    margin: 100px 0px 10px 100px;
  }
  .inFavorites-product img{
    width: 150px;
    height: 100%;
    object-fit: cover;
    float: left;
  }
  /* In Favorites Product Info */
  .inFavorites-product-info{
    position: relative;
    left: 20px;
    float: left;
  }
  .inFavorites-product-info span{
    /* display: block; */
    line-height: 28px;
  }
  .inFavorites-product-info span:first-child{
    font-size: 25px;
    letter-spacing: 2px;
    font-weight: 600;
    padding: 10px 0;
  }
  /* Delete Favorite Button */
  .deleteFave-btn{
    position: absolute;
    top: 0;
    right: -70px;
    width: 70px;
    height: 100%;
    background-color: var(--red-c);
    padding: 10px;
    color: var(--white-c);
    display: flex;
    border: 1px solid var(--inCartBord-c);
    border-left: none;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .deleteFave-btn i {
    font-size: 40px;
    cursor: pointer;
    transition: 0.3s;
  }
  /* Delete Button  Effects */
  .deleteFave-btn:hover i{
    opacity: 0.8;
  }
  .deleteFave-btn:active i{
    transform: scale(0.9);
    opacity: 1;
  }
  /* End Favorites Container */
  
  /* Footer */
  footer{
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 30px 0;
    text-align: left;
  }
  /* Footer Infooo */
  .footer-info{
    margin: 0 0 0 50px;
  }
  .footer-info span{
    color: var(--doveGray-c);
    font-weight: 400;
  }
  .footer-info span i{
    color: var(--heartRead-c);
  }
  .footer-info span a{
    color: var(--black-c);
    letter-spacing: 2px;
  }
  /* End conatiner */
  
  /* Start Primary Area */
  .primary-area{
    position: absolute;
    top: 150px;
    left: -250px;
    width: 250px;
    min-height: 100px;
    background-color: var(--white-c);
    padding: 20px 10px 10px;
    border: 1px solid var(--whiteSomke-c);
    border-left: none;
    text-align: center;
    z-index: 1002;
    transition: 0.5s ease-in-out;
  }
  /* Hide Primary Area */
  .conatiner.hide ~ .primary-area{
    opacity: 0;
  }
  /* Open Primary Area */
  .primary-area.open{
    left: 0;
  }
  /* Heading */
  .primary-area h2{
    font-weight: 400;
  }
  /* Primary Content Box */
  .primary-content{
    position: relative;
    width: 100%;
    height: 100%;
  }
  /* Primary Colors Box */
  .primary-colors-box{
    position: relative;
    padding: 20px 0 10px 0;
  }
  .primary-colors-box span{
    /* display: inline-block; */
    width: 30px;
    height: 30px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
  }
  .primary-colors-box span.active{
    transform: translateY(-2px);
  }
  .primary-colors-box span:hover{
    transform: translateY(-2px);
  }
  .primary-colors-box span:active{
    opacity: 0.8;
  }
  /* Boxs Backgrounds Color */
  .primary-colors-box span:first-child{
    background-color: rgb(80, 216, 144);
  }
  .primary-colors-box span:nth-child(2){
    background-color: rgb(103, 58, 183);
  }
  .primary-colors-box span:nth-child(3){
    background-color: rgb(0, 102, 255);
  }
  .primary-colors-box span:last-child{
    background-color: rgb(233, 30, 99);
  }
  /* Show Primary Box Button */
  .show-PrimaryBox-Btn{
    position: absolute;
    top: -40px;
    right: -50px;
    width: 40px;
    height: 40px;
    background-color: var(--white-c);
    border: 1px solid var(--whiteSomke-c);
    line-height: 45px;
    border-left: none;
    cursor: pointer;
  }
  .show-PrimaryBox-Btn i{
    font-size: 22px;
    transition: 0.3s ease-in-out;
    pointer-events: none;
  }
  .show-PrimaryBox-Btn:hover i{
    opacity: 0.6;
  }
  /* overflow layer */
  .primary-overflow{
    width: 100%;
    height: 100%;
    background-color: transparent;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1001;
    display: none;
  }
  .primary-area.open ~ .primary-overflow{
    display: block;
  }
  /* End Primary Area */
  
  /* Responsive Friendly */
  @media (max-width: 768px) {
    /* Close Buttons */
    .closeCart-btn,
    .closeFavorites-btn{
      top: 30px;
      right: 25px;
    }
    /* Product */
    .inCart-product,
    .inFavorites-product{
      width: 83%;
      margin-left: 25px;
    }
    .inCart-product:first-child,
    .inFavorites-product:first-child{
      margin: 30px 0px 10px 25px;
    }
  }
  @media (max-width: 576px){
    .cart-header > h2,
    .favorites-header > h2{
      margin: 0 auto 10px auto;
    }
    .cart-header > .closeCart-btn,
    .favorites-header >.closeFavorites-btn{
      margin: 0 auto;
    }
    /* Alarms */
    .noProductsInCart,
    .noFavorites{
      min-width: 300px;
    }
    /* Alarms Icons */
    .noProductsInCart i,
    .noFavorites i{
      font-size: 70px;
    }
    .noProductsInCart h3,
    .noFavorites h3{
      font-size: 16px;
    }
    /* Product */
    .inCart-product,
    .inFavorites-product{
      width: 270px;
      height: 340px;
      margin-top: 90px;
      margin: 100px auto;
      z-index: 150;
    }
    .inCart-product:first-child,
    .inFavorites-product:first-child{
      margin: 30px auto;
    }
    /* Areas */
    .inCart-area,
    .inFavorites-area{
      padding-bottom: 150px;
    }
    /* Product Image */
    .inCart-product img,
    .inFavorites-product img{
      width: 100%;
      height: 150px;
    }
    /* Quantity */
    .quantity-box{
      top: 70%;
    }
    /* Delete Buttons */
    .delete-btn,
    .deleteFave-btn{
      top: auto;
      right: 0;
      bottom: -79px;
      width: 100%;
      height: 80px;
      border-radius: 0 0 10px 10px;
      border-left: 1px solid;
    }
    /* Countrols */
    .totalPrice-box,
    .checkout-btn{
      width: 100%;
      top: 70%;
    }
    .totalPrice-box{
      top: 40px;
    }
  }
  /* End Responsive */
.btn {
  display: inline-block;
  display: inline;
  zoom: 1;
  padding: 4px 10px 4px;
  margin-bottom: 0;
  font-size: 13px;
  line-height: 18px;
  color: #333333;
  text-align: center;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
  vertical-align: middle;
  background-color: #f5f5f5;
  background-image: linear-gradient(top, #ffffff, #e6e6e6);
  background-repeat: repeat-x;
  filter: progid:dximagetransform.microsoft.gradient(startColorstr=#ffffff, endColorstr=#e6e6e6, GradientType=0);
  border-color: #e6e6e6 #e6e6e6 #e6e6e6;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2),
    0 1px 2px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  margin-left: 0.3em;
}
.btn:hover,
.btn:active,
.btn.active,
.btn.disabled,
.btn[disabled] {
  background-color: #e6e6e6;
}
.btn-large {
  padding: 9px 14px;
  font-size: 15px;
  line-height: normal;
  border-radius: 5px;
}
.btn:hover {
  color: #333333;
  text-decoration: none;
  background-color: #e6e6e6;
  background-position: 0 -15px;
  transition: background-position 0.1s linear;
}
.btn-primary,
.btn-primary:hover {
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  color: #ffffff;
}
.btn-primary.active {
  color: rgba(255, 255, 255, 0.75);
}
.btn-primary {
  background: linear-gradient( 97.3deg,  rgba(25,50,70,0.81) 10.7%, rgba(250, 81, 9, 0.72) 39.5%, rgba(255,192,0,0.81) 69.7% ) !important;
  background-image: linear-gradient(top, #6eb6de, #4a77d4);
  background-repeat: repeat-x;
  filter: progid:dximagetransform.microsoft.gradient(startColorstr=#6eb6de, endColorstr=#4a77d4, GradientType=0);
  border: 1px solid #272726 !important;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2),
    0 1px 2px rgba(0, 0, 0, 0.5);
}
.btn-primary:hover,
.btn-primary:active,
.btn-primary.active,
.btn-primary.disabled,
.btn-primary[disabled] {
  filter: none;
  background-color: rgb(225, 193, 12) !important;
}
.btn-block {
  width: 100%;
  display: block;
}

.login {
  position: absolute;
  top: 40%;
  left: 50%;
  margin: -150px 0 0 -150px;
  width: 300px;
  height: 300px;
}
.login h1 {
  color: #fff;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  letter-spacing: 1px;
  text-align: center;
}

.formInput {
  width: 100% !important;
  margin-bottom: 10px;
  background: rgba(7, 6, 0, 0.3);
  border: 2px #FEFFD2 solid !important; 
  outline: none;
  padding: 10px;
  font-size: 13px;
  color: #fff;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  box-shadow: inset 0 -5px 45px rgba(100, 100, 100, 0.2),
    0 1px 1px rgba(255, 255, 255, 0.2);
  transition: box-shadow 0.5s ease;
}

.dashSearchInput::placeholder {
  /* font-weight: bold; */
  font-size: 16px;
  color: #FFBB0F;
}

.dashSearchInput {
  width: 60%;
  margin-bottom: 10px;
  margin-left: 10px;
  background: rgba(7, 6, 0, 0.7);
  border: 2px #B7B7B7 solid !important; 
  outline: none;
  padding: 10px;
  font-size: 16px;
  color: #fff;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  box-shadow: inset 0 -5px 45px rgba(100, 100, 100, 0.2),
    0 1px 1px rgba(255, 255, 255, 0.2);
  transition: box-shadow 0.5s ease;
}
.formInput:focus {
  box-shadow: inset 0 -5px 45px rgba(100, 100, 100, 0.4),
    0 1px 1px rgba(255, 255, 255, 0.2);
}

.gm-style .gm-style-iw-c {
    background: linear-gradient( 97.3deg,  rgba(25,50,70,0.81) 10.7%, rgba(250, 81, 9, 0.72) 39.5%, rgba(255,192,0,0.81) 69.7% ) !important;
 }
