 div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
  color: white;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
/* body {
	line-height: 1;
} */
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
  
}

.linebar{
  text-align:center;

}



.linebar h1{
  padding: 60px 0;
  font-weight:800;
  font-size:36px;
  text-decoration:none;
}


.linebar a{
  text-decoration:none;
  color:#1a237e;
  
}

.footer {
  left: 0;
  bottom: 0;
  width: 100%;
  height:40px;
  background-color: #E3E3E3;
 
}
.footer p{
  padding:10px;
  text-align:center;
  color: black;
}
.footer strong{
  font-weight:800;
}
.linebar2{
 background-image: linear-gradient(120deg, #fccb90 0%, #d57eeb 100%);
 width: 80%;
 margin-left: auto;
 margin-right: auto;
  text-align:center;
}

.linebar2 ul{
  padding: 15px 0px;
}

li a{
  text-decoration:none;
  /* font-weight:bold; */
  color:white; 
  padding: 0 15px;
}

li a:hover{
  color: #F8F4EC;
  background-color: #222831;
}

li{
  display:inline;
  

}


.bazarheader{
  display:flex;
  flex-wrap:wrap;
  margin-top:40px;
  margin-left:40px;

}
.content1{

  height:250px;
  min-width:300px;
  margin-right:65px;
}
.content2{
  margin-right:15px;
  margin-bottom:15px;
  font-size:25px;
  height:450px;
  width:350px;
  border-radius:15px;
}

.catalog{
  font-size:60px;
  font-weight:bold;
  color: orange;
}

.catalog #bazzarbutton{
  /* color:white; */
  font-weight:bold;
  font-size: 14px;
  border-radius:100px;
  width: 120px;
  height:45px;
  border-style:none;
  margin-bottom:15px;
  background-image:linear-gradient(to right, #B65EBA, #6454F0 );
}
.catalog button:hover{
  cursor:pointer;
  background-image:linear-gradient(to right, #B654BA, #6434F0 );

}

.circledesign {
    font-size:30px;
    bottom: 0;
    width: auto;
    height: 50px;
}

.ImageContent {
    width: auto;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    height: 401px;
    /* background-image: url(https://i.pinimg.com/564x/01/10/fb/0110fba5b46c2d0ccc9b9a5e4fe72295.jpg); */

  background-size:120%;
    background-repeat: no-repeat;

  
    
  }
 .ImageContent2 {
    width: auto;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    height: 401px;
    background-image:       url(https://firebasestorage.googleapis.com/v0/b/miami-auth-58a82.appspot.com/o/MIAMI%20PHOTOSE%2Fhousing.png?alt=media&token=c6a71e84-54ef-4f7e-ae84-727a53e60f7e);

  background-size:100%;
    background-repeat: no-repeat;

}

.circledesign{
  font-weight:bold;
  color: orange;
}

.content2:hover{

  opacity:0.9;
  
background-image: url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%239C92AC' fill-opacity='0.28'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  cursor:pointer;
background-color: white;
}

::selection {
  background: #03A9F4; /* WebKit/Blink Browsers */
}

/* SEARCH ASSET  */
#search {
	display: grid;
	grid-area: search;
	grid-template:
		"search" 60px
		/ 420px;
	justify-content: center;
	align-content: center;
	justify-items: stretch;
	align-items: stretch;
	/* background: hsl(0, 0%, 99%); */
  margin-bottom: 25px;
}

#search input {
	display: block;
	grid-area: search;
	-webkit-appearance: none;
	appearance: none;
	width: 100%;
	height: 100%;
	background: none;
	padding: 0 30px 0 60px;
	border: none;
	border-radius: 100px;
	font: 24px/1 system-ui, sans-serif;
	outline-offset: -8px;
}


#search svg {
	grid-area: search;
	overflow: visible;
	color: #FFF;
	fill: none;
	stroke: currentColor;
	visibility: visible !important;
}

.spark {
	fill: currentColor;
	stroke: none;
	r: 15;
}

.spark:nth-child(1) {
	animation:
		spark-radius 2.03s 1s both,
		spark-one-motion 2s 1s both;
}

@keyframes spark-radius {
	0% { r: 0; animation-timing-function: cubic-bezier(0, 0.3, 0, 1.57) }
	30% { r: 15; animation-timing-function: cubic-bezier(1, -0.39, 0.68, 1.04) }
	95% { r: 8 }
	99% { r: 10 }
	99.99% { r: 7 }
	100% { r: 0 }
}

@keyframes spark-one-motion {
	0% { transform: translate(-20%, 50%); animation-timing-function: cubic-bezier(0.63, 0.88, 0, 1.25) }
	20% { transform: rotate(-0deg) translate(0%, -50%); animation-timing-function: ease-in }
	80% { transform: rotate(-230deg) translateX(-20%) rotate(-100deg) translateX(15%); animation-timing-function: linear }
	100% { transform: rotate(-360deg) translate(30px, 100%); animation-timing-function: cubic-bezier(.64,.66,0,.51) }
}

.spark:nth-child(2) {
	animation:
		spark-radius 2.03s 1s both,
		spark-two-motion 2.03s 1s both;
}

@keyframes spark-two-motion {
	0% { transform: translate(120%, 50%) rotate(-70deg) translateY(0%); animation-timing-function: cubic-bezier(0.36, 0.18, 0.94, 0.55) }
	20% { transform: translate(90%, -80%) rotate(60deg) translateY(-80%); animation-timing-function: cubic-bezier(0.16, 0.77, 1, 0.4) }
	40% { transform: translate(110%, -50%) rotate(-30deg) translateY(-120%); animation-timing-function: linear }
	70% { transform: translate(100%, -50%) rotate(120deg) translateY(-100%); animation-timing-function: linear }
	80% { transform: translate(95%, 50%) rotate(80deg) translateY(-150%); animation-timing-function: cubic-bezier(.64,.66,0,.51) }
	100% { transform: translate(100%, 50%) rotate(120deg) translateY(0%) }
}

.spark:nth-child(3) {
	animation:
		spark-radius 2.05s 1s both,
		spark-three-motion 2.03s 1s both;
}

@keyframes spark-three-motion {
	0% { transform: translate(50%, 100%) rotate(-40deg) translateX(0%); animation-timing-function: cubic-bezier(0.62, 0.56, 1, 0.54) }
	30% { transform: translate(40%, 70%) rotate(20deg) translateX(20%); animation-timing-function: cubic-bezier(0, 0.21, 0.88, 0.46) }
	40% { transform: translate(65%, 20%) rotate(-50deg) translateX(15%); animation-timing-function: cubic-bezier(0, 0.24, 1, 0.62) }
	60% { transform: translate(60%, -40%) rotate(-50deg) translateX(20%); animation-timing-function: cubic-bezier(0, 0.24, 1, 0.62) }
	70% { transform: translate(70%, -0%) rotate(-180deg) translateX(20%); animation-timing-function: cubic-bezier(0.15, 0.48, 0.76, 0.26) }
	100% { transform: translate(70%, -0%) rotate(-360deg) translateX(0%) rotate(180deg) translateX(20%); }
}




.burst {
	stroke-width: 3;
}

.burst :nth-child(2n) { color: #ecebea }
.burst :nth-child(3n) { color: #ffab00 }
.burst :nth-child(4n) { color: #e5e7e4 }
.burst :nth-child(5n) { color: #c8c9c9 }

.circle {
	r: 6;
}

.rect {
	width: 10px;
	height: 10px;
}

.triangle {
	d: path("M0,-6 L7,6 L-7,6 Z");
	stroke-linejoin: round;
}

.plus {
	d: path("M0,-5 L0,5 M-5,0L 5,0");
	stroke-linecap: round;
}




.burst:nth-child(4) {
	transform: translate(30px, 100%) rotate(150deg);
}

.burst:nth-child(5) {
	transform: translate(50%, 0%) rotate(-20deg);
}

.burst:nth-child(6) {
	transform: translate(100%, 50%) rotate(75deg);
}

.burst * {}

@keyframes particle-fade {
	0%, 100% { opacity: 0 }
	5%, 80% { opacity: 1 }
}

.burst :nth-child(1) { animation: particle-fade 600ms 2.95s both, particle-one-move 600ms 2.95s both; }
.burst :nth-child(2) { animation: particle-fade 600ms 2.95s both, particle-two-move 600ms 2.95s both; }
.burst :nth-child(3) { animation: particle-fade 600ms 2.95s both, particle-three-move 600ms 2.95s both; }
.burst :nth-child(4) { animation: particle-fade 600ms 2.95s both, particle-four-move 600ms 2.95s both; }
.burst :nth-child(5) { animation: particle-fade 600ms 2.95s both, particle-five-move 600ms 2.95s both; }
.burst :nth-child(6) { animation: particle-fade 600ms 2.95s both, particle-six-move 600ms 2.95s both; }

@keyframes particle-one-move { 0% { transform: rotate(0deg) translate(-5%) scale(0.0001, 0.0001) } 100% { transform: rotate(-20deg) translateX(8%) scale(0.5, 0.5) } }
@keyframes particle-two-move { 0% { transform: rotate(0deg) translate(-5%) scale(0.0001, 0.0001) } 100% { transform: rotate(0deg) translateX(8%) scale(0.5, 0.5) } }
@keyframes particle-three-move { 0% { transform: rotate(0deg) translate(-5%) scale(0.0001, 0.0001) } 100% { transform: rotate(20deg) translateX(8%) scale(0.5, 0.5) } }
@keyframes particle-four-move { 0% { transform: rotate(0deg) translate(-5%) scale(0.0001, 0.0001) } 100% { transform: rotate(-35deg) translateX(12%) } }
@keyframes particle-five-move { 0% { transform: rotate(0deg) translate(-5%) scale(0.0001, 0.0001) } 100% { transform: rotate(0deg) translateX(12%) } }
@keyframes particle-six-move { 0% { transform: rotate(0deg) translate(-5%) scale(0.0001, 0.0001) } 100% { transform: rotate(35deg) translateX(12%) } }



.bar {
	width: 100%;
	height: 100%;
	ry: 50%;
	stroke-width: 10;
	animation: bar-in 900ms 3s both;
}

@keyframes bar-in {
	0% { stroke-dasharray: 0 180 0 226 0 405 0 0 }
	100% { stroke-dasharray: 0 0 181 0 227 0 405 0 }
}

.magnifier {
	animation: magnifier-in 600ms 3.6s both;
	transform-box: fill-box;
}

@keyframes magnifier-in {
	0% { transform: translate(20px, 8px) rotate(-45deg) scale(0.01, 0.01); }
	50% { transform: translate(-4px, 8px) rotate(-45deg); }
	100% { transform: translate(0px, 0px) rotate(0deg); }
}

.magnifier .glass {
	cx: 27;
	cy: 27;
	r: 8;
	stroke-width: 3;
}
.magnifier .handle {
	x1: 32;
	y1: 32;
	x2: 44;
	y2: 44;
	stroke-width: 3;
}



#results {
	grid-area: results;
	background: hsl(0, 0%, 95%);
}