/* html, body {
    height: 100%;
  }
  
  html {
    background: #eee;
  }
  
  body {
    display: flex;
    justify-content: center;
    align-items: center;
  } */
  
  .box {
    width: 100vw;
    height: 70vh;
    border-radius: 5px;
    box-shadow: 0 2px 30px rgba(0, 0, 0, 0.2);
    background: white;
    position: relative;
    margin: 15px;
    overflow: hidden;
    transform: translate3d(0, 0, 0);
  }
  
  .wave {
    opacity: 0.2;
    position: absolute;
    background: rgb(3, 40, 59);
    width: 160vw;
    height: 160vw;
    transform-origin: 50% 48%;
    border-radius: 43%;
    animation: drift 16000ms infinite linear;
    left: -25vw;
    top: -120vw;
  }
  
  .wave.-two {
    animation: drift 20000ms infinite linear;
    opacity: 0.2;
    background: white;
  }
  
  .wave.-three {
    animation: drift 18000ms infinite linear;
  }
  
  .box:after {
    content: "";
    display: block;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 11;
    transform: translate3d(0, 0, 0);
  }
  
  .box-header {
    height: 30vw;
    background-image: -ms-linear-gradient(right, #0c93C0, #FFF);
    background-image: -o-linear-gradient(right, #0c93C0, #FFF);
    background: linear-gradient(to bottom, #121b53, rgba(221, 238, 255, 0) 80%, rgba(0, 0, 0, 0));
    z-index: 20;
    position: relative;
  }
  
  .title {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 40;
    line-height: 300px;
    text-align: center;
    transform: translate3d(0, 0, 0);
    color: white;
    text-transform: uppercase;
    font-family: "Playfair Display", serif;
    letter-spacing: 0.4em;
    font-size: 30px;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
    text-indent: 0.3em;
  }
  
  @keyframes drift {
    from {
      transform: rotate(0deg);
    }
    from {
      transform: rotate(360deg);
    }
  }