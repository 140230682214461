.housefinContainer {
    background-color: #f4f4f4;
    height: 550px;
    width: 80% !important;
    margin: 0 auto;
    border-radius: 15px;
    -webkit-box-shadow: 0 0 15px #999;
    -moz-box-shadow: 0 0 15px #999;
    box-shadow: 0 0 15px #999;
}

h2 {
    font-weight: 200;
}

.header {
    background-image: linear-gradient(#c0e13e, #0d111e);
    color: #fff;
    padding: 20px;
    margin: 0;
    border-radius: 15px 15px 0 0;
    margin-bottom: 20px;
}

.currency {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100px;
    background-color: #fefefe;
    border-radius: 15px;
    margin: 10px 20px;
    border: 1px solid #f0f0f0;
}

.currency input {
    border: none;
    font-size: 22px;
    text-align: left;
    margin-left: 20px;
    outline: none;
    width: 150px;
    border-bottom: 1px solid #f0f0f0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}

.currency select {
    border: 0;
    padding-right: 10px;
    font-size: 22px;
    margin-right: 20px;
}

.middle {
    display: flex;
    align-items: center;
    justify-content: center;
}

.middle button {
    background-color: #57aff2;
    border: 2px solid #ecf0f1;
    border-radius: 50%;
    cursor: pointer;
    color: #fff;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 50px;
    transform: rotate(90deg);
    z-index: 1;
    position: absolute;
    outline: none;
}

.middle button:active {
    transform: rotate(90deg) scale(0.9);
}

.rate {
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    padding: 0 10px;
    margin: 10px 15px 30px 25px;
    color: #a8a8a8;
    text-transform: uppercase;
}

@media screen and (min-width: 640px) {

    .housefinContainer {
        background-color: #f4f4f4;
        width: 80% !important;
        margin: 0 auto;
    }

}


/* ATOM STRUCTURE */
/* @import url("https://cdnjs.cloudflare.com/ajax/libs/bulma/0.7.5/css/bulma.min.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0/css/all.min.css");
@import url("https://fonts.googleapis.com/css?family=Quicksand:400,700&display=swap"); */

.has-content-centered {
  justify-content: center;
}

.has-content-right {
  justify-content: flex-end;
}

.is-flex, .modal .modal-card-foot button, .circle, .layer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.is-round, .modal .modal-card-foot button, .ellipses .ellipsis {
  border-radius: 50%;
}

.is-strong {
  font-weight: 700;
}

.tittle, .subtitle {
  text-transform: uppercase;
  color: #ffffff;
}
.tittle.is-strong, .subtitle.is-strong {
  font-weight: 700;
  color: #94AD42;
}

.circle-graphic {
  pointer-events: none;
}
.circle-graphic.pause .module,
.circle-graphic.pause .node {
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}

.layer {
  width: 90%;
  height: 100%;
  position: absolute;
}

.module {
  position: absolute;
  display: flex;
  justify-content: flex-end;
  width: 38em;
  left: calc(50% - 6em);
  top: calc(50% - 6em);
  transform-origin: 6em 6em;
  -webkit-animation: spin 48s linear infinite;
          animation: spin 48s linear infinite;
  transition: transform 0.3s ease-out;
}
.module:before {
  content: "";
  display: block;
  width: calc(100% - 6em);
  position: absolute;
  border-bottom: dotted 8px #05BBC1;
  top: 50%;
  left: 6em;
  margin-top: -4px;
}
.module.pause .leaf {
  opacity: 1;
}
.module .circle {
  animation: spin 48s linear infinite reverse;
  pointer-events: all;
  cursor: pointer;
  transition: background 0.2s ease-out;
}
.module .circle.pause {
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.module .circle:hover {
  background: #ffffff;
  border-color: #ffffff;
  box-shadow: 0px 10px 15px #062133;
}
.module .circle:hover .tittle,
.module .circle:hover .subtitle {
  color: #94AD42;
}
.module:nth-child(1) {
  -webkit-animation-delay: -8s;
          animation-delay: -8s;
}
.module:nth-child(1) .circle {
  -webkit-animation-delay: -8s;
          animation-delay: -8s;
}
.module:nth-child(2) {
  -webkit-animation-delay: -16s;
          animation-delay: -16s;
}
.module:nth-child(2) .circle {
  -webkit-animation-delay: -16s;
          animation-delay: -16s;
}
.module:nth-child(3) {
  -webkit-animation-delay: -24s;
          animation-delay: -24s;
}
.module:nth-child(3) .circle {
  -webkit-animation-delay: -24s;
          animation-delay: -24s;
}
.module:nth-child(4) {
  -webkit-animation-delay: -32s;
          animation-delay: -32s;
}
.module:nth-child(4) .circle {
  -webkit-animation-delay: -32s;
          animation-delay: -32s;
}
.module:nth-child(5) {
  -webkit-animation-delay: -40s;
          animation-delay: -40s;
}
.module:nth-child(5) .circle {
  -webkit-animation-delay: -40s;
          animation-delay: -40s;
}
.module:nth-child(6) {
  -webkit-animation-delay: -48s;
          animation-delay: -48s;
}
.module:nth-child(6) .circle {
  -webkit-animation-delay: -48s;
          animation-delay: -48s;
}

.circle {
  width: 13em;
  height: 13em;
  border-radius: 50%;
  position: relative;
  border: solid 3px #94AD42;
  background: #051b2a;
}
.circle.is-main {
  background-color: rgba(5, 30, 46, 0.95);
  border-width: 15px;
  width: 16.5em;
  height: 16.5em;
  overflow: hidden;
  pointer-events: all;
  cursor: pointer;
  transition: background 0.2s ease-out, border 0.2s ease-out;
}
.circle.is-main:before {
  content: "";
  background: url("http://innou.io/beta/assets/images/maze-white.png") center;
  background-size: 50%;
  position: absolute;
  animation: spin 48s linear reverse infinite;
  width: 100%;
  height: 100vh;
  opacity: 0.25;
  z-index: 1;
}
.circle.is-main .tittle,
.circle.is-main .subtitle {
  position: relative;
  z-index: 2;
}
.circle.is-main .subtitle {
  margin-top: -1em;
}
.circle.is-main:hover {
  background-color: #ffffff;
  border-color: #ffffff;
}
.circle.is-main:hover .tittle,
.circle.is-main:hover .subtitle {
  color: #94AD42;
}
.circle.is-second {
  background: none;
  border: dotted 1px #05BBC1;
  width: 50em;
  height: 50em;
}
.circle.is-third {
  background: none;
  border: dashed 5px #666666;
  width: 58em;
  height: 56em;
  transition: border-color 0.2s ease-out;
  animation: spin 192s linear infinite reverse;
}
.pause .circle.is-third {
  border-color: #880621;
}

.leaf {
  position: absolute;
  width: 3em;
  height: 3em;
  background: url("https://making-the-web.com/sites/default/files/clipart/156536/green-leaf-icon-156536-2289922.png") no-repeat center;
  background-size: contain;
  transform: rotate(40deg);
  top: 4em;
  right: -4.5em;
  opacity: 0.3;
}

.ellipses {
  transform: rotate(30deg);
}
.ellipses .ellipsis {
  background: url("https://walls4u.co.uk/app/uploads/2018/10/AdobeStock_184585340.jpg");
  position: absolute;
  width: 60em;
  height: 37em;
  border: solid 2px #94AD42;
  opacity: 0.25;
}
.ellipses .ellipsis:nth-child(1) {
  -webkit-animation: spin linear infinite;
          animation: spin linear infinite;
  -webkit-animation-duration: 47s;
          animation-duration: 47s;
  -webkit-animation-delay: -8s;
          animation-delay: -8s;
}
.ellipses .ellipsis:nth-child(2) {
  -webkit-animation: spin linear infinite;
          animation: spin linear infinite;
  -webkit-animation-duration: 46s;
          animation-duration: 46s;
  -webkit-animation-delay: -16s;
          animation-delay: -16s;
}
.ellipses .ellipsis:nth-child(3) {
  -webkit-animation: spin linear infinite;
          animation: spin linear infinite;
  -webkit-animation-duration: 45s;
          animation-duration: 45s;
  -webkit-animation-delay: -24s;
          animation-delay: -24s;
}
.ellipses .circle.is-cover {
  position: absolute;
  background: rgba(6, 33, 51, 0.9);
  border: none;
  width: 42em;
  height: 42em;
}

.modal .modal-card-head {
  background: #94AD42;
  border: none;
  text-transform: uppercase;
}
.modal .modal-card-title {
  color: #ffffff;
}
.modal .modal-card-body,
.modal .modal-card-foot {
  background: #09304a;
  border: none;
  color: #ffffff;
}
.modal .modal-card-body p {
  min-height: 360px;
  max-height: 360px;
  padding-right: 1em;
  padding-bottom: 1em;
  margin: 1em;
  margin-right: 0;
  margin-bottom: 0;
  overflow: auto;
}
.modal .modal-card-body p.is-overflowing {
  border-bottom: solid 4px #880621;
}
.modal .modal-card-foot button {
  background: #94AD42;
  color: #ffffff;
  border: none;
  width: 2.5em;
  height: 2.5em;
}
.modal.is-active .modal-card {
  -webkit-animation: scaleUpBounce 0.2s ease-in-out;
          animation: scaleUpBounce 0.2s ease-in-out;
}

@-webkit-keyframes spin {
  to {
    transform: rotate(1turn);
  }
}

@keyframes spin {
  to {
    transform: rotate(1turn);
  }
}
@-webkit-keyframes scaleUpBounce {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes scaleUpBounce {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

/* WALLET INFOR */
.bank {
  width: 90% !important;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  font-family: 'Ubuntu', sans-serif;
}

.mobile {
  width: 100%;
  height: 95%;
  background-color: white;
  border-radius: 35px;
  padding: 0 20px;
  box-shadow: -3px 2px 19px #dadada;
  padding-bottom: 10px;
}

.profile {
  display: flex;
  align-items: center;
  height: 60px;
  margin: 20px 0;
}

.profile__avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  background-color: #96edc9;
  border-radius: 40%;
  margin: 0;
}

.profile__avatar img {
  width: 50%;
}

.profile__name {
  font-weight: 600;
  margin: 0 20px;
  font-size: 15px;
}

.balance {
  margin: 20px 0;
}

.balance__text {
  font-size: 15px;
  color: #979797;
  margin-bottom: 10px;
}

.balance__amount {
  font-weight: 600;
  font-size: 36px;
}

.walletcard {
  display: flex;
  flex-direction: column;
  background: rgb(48, 188, 238);
  background: linear-gradient(90deg, rgba(48, 188, 238, 1) 0%, rgba(21, 208, 149, 1) 100%);
  margin: 20px 0;
  border-radius: 20px;
  padding: 25px 30px;
}

.card__information {
  margin-bottom: 20px;
  align-items: center;
  justify-content: space-between;
}

.card__information__text {
  font-size: 20px;
  color: #ffffff;
  line-height: 26px;
  font-weight: 600;
  margin-right: 25px;
}

.walletcard__button {
  width: 100%;
  background-color: #ffffff;
  color: #000000;
  border: none;
  border-radius: 10px;
  line-height: 45px;
  font-weight: 600;
  font-size: 16px;
}

.nav {
  display: flex;
}

.wallettabs {
  min-height: 35%;
}

.nav__tab {
  font-size: 16px;
  font-weight: 600;
  color: #979797;
  width: 150px;
  cursor: pointer;
}

.nav__tab--active {
  color: #000000;
}

.indicator {
  display: inline-block;
  background-color: #38c1ff;
  height: 4px;
  border-radius: 20px;
  vertical-align: 3px;
  transform: translate(calc(var(--i)* 150px));
  transition-duration: 0.3s;
}

.walletcontent {
  margin-top: 15px;
  background-color: #29ADB2;
  overflow-y: scroll;
  opacity: 0;
  height: 0;
}

.walletcontent:first-child {
  opacity: 1;
  height: 180px;
  font-size: 14px;
}

.today {
  display: flex;
  justify-content: space-between;
  color: #45474B;
}

.transaction {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
}

.transaction__who {
  display: flex;
  align-items: center;
}

.transaction__who figure {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 40%;
  margin-right: 20px;
}

.transaction__who img {
  width: 40%;
}

.transaction__who__name {
  font-weight: 400;
  color: #31363F;
  margin-bottom: 4px;
}

.transaction__who__type {
  font-size: 13px;
}

.transaction__data__amount {
  font-weight: 600;
  margin-bottom: 4px;
  color: #EEF5FF;
}

.transaction__data__amount--depot {
  color: #58c87e;
}

.transaction__data__date {
  color: #31363F;
  font-size: 13px;
  text-align: right;
}

.transaction__who--apple figure,
.transaction__who--amazon figure {
  background-color: black;
}

.transaction__who--tom figure {
  background-color: #5cdfac;
}

.transaction__who--tom figure img,
.transaction__who--amazon figure img {
  width: 50%;
}

/* WALLET OPTION */

.about {
  position: fixed;
  z-index: 10;
  bottom: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  transition: all 0.2s ease;
}
.about .bg_links {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 100%;
  backdrop-filter: blur(5px);
  position: absolute;
}
.about .logo {
  width: 40px;
  height: 40px;
  z-index: 9;
  background-image: url(https://rafaelalucas91.github.io/assets/codepen/logo_white.svg);
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: 10px 7px;
  opacity: 0.9;
  transition: all 1s 0.2s ease;
  bottom: 0;
  right: 0;
}
.about .social {
  opacity: 0;
  right: 0;
  bottom: 0;
}
.about .social .icon {
  width: 100%;
  height: 100%;
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  display: flex;
  transition: all 0.2s ease, background-color 0.4s ease;
  opacity: 0;
  border-radius: 100%;
}
.about .social.portfolio {
  transition: all 0.8s ease;
}
.about .social.portfolio .icon {
  background-image: url(https://rafaelalucas91.github.io/assets/codepen/link.svg);
}
.about .social.dribbble {
  transition: all 0.3s ease;
}
.about .social.dribbble .icon {
  background-image: url(https://rafaelalucas91.github.io/assets/codepen/dribbble.svg);
}
.about .social.linkedin {
  transition: all 0.8s ease;
}
.about .social.linkedin .icon {
  background-image: url(https://rafaelalucas91.github.io/assets/codepen/linkedin.svg);
}
.about:hover {
  width: 105px;
  height: 105px;
  transition: all 0.6s cubic-bezier(0.64, 0.01, 0.07, 1.65);
}
.about:hover .logo {
  opacity: 1;
  transition: all 0.6s ease;
}
.about:hover .social {
  opacity: 1;
}
.about:hover .social .icon {
  opacity: 0.9;
}
.about:hover .social:hover {
  background-size: 28px;
}
.about:hover .social:hover .icon {
  background-size: 65%;
  opacity: 1;
}
.about:hover .social.portfolio {
  right: 0;
  bottom: calc(100% - 40px);
  transition: all 0.3s 0s cubic-bezier(0.64, 0.01, 0.07, 1.65);
}
.about:hover .social.portfolio .icon:hover {
  background-color: #698fb7;
}
.about:hover .social.dribbble {
  bottom: 45%;
  right: 45%;
  transition: all 0.3s 0.15s cubic-bezier(0.64, 0.01, 0.07, 1.65);
}
.about:hover .social.dribbble .icon:hover {
  background-color: #ea4c89;
}
.about:hover .social.linkedin {
  bottom: 0;
  right: calc(100% - 40px);
  transition: all 0.3s 0.25s cubic-bezier(0.64, 0.01, 0.07, 1.65);
}
.about:hover .social.linkedin .icon:hover {
  background-color: #0077b5;
}

.wrapper {
  width: 100vw;
  margin: 0 auto;
  height: 400px;
  background-color: #161616;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: all 0.3s ease;
}

@media screen and (max-width: 767px) {
  .wrapper {
    height: 700px;
  }
  .bank{
    display: block;
  }
}
.walletoptioncontent {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1024px) {
  .walletoptioncontent {
    padding-top: 300px;
    display: block;
    /* flex-direction: column; */
  }
}
.walletoptioncard {
  width: 100%;
  max-width: 300px;
  min-width: 200px;
  height: 250px;
  background-color: #F1EFEF;
  margin: 10px;
  border-radius: 10px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.24);
  border: 2px solid rgba(7, 7, 7, 0.12);
  font-size: 16px;
  transition: all 0.3s ease;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  transition: all 0.3s ease;
}

.icon {
  margin: 0 auto;
  width: 100%;
  height: 80px;
  max-width: 80px;
  background: linear-gradient(97.3deg, rgba(25, 50, 70, 0.81) 10.7%, rgba(155, 65, 25, 0.72) 39.5%, rgba(255, 192, 0, 0.81) 69.7%);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  transition: all 0.8s ease;
  background-position: 0px;
  background-size: 200px;
}

.material-icons.md-18 {
  font-size: 18px;
}

.material-icons.md-24 {
  font-size: 24px;
}

.material-icons.md-36 {
  font-size: 36px;
}

.material-icons.md-48 {
  font-size: 48px;
}

.walletoptioncard .title {
  width: 100%;
  margin: 0;
  text-align: center;
  margin-top: 30px;
  color: black;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 4px;
}

.walletoptioncard .text {
  width: 80%;
  margin: 0 auto;
  font-size: 13px;
  text-align: center;
  margin-top: 20px;
  color: black;
  font-weight: 200;
  letter-spacing: 2px;
  opacity: 0;
  max-height: 0;
  transition: all 0.3s ease;
}

.walletoptioncard:hover {
  height: 270px;
}

.walletoptioncard:hover .info {
  height: 90%;
}

.walletoptioncard:hover .text {
  transition: all 0.3s ease;
  opacity: 1;
  max-height: 40px;
}

.walletoptioncard:hover .icon {
  background-position: -120px;
  transition: all 0.3s ease;
}

.walletoptioncard:hover .icon i {
  background: linear-gradient(90deg, #FF7E7E, #FF4848);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  opacity: 1;
  transition: all 0.3s ease;
}

/* HOUSEFIN */
.housefindashboard {
  width: 100%;
  display: flex;
  padding: 25px;
  background: #0c0c0c83;
  border-radius: 20px;
}
.housefindashboard .dashsidebar {
  border-radius: 20px;
  width: 250px;
  margin-right: 30px;
  padding: 20px 0px;
  height: max-content;
  position: sticky;
  top: 50px;
  background-color: #fcf5ed1b;
}
.housefindashboard .dashsidebar .logo {
  font-size: 20px;
  font-weight: bolder;
  text-align: center;
  margin-bottom: 25px;
}
.housefindashboard .dashsidebar .dashmenu .ulist .liitem {
  position: relative;
  display: block;
  font-size: 17px;
  font-weight: 700;
  color: #555558;
  padding: 20px 30px;
  cursor: pointer;
  margin-bottom: 5px;
}
.housefindashboard .dashsidebar .dashmenu .ulist .liitem:hover {
  background:#31363F !important;
  color: #eeba45;
}
.housefindashboard .dashsidebar .dashmenu .ulist .liitem:hover i {
  color: #eeba45;
}
.housefindashboard .dashsidebar .dashmenu .ulist .liitem:hover:after {
  content: "";
  height: 100%;
  width: 4px;
  background: #ecb432;
  margin-left: auto;
  display: inline-block;
  position: absolute;
  right: 0;
  top: 0;
  transition: 300ms all;
}
.housefindashboard .dashsidebar .dashmenu .ulist .liitem i {
  font-size: 20px;
  margin-right: 15px;
}
.housefindashboard .content {
  flex: 1;
}
.housefindashboard .content .searchbar {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.housefindashboard .content .searchbar i {
  padding-left: 22px;
  position: absolute;
  color: white;
}
.housefindashboard .content .searchbar i.fa-bell {
  right: 80px;
  font-size: 25px;
}
.housefindashboard .content .searchbar img {
  right: 15px;
  border-radius: 50%;
  position: absolute;
}
.housefindashboard .content .searchbar input {
  border-radius: 15px;
  color: white;
  font-weight: 500;
  background-color: #fcf5ed1b;
  font-size: 18px;
  width: 100%;
  padding: 20px 55px;
}
.housefindashboard .content .items {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.housefindashboard .content .items .itemgoogle {
  margin-top: 25px;
  background: #323232df;
  width: 30%;
  height: fit-content;
  border-radius: 15px;
  padding: 20px;
}
.housefindashboard .content .items .itemgoogle .itemheader {
  align-items: center;
  display: flex;
}
.housefindashboard .content .items .itemgoogle .itemheader .logo i.fa-google {
  width: 50px;
  height: 50px;
  font-size: 26px;
  color: #DB4437;
  display: grid;
  place-items: center;
  background: #eed5c9;
  border-radius: 15px;
  margin-right: 15px;
}
.housefindashboard .content .items .itemgoogle .itemheader .brand h3 {
  color: #232a3d;
}
.housefindashboard .content .items .itemgoogle .itemheader .brand h5 {
  color: #999cab;
}
.housefindashboard .content .items .itemgoogle .itemheader i.fa-ellipsis-v {
  color: #767b8e;
  margin-left: auto;
}
.housefindashboard .content .items .itemgoogle .itemcontent .importants {
  margin-top: 30px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}
.housefindashboard .content .items .itemgoogle .itemcontent .importants a {
  background: #fff7e6;
  color: #ff7b74;
  padding: 10px 15px;
  font-size: 13px;
  border: 1px solid #ffaea9;
  border-radius: 5px;
}
.housefindashboard .content .items .itemgoogle .itemcontent .importants a.ilk {
  background: #fff7e6;
  border-radius: none;
  border: none;
  color: #ffb01b;
  padding: 10px;
  font-size: 13px;
  font-weight: bolder;
}
.housefindashboard .content .items .itemgoogle .itemcontent .taskdone {
  color: #a8abb7;
}
.housefindashboard .content .items .itemgoogle .itemcontent .taskdone span {
  color: #444;
}
.housefindashboard .content .items .itemgoogle .itemcontent .taskdone .progresbar {
  width: 100%;
  height: 6px;
  margin-top: 15px;
  border-radius: 10px;
  overflow: hidden;
  background: #e9ebf3;
}
.housefindashboard .content .items .itemgoogle .itemcontent .taskdone .progresbar .inner {
  width: 70%;
  height: 100%;
  border-radius: 10px;
  background: #545dad;
}
.housefindashboard .content .items .itemgoogle .itemcontent .importans2 {
  margin-bottom: 20px;
  margin-top: 15px;
  display: flex;
}
.housefindashboard .content .items .itemgoogle .itemcontent .importans2 a.ilk {
  background: #f0f8ef;
  color: #79bd72;
  padding: 10px 15px;
  font-size: 13px;
  color: #77bc70;
  font-weight: bolder;
  border-radius: 5px;
  margin-right: 10px;
}
.housefindashboard .content .items .itemgoogle .itemcontent .importans2 a {
  background: #e6f2ff;
  padding: 10px 15px;
  font-size: 13px;
  font-weight: bolder;
  border-radius: 5px;
  margin-right: 10px;
}
.housefindashboard .content .items .itemgoogle .itemcontent .users {
  padding: 0 0 0 10px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}
.housefindashboard .content .items .itemgoogle .itemcontent .users img {
  border-radius: 50%;
  border: 3px solid #fff;
  margin-left: -10px;
}
.housefindashboard .content .items .itemgoogle .itemcontent .users div.more {
  display: grid;
  place-items: center;
  border: 3px solid #fff;
  background: #fff;
  font-weight: bolder;
  color: #8f93a2;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  margin-left: -10px;
}
.housefindashboard .content .items .itemgoogle .itemcontent .importants3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.housefindashboard .content .items .itemgoogle .itemcontent .importants3 a {
  background: #fff1eb;
  color: #fd9965;
  padding: 10px 15px;
  font-size: 13px;
  font-weight: bolder;
  border-radius: 5px;
  margin-right: 10px;
}
.housefindashboard .content .items .itemgoogle .itemcontent .importants3 i {
  color: #ffa700;
  padding: 10px;
  border: 1px solid #e2e2e3;
  border-radius: 50%;
}
.housefindashboard .content .items .itemtask {
  margin-top: 25px;
  background: #ffc55a;
  border-radius: 15px;
  padding: 20px;
  height: fit-content;
  width: 30%;
}
.housefindashboard .content .items .itemtask .itemhead span {
  color: #8c8d97;
}
.housefindashboard .content .items .itemtask .tasks {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}
.housefindashboard .content .items .itemtask .tasks .taskitem {
  font-weight: 600;
  display: flex;
  padding: 12px 0;
  color: #444;
  cursor: pointer;
  font-size: 15px;
  border-bottom: 1px solid #a1a1a154;
  justify-content: space-between;
}
.housefindashboard .content .items .itemcalendar {
  margin-top: 25px;
  width: 30%;
  border-radius: 15px;
  padding: 20px;
  background: #FFC55A;
}
.housefindashboard .content .items .itemcalendar .itemhead {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.housefindashboard .content .items .itemcalendar .itemhead h3 {
  font-size: 23px;
}
.housefindashboard .content .items .itemcalendar .itemhead .arrows i {
  cursor: pointer;
  color: #FC4100;
  font-size: 23px;
}
.housefindashboard .content .items .itemcalendar .itemhead .arrows i:first-child {
  margin-right: 5px;
}
.housefindashboard .content .items .itemcalendar .takvim {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
}
.housefindashboard .content .items .itemcalendar .takvim .item {
  color: #3d4353;
  font-weight: bold;
  width: 14.23%;
}
.housefindashboard .content .items .itemcalendar .takvim .days {
  cursor: pointer;
  margin: 15px 0 0;
  width: 14.23%;
  text-align: center;
  padding: 10px;
  font-weight: 500;
}
.housefindashboard .content .items .itemcalendar .takvim .days.old {
  color: #d4dade;
}
.housefindashboard .content .items .itemcalendar .takvim .days.select {
  background: #ecb22e;
  border-radius: 50%;
  color: #fff;
}
.housefindashboard .content .items .itemgoogle.slack .itemheader .logo i {
  width: 50px;
  height: 50px;
  font-size: 26px;
  color: #444;
  display: grid;
  place-items: center;
  background: #fff;
  border-radius: 15px;
  margin-right: 15px;
}
.housefindashboard .content .items .itemgoogle.slack .itemcontent .importants a.ilk {
  background: #e0ecdb;
  color: #70b868;
}
.housefindashboard .content .items .itemgoogle.slack .itemcontent .importants a {
  background: #fff;
  border: 1px solid #70b868;
  color: #70b868;
}
.housefindashboard .content .items .itemgoogle.slack .itemcontent .taskdone .progresbar .inner {
  background: #e257b3;
}
.housefindashboard .content .items .itemvideos {
  margin-top: 25px;
  background: #393E46;
  width: 30%;
  height: fit-content;
  border-radius: 15px;
  padding: 20px;
}
.housefindashboard .content .items .itemvideos .itemheads .dashtitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.housefindashboard .content .items .itemvideos .itemheads .dashtitle i {
  font-size: 25px;
}

::placeholder {
  color: #787778;
}

::-webkit-scrollbar {
  width: 10px;
  background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
  background-color: #ff7c00;
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.2) 75%, transparent 75%, transparent);
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #F5F5F5;
}

@media (max-width: 768px) {
  .items {
    flex-direction: column;
  }

  .itemgoogle, .itemtask, .itemcalendar, .itemvideos {
    width: 100% !important;
  }
}


/* Get Megajoules */
div.container {
  position: relative;
  margin: 70px auto;
  width: 350px;
  text-align: center;
  background: #fff;
  -moz-box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.1), 0px 60px 75px -25px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.1), 0px 60px 75px -25px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.1), 0px 60px 75px -25px rgba(0, 0, 0, 0.2);
  cursor: default;
}
div.container img.img-fruit {
  margin-top: -60px;
}
div.container div.control-btn span {
  position: absolute;
  margin-top: 20px;
  width: 35px;
  height: 35px;
  background: orange;
  color: #fff;
  font-size: 13px;
  /* display: inline-block; */
  line-height: 36px;
  cursor: pointer;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  -moz-box-shadow: 0px 2px 7px rgba(241, 44, 51, 0.7);
  -webkit-box-shadow: 0px 2px 7px rgba(241, 44, 51, 0.7);
  box-shadow: 0px 2px 7px rgba(241, 44, 51, 0.7);
  -moz-transition: background 0.3s ease-in;
  -o-transition: background 0.3s ease-in;
  -webkit-transition: background 0.3s ease-in;
  transition: background 0.3s ease-in;
}
div.container div.control-btn span:hover {
  background: orange;
}
div.container span.prev {
  left: -17.5px;
}
div.container span.next {
  right: -17.5px;
}
div.container h3 {
  font-size: 30px;
  letter-spacing: 3px;
  margin-top: 35px;
  color: orange;
}
div.container p {
  font-size: 11px;
  padding: 15px 45px;
  color: #878787;
  line-height: 22px;
}
div.container div.properties {
  margin-top: 20px;
  height: 130px;
}
div.container div.properties:before, div.container div.properties:after {
  content: " ";
  display: inline-block;
}
div.container div.properties:after {
  clear: both;
}
div.container div.properties div {
  display: inline-block;
  text-align: center;
  width: 33.33%;
  float: left;
}
div.container div.properties div h4 {
  font-size: 14px;
  color: #757575;
  padding: 25px 0px;
}
div.container div.properties .qty .fa {
  font-size: 7px;
  color: #757575;
  background: #dfdfdf;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  padding: 4px;
  cursor: pointer;
}
div.container div.properties .qty .number {
  font-size: 11px;
  color: #757575;
  padding: 5px;
}
div.container div.properties .price .fa {
  font-size: 18px;
  color: #757575;
}
div.container div.properties .price .amount {
  color: #646363;
  font-size: 18px;
  padding: 0 5px;
}
div.container div.properties .delivery .fa {
  font-size: 15px;
  color: #757575;
}
div.container div.properties .delivery .time {
  display: inline-block;
  color: #757575;
  font-size: 11px;
}
div.container .ip-add-cart {
  border: none;
  padding: 12px 50px;
  background: black;
  margin-bottom: 15px;
  color: #fff;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
  -moz-border-radius: 25px;
  -webkit-border-radius: 25px;
  border-radius: 25px;
  -moz-box-shadow: 0px 15px 45px rgba(242, 45, 52, 0.5);
  -webkit-box-shadow: 0px 15px 45px rgba(242, 45, 52, 0.5);
  box-shadow: 0px 15px 45px rgba(242, 45, 52, 0.5);
  cursor: pointer;
  -moz-transition: background 0.3s ease-in;
  -o-transition: background 0.3s ease-in;
  -webkit-transition: background 0.3s ease-in;
  transition: background 0.3s ease-in;
}
div.container .ip-add-cart:hover {
  background: #da1920;
}

/* Start Credit Style */
div.credit {
  position: absolute;
  padding: 0px 10px;
  padding-bottom: 8px;
  height: 15px;
  right: 10px;
  top: 10px;
  display: inline-block;
  background: rgba(255, 255, 255, 0.7);
  font-size: 7px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.7);
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 10px 15px -15px rgba(0, 0, 0, 0.3);
  cursor: default;
}
div.credit:hover {
  border: 1px solid rgba(0, 0, 0, 0.2);
}
div.credit img {
  position: relative;
  width: 14px;
  margin-left: 3px;
  top: 4px;
}
