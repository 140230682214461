.listCont {
  font-family: sans-serif;
  font-size: 18px;
  text-align: center;
}
.search{
  color: black
}
.holder {
  width: 50%;
  margin: 50px auto 20px;
  color: "white";
  padding: 20px;
}

ul {
  list-style: none;
}

ul li {
  font-size: 18px;
  overflow: hidden;
  padding-bottom: 20px;
}

ul li:last-child {
  margin-bottom: 0;
}

.listImage {
  float: left;
  display: block;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-right: 10px;
}

span {
  /* display: block; */
  width: 100%;
  /* font-size: 18px; */
  font-weight: bold;
}

span.phone {
  font-weight: normal;
  font-style: italic;
  font-size: 18px;
  color: #999;
}
