.gpt3__possibility {
    display: flex;
    flex-direction: row;
    margin-left: auto;
    margin-right: auto;
    width: 92%;
}

.gpt3__possibility-image {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    margin-right: 2rem;
}

.gpt3__possibility-image img {
    width: 100%;
    height: 100%;
}

.gpt3__possibility-content {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex-direction: column;
    box-shadow: rgba(48, 47, 36, 0.885) 0px 3px 6px, rgba(80, 79, 61, 0.775) 0px 3px 6px;
}

.gpt3__possibility-content h4 {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: #F9E2AF;
}

.gpt3__possibility-content h4:last-child {
    color: #ff8A71;
}

.gpt3__possibility-content h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 34px;
    line-height: 45px;
    margin: 1rem 0;
}

.gpt3__possibility-content p {
    color: var(--color-text);
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;

    margin-bottom: 2rem;
}

@media screen and (max-width: 950px) {
    .gpt3__possibility {
        flex-direction: column;
    }

    .gpt3__possibility-image {
        margin: 1rem 0;
    }

    .gpt3__possibility-image img {
        width: unset;
        height: unset;
    }

    .gpt3__possibility-content {
        margin-top: 2rem;
    }
}

@media screen and (max-width: 700px) {
    .gpt3__possibility-image img {
        width: 100%;
        height: 100%;
    }
}

/* Button */

.wrapb {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .buttonb {
    width: 140px;
    height: 45px;
    font-family: 'Roboto', sans-serif;
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 2.5px;
    font-weight: 500;
    color: #000;
    background-color: #fff;
    border: none;
    border-radius: 45px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
    }
  
  .buttonb:hover {
    background-color: #2EE59D;
    box-shadow: 0px 15px 20px rgba(46, 229, 157, 0.4);
    color: #fff;
    transform: translateY(-7px);
  }

  .slider::-webkit-scrollbar {
    display: none;
 }

 .slider{
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }