@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');
/* OPT CSS */
@tailwind base;
@tailwind components;
@tailwind utilities;

.react-tel-input .form-control {
  width: 60%;
  /* padding: 1.5rem 0px; */
  margin-left: 30px !important;
  font-size: 1rem;
  line-height: 1.5rem;
}
.react-tel-input .selected-flag .arrow{
  background: transparent;
}
.react-tel-input .country-list .country-name {
  /* display: none !important; */
  color: #040C18 !important;
}

.react-tel-input .country-list .country {
  padding: 7px 9px;
  display: block;
}
.opt-container {
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
  margin-left: 30px;
  /* width: 74%; */
  color: #FF731D !important;
}

.opt-container input {
  flex: 1 1 0%;
  padding-top: 1.25rem;
  font-size: 14px !important;
  /* 20px */
  padding-bottom: 1.25rem;
  /* 20px */
  outline: 2px solid transparent;
  color: orange !important;
  outline-offset: 2px;
  border: 1px solid orange !important;
  width: 200px;
}

.opt-container input:focus{
  border: 1px solid white !important;
}

.box input {
  border: 1px solid white !important;
}
:root {
  --font-family: 'Manrope', sans-serif;

  /* --gradient-text:    linear-gradient(89.97deg, #EEEEEE 1.84%, #CF0A0A 102.67%); */
  --gradient-text: #FFB100;
  --gradient-bar: linear-gradient(103.22deg, #FF731D -13.86%, #EAEAEA 99.55%);

  --color-bg: #040C18;
  --color-footer: linear-gradient(97.3deg, rgba(25, 50, 70, 0.81) 10.7%, rgba(155, 65, 25, 0.72) 39.5%, rgba(255, 192, 0, 0.81) 69.7%);
  --color-blog: #042c54;
  --color-text: #EAEAEA;
  --color-subtext: #EAEAEA;
}