/* SLIDER */
.bdt-timeline-container {
  display: flow-root;
  box-sizing: content-box;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  overflow: hidden;
  margin-top: 50px;
}

.upk-salf-slider-wrapper {
  display: flex;
  flex-direction: row;
  background: #FFC94A;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
  height: 400px;
  padding: 30px;
  position: relative;
}

.upk-salf-slider-wrapper .upk-salf-item {
  position: relative;
  display: flex;
  flex-direction: row-reverse;
}

.upk-salf-slider-wrapper .upk-salf-item.swiper-slide-active .upk-salf-title,
.upk-salf-slider-wrapper .upk-salf-item.swiper-slide-active .upk-salf-desc,
.upk-salf-slider-wrapper .upk-salf-item.swiper-slide-active .upk-salf-button {
  opacity: 1;
}

.upk-salf-slider-wrapper .upk-salf-item .upk-salf-image-wrap {
  height: 100%;
  width: 100%;
}

.upk-salf-slider-wrapper .upk-salf-item .upk-xanc-img {
  display: block;
  width: 100% !important;
  max-width: 100% !important;
  height: 800px;
  -o-object-fit: cover;
  object-fit: cover;
}

.upk-salf-slider-wrapper .upk-salf-item .upk-salf-content-wrap {
  position: absolute;
  left: 0;
  bottom: 0;
  top: unset;
  max-width: 460px;
  overflow: hidden;
  background: #ffffff8a;
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  transition: -webkit-backdrop-filter 0.9s;
  transition: backdrop-filter 0.9s;
  transition: backdrop-filter 0.9s, -webkit-backdrop-filter 0.9s;
  padding: 20px;
  padding-left: 20px;
}

.upk-salf-slider-wrapper .upk-salf-item .upk-salf-title {
  font-size: 23px;
  font-weight: 700;
  line-height: 1.2;
  color: #2B2D42;
  text-transform: capitalize;
  margin-bottom: 20px;
  opacity: 0;
}

.upk-salf-slider-wrapper .upk-salf-item .upk-salf-desc {
  color: #656565;
  font-size: 14px;
  line-height: 1.6;
  text-transform: capitalize;
  margin-bottom: 20px;
  opacity: 0;
}

.upk-salf-slider-wrapper .upk-salf-item .upk-salf-button {
  opacity: 0;
}

.upk-salf-slider-wrapper .upk-salf-item .upk-salf-button .link {
  color: #2b2d42;
  cursor: pointer;
  font-weight: 500;
  text-decoration: none;
  text-transform: capitalize;
  font-size: 14px;
  transition: all 0.3s ease;
}

.upk-salf-slider-wrapper .upk-salf-item .upk-salf-button .link:hover {
  color: #ff215a;
}

.upk-salf-slider-wrapper .upk-salf-item .upk-salf-button .link--arrowed {
  display: inline-block;
}

.upk-salf-slider-wrapper .upk-salf-item .upk-salf-button .link--arrowed .arrow-icon {
  position: relative;
  top: 0;
  transition: transform 0.3s ease;
  vertical-align: middle;
}

.upk-salf-slider-wrapper .upk-salf-item .upk-salf-button .link--arrowed .arrow-icon--circle {
  transition: stroke-dashoffset 0.3s ease;
  stroke-dasharray: 95;
  stroke-dashoffset: 95;
}

.upk-salf-slider-wrapper .upk-salf-item .upk-salf-button .link--arrowed g {
  stroke: currentColor;
  color: #2b2d42;
}

.upk-salf-slider-wrapper .upk-salf-item .upk-salf-button .link--arrowed:hover .arrow-icon {
  transform: translate3d(5px, 0, 0);
}

.upk-salf-slider-wrapper .upk-salf-item .upk-salf-button .link--arrowed:hover .arrow-icon--circle {
  stroke-dashoffset: 0;
}

.upk-salf-slider-wrapper .upk-salf-item .upk-salf-button .link--arrowed:hover g {
  color: #ff215a;
}

.upk-salf-slider-wrapper .upk-page-scroll {
  position: absolute;
  bottom: 8%;
  right: 5%;
  transform: rotate(90deg);
  z-index: 1;
  display: none;
}

.upk-salf-slider-wrapper .upk-page-scroll .arrow-up {
  height: 70px;
  width: 70px;
  display: block;
  background: #ffffffc9;
  -webkit-backdrop-filter: blur(18px);
  backdrop-filter: blur(18px);
  position: relative;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.25, 1.7, 0.35, 1.5);
  transform: rotate(-90deg);
  overflow: hidden;
}

.upk-salf-slider-wrapper .upk-page-scroll .arrow-slide {
  left: 0;
  top: -100%;
  width: 100%;
  height: 100%;
  background: #ff215a;
  position: absolute;
  display: block;
  z-index: 0;
}

.upk-salf-slider-wrapper .upk-page-scroll .long-arrow-left {
  display: block;
  margin: 30px auto;
  width: 16px;
  height: 16px;
  border-top: 2px solid #2b2d42;
  border-left: 2px solid #2b2d42;
}

.upk-salf-slider-wrapper .upk-page-scroll .long-arrow-left {
  transform: rotate(-135deg);
}

.upk-salf-slider-wrapper .upk-page-scroll .long-arrow-left::after {
  content: "";
  display: block;
  width: 2px;
  height: 25px;
  background-color: #2b2d42;
  transform: rotate(-45deg) translate(8px, 3px);
  left: 0;
  top: 0;
}

.upk-salf-slider-wrapper .upk-page-scroll .arrow-up:hover {
  transition: all 0.1s;
}

.upk-salf-slider-wrapper .upk-page-scroll .arrow-up:hover .left-arm:after {
  transform: rotate(-10deg);
}

.upk-salf-slider-wrapper .upk-page-scroll .arrow-up:hover .right-arm:after {
  transform: rotate(10deg);
}

.upk-salf-slider-wrapper .upk-page-scroll .arrow-up:hover .arrow-slide {
  transition: all 0.5s ease-in-out;
  transform: translateY(200%);
}

.upk-salf-slider-wrapper .upk-salf-nav-pag-wrap {
  /* position: absolute; */
  top: 0;
  height: 100%;
  right: 0;
}

.upk-salf-slider-wrapper .upk-salf-navigation {
  margin-top: 40px;
  margin-right: 2px;
}

.upk-salf-slider-wrapper .upk-salf-navigation .link--arrowed {
  display: inline-block;
}

.upk-salf-slider-wrapper .upk-salf-navigation .link--arrowed .arrow-icon {
  position: relative;
  top: 0;
  transition: transform 0.3s ease;
  vertical-align: middle;
}

.upk-salf-slider-wrapper .upk-salf-navigation .link--arrowed .arrow-icon--circle {
  transition: stroke-dashoffset 0.3s ease;
  stroke-dasharray: 95;
  stroke-dashoffset: 95;
}

.upk-salf-slider-wrapper .upk-salf-navigation .link--arrowed g {
  stroke: currentColor;
  color: #2b2d42;
}

.upk-salf-slider-wrapper .upk-salf-navigation .link--arrowed:hover .arrow-icon {
  transform: translate3d(5px, 0, 0);
}

.upk-salf-slider-wrapper .upk-salf-navigation .link--arrowed:hover .arrow-icon--circle {
  stroke-dashoffset: 0;
}

.upk-salf-slider-wrapper .upk-salf-navigation .link--arrowed:hover g {
  color: #ff215a;
}

.upk-salf-slider-wrapper .upk-salf-navigation .upk-button-next {
  margin-top: 15px;
  transform: rotate(90deg);
}

.upk-salf-slider-wrapper .upk-salf-navigation .upk-button-prev {
  transform: rotate(-90deg);
}

.upk-salf-slider-wrapper .upk-salf-pagi-wrap {
  position: absolute;
  top: 43%;
  right: 0;
  margin-right: 22px;
}

@-webkit-keyframes progress {
  0% {
    stroke-dashoffset: 75;
    opacity: 1;
  }

  95% {
    stroke-dashoffset: 0;
    opacity: 1;
  }

  100% {
    opacity: 0;
    stroke-dashoffset: 0;
  }
}

@keyframes progress {
  0% {
    stroke-dashoffset: 75;
    opacity: 1;
  }

  95% {
    stroke-dashoffset: 0;
    opacity: 1;
  }

  100% {
    opacity: 0;
    stroke-dashoffset: 0;
  }
}

.upk-salf-slider-wrapper .upk-salf-pagi-wrap .swiper-pagination-bullet {
  background-color: transparent;
  opacity: 0.8;
}

.upk-salf-slider-wrapper .upk-salf-pagi-wrap .swiper-pagination-bullet--svg-animation {
  width: 20px;
  height: 20px;
  margin: 6px -7px;
  display: inline-block;
}

.upk-salf-slider-wrapper .upk-salf-pagi-wrap .swiper-pagination-bullet--svg-animation svg {
  transform: rotate(-90deg);
}

.upk-salf-slider-wrapper .upk-salf-pagi-wrap .swiper-pagination-bullet--svg-animation .svg__circle-inner {
  stroke: #2b2d42;
  fill: transparent;
  transition: all 0.3s ease;
}

.upk-salf-slider-wrapper .upk-salf-pagi-wrap .swiper-pagination-bullet-active .svg__circle {
  stroke: #ff215a;
  stroke-dasharray: 75;
  stroke-dashoffset: 0;
  -webkit-animation: progress 4s ease-in-out 1 forwards;
  animation: progress 4s ease-in-out 1 forwards;
}

.upk-salf-slider-wrapper .upk-salf-pagi-wrap .swiper-pagination-bullet-active .svg__circle-inner {
  fill: #2b2d42;
  stroke: #ff215a;
}

@media (min-width: 768px) {
  .upk-salf-slider-wrapper {
    height: 550px;
    padding: 60px;
  }

  .upk-salf-slider-wrapper .upk-page-scroll {
    bottom: 4%;
    display: inherit;
  }

  .upk-salf-slider-wrapper .upk-salf-item .upk-salf-title {
    font-size: 40px;
  }

  .upk-salf-slider-wrapper .upk-salf-item .upk-salf-content-wrap {
    max-width: 400px;
    padding: 40px;
    padding-left: 0;
    top: 50%;
    transform: translateY(-50%);
    bottom: unset;
  }

  .upk-salf-slider-wrapper .upk-salf-item .upk-salf-image-wrap {
    width: 80%;
  }

  .upk-salf-slider-wrapper .upk-salf-navigation {
    margin-top: 60px;
    margin-right: 20px;
  }

  .upk-salf-slider-wrapper .upk-salf-pagi-wrap {
    margin-right: 47px;
  }

  .upk-salf-slider-wrapper .upk-salf-pagi-wrap .swiper-pagination-bullet--svg-animation {
    margin: 6px 0;
  }
}

@media (min-width: 1024px) {
  .upk-salf-slider-wrapper {
    height: 650px;
    padding: 70px;
  }

  .upk-salf-slider-wrapper .upk-page-scroll {
    bottom: 9%;
  }

  .upk-salf-slider-wrapper .upk-salf-item .upk-salf-title {
    font-size: 50px;
  }

  .upk-salf-slider-wrapper .upk-salf-item .upk-salf-content-wrap {
    max-width: 460px;
    padding: 50px;
    padding-left: 0;
  }

  .upk-salf-slider-wrapper .upk-salf-item .upk-salf-desc {
    font-size: 16px;
  }

  .upk-salf-slider-wrapper .upk-salf-item .upk-salf-button .link {
    font-size: 16px;
  }

  .upk-salf-slider-wrapper .upk-salf-item .upk-salf-image-wrap {
    width: 70%;
  }

  .upk-salf-slider-wrapper .upk-salf-navigation {
    margin-top: 85px;
    margin-right: 30px;
  }

  .upk-salf-slider-wrapper .upk-salf-pagi-wrap {
    margin-right: 53px;
  }

  .upk-salf-slider-wrapper .upk-salf-pagi-wrap .swiper-pagination-bullet--svg-animation {
    margin: 6px -5px;
  }
}

@media (min-width: 1440px) {
  .upk-salf-slider-wrapper {
    height: 700px;
    padding: 80px;
  }

  .upk-salf-slider-wrapper .upk-salf-item .upk-salf-title {
    font-size: 55px;
  }
}

.button {
  background: #f00;
  padding: 18px 20px;
  text-transform: uppercase;
  margin-top: 50px;
  margin-bottom: 50px;
  display: inline-block;
  text-decoration: none;
  font-weight: 700;
  font-size: 15px;
  color: #fff;
}

/* SLIDER */

/*SERVICES */
.servicecard-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.servicecard {
  overflow: visible;
  width: 300px;
  height: 320px;
  margin: 10px;
}

.servicecontent {
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 300ms;
  box-shadow: 0px 0px 10px 1px #000000ee;
  border-radius: 5px;
}

.servicefront,
.serviceback {
  background-color: #151515;
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  border-radius: 5px;
  overflow: hidden;
}

.serviceback {
  width: 100%;
  height: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.serviceback::before {
  position: absolute;
  content: ' ';
  display: block;
  width: 160px;
  height: 160%;
  background: linear-gradient(90deg, transparent, #ff9966, #ff9966, #ff9966, #ff9966, transparent);
  animation: rotation_481 5000ms infinite linear;
}

.serviceback-content {
  position: absolute;
  width: 99%;
  height: 99%;
  background-color: #151515;
  border-radius: 5px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.servicecard:hover .servicecontent {
  transform: rotateY(180deg);
}

@keyframes rotation_481 {
  0% {
    transform: rotateZ(0deg);
  }

  0% {
    transform: rotateZ(360deg);
  }
}

.servicefront {
  transform: rotateY(180deg);
  background-image: url('https://img.freepik.com/free-vector/abstract-black-gold-luxury-background_361591-4205.jpg?t=st=1713879190~exp=1713882790~hmac=cce9d1b84c8a1f8ea4e576930fe0edbadaa6fddf9ab5824d5e890a86a57d2554&w=360');
  background-size: cover;
  background-position: center;
  color: white;
}

.servicefront .servicefront-content {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  /* Center align text */
}

.servicefront-content .servicebadge {
  background-color: #00000055;
  padding: 2px 10px;
  border-radius: 10px;
  backdrop-filter: blur(2px);
  width: fit-content;
  margin-top: 20px;
  /* Add top margin */
}

.servicedescription {
  box-shadow: 0px 0px 10px 5px #00000088;
  width: 100%;
  padding: 10px;
  background-color: #00000099;
  backdrop-filter: blur(5px);
  border-radius: 5px;
}

.servicetitle {
  max-width: 100%;
  display: flex;
  justify-content: space-around;
}

.servicetitle p {
  width: 90%;
  font-size: 15px;
}

.servicebutton-container {
  text-align: center;
  margin-top: 0px;
  margin-bottom: 40px;
}

.servicecard-button {
  font-family: 'Montserrat', sans-serif;
  text-decoration: none;
  padding: 8px 16px;
  border: 1px solid #ffffff;
  color: #ffffff;
  transition: background-color 0.3s;
  background-color: transparent;
}

.servicecard-button:hover {
  background-color: #FFC94A;
  color: #151515;
}

.servicefront .serviceimg {
  position: absolute;
  width: 100px !important;
  object-fit: cover;
  object-position: center;
}

.servicecircle {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  background-color: #ffbb66;
  position: relative;
  filter: blur(15px);
  animation: floating 2600ms infinite linear;
}

#servicebottom {
  background-color: #ff8866;
  left: 50px;
  top: 0px;
  width: 150px;
  height: 150px;
  animation-delay: -800ms;
}

#serviceright {
  background-color: #ff2233;
  left: 160px;
  top: -80px;
  width: 30px;
  height: 30px;
  animation-delay: -1800ms;
}

@keyframes floating {
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(0px);
  }
}

@media (max-width: 768px) {
  .servicecard-container {
    flex-direction: column;
    align-items: center;
    margin-top: 45rem;
    margin-bottom: 45rem;
  }

  .servicecard {
    margin: 10px 0;
    /* Add some margin to separate the stacked cards */
    min-width: 300px;
    /* Set a minimum width for each card */
    min-height: 400px;
    /* Set a minimum height for each card */
  }
}

/*SERVICES */






/*TABS*/
.tabs {
  width: 80%;
  height: 100px;
  margin: auto;
  margin-top: 50px;
  display: flex;
  align-items: center;
  box-shadow: 10px 10px 19px #1c1e22, -10px -10px 19px #262a2e;
  overflow: hidden;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.tabs li {
  background: white;
  padding-top: 30px;
  padding-left: 15px;
  width: 25%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  font-family: 'Poppins', sans-serif;
  transition: .5s;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  -ms-transition: .5s;
  -o-transition: .5s;
  cursor: pointer;
}

.tabs li:hover {
  background: #1c1e22;
  box-shadow: 10px 10px 19px #1c1e22, -10px -10px 19px #262a2e;
  color: yellow;
  position: relative;
  z-index: 1;
  border-radius: 10px;
}

.active {
  background: #1c1e22;
  color: white;
  box-shadow: 10px 10px 19px #1c1e22, -10px -10px 19px #262a2e;
  position: relative;
  z-index: 1;
  border-radius: 10px;
}

.contents {
  width: 80%;
  margin: auto;
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 30px;
}

.boxxxx {
  gap: 20px;
  background: linear-gradient(97.3deg, rgba(25, 50, 70, 0.81) 10.7%, rgba(250, 81, 9, 0.72) 39.5%, rgba(255, 192, 0, 0.81) 69.7%);
  box-shadow: 10px 10px 19px #1c1e22, -10px -10px 19px #262a2e;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  padding: 20px;
  width: 100%;
  animation: moving 1s ease;
  -webkit-animation: moving 1s ease;
}

.boxxxx img {
  width: 50%;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.boxxxx h3 {
  color: #c4cfde;
  font-family: 'Poppins', sans-serif;
  font-size: 2rem;
  margin-bottom: 20px;
}

.boxxxx p {
  color: black;
  opacity: .5;
  font-family: 'Poppins', sans-serif;
  font-size: 1rem;
}

.show {
  display: flex;
}

.hide {
  display: none;
}

@keyframes moving {
  from {
    transform: translateX(-50px);
    -webkit-transform: translateX(-50px);
    -moz-transform: translateX(-50px);
    -ms-transform: translateX(-50px);
    -o-transform: translateX(-50px);
    opacity: 0;
  }

  to {
    transform: translateX(0px);
    -webkit-transform: translateX(0px);
    -moz-transform: translateX(0px);
    -ms-transform: translateX(0px);
    -o-transform: translateX(0px);
    opacity: 1;
  }
}

/* SERVICES */

.viewport{
  position:relative;
  /* background: linear-gradient( 97.3deg,  rgba(25,50,70,0.81) 10.7%, rgba(250, 81, 9, 0.72) 39.5%, rgba(255,192,0,0.81) 69.7% ); */
  background-size: 200% 200%;
  height: auto;
  width:96%;
  margin-left: auto;
  margin-right: auto ;
  -webkit-animation:   AnimationName 10s ease infinite;
-moz-animation: AnimationName 10s ease infinite;
animation: AnimationName 10s ease infinite;
  
}

@-webkit-keyframes AnimationName {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@-moz-keyframes AnimationName {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@keyframes AnimationName { 
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}

.ServiceCardd p{
  font-family: lato;
  margin:0px;
  color: #2A3335;
  font-weight: 400;
  font-size:15px;
  line-height:110%;
  padding-left: 8%;
  padding-right: 8%;
  padding-top:3%;
  padding-bottom:8%;
  border-radius:20px;
  cursor:pointer;
  transition: ease 1s;
  -webkit-transition: ease 1s;
}

.ServiceCardd h3{
  font-family: lato;
  margin:0px;
  color: #2A3335;
  font-weight: 400;
  font-size:20px;
  line-height:90%;
  padding-left: 8%;
  padding-top:8%;
  padding-bottom:3%;
  border-radius:20px;
  cursor:pointer;
  transition: ease 1s;
  -webkit-transition: ease 1s;
}

h1{
  font-family: lato;
  color:white;
  font-weight: 400;
  font-size:35px;
  line-height:90%;
  padding-bottom:140px;
  padding-top:150px;
  position:relative;
  text-align:center;
  
}

.title{
  width:78%;
  margin:auto;
  padding-left:0px;
  padding-bottm:0px;
}

.cardsbuttonz:hover{
  background-color: orange !important;
}

.ServiceCardds{
  width:90%;
  position:relative;
  margin-left:auto;
  margin-right:auto;
  padding-bottom:200px;
  padding-top: 100px;
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
  
}

.ServiceCardd{
  width:22%;
  position:relative;
  background-image: linear-gradient(120deg, #f6d365 0%, #fda085 100%);
  margin:1.13%;
  border-radius:5px;
  transition: 1s;
  -webkit-transition:0.5s;
  cursor:pointer;
    flex: 1 1 auto;
}

.ServiceCardd:hover{
  transform: scale(1.05);
  box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.729);
  border: 5px solid #FFE5CF;
}

@media screen and (max-width: 1200px){
  .ServiceCardd{
  width:30%;
  margin:1.6%;
  }
}

@media screen and (max-width: 800px){
  .ServiceCardd{
  width:45%;
  margin:2.5%;
  }
}

@media screen and (max-width: 500px){
  .ServiceCardd{
  width:98%;
  margin:2%;
  }
}

.ServiceCardd_img{
  width:100%;
  height:200px;
  position:reltive;
  border-radius: 5px 5px 0px 0px;
  background-size: cover;
  background-position:center center; 
  background-color: white;
  transition:1s;
  -webkit-transition:1s;
}

.ServiceCardd1{
   background-image: url(https://img.freepik.com/free-photo/high-view-arrow-standing-coins_23-2148546941.jpg?ga=GA1.1.173630586.1722529162&semt=sph);
}
.ServiceCardd2{
   background-image: url(https://img.freepik.com/free-photo/financial-charts-with-pencil-table_1232-3120.jpg?t=st=1722537680~exp=1722541280~hmac=409ad43879970e25a43f97fee96acbeda01aa70ad9c7cdaf82c4f0e3e5d670d6&w=1800);
}
.ServiceCardd3{
   background-image: url(https://img.freepik.com/free-photo/pile-coins-arrow-pointing-up_23-2148546825.jpg?ga=GA1.1.173630586.1722529162&semt=sph);
}
.ServiceCardd4{
   background-image: url(https://img.freepik.com/free-photo/fraud-word-with-letter-magnifying-glass-flat-lay_23-2148546897.jpg?ga=GA1.1.173630586.1722529162&semt=sph);
}
.ServiceCardd5{
   background-image: url(https://img.freepik.com/free-photo/business-commerce_53876-92772.jpg?t=st=1722538176~exp=1722541776~hmac=601b72d7c7c71a0c329ee1697c5e796cac88ef130bb35ac8a1a341967561bc86&w=1060);
}
.ServiceCardd6{
   background-image: url(http://www.festabimbianimazione.it/media/k2/galleries/136/Colori%20Fluo%20festa%20Arezzo%207.JPG);
}

.ServiceCardd7{
  background-image: url(https://img.freepik.com/free-photo/social-media-communication-concept_23-2150822060.jpg?t=st=1737712072~exp=1737715672~hmac=0d19e6705e42affe4a46111bf95c7fa609ca7612fc8544e4ef1af1a03ef3e545&w=826)
}

.ServiceCardd8{
  background-image: url(https://img.freepik.com/free-photo/social-media-communication-concept_23-2150822056.jpg?t=st=1737711921~exp=1737715521~hmac=e04cd8c62b0053c8df1b518c86f2855bf7516db6fc7c5b6332bf3f1317ca9e01&w=1480)
}

.ServiceCardd10{
  background-image: url(https://img.freepik.com/free-photo/texture-yellow-rock-background_181624-37652.jpg?t=st=1726914998~exp=1726918598~hmac=5b5ceddaf172f992ffff5368b1884d5296aefd65f75a4f328cd55d9adbdeaba5&w=1800);
}
.ServiceCardd11{
  
}

.line{
  height:2px;
  width:84%;
  margin:auto;
  background-color: #FF885B;
}

.title input{
  background: transparent;
  border:1px solid white;
  padding-top:10px;
  padding-bottom:10px;
  padding-left:20px;
  display:inline;
  font-family: lato;
  color: white;
  font-size:18px;
  line-height:90%;
  border-radius:30px;
  width:150px;
  float:right;
  margin-top:30px;
}

.title textarea:focus, input:focus{
    outline: none;
}

.plus{
  width:20px;
  height:20px;
  background: transparent;
  border-radius: 50%;
  position: absolute;
  border: 2px solid white;
  top:10px;
  right:10px;
  transition: 1s;
  -webkit-transition: 1s;
}

.plus:hover{
  transform: scale(1.1) rotate(90deg);
}

.plus p{
  padding: 0px !important;
  display:inline;
  margin:0px;
  line-height:50%;
  left:50%;
  top:45.3%;
  transform:translate(-50%,-50%);
  position:absolute;
  color: white;
}

.zoomed{
  width:100%;
}

.background-video{
 z-index: -10;
 width: 100vw;
 height: 999px;
 position: absolute;
 overflow: hidden;
 top: 0;

 
 
  /* position: fixed; optional depending on what you want to do in your app */
  
}  

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 180vh !important;
  background: rgba(0, 0, 0, 0.6); /* Dark overlay with 50% opacity */
  z-index: -1; /* Ensure the overlay is behind the content but above the video */
}