
  .fix {
    overflow: hidden;
  }
  .containerrr {
    padding: 10px 70px 70px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
  }
  /* img-pop */
  .img-pop {
    position: relative;
    width: 480px;
    margin: 0 20px 20px 0;
  }
  
  img {
    width: 100%;
    max-width: 480px;
  }
  
  .btn {
    font-size: 50px;
    text-decoration: none;
    height: 100px;
    width: 100px;
    background: #179f5d;
    color: #fff;
    line-height: 100px;
    text-align: center;
    border-radius: 50%;
    transition: all 0.3s;
  }
  
  .btn:hover {
    background: white !important;
    color: black !important;
  }
  
  .img-pop a {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
  }
  
  /* video 2 center using margin auto*/
  
  .video2 {
    position: relative;
    width: 480px;
  }
  
  .video2 a {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 80px;
    height: 80px;
  }
  .btn2 img {
    width: 80px;
    transition: all 0.3s;
  }
  
  .btn2 img:hover {
    width: 90px;
  }
  
  /* video 3 using flex */
  
  .video3 {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .video3 a {
    position: absolute;
  }
  
  .btn3 img {
    width: 80px;
    transition: all 0.3s;
  }
  .btn3 img:hover {
    width: 150px;
  }
  
  /* image hover */
  .img-hover {
    transition: all 0.5s;
  }
  .img-hover:hover {
    -moz-transform: scale(1.2) rotate(2deg);
    -webkit-transform: scale(1.2) rotate(2deg);
    -o-transform: scale(1.2) rotate(2deg);
    -ms-transform: scale(1.2) rotate(2deg);
    transform: scale(1.2) rotate(2deg);
  }
  
  .popup{
    display: none;
  }

  .activePopup{
    /* position: relative; */
    /* display: block;
    position: absolute; */
    width: 100%;
    max-width: 480px;
  }

  /* megajoule Card */

.Megajoulecard:after {
  content: "";
  display: table;
  clear: both;
}

.Megajoulecard {
  width: 90% !important;
  /* background-color: white; */
  margin: 60px auto;
  padding: 10px;
}

.img {
  width: 80%;
  /* height: 250px; */
}

.h1,
.h2 {
  margin: 0;
}

.item {
  float: left;
  width: 50%;
  box-sizing: border-box;
  padding: 0px 10px;
}

.h1 {
  font-size: 2.5rem;
  font-family: 'Coda';
  text-align: center;
  color: #2e3849;
}

.h2 {
  font-family: 'Lato';
  color: #8f9491;
  text-transform: uppercase;
  text-align: center;
  font-size: 1.8rem;
}

.p {
  font-family: 'Open Sans';
  color: black;
  font-size: 13px;
}

.open {
  font-family: 'Open Sans';
  color: #8f9491;
  font-size: 0.8rem;
}

#price1 {
  display: block;
  font-family: 'Lato';
  text-align: center;
  font-size: 1.4rem;
  font-weight: bold;
}

#green-arrow a {
  text-decoration: none;
  display: block;
  font-size: 2rem;
  color: #38e4ae;
  padding-left: 10px;
}

.p {
  margin-top: 5px;
}

.stock {
  font-family: 'Lato';
  font-weight: bold;
  color: #2e3849;
}

.fa-star {
  color: #edc368;
}

#grey,
.arrow {
  color: #8f9491;
}

.arrow {
  font-size: 2.5rem;
  font-family: 'Open Sans';
  display: inline !important;
  margin-bottom: 0;
  background-color: white;
  border: 0;
}

#number {
  font-size: 1.8rem;
  font-family: 'Lato';
}

#quantity {
  font-size: 1.3rem;
  font-family: 'Lato';
}

#cart {
  background-color:orange !important;
  border: 0;
  color: white;
  font-size: 15px;
  padding: 6px;
}

/* MODAL CSS */
.open-modal-btn {
  background: #fff;
  padding: 18px 25px;
  border-radius: 20px;
  font-weight: 500;
  border: 0;
  outline: none;
  font-size: 22px;
  cursor: pointer;
  line-height: 23px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
  transition: all 0.1s ease-in-out;
}

.open-modal-btn:hover {
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.1);
  transform: translateY(-1px);
}

.open-modal-btn:active {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  transform: translateY(0);
}

.overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  transition: all 0.5s ease-in-out;
}

.modalll {
  background-color: #272829;
  width: 98%;
  max-width: 98%;
  max-height: 100vh;
  overflow: scroll;
  padding: 40px 30px;
  border-radius: 10px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
  transition: all 0.5s ease-in-out;
}

.modalll img {
  border-radius: 5px;
  width: 100%;
}

.modalll svg {
  position: absolute;
  right: 10px;
  top: 10px;
  height: 24px;
  width: 24px;
  opacity: 0.3;
  cursor: pointer;
}

.modalll svg:hover {
  opacity: 1;
}

.overlay.animated {
  opacity: 0;
  visibility: hidden;
}

.overlay.animated.show {
  opacity: 1;
  visibility: visible;
}

.overlay.animated.show .modalll {
  opacity: 1;
  visibility: visible;
}

.overlay.animated.show .modalll {
  transform: translate(-50%, -50%);
}

/* Youtube Video */
.videocard {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  background-color: #fff;
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.2);
}
@media (min-width: 480px) {
  .videocard {
    width: 70%;
    max-width: 760px;
  }
}
.card__sharing {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 30px 0 15px;
}
.card__social {
  display: flex;
}
.card__social-icon {
  margin-left: 1.5em;
  font-size: 1.3rem;
}
.videocard__info {
  margin: 0 auto;
  max-width: 500px;
  padding: 6% 8%;
}
@media (min-width: 768px) {
  .videocard__info {
    padding: 4% 10%;
  }
}
.videocard__author {
  font-weight: 300;
  font-size: 1.05rem;
}
.videocard__title {
  margin: 0.5em 0 0.7em;
  font-size: 1.3rem;
  font-weight: 500;
}
.videocard__description {
  font-weight: 300;
  line-height: 1.4;
  color: #828282;
}
.videocard__timestamp {
  display: flex;
  color: #828282;
  align-items: center;
  font-size: 1rem;
  line-height: 1;
  color: #d2d1d1;
  letter-spacing: 1px;
}
.card__timestamp-icon {
  margin-right: 0.5em;
  font-size: 1.3em;
  line-height: 1;
}

.video {
  width: 100%;
  background: url("https://img.freepik.com/free-photo/beautiful-landscape-with-small-village_23-2149721819.jpg?t=st=1710936542~exp=1710940142~hmac=179180ae134f05134f7e2523abd1c4f0bdf2c8e1f42f27674a335e78d6ec577c&w=2000") center/cover no-repeat;
}
.video__container {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 56.25%;
}
.video__placeholder {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("https://img.freepik.com/free-photo/beautiful-landscape-with-small-village_23-2149721819.jpg?t=st=1710936542~exp=1710940142~hmac=179180ae134f05134f7e2523abd1c4f0bdf2c8e1f42f27674a335e78d6ec577c&w=2000") center/cover no-repeat;
}
.video__embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* EXPANDABLE CARDS */
/* Mohave & Open Sans Fonts */
@import url("https://fonts.googleapis.com/css2?family=Mohave:wght@300;700&family=Open+Sans&display=swap");
/* Fonts & Colors */
:root {
  --title-font: "Mohave", sans-serif;
  --body-font: "Open Sans", sans-serif;
  --orange: hsl(0, 100%, 65%);
  --lighter-orange: hsl(0, 100%, 77%);
  --grey: hsl(197, 3%, 46%);
  --dark: hsl(0, 0%, 16%);
  --white: hsl(0, 0%, 100%);
}

/* General */
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

::selection {
  background-color: var(--lighter-orange);
  color: var(--white);
}


p {
  margin: 0;
}
p:not(:last-child) {
  margin-bottom: 14px;
}

ul {
  list-style: none;
}

.expandableImage {
  width: 100%;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

/* Layout */
.main {
  max-width: 1200px;
  margin: 0 auto;
}

/* Typography */
.card_title {
  font-family: var(--title-font);
  font-size: 28px;
  line-height: 24px;
  text-transform: uppercase;
}

.card_text p {
  font-family: var(--body-font);
  font-size: 14px;
  line-height: 24px;
}

/* Utilities */
.orange {
  color: var(--orange);
}

.note-sm {
  color: var(--grey);
  font-family: var(--title-font);
  font-size: 14px;
}

/* Components */
.expandablecards {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  margin: 50px 0;
}
.expandablecards_item {
  display: flex;
  padding: 1rem;
}

.expandablecard {
  background-color: var(--white);
  border-radius: 0.25rem;
  box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.expandablecard_content {
  padding: 26px 16px 16px;
}
.expandablecard_title {
  margin-bottom: 5px;
}
.expandablecard_text {
  margin: 16px 0;
}
.expandablecard_btn {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 12px;
  text-transform: capitalize;
  font-family: var(--body-font);
  float: right;
}
.expandablecard_btn:hover {
  text-decoration: underline;
}

/* Media queries */
@media (min-width: 40rem) {
  .expandablecards_item {
    width: 50%;
  }
}
@media (min-width: 56rem) {
  .expandablecards_item {
    width: 33.3333%;
  }
}

/* GROWTH SEEKING ASSET APPLICATION */
.gsacontainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 800px;
  background: #fff;
  margin: 40px auto;
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
}
.gsacontainer .gsaitem {
  flex-basis: 25%;
  max-width: 25%;
  text-align: center;
  position: relative;
  padding: 0.5rem;
  box-sizing: border-box;
}
.gsacontainer .gsaitem a {
  text-decoration: none;
  color: #333;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.gsacontainer .gsaitem a:before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  width: 0px;
  height: 0px;
  opacity: 0;
  border: 2px solid #007bfc;
  border-bottom: none;
  border-radius: 8px 8px 0 0;
  transition: width 0.15s ease-out 0.15s, left 0.15s ease-out 0.15s, height 0.15s ease-out, opacity ease-out 0.3s;
}
.gsacontainer .gsaitem a:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0px;
  height: 0px;
  opacity: 0;
  border: 2px solid #007bfc;
  border-top: none;
  border-radius: 0 0 8px 8px;
  transition: width 0.15s ease-out 0.15s, left 0.15s ease-out 0.15s, height 0.15s ease-out, opacity ease-out 0.3s;
}
.gsacontainer .gsaitem-image {
  padding: 2.4rem;
  height: 4rem;
  width: 4rem;
}
.gsacontainer .gsaitem-text {
  font-size: 1rem;
  font-size: 1.2rem;
  font-weight: 700;
  color: #555;
  line-height: 1rem;
  transition: transform ease 0.4s, opacity ease 0.4s;
  opacity: 1;
}
.gsacontainer .gsaitem-link-desc {
  font-size: 1rem;
  font-size: 1.2rem;
  font-weight: 700;
  color: #555;
  line-height: 1rem;
  transition: transform ease 0.4s, opacity ease 0.4s;
  opacity: 0;
}
.gsacontainer .gsaitem a:hover {
  /*
  &:before{
    opacity: 1;
    width: calc(50% - 1px);
    height: calc(100% - 2px);
  }

  &:after{
    opacity: 1;
    width: calc(50% - 1px);
    height: calc(100% - 2px);
  }

  */
}
.gsacontainer .gsaitem a:hover .gsaitem-text {
  transform: translatey(-1rem);
  opacity: 0;
}
.gsacontainer .gsaitem a:hover .gsaitem-link-desc {
  transform: translatey(-1rem);
  opacity: 1;
}
.gsacontainer .gsaitem a:hover:before {
  width: 100%;
  height: calc(100% - 10px);
  left: 0px;
  opacity: 1;
  transition: width 0.15s ease-out, left 0.15s ease-out, height 0.15s ease-out 0.15s, opacity ease-out 0.15s;
}
.gsacontainer .gsaitem a:hover:after {
  width: 100%;
  height: 10px;
  left: 0px;
  opacity: 1;
  transition: width 0.15s ease-out, left 0.15s ease-out, height 0.15s ease-out 0.15s, opacity ease-out 0.15s;
}