
/**
 * ----------------------------------------
 * animation wobble-hor-bottom
 * ----------------------------------------
 */

.wobble-hor-bottom {
	-webkit-animation: wobble-hor-bottom 6s infinite both;
	        animation: wobble-hor-bottom 6s infinite both;
}
 @-webkit-keyframes wobble-hor-bottom {
    0%,
    100% {
      -webkit-transform: translateX(0%);
              transform: translateX(0%);
      -webkit-transform-origin: 50% 50%;
              transform-origin: 50% 50%;
    }
    15% {
      -webkit-transform: translateX(-30px) rotate(-6deg);
              transform: translateX(-30px) rotate(-6deg);
    }
    30% {
      -webkit-transform: translateX(15px) rotate(6deg);
              transform: translateX(15px) rotate(6deg);
    }
    45% {
      -webkit-transform: translateX(-15px) rotate(-3.6deg);
              transform: translateX(-15px) rotate(-3.6deg);
    }
    60% {
      -webkit-transform: translateX(9px) rotate(2.4deg);
              transform: translateX(9px) rotate(2.4deg);
    }
    75% {
      -webkit-transform: translateX(-6px) rotate(-1.2deg);
              transform: translateX(-6px) rotate(-1.2deg);
    }
  }
  @keyframes wobble-hor-bottom {
    0%,
    100% {
      -webkit-transform: translateX(0%);
              transform: translateX(0%);
      -webkit-transform-origin: 50% 50%;
              transform-origin: 50% 50%;
    }
    15% {
      -webkit-transform: translateX(-30px) rotate(-6deg);
              transform: translateX(-30px) rotate(-6deg);
    }
    30% {
      -webkit-transform: translateX(15px) rotate(6deg);
              transform: translateX(15px) rotate(6deg);
    }
    45% {
      -webkit-transform: translateX(-15px) rotate(-3.6deg);
              transform: translateX(-15px) rotate(-3.6deg);
    }
    60% {
      -webkit-transform: translateX(9px) rotate(2.4deg);
              transform: translateX(9px) rotate(2.4deg);
    }
    75% {
      -webkit-transform: translateX(-6px) rotate(-1.2deg);
              transform: translateX(-6px) rotate(-1.2deg);
    }
  }

  /**
 * ----------------------------------------
 * end animation wobble-hor-bottom
 * ----------------------------------------
 */


/**
 * ----------------------------------------
 * animation slide-in-right
 * ----------------------------------------
 */

 .slide-in-right {
	-webkit-animation: slide-in-right 5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-right 5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
@-webkit-keyframes slide-in-right {
        0% {
          -webkit-transform: translateX(1000px);
                  transform: translateX(1000px);
          opacity: 0;
        }
        100% {
          -webkit-transform: translateX(0);
                  transform: translateX(0);
          opacity: 1;
        }
      }
      @keyframes slide-in-right {
        0% {
          -webkit-transform: translateX(1000px);
                  transform: translateX(1000px);
          opacity: 0;
        }
        100% {
          -webkit-transform: translateX(0);
                  transform: translateX(0);
          opacity: 1;
        }
      }

/**
 * ----------------------------------------
 * animation slide-in-right
 * ----------------------------------------
 */


/**
 * ----------------------------------------
 * animation slide-in-elliptic-top-fwd
 * ----------------------------------------
 */

 .slide-in-elliptic-top-fwd {
	-webkit-animation: slide-in-elliptic-top-fwd 2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-elliptic-top-fwd 2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
 @-webkit-keyframes slide-in-elliptic-top-fwd {
        0% {
          -webkit-transform: translateY(-600px) rotateX(-30deg) scale(0);
                  transform: translateY(-600px) rotateX(-30deg) scale(0);
          -webkit-transform-origin: 50% 100%;
                  transform-origin: 50% 100%;
          opacity: 0;
        }
        100% {
          -webkit-transform: translateY(0) rotateX(0) scale(1);
                  transform: translateY(0) rotateX(0) scale(1);
          -webkit-transform-origin: 50% 1400px;
                  transform-origin: 50% 1400px;
          opacity: 1;
        }
      }
      @keyframes slide-in-elliptic-top-fwd {
        0% {
          -webkit-transform: translateY(-600px) rotateX(-30deg) scale(0);
                  transform: translateY(-600px) rotateX(-30deg) scale(0);
          -webkit-transform-origin: 50% 100%;
                  transform-origin: 50% 100%;
          opacity: 0;
        }
        100% {
          -webkit-transform: translateY(0) rotateX(0) scale(1);
                  transform: translateY(0) rotateX(0) scale(1);
          -webkit-transform-origin: 50% 1400px;
                  transform-origin: 50% 1400px;
          opacity: 1;
        }
      }

/**
 * ----------------------------------------
 * animation slide-in-elliptic-top-fwd
 * ----------------------------------------
 */