.bounce-top {
	-webkit-animation: bounce-top 10s cubic-bezier(0.390, 0.575, 0.565, 1.000) infinite both;
	        animation: bounce-top 10s cubic-bezier(0.390, 0.575, 0.565, 1.000) infinite both;
}
/* ----------------------------------------------
 * Generated by Animista on 2023-4-18 17:3:6
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation bounce-top
 * ----------------------------------------
 */
 @-webkit-keyframes bounce-top {
    0% {
      -webkit-transform: translateY(-45px);
              transform: translateY(-45px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
      opacity: 1;
    }
    24% {
      opacity: 1;
    }
    40% {
      -webkit-transform: translateY(-24px);
              transform: translateY(-24px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    65% {
      -webkit-transform: translateY(-12px);
              transform: translateY(-12px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    82% {
      -webkit-transform: translateY(-6px);
              transform: translateY(-6px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    93% {
      -webkit-transform: translateY(-4px);
              transform: translateY(-4px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    25%,
    55%,
    75%,
    87% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    100% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
      opacity: 1;
    }
  }
  @keyframes bounce-top {
    0% {
      -webkit-transform: translateY(-45px);
              transform: translateY(-45px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
      opacity: 1;
    }
    24% {
      opacity: 1;
    }
    40% {
      -webkit-transform: translateY(-24px);
              transform: translateY(-24px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    65% {
      -webkit-transform: translateY(-12px);
              transform: translateY(-12px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    82% {
      -webkit-transform: translateY(-6px);
              transform: translateY(-6px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    93% {
      -webkit-transform: translateY(-4px);
              transform: translateY(-4px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    25%,
    55%,
    75%,
    87% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    100% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
      opacity: 1;
    }
  }
  

/* ----------------------------------------------
 * Generated by Animista on 2023-1-4 21:23:15
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation heartbeat
 * ----------------------------------------
 */
 @-webkit-keyframes heartbeat {
    from {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: center center;
              transform-origin: center center;
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    10% {
      -webkit-transform: scale(0.91);
              transform: scale(0.91);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    17% {
      -webkit-transform: scale(0.98);
              transform: scale(0.98);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    33% {
      -webkit-transform: scale(0.87);
              transform: scale(0.87);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    45% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
  }
  @keyframes heartbeat {
    from {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: center center;
              transform-origin: center center;
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    10% {
      -webkit-transform: scale(0.91);
              transform: scale(0.91);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    17% {
      -webkit-transform: scale(0.98);
              transform: scale(0.98);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    33% {
      -webkit-transform: scale(0.87);
              transform: scale(0.87);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    45% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
  }
  
.gpt3__header{
    display: flex;
}

.gpt3__header-content{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin-right: 5rem;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.gpt3__header-content{
        /* width: 200px;
        height: 200px;
        margin: auto; */
        /* background: url("//blog.codepen.io/wp-content/uploads/2012/06/Button-White-Large.png") no-repeat 50%/70% rgba(0, 0, 0, 0.1); */
        color: #000000;
        background: radial-gradient( circle farthest-corner at -8.9% 51.2%,  rgb(152, 152, 152) 0%, rgb(183, 182, 182) 15.9%, rgb(214, 211, 208) 15.9%, rgb(143, 142, 142) 24.4%, rgb(249, 246, 246) 24.5%, rgb(255, 255, 255) 66% );
        box-shadow: rgba(48, 47, 36, 0.885) 0px 3px 6px, rgba(80, 79, 61, 0.775) 0px 3px 6px;
      }
      .gpt3__header-content::before, .gpt3__header-content::after {
        content: "";
        /* z-index: 211; */
        margin: 5%;
        box-shadow: inset 0 0 0 2px;
        animation: clipMe 8s linear infinite;
      }
      .gpt3__header-content::before {
        animation-delay: -4s;
      }
      .gpt3__header-content:hover::after, .gpt3__header-content:hover::before {
        background-color: rgba(255, 0, 0, 0.3);
      }
      
      @keyframes clipMe {
        0%, 100% {
          clip: rect(0px, 220px, 2px, 0px);
        }
        25% {
          clip: rect(0px, 2px, 220px, 0px);
        }
        50% {
          clip: rect(218px, 220px, 220px, 0px);
        }
        75% {
          clip: rect(0px, 220px, 220px, 218px);
        }
      }

.gpt3__header-content h1{
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 62px;
    line-height: 75px;
    letter-spacing: -0.04em;
}

.gpt3__header-content p{
    font-family: var(--font-family);
    font-size: 20px;
    font-style: normal;
    line-height: 27px;
    color: rgb(92, 92, 92);
    margin-top: 1.5rem;
}

.gpt3__header-content__input{
    width: 100%;
    margin: 2rem 0 1rem;
    display: flex;
}

.gpt3__header-content__input input{
    flex: 2;
    width: 100%;
    min-height: 50px;
    background: var(--color-footer);
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    font-family: var(--font-family);
    font-size: 20px;
    line-height: 27px;
    border: 2px solid var(--color-footer);
    padding: 0 1rem;
    outline: none;
    color: black;
}

.gpt3__header-content__input button{
    flex: 0.6;
    width: 100%;
    min-height: 50px;
    /* background: #ff4820; */
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    font-family: var(--font-family);
    font-size: 20px;
    font-weight: bold;
    padding: 0.1rem;
    line-height: 27px;
    color: #fff;
    cursor: pointer;
    outline: none;
    border: none;
}

.gpt3__header-content__people{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 2rem;
}

.gpt3__header-content__people img{
    width: 181.79px;
    height: 38px;
}

.gpt3__header-content__people p {
  margin: 0 0 0 1rem;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 12px;
  line-height: 38px;
  color: #fff;
  text-align: center;
}

.gpt3__header-image{
    flex: 1;
    justify-content: center;
    align-items: center;
}

.gpt3__header-image .headImg{
    max-width: 100% !important;
    height: auto;
}

@media screen and (max-width: 1050px){
    .gpt3__header{
        flex-direction: column;
    }

    .gpt3__header-content{
        margin: 0 0 3rem;
    }
}

@media screen and (max-width: 650px){
    .gpt3__header h1{
       font-size: 48px;
       line-height: 60px;
    }

    .gpt3__header p{
        font-size: 16px;
        line-height: 24px;
     }

     .gpt3__header-content__people{
        flex-direction: column;
     }
     .gpt3__header-content__people p{
        margin: 0;
     }

     .gpt3__header-content__input input,
     .gpt3__header-content__input button
     {
        font-size: 16px;
        line-height: 24px;
     }
}

@media screen and (max-width: 490px){
    .gpt3__header h1{
        font-size: 36px;
        line-height: 48px;
     }
 
     .gpt3__header p{
         font-size: 14px;
         line-height: 24px;
      }

      .gpt3__header-content__input input,
     .gpt3__header-content__input button
     {
        font-size: 12px;
        line-height: 16px;
     }
}

.wa-float-img-circle {
    width: 90px; height: 90px; bottom: 95px; right: 20px; border-radius: 100%; position: fixed; z-index: 99999; display: flex; transition: all .3s; align-items: center; justify-content: center; background: #25D366; } 
    .wa-float-img-circle img { position: relative; } .wa-float-img-circle:before { position: absolute; content: ''; background-color: #25D366; width: 70px; height: 70px; bottom: -7px; right: -7px; border-radius: 100%; animation: wa-float-circle-fill-anim 2.3s infinite ease-in-out; transform-origin: center; opacity: .2; } .wa-float-img-circle:hover{ box-shadow: 0px 3px 16px #24af588a; } .wa-float-img-circle:focus{ box-shadow: 0px 0 0 3px #25d36645; } .wa-float-img-circle:hover:before, .wa-float-img-circle:focus:before{ display: none; } @keyframes wa-float-circle-fill-anim { 0% { transform: rotate(0deg) scale(0.7) skew(1deg); } 50% { transform: rotate(0deg) scale(1) skew(1deg); } 100% { transform: rotate(0deg) scale(0.7) skew(1deg); } }

    .heartbeat {
        -webkit-animation: heartbeat 1.5s ease-in-out infinite both;
                animation: heartbeat 1.5s ease-in-out infinite both;
    }