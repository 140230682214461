/* 
** Layout, Text & Colors 
*/

/* body {
    background: #150f21;
    font-size: 18px;
  } */

/* p {
    line-height: 1.5;
} */

/* Cards */
/* .card-column {
    width: 50%;
    float: left;
    padding: 4%;
    box-sizing: border-box;
}

.column-1 {
    padding-top: 100px;
}

.cardgrowth {
    width: 30%;
    max-width: 40%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    background: #EB5160;
    color: #fff;
    cursor: pointer;
    margin-bottom: 60px;
}

.borderr {
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 6px;
    border: 1px solid #fff;
    opacity: 0.5;
    left: -6px;
    top: -6px;
}

.cardgrowth h1 {
    position: relative;
    padding: 190px 0px 100px 10px;
    width: 90%;
}

.cardgrowth>img {
    width: 60%;
    position: absolute;
    top: -6%;
    left: -6%;
}

.card-color-0 {
    background-color: #EB5160;
}

.card-color-1 {
    background-color: #8F3985;
}

.card-color-2 {
    background-color: #8DAA91;
}

.card-color-3 {
    background-color: #888DA7;
} */

/* The cover (expanding background) */
/* .coverr {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #EB5160;
    z-index: 9999;
} */

/* The open page content */
/* .open-contentt {
    width: 100%;
    height: 100%;
    z-index: 10000;
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0;
    pointer-events: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.open-contentt img {
    position: relative;
    width: 90%;
    margin-left: 3%;
    margin-top: 20px;
    z-index: 5;
}

.open-contentt .textt {
    background: #fff;
    margin-top: -56%;
    padding: 60% 5% 5% 5%;
    width: 95%;
    margin-left: 5%;
    margin-bottom: 5%;
}

.open-contentt .textt h1,
.open-contentt .textt p {
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.close-contentt {
    display: block;
    position: absolute;
    right: 12px;
    top: 12px;
    width: 30px;
    height: 30px;
}

.close-contentt span {
    background: #222;
    width: 30px;
    height: 6px;
    display: block;
    position: absolute;
    top: 14px;
}

.x-1 {
    transform: rotate(45deg);
}

.x-2 {
    transform: rotate(-45deg);
} */

/* 
  ** Transitions
  */

/* .cardgrowth {
    transition: opacity 200ms linear 320ms, transform 200ms ease-out 320ms;
}

.borderr {
    transition: opacity 200ms linear, transform 200ms ease-out;
}

.cardgrowth img {
    transition: opacity 200ms linear 0ms, transform 200ms ease-in 0ms;
}

.cardgrowth h1 {
    transform: translate3d(20%, 0px, 0px);
    transition: opacity 200ms linear 120ms, transform 200ms ease-in 120ms;
} */

/* Clicked card */
/* .cardgrowth.clicked img {
    transform: translate3d(0px, -40px, 0px);
    opacity: 0;
}

.cardgrowth.clicked .borderr {
    opacity: 0;
    transform: scale(1.3);
}

.cardgrowth.out,
.cardgrowth.out img {
    transform: translate3d(0px, -40px, 0px);
    opacity: 0;
}

.cardgrowth.out h1,
.cardgrowth.clicked h1 {
    transform: translate3d(20%, -40px, 0px);
    opacity: 0;
}

.coverr {
    transition: transform 300ms ease-in-out;
}

.open-contentt {
    transition: opacity 200ms linear 0ms;
}

.open-contentt.open {
    opacity: 1;
    pointer-events: all;
    transition-delay: 1000ms;
} */

/* 
  ** Media Queries
  */

/* @media screen and (max-width: 600px) {
    .card-column {
        width: 90%;
    }

    .column-1 {
        padding-top: 0px;
    }

    .open-contentt img {
        margin-top: 40px;
    }
} */

.gallery {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin: 50px 0;
  }
  
  .growthcontainer {
    width: 250px;
    margin: 15px;
    box-sizing: border-box;
    float: left;
    text-align: center;
    border-radius: 20px;
    cursor: pointer;
    padding-top: 10px;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    transition: 4s;
    background: #f2f2f2;
  }
  
  .content:hover {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    transform: translate(0px -8px);
  }
  
  img {
    width: 250px;
    /* height: 280px; */
    text-align: center;
    margin: 0 auto;
    display: block;
    margin-top: -15px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    obect-fit: cover;
  }
  
  p {
    color: #b2bec3;
    padding-top: 0 8px;
  }
  
  h6 {
    font-size: 26px;
    text-align: center;
    color: #222f3e;
    margin: 0;
  }
  
  ul {
    list-style: none;
    /* display: flex; */
    justify-content: center;
    align-items: center;
    padding: 0;
  }
  
  li {
    padding: 5px;
  }
  
  .fa {
    font-size: 26px;
    transition: 4s;
  }
  
  .checked {
    color: #ff9f43;
  }
  
  .fa:hover {
    transform: scale(1.3);
    transition: 0.6s;
  }
  
  button {
    text-align: center;
    font-size: 24px;
    color: #fff;
    width: 100%;
    padding: 15px;
    border: 0;
    outline: none;
    cursor: pointer;
    margin-top: 5px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
  }
  
  .buy-1 {
    background-color: #2183a2;
  }
  
  .buy-2 {
    background-color: #3b3e6e;
  }
  
  .buy-3 {
    background-color: #0b0b0b;
  }
  
  .buy-4 {
    background-color: #ff9f43;
  }
  
  @media (max-width: 1000px) {
    .content {
      width: 45%;
    }
  }
  
  @media (max-width: 750px) {
    .content {
      width: 100%;
    }
  }