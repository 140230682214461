.dashcontainer {
  height: 100%;
  margin: 0;
  font-family: kanit;
  font-size: 16px;
  letter-spacing: 0.05rem;
}

.options{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap ;/* Allows items to wrap to the next row */
  gap: 10px; /* Adds space between items */
}
.worldmap {
  width: 100%;
  position: absolute;

  z-index: 0;
}

.assetRow.selected {
  background-image: linear-gradient(120deg, #f6d365 0%, #fda085 100%) !important;
  /* Change background when clicked */
}

.gm-style .gm-style-iw-c {
  background: linear-gradient(to top, #dfe9f3 0%, white 100%) !important;
}

.chat-input {
  width: 100%;
  box-sizing: border-box;
  padding-left: 25px;
  align-self: flex-end;
  display: flex;
  margin-bottom: 15px;
  position: relative;
}

.chat-input textarea {
  font-family: kanit;
  padding: 10px;
  color: #eee;
  background: transparent;
  border: 3px solid orange;
  border-right: 0px;
  text-shadow: 0px 0px 0px #eee;
  -webkit-text-fill-color: transparent;
  font-size: 1rem;
  width: 100%;
  box-sizing: border-box;
  resize: none;
  transition: background .2s ease-out;
}

.submit-icon {
  width: 50px;
  height: 100%;
  cursor: pointer;
  flex-grow: 1;
  background: orangered;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 50px;
  color: #bbbbbf;
  box-sizing: border-box;
  border: 3px solid #333339;
}

textarea::-webkit-input-placeholder {
  color: #68686e;
  text-shadow: none;
  -webkit-text-fill-color: initial;
}

.chat-input textarea:focus {
  outline: none;
  background: transparent;
  background: #222229;
}

.chat-input textarea:hover {
  background: #222229;
  transition: background .2s ease-out;
}

.spacing-adjuster {
  width: 100%;
  flex-grow: 1;
  height: 100%;
}

.draghere {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  color: white;
  font-size: 1.4rem;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  background: transparent;
  border: 2px dashed #58585e;
}

.file-container li:hover {
  background: #3f3f45;
}

.performance-table {
  table-layout: fixed;
  color: #fff;
  width: 100%;
  min-width: 100%;
  border-collapse: collapse;
  box-sizing: border-box;
  font-size: 1.3rem;
  font-weight: 200;
}

.align-right {
  text-align: right;
  float: right;
}

.dashli {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  display: block !important;
  background: linear-gradient(109.6deg, rgba(0, 0, 0, 0.93) 11.2%, rgba(63, 61, 61, 1) 78.9%);
  margin-bottom: 10px;
  cursor: pointer;
}

.performance-table tr:nth-child(odd) {
  background: #515158;
}

.performance-table tr:nth-child(even) {
  background: #48484e;
}

.performance-table tr:nth-child(1) {
  background: #333339;
  font-size: 1.2rem;
}

.dashtable-header {
  background: linear-gradient(to top, #dfe9f3 0%, white 100%);
  margin-top: 30px;
}

.element-header {
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: 15px;
  box-sizing: border-box;
  margin-bottom: 15px;
  width: 100%;
}

.performance-table td {
  padding: 20px 5px 20px 5px;
  color: #fff !important;
  line-height: 1em;
  text-align: right;
}

p {
  margin-top: 0.4rem;
  font-weight: 200;
}




.dashrow.todo {
  background: linear-gradient(109.6deg, rgba(0, 0, 0, 0.93) 11.2%, rgba(63, 61, 61, 1) 78.9%);
}

.table-header.todo {
  background: transparent;
  margin-left: 15px;
  margin-right: 15px;
}

.element-header.social {
  margin-bottom: 0px;
}

.social-element {
  width: 66.6666%;
  flex-flow: column;
  height: 100%;
  display: flex;
}

.todo-element {
  width: 33.3333%;
  height: 100%;
}

.file-container {
  /* background: linear-gradient( 97.3deg,  rgba(25,50,70,0.81) 10.7%, rgba(250, 81, 9, 0.72) 39.5%, rgba(255,192,0,0.81) 69.7% ); */
  background: linear-gradient(109.6deg, rgba(0, 0, 0, 0.93) 11.2%, rgba(63, 61, 61, 1) 78.9%);
  color: #eee;
  border: 15px solid #29292e;
  border-top: 0px;
  height: 400px;
  padding: 25px;
  display: flex;
  flex-flow: column;
  box-sizing: border-box;
}

.dashul {
  list-style: none;
  background: linear-gradient(109.6deg, rgba(0, 0, 0, 0.93) 11.2%, rgba(63, 61, 61, 1) 78.9%);
  margin: 0;
  padding: 0;
}

.jimmy-image {
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  width: 60px;
  background: url('https://pexels.imgix.net/photos/2261/food-man-person-eating.jpg?fit=crop&w=65&h=65');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
}

.anna-image {
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  width: 60px;
  background: url('https://pexels.imgix.net/photos/54204/pexels-photo-54204.jpeg?fit=crop&w=60&h=60');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
}

.chatbox-container {
  margin-left: 25px;
  flex-flow: row;
  margin-top: 20px;
  display: flex;
}

.chatbox-left {
  background: #48484e;
  line-height: 1.2em;
  max-width: 75%;
  min-height: 77px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  padding: 10px;
  box-sizing: border-box;
  font-size: 1rem;
  padding-right: 20px;
  color: #fff;
  font-weight: 200;
}

.username {
  display: block;
  color: #ccc;
}

.username.right {
  text-align: right;
}

.update-number {
  display: inline-block;
  background: #FF8A8A;
  height: 1rem;
  margin-left: 30px;
  color: #ccc;
  font-size: .9rem;
  margin-top: .3rem;
  margin-bottom: .3rem;
  padding: 1px;
  padding-bottom: 8px;
  text-align: center;
  font-weight: 500;
  width: 1.5rem;
  border-radius: 10px;
}

.country-select {
  margin-top: 10px;
  color: black;
  cursor: pointer;
  display: inline-block;
  font-weight: 200;
  letter-spacing: .2rem;
  margin-right: 10px;
  font-size: 1.3rem;
  padding-left: 20px;
  padding-right: 20px;
  border-bottom: 3px solid #44444a;
  position: relative;
  transition: border-color .2s ease-out;
}

.country-select:first-child {
  border-bottom-color: orange;
  margin-left: 0px;
}

.country-select:hover {
  border-color: #FFAD60;
  transition: border-color .2s ease-out;
}

.country-selected {
  margin-top: 10px;
  color: black;
  cursor: pointer;
  display: inline-block;
  font-weight: 200;
  letter-spacing: .2rem;
  margin-right: 10px;
  font-size: 1.3rem;
  padding-left: 20px;
  padding-right: 20px;
  border-bottom: 3px solid #FFAD60;
  position: relative;
  transition: border-color .2s ease-out;
}

th:after {
  width: 50%;
  margin: auto;
  content: '';
  height: 2px;
  background: #ccc;
}

.main-color {
  color: #FFAD60;
}

td.table-desc {
  font-size: 1.3rem;
  text-align: center;
}

.navigation-elm {
  padding-left: 20px;
  box-sizing: border-box;
  color: #ccc;
  display: flex;
  align-items: center;
  height: 60px;
  width: 100%;
  cursor: pointer;
  font-weight: 200;
  letter-spacing: 0.05rem;
  transition: background .1s ease-out;
}

.sub-navigation-elm {
  padding-left: 80px;
  box-sizing: border-box;
  background: rgba(100, 100, 130, 0.1);
  color: #ccc;
  display: flex;
  align-items: center;
  height: 50px;
  width: 100%;
  cursor: pointer;
  font-weight: 200;
  letter-spacing: 0.05rem;
  font-size: .9rem;
  transition: background .1s ease-out;
}

.sub-navigation-elm:hover {
  background: rgba(100, 100, 130, 0.3);
}

.navigation-elm:nth-child(1) {
  background: rgba(100, 100, 130, 0.2);
}

.navigation-elm:hover {
  background: rgba(100, 100, 130, 0.2);
  transition: all .1s ease-out;
}

.navigation-elm:hover .nav-text {
  transform: scale(1.1);
  transition: transform .1s ease-out;
}

.nav-text {
  transition: transform .1s ease-out;
}

.piechart-label {
  width: 70%;
  height: 100%;
  line-height: 1em;
  min-height: 100px;
  color: #eee;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  padding: 0 5% 0 5%;
}

.small {
  font-size: .9rem;
}

.label-heading {
  color: #fff;
  align-self: flex-start;
  font-size: 1.4rem;
  padding: 10px
}

.key-metrics {
  min-height: 80px;
  border-top: 4px solid black;
  min-width: 100px;
  padding: 0 10px 0 10px;
  color: white;
  flex-flow: column;
  line-height: 1em;
  text-align: center;
  background: #3f3f45;
  margin: 60px 60px 20px 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
}

a,
a:active,
a:visited {
  color: inherit;
}

.madeby {
  color: #999;
  position: absolute;
  right: 15px;
  font-size: 1rem;
  top: 15px;
}

.white-border {
  border-top: 4px solid white;
}

.blue-border {
  border-top: 4px solid #5AB3D1;
}

.fa-fw {
  margin-right: 15px;
}

.dashsidebar {
  padding-top: 2%;
  height: 100%;
  left: 0;
  width: 300px;
  display: flex;
  color: #fff;
  flex-flow: column;
  position: fixed;
  background: radial-gradient(circle farthest-corner at -8.9% 51.2%, rgba(255, 124, 0, 1) 0%, rgba(255, 124, 0, 1) 15.9%, rgba(255, 163, 77, 1) 15.9%, rgba(255, 163, 77, 1) 24.4%, rgb(27, 27, 27) 24.5%, rgb(27, 29, 31) 66%);
  z-index: 1;
}

.dashrow {
  display: flex;
  width: 80%;
  margin-left: 300px;
}

.profilerow {
  display: flex;
  width: 98%;
  margin-left: auto;
  margin-right: auto;
}

.stats-table td:last-of-type {
  padding-right: 15px;
}

.stats-table td:first-of-type {
  padding-left: 15px;
}

.tableHead li:first-of-type {
  padding-left: 15px;
}

.tableHead li {
  padding: 20px 5px 20px 5px;
  font-size: 1rem;
  text-align: right;
  margin-right: 5%;
}

.tableHead {
  list-style: none;

}

.stats-table {
  width: 100%;
  height: 500px;
  overflow: auto;
  /* background: #3a3a3f; */
  box-sizing: border-box;
  border: 25px solid #E2EBF4;
  border-right-width: 15px;
}

.piechart-element {
  width: 33.3333%;
  box-sizing: border-box;
  display: flex;
  padding-right: 20px;
  min-height: 100px;
  max-height: 200px;
  justify-content: center;
  align-items: center;
  position: relative;
}

.position-marker {
  content: '';
  position: absolute;
  height: 3vw;
  width: 3vw;
  border-radius: 50%;
  background: hsla(200, 30%, 45%, 0.6);
  top: 20%;
  left: 10%;
  z-index: 4;
  position: relative;
  transition: all .2s ease-out;
}

.position-marker.no2 {
  top: 5%;
  width: 2vw;
  height: 2vw;
  left: 45%;
  z-index: 2;
}

.position-marker.no3 {
  top: 5%;
  width: 2vw;
  height: 2vw;
  left: 18%;
  z-index: 2;
}

.position-marker.no4 {
  top: -5%;
  width: 3vw;
  height: 3vw;
  left: 40%;
  z-index: 2;
}

.position-marker.no5 {
  top: -5%;
  width: 4vw;
  height: 4vw;
  left: 75%;
  z-index: 6;
}

.position-marker.no6 {
  top: 0%;
  width: 1.5vw;
  height: 1.5vw;
  left: 30%;
  z-index: 6;
}

.order-scroll {
  text-align: right;
  cursor: pointer;
  width: 100%;
  padding-right: 5px;
  padding-bottom: 3px;
  color: black;
  border: 15px solid #FEFEFE;
  border-top: 0px;
  box-sizing: border-box;
  transition: background .2s ease-out, color .2s ease-out;
}

.order-scroll:hover {
  color: #fff;
  background: rgba(0, 0, 0, 0.1);
  transition: background .2s ease-out, color .2s ease-out;
}

.order-container {
  height: 600px;
  max-height: 600px;
  /* Adjust the height as needed */
  overflow-y: auto;
  /* Enables vertical scrolling */
  background: linear-gradient(to top, #dfe9f3 0%, white 100%);

}

.position-marker:hover {
  background: hsla(200, 20%, 55%, 0.6);
  transform: scale(1.1);
  transition: all .2s ease-out;
}

.position-marker:after {
  content: '';
  background: hsla(200, 30%, 45%, 0.8);
  height: 33.33%;
  width: 33.33%;
  position: absolute;
  top: 0;
  left: 0;
  margin: auto;
  bottom: 0;
  right: 0;
  border-radius: 50%;
  z-index: 2;
}

.piechart-container {
  width: 30%;
  display: flex;
  position: relative;
}

.piechart-percent {
  position: absolute;
  width: 30%;
  top: 0;
  right: 10px;
  font-size: 1.8vw;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.map-container {
  height: 83vh;
  width: 60vw;
  background-image: url('https://upload.wikimedia.org/wikipedia/commons/a/a0/World_map_with_points.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top;
}

.stats-element {

  background-image: linear-gradient(to top, #dfe9f3 0%, white 100%);

  border-right-width: 0px;
  box-sizing: border-box;
  position: relative;
  /* width: 66.666%; */
}

.order-element {
  color: #100f0f;
  width: 33.333%;
  display: flex;
  flex-flow: column;
  box-sizing: border-box;

  background-image: linear-gradient(to top, #dfe9f3 0%, white 100%);

}

.order-element h1 {
  padding-left: 20px;
  font-size: 2.12rem !important;
  padding-top: 10px;
}

.order-element br {
  line-height: 1em;
}

.highlight {
  color: #FFBB75;
}

.order-element p {
  padding-left: 20px;
  margin-bottom: 10px;
  font-size: 1rem;
  line-height: 1.2em;
}

.order-element p:last-child {
  margin-bottom: 5px;
}

h1 {
  color: #fff;
  font-weight: 200;
  line-height: .9em;
  margin: 0;
  margin-top: .2em;
  font-size: 1.2rem;
}

.order-item {
  background-image: linear-gradient(120deg, #f6d365 0%, #fda085 100%);
  width: 100%;
  /* padding: 10px 0 10px 0; */
  color: #4A4947;
  border: 15px solid #FEFEFE;
  border-top: 0px;
  box-sizing: border-box;
  position: relative;
}

.order-item:after {
  content: '';
  background: #3C3D37;
  height: 100%;
  width: 5px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 5;
}

.order-scroll.up {
  border-bottom: 0px;
}

.order-item.highlight:after {
  content: '';
  background: #FFBB75;
  height: 100%;
  width: 5px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 5;
}

.order-item.hold:after {
  content: '';
  background: #999;
  height: 100%;
  width: 5px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 5;
}

.inactive {
  color: #aaa;
}

.piechart-container canvas {
  margin-top: 10%;
  margin-bottom: 10%;
}

.piechart-element:nth-child(odd) {
  background: #3a3a3f;
}

.piechart-element:nth-child(even) {
  background: #3f3f45;
}

.linechart-element {
  position: relative;
  overflow: hidden;
  transform: scale(1.02);
  height: 40%;
}

.dashcontainer {
  overflow-X: hidden;
  /* background: linear-gradient(97.3deg, rgba(25, 50, 70, 0.81) 10.7%, rgba(250, 81, 9, 0.72) 39.5%, rgba(255, 192, 0, 0.81) 69.7%); */
  box-sizing: border-box;
  width: 100%;
  z-index: 0;
  position: relative;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #555;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #222227;
  max-height: 20p;
}

@media only screen and (max-width: 1280px) {
  html {
    font-size: 14px;
  }

  .position-marker {
    left: 20%;
  }

  .position-marker.no2 {
    left: 45%;
  }

  .position-marker.no3 {
    left: 25%;
  }

  .position-marker.no4 {
    left: 42%;
  }

  .position-marker.no5 {
    left: 70%;
  }

  .position-marker.no6 {
    left: 32%;
  }

  .madeby {
    top: 75px;
  }

  .navigation-elm:nth-child(1) {
    background: #333339;
  }

  .dashsidebar {
    width: 100%;
    flex-flow: row;
    height: 60px;
    font-size: .9rem;
    padding-top: 0px;
    margin-bottom: 40px;
    position: relative;
  }

  .sub-navigation-elm {
    display: none;
  }

  .update-number {
    display: none;
  }

  .dashcontainer {
    padding-top: 60px;
    padding-left: 0px;
  }

  .dashrow {
    display: flex;
    width: 100%;
    margin-left: 0px;
  }
}

@media only screen and (max-width:920px) {
  .text-long {
    display: none;
  }
}

@media only screen and (max-width: 800px) {
  .key-metrics {
    margin-top: 0px;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }

  .dashrow {
    flex-direction: column;
  }

  .piechart-element {
    width: 100%;
  }

  .piechart-label {
    padding-left: 15px;
  }

  .stats-element {
    width: 100%;
  }

  .order-element {
    width: 100%;
  }

  .social-element {
    width: 100%;
  }

  .todo-element {
    width: 100%;
  }

  .map-container {
    height: 60vw;
  }

  .piechart-percent {
    font-size: 8vw;
  }

  .chat-input {
    padding-right: 15px;
    padding-left: 15px;
  }

  .stats-table {
    border-width: 15px;
  }
}

@media only screen and (max-width: 640px) {
  .text-short {
    display: none;
  }

  .navigation-elm {
    font-size: 4.5vw;
    justify-content: center;
    text-align: center;
  }
}