.demo-wrapper {
    padding: 2em .5em;
    width: 100%;
    height:100%;
    perspective: 3300px;
    position: relative;
  }

  .dashboard {
    margin: 0 auto;
    width: 100%;
    padding: 1em;
    transform: translateX(200px);
    opacity:0;
    animation: start 1s ease-out forwards;
  }
  @keyframes start{
    0%{
      transform: translateX(200px);
      opacity:0;
    }
    50%{
      opacity:1;
    }
    100%{
      transform: translateX(0);
      opacity:1;
    }
  }

  .fadeOutback{
    animation: fadeOutBack 0.3s ease-out 1 normal forwards;
   }
   .fadeInForward-1, .fadeInForward-2, .fadeInForward-3 {
     /*remember: in the second animation u have to set the final values reached by the first one*/
     opacity:0;
     transform: translateZ(-5em) scale(0.75);
     animation: fadeInForward .5s cubic-bezier(.03,.93,.43,.77) .4s normal forwards;
   }

   .fadeInForward-2{
     animation-delay: .55s;
   }
   .fadeInForward-3{
     animation-delay: .7s;
   }

   @keyframes fadeOutBack{
     0% {transform: translateX(-2em) scale(1); opacity:1;}
     70% {transform: translateZ(-5em) scale(0.6); opacity:0.5;}
     95% {transform: translateZ(-5em) scale(0.6); opacity:0.5;}
     100% {transform: translateZ(-5em) scale(0); opacity:0;}
   }
   @keyframes fadeInForward{
     0% {transform: translateZ(-5em) scale(0); opacity:0;}
     100% {transform: translateZ(0) scale(1); opacity:1;}
   }

   .r-page {
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: 2em;
    font-weight: 300;
    position: absolute;
    right: 0;
    top: 0;
    left:0;
    bottom:0;
    opacity: 0;
    color: white;
    z-index: 10;
    padding:10px;
    transform-origin: 100% 0%;
    transform: rotateY(-90deg) translateZ(5em)
  }

  .s-page {
    color: white;
    z-index: 10;
    text-align: center;
    font-size: 2em;
    font-weight: 300;
  }
  .page-content{
    overflow-y:auto;
    max-height:100%;
    font-size:.6em;
    padding:.6em;
    text-align:left;
  }

  .s-page, .r-page{
    background-color: white;
    color:black;
  }

  .page-title {
    margin: .25em 0;
    font-weight: 100;
    font-size: 3em;
    text-align:center;
  }

  .close-button {
    font-size: 1.5em;
    width: 1em;
    height: 1em;
    position: absolute;
    top: .75em;
    right: .75em;
    cursor: pointer;
    line-height: .8em;
    text-align: center
  }

   /* opens the r-page type pages*/
   .openpage{ 
    animation: rotatePageInFromRight 1s cubic-bezier(.66,.04,.36,1.03) 1 normal forwards;
  }
  /* closes the r-page type pages */
  .slidePageLeft{
    transform: rotateY(0) translateZ(0) ; opacity:1;
    animation:slidePageLeft .8s ease-out 1 normal forwards;
  }
  /* opens the s-page type pages */
  .slidePageInFromLeft{
    animation: slidePageInFromLeft .8s cubic-bezier(.01,1,.22,.99) 1 0.25s normal forwards;
  }
  /* closes the s-page type pages*/
  .slidePageBackLeft{
    opacity:1; 
    left:0;
    animation: slidePageBackLeft .8s ease-out 1 normal forwards;
  }

  @keyframes rotatePageInFromRight{
    0% {transform:rotateY(-90deg) translateZ(5em);opacity:0}
    30% {opacity:1}
    100% {transform: rotateY(0deg) translateZ(0) ; opacity:1}
  }

  /*When the close-button is clicked, the page slides to the left*/
  /*note that the start of the second animation is the same state as the
  end of the previous one*/

  @keyframes slidePageLeft{
    0% {left:0; transform: rotateY(0deg) translateZ(0) ; opacity:1}
    70% {opacity:1;}
    100% {opacity:0; left:-150%; transform: rotateY(0deg)}
  }
  @keyframes slidePageInFromLeft{
    0% {opacity:0; }
    30% {opacity:1}
    100% {opacity:1; left:0;}
  }
  @keyframes slidePageBackLeft{
    0% {opacity:1; left:0; transform: scale(0.95);}
    10% {transform: scale(0.9);}
    70% {opacity:1;}
    100% {opacity:0; left:-150%;}
  }

  .tile{
    float: left;
    margin: 0 auto 1%;
    color: white;
    font-size: 1.3em;
    text-align: center;
    height: 8em;
    font-weight: 300;
    overflow: hidden;
    cursor: pointer;
    position:relative;
    background-color: #fff;
    color: #333;
    position:relative;
    transition: background-color 0.2s ease-out
  }
  .tile-2xbig{
    height:16.15em;
    width:100%;
  }
  .tile-big {
    width: 100%
  }
  .tile-small {
    width: 49%;
    margin-right: 2%
  }
  .tile-small.last {
    margin-right: 0
  }

  .tile-caption{
    position:absolute;
    z-index:1;
    background-color: #455962;
    color:#fff;
    font-size:1em;
    padding:1em;
    text-align: left;
  }
  .caption-bottom{
    left:0;
    bottom:0;
    right:0;
    height:40%;
  }
  .caption-left{
    left:-100%;
    top:0;
    bottom:0;
    width:40%;
    transition: left .3s linear;
  }
  .tile:hover .caption-left{
    left:0;
  }

  .tile div{
    position:absolute;
    top:0; left:0; right:0; bottom:0;
    width:100%;
    height:100%;
    text-align:center;
    display:table;
    padding:0 1em;
    transition: all .3s ease;
  }
  .tile div p{
    display:table-cell;
    vertical-align:middle;
  }

  /* slide text inside tile up */
            /* 2nd div will be positioned below the bottom of the tile*/
            .slideTextUp div:nth-child(2){
                top:100%;
              }
              /*both divs will be translated up on hover*/
              .slideTextUp:hover div{
                transform: translateY(-100%);
              }
              .tile-1 p{
                font-size:1.3em;
              }

    /* slide text inside tile to the right*/
    .slideTextRight div:first-child{
        left:-100%;
      }
      .slideTextRight:hover div{
        transform: translateX(100%);
      } 

      /* slide text inside tile to the left */
      .slideTextLeft div:nth-child(2){
        left:100%;
      }
      .slideTextLeft:hover div{
        transform: translateX(-100%);
      } 

         /* rotate tile in 3D*/
         .rotate3d{
            /* add a perspective to the tile to activate 3d space inside it*/
            perspective: 800px;
            overflow: visible;
          }
          .faces{
            /* preserve the 3d space in the container wrapping the two "faces" and define a transition */
            transform-style: preserve-3d;
            transition: transform 1s;
          }
          .faces div {
            /* position faces on top of each other */
            display: block;
            position: absolute;
            top:0; left:0; right:0; bottom:0;
            width: 100%;
            height: 100%;
            /* hide backface visibility so that the back of the face is hidden when it's rotated */
            backface-visibility: hidden;
          }

          .rotate3dY:hover .faces:hover{
            transform: rotateY( 180deg );
        }
        .rotate3dX:hover .faces:hover{
            transform: rotateX( 180deg );
        }

        .col1,
            .col2,
            .col3 {
              width: 99%;
              margin: 1em auto
            }
            @media screen and (min-width: 43.75em) {
              .col1,
              .col2,
              .col3 {
                float: left;
                margin-right: 1%;
                width: 49%
              }
              .page-title{
                font-size:2.5em;
              }
              .page-content{
                font-size:1em;
              }
              .close-button{
                font-size:2em;
              }
            }

            @media screen and (min-width: 64em) {
              .col1,
              .col2,
              .col3 {
                float: left;
                margin-right: .5%;
                width: 31%
              }

              .col3 {
                margin-right: 0
              }

              .col1 {
                margin-left: 2em
              }
              .page-title{
                font-size:3.5em;
              }
            }